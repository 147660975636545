import msgId from 'resources/intl';

/** エラー情報 */
export interface ErrorInfo {
  /** エラー詳細 */
  errorDescription: string;
  /** エラーフィールドID */
  errorField?: string;
  /** エラーID */
  errorId: string;
}

/**
 * エラーモデル
 */
export interface ErrorResult {
  /** ステータス */
  status: number;
  /** エラーレスポンス */
  response: ErrorInfo | ErrorInfo[];
}

export interface ErrorMessages {
  [key: string]: string;
}

/**
 * エラーメッセージモデル
 */
export interface ErrorStatusMessages {
  fallback: string | undefined;
  [key: string]: string | undefined;
}

export interface FieldLabels {
  PARAMETER_LENGTH_ERROR?: ErrorStatusMessages;
  PARAMETER_FORMAT_ERROR?: ErrorStatusMessages;
  PARAMETER_DUPLICATION_ERROR?: ErrorStatusMessages;
  PARAMETER_INVALID_CHARACTER_ERROR?: ErrorStatusMessages;
  PARAMETER_REQUIREMENTS_ERROR?: ErrorStatusMessages;
  PARAMETER_REFERENCE_ERROR?: ErrorStatusMessages;
  NOT_FOUND_ERROR?: ErrorStatusMessages;
  NOT_AUTHORIZED_ERROR?: ErrorStatusMessages;
  CODE_MISMATCH_ERROR?: ErrorStatusMessages;
  CODE_EXPAIRED_ERROR?: ErrorStatusMessages;
  fallback: ErrorStatusMessages;
  [key: string]: ErrorStatusMessages | undefined;
}

/**
 * ErrorResult かどうかを判定する。
 * @param arg 判定対象
 * @returns 判定対象がErrorResultの場合true、そうでない場合false
 */
export const isErrorResult = (arg: unknown): arg is ErrorResult =>
  (typeof arg === 'object' &&
    arg !== null &&
    typeof (arg as ErrorResult).status === 'number' &&
    typeof ((arg as ErrorResult).response as ErrorInfo).errorDescription === 'string' &&
    typeof ((arg as ErrorResult).response as ErrorInfo).errorField === 'string' &&
    typeof ((arg as ErrorResult).response as ErrorInfo).errorId === 'string') ||
  Array.isArray((arg as ErrorResult).response as ErrorInfo[]);

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function getErrorResult(error: any): ErrorResult {
  let errorResult: ErrorResult;
  if (isErrorResult(error)) {
    return error;
  } else if (
    error.code === 'NetworkError' ||
    (error.message === 'Network Error' && error.isAxiosError)
  ) {
    // Timeout、実際にはサーバーエラーではないが504 Gateway timeoutで代用
    errorResult = {
      status: 504,
      response: {
        errorDescription: error.message,
        errorField: '',
        errorId: 'NetworkError',
      },
    };
  } else if (error != null && error.response != null && error.response.data != null) {
    if (Array.isArray(error.response.data)) {
      const errorInfos: ErrorInfo[] = error.response.data.map(
        (x: { error_description: string; error_field: string; error_id: string }) => {
          return {
            errorDescription: x.error_description,
            errorField: x.error_field,
            errorId: x.error_id,
          };
        }
      );
      errorResult = {
        status: error.response.status,
        response: errorInfos,
      };
    } else if (error.response.data.errorDescription != null) {
      errorResult = {
        status: error.response.status,
        response: {
          errorDescription: error.response.data.errorDescription,
          errorField: error.response.data.errorField,
          errorId: error.response.data.errorId,
        },
      };
    } else {
      errorResult = {
        status: error.response.status,
        response: {
          errorDescription: error.response.data.error_description,
          errorField: error.response.data.error_field,
          errorId: error.response.data.error_id,
        },
      };
    }
  } else if (error != null && error.code != null) {
    errorResult = {
      status: error.response ? error.response.status : 400,
      response: {
        errorDescription: error.response ? error.response.errorDescription : error.message,
        errorField: '',
        errorId: error.response ? error.response.errorId : error.code,
      },
    };
  } else if (error != null && error.response != null && error.response.status != null) {
    errorResult = {
      status: error.response.status,
      response: {
        errorDescription: error.response.errorDescription,
        errorField: '',
        errorId: error.response.errorId,
      },
    };
  } else {
    errorResult = {
      status: 0,
      response: {
        errorDescription: error.message,
        errorField: '',
        errorId: '',
      },
    };
  }

  return errorResult;
}

export function getErrorMessage(errors: ErrorInfo[], labels: FieldLabels): ErrorMessages {
  const messages: ErrorMessages = {
    authorityId: '',
    userName: '',
    accountGroupId: '',
    mailAddress: '',
    password: '',
    accountGroupName: '',
    accountGroupUrl: '',
    accountGroupLogo: '',
    shipId: '',
    shipName: '',
    propulsionSerialNumbers: '',
    machineId: '',
    machineName: '',
    dataFormatId: '',
    serialNumbers: '',
    cognitoAccountId: '',
    newPassword: '',
    authCode: '',
    accountId: '',
    confirmNewMailAddress: '',
    confirmNewPassword: '',
    memo: '',
  };

  errors.forEach((err) => {
    if (err.errorId && err.errorField) {
      const labelErrorId = labels[err.errorId];
      if (labelErrorId != null) {
        const message = labelErrorId[err.errorField];
        if (message != null) {
          messages[err.errorField] = message;
        } else {
          if (labelErrorId.fallback != null) {
            messages[err.errorField] = labelErrorId.fallback;
          }
        }
      } else {
        if (labels.fallback.fallback != null) {
          messages[err.errorField] = labels.fallback.fallback;
        }
      }
    }
  });

  return messages;
}

export function getErrorMsgIdFromErrorResult(errorResult: ErrorResult): string {
  if (errorResult.status === 401) {
    return msgId.errorMessageAuth;
  } else if (errorResult.status === 500) {
    return msgId.errorMessageServer;
  } else {
    return msgId.errorMessageOther;
  }
}
