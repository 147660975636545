export const AuthorityAdministrators = 1;
export const AuthorityDevelopers = 2;
export const AuthorityOperators = 3;
export const AuthorityAdminUsers = 6;
export const AuthorityUsers = 4;
export const AuthorityGuests = 5;

/**
 * アカウント情報モデル
 */
export interface Account {
  /** アカウントID */
  accountId: number;
  /** CognitoのアカウントID(username) */
  cognitoAccountId: string;
  /** 権限ID */
  authorityId: number;
  /** 権限名 */
  authorityName: string;
  /** アカウントグループID */
  accountGroupId: number;
  /** アカウントグループ名 */
  accountGroupName: string;
  /** メールアドレス */
  mailAddress: string;
  /** メールアドレスが検証済みかどうか */
  mailAddressVerified: boolean;
  /** アカウントステータス */
  accountStatus: string;
  /** ホームパス */
  homePath: string;
  /** 作成日 */
  createdAt: string;
  /** 更新日 */
  updatedAt: string;
}

/**
 * アカウントグループモデル
 */
export interface AccountGroup {
  /** アカウントグループID */
  accountGroupId: number;
  /** アカウントグループ名 */
  accountGroupName: string;
  /** アカウントグループURL */
  accountGroupUrl: string;
  /** アカウントグループURL */
  accountGroupUrlText: string;
  /** アカウントグループロゴURL */
  accountGroupLogoUrl?: string;
  /** 作成日 */
  createdAt: string;
  /** 更新日 */
  updatedAt: string;
}

/**
 * アカウント情報登録更新モデル
 */
export interface AdminAccount {
  /** CognitoアカウントID(username) */
  cognitoAccountId?: string;
  /** 権限ID */
  authorityId?: number;
  /** アカウントグループID */
  accountGroupId?: number;
  /** メールアドレス */
  mailAddress?: string;
  /** パスワード */
  password?: string;
}

/**
 * アカウントグループ登録更新モデル
 */
export interface AdminAccountGroup {
  /** アカウントグループID */
  accountGroupId: number;
  /** アカウントグループ名 */
  accountGroupName: string;
  /** アカウントグループURL */
  accountGroupUrl?: string;
  /** アカウントグループロゴ画像 */
  accountGroupLogo?: string;
  /** アカウントグループロゴ画像クリア状態 */
  accountGroupLogoCleared: boolean | undefined;
}

/**
 * Cognitoユーザー情報モデル
 */
export interface CognitoUserInfo {
  /** ユーザー名 */
  userName: string;
}

/**
 * アカウント更新情報モデル
 */
export interface UpdateAccount {
  /** 権限ID */
  authorityId: number;
}
