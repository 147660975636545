import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import { theme } from 'resources/theme';
import dimens from 'resources/dimens';
import colors from 'resources/colors';
import { Ship } from 'models/ships';
import { Box, Button, ClickAwayListener, Grow, Link, Paper, Popper, SxProps } from '@mui/material';
import { DialogResult } from 'components/AlertDialog';
import { SearchBar } from 'components/SearchBar';
import List, { ItemDragging } from 'devextreme-react/list';
import { arrayMoveImmutable } from 'array-move';
import AppLogger from 'utils/AppLogger';

const StyledPopper = styled(Popper)({
  zIndex: theme.zIndex.drawer + 101,
});

const StyledLink = styled(Link)({
  color: theme.palette.background.default,
  cursor: 'pointer',
  textDecoration: 'underline',
  whiteSpace: 'nowrap',
});

const Arrow = styled('span')({
  position: 'absolute',
  width: 0,
  height: 0,
  borderLeft: '1em solid transparent',
  borderRight: '1em solid transparent',
  borderBottom: '1em solid #FFF',
  marginTop: '-0.9em',
  '&:before': {
    borderWidth: '0 1em 1em 1em',
    borderColor: 'transparent transparent white transparent',
  },
});

const SearchBarDiv = styled('div')({
  height: dimens.searchBar.height,
  display: 'flex',
  justifyContent: 'center',
  alignItem: 'center',
});

const FooterButtonDiv = styled('div')({
  width: '100%',
  background: theme.palette.background.default,
  borderTop: `1px solid #E0E0E0`,
  borderRadius: '0px 0px 4px 4px',
});

const FooterButton = styled(Button)({
  width: '50%',
  textAlign: 'center',
  height: dimens.searchBar.height,
  borderRadius: 0,
});

type WorkItem = {
  id: string;
  text: string;
  ship: Ship;
};

interface ShipReorderPopupProps {
  sx?: SxProps;
  ships: Ship[];
  selectedShipIds: string[];
  onClose: (
    result: DialogResult,
    reorderedShips: Ship[] | undefined,
    checkedShips: string[] | undefined
  ) => void;
}

export function ShipReorderPopup(props: ShipReorderPopupProps): JSX.Element {
  const { sx, ships, selectedShipIds, onClose } = props;
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [arrowRef, setArrowRef] = useState<HTMLSpanElement>();
  const paparRef = useRef<HTMLDivElement>(null);
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [workItems, setWorkItems] = useState<WorkItem[]>(
    ships.map((x) => {
      return { id: x.shipId, text: x.name, ship: x };
    })
  );
  const [selectedItemKeys, setSelectedItemKeys] = useState<string[]>(selectedShipIds);
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleToggle = () => {
    const tmpOpen = prevOpen.current;
    setOpen((prevOpen) => !prevOpen);
    if (tmpOpen === true) {
      setSelectedItemKeys(selectedShipIds);
      setWorkItems(
        ships.map((x) => {
          return { id: x.shipId, text: x.name, ship: x };
        })
      );
      onClose('cancel', undefined, undefined);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClickAway = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    const rects = paparRef.current?.getClientRects();
    if (rects && rects.length > 0) {
      if (
        rects[0].left > 0 &&
        rects[0].left <= event.clientX &&
        rects[0].left + rects[0].width >= event.clientX &&
        rects[0].top <= event.clientY &&
        rects[0].top + rects[0].height >= event.clientY
      ) {
        AppLogger.debug('in rect');
      } else {
        setOpen(false);
        setSearchFilter('');
        setSelectedItemKeys(selectedShipIds);
        setWorkItems(
          ships.map((x) => {
            return { id: x.shipId, text: x.name, ship: x };
          })
        );
        onClose('cancel', undefined, undefined);
      }
    }
  };

  const handleSeachInputChange = (value: string) => {
    setSearchFilter(value);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDragStart = (e: any) => {
    e.itemData = workItems[e.fromIndex];
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleReorder = (e: any) => {
    setWorkItems(arrayMoveImmutable(workItems, e.fromIndex, e.toIndex));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOptionChanged = (e: any) => {
    if (e.name === 'selectedItemKeys') {
      if (
        selectedItemKeys.length != e.value.length ||
        e.value.filter((val: string) => !selectedItemKeys.includes(val)).length > 0
      ) {
        setSelectedItemKeys(e.value);
      }
    }
  };

  const handleClose = (result: DialogResult) => {
    setOpen(false);
    setSearchFilter('');
    if (result === 'ok') {
      const reorderedShips = workItems.map((x) => x.ship);
      setSelectedItemKeys(selectedItemKeys);
      onClose(result, reorderedShips, selectedItemKeys);
    } else {
      setSelectedItemKeys(selectedShipIds);
      setWorkItems(
        ships.map((x) => {
          return { id: x.shipId, text: x.name, ship: x };
        })
      );
      onClose(result, undefined, undefined);
    }
  };

  const handleArrowRef = (node: HTMLElement) => {
    setArrowRef(node);
  };

  return (
    <React.Fragment>
      <Box
        ref={anchorRef}
        sx={sx}
        aria-controls={open ? 'ship-list-grow' : undefined}
        aria-haspopup="true"
      >
        <StyledLink onClick={handleToggle}>
          {intl.formatMessage({ id: msgId.shipDisplaySettings })}
        </StyledLink>
      </Box>
      <StyledPopper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="auto"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 20],
            },
          },
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'center top',
            }}
          >
            <Paper
              ref={paparRef}
              sx={{
                minWidth: 300,
                width: 300,
                padding: 0,
                background: colors.subDrawer.background,
              }}
            >
              <Arrow ref={handleArrowRef} />
              <ClickAwayListener onClickAway={handleClickAway}>
                <Box id="ship-list-grow">
                  <SearchBarDiv>
                    <SearchBar
                      sx={{
                        marginTop: '4px',
                        width: 300,
                        padding: '4px 8px 4px 8px',
                      }}
                      placeholder={intl.formatMessage({ id: msgId.shipNameSearch })}
                      onChange={(event) => handleSeachInputChange(event.target.value)}
                      value={searchFilter}
                    />
                  </SearchBarDiv>
                  <Box sx={{ background: theme.palette.background.default }}>
                    <List
                      dataSource={workItems}
                      keyExpr="id"
                      showSelectionControls={true}
                      selectionMode="all"
                      selectAllMode="page"
                      height={330}
                      searchMode="contains"
                      searchValue={searchFilter.trim()}
                      scrollByContent={true}
                      focusStateEnabled={false}
                      searchExpr="text"
                      selectedItemKeys={selectedItemKeys}
                      onOptionChanged={handleOptionChanged}
                    >
                      <ItemDragging
                        allowReordering={true}
                        onDragStart={handleDragStart}
                        onReorder={handleReorder}
                      ></ItemDragging>
                    </List>
                  </Box>
                  <FooterButtonDiv>
                    <FooterButton onClick={() => handleClose('cancel')} color="primary">
                      {intl.formatMessage({ id: msgId.cancel })}
                    </FooterButton>
                    <FooterButton
                      onClick={() => handleClose('ok')}
                      color="primary"
                      disabled={selectedItemKeys.length === 0}
                    >
                      {intl.formatMessage({ id: msgId.ok })}
                    </FooterButton>
                  </FooterButtonDiv>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </React.Fragment>
  );
}
