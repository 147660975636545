import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/rootReducer';
import { MenuDashboard } from 'models/menu';
import { useSelector } from 'react-redux';

/**
 * MainDrawerState インターフェース
 */
interface MainDrawerState {
  open: boolean;
  selectedMenuKey: string;
}

/**
 * MainDrawerState の初期状態
 */
const initialState: MainDrawerState = {
  open: true,
  selectedMenuKey: MenuDashboard,
};

export const mainDrawer = createSlice({
  name: 'mainDrawer',
  initialState,
  reducers: {
    /** 選択メニューを設定 */
    selectMenu: (state: MainDrawerState, { payload }: PayloadAction<string>) => {
      state.selectedMenuKey = payload;
    },
    /** 開閉状態を切り替え */
    toggleOpen: (state: MainDrawerState) => {
      state.open = !state.open;
    },
    /** 状態を初期化する。 */
    clearMainDrawer: (state) => {
      state.open = initialState.open;
      state.selectedMenuKey = initialState.selectedMenuKey;
    },
  },
});

export const { selectMenu, toggleOpen, clearMainDrawer } = mainDrawer.actions;

const mainDrawerState = (state: RootState) => state.mainDrawer;
const selectSelectedMenuKey = createSelector(mainDrawerState, (x) => x.selectedMenuKey);
const selectOpen = createSelector(mainDrawerState, (x) => x.open);

export const useMainDrawerSelectedMenuKey = () => useSelector(selectSelectedMenuKey);
export const useMainDrawerOpen = () => useSelector(selectOpen);

export default mainDrawer.reducer;
