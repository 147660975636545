import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import { InfoWindow, Marker } from '@react-google-maps/api';
import ShipPinForcused from 'resources/assets/ShipPinForcused.svg';

export interface ShipMarkerProps {
  shipName: string;
  lat: string;
  lng: string;
  deg: string;
  date: string;
}

export function ShipMarker(props: ShipMarkerProps): JSX.Element {
  const { shipName, lat, lng, deg, date } = props;
  const intl = useIntl();
  const [open, setOpen] = useState<boolean>(true);
  const [image] = useState(new Image());
  const [url, setUrl] = useState<string>('');
  if (url == '') {
    setTimeout(() => setUrl('1'), 100);
    setTimeout(() => setUrl('2'), 300);
    setTimeout(() => setUrl('3'), 1000);
  }

  const rotateIcon = (deg: number) => {
    const canvas = document.createElement('canvas');
    canvas.width = 68;
    canvas.height = 68;
    const context = canvas.getContext('2d');
    const centerX = 34;
    const centerY = 34;
    const angle = (deg * Math.PI) / 180;

    if (context != null) {
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.save();
      context.translate(centerX, centerY);
      context.rotate(angle);
      context.translate(-centerX, -centerY);
      context.drawImage(image, 0, 0);
      context.restore();
    }

    return canvas.toDataURL('image/png');
  };

  useEffect(() => {
    image.src = ShipPinForcused;
  }, [image]);

  function getMinSec(num: string) {
    let min, sec;
    if (num.split('.').length === 1) {
      min = 0;
      sec = '00';
    } else {
      min = parseInt(num.split('.')[1].substring(0, 2), 10);
      sec = num.split('.')[1].substring(2, 4);
    }

    return { min: min, sec: sec };
  }

  function getLatText(lat: number): string {
    let val = lat;
    let latText;
    if (lat < 0) {
      val *= -1;
      latText = intl.formatMessage({ id: msgId.shipInfoLatSouth });
    } else {
      latText = intl.formatMessage({ id: msgId.shipInfoLatNorth });
    }
    const degrees = parseInt(val + '', 10);
    const minutes = ((val - degrees) * 6) / 10 + '000';
    const { min, sec } = getMinSec(minutes);
    latText +=
      degrees +
      intl.formatMessage({ id: msgId.shipInfoUnitDegrees }) +
      min +
      '.' +
      sec +
      intl.formatMessage({ id: msgId.shipInfoUnitMinutes });

    return latText;
  }
  function getLonText(lon: number): string {
    let val = lon;
    let lonText;
    if (lon < 0) {
      val *= -1;
      lonText = intl.formatMessage({ id: msgId.shipInfoLonWest });
    } else {
      lonText = intl.formatMessage({ id: msgId.shipInfoLonEast });
    }
    const degrees = parseInt(val + '', 10);
    const minutes = ((val - degrees) * 6) / 10 + '000';
    const { min, sec } = getMinSec(minutes);
    lonText +=
      degrees +
      intl.formatMessage({ id: msgId.shipInfoUnitDegrees }) +
      min +
      '.' +
      sec +
      intl.formatMessage({ id: msgId.shipInfoUnitMinutes });

    return lonText;
  }

  const handleSetOpen = (o: boolean): void => {
    setOpen(!o);
  };

  return (
    <Marker
      position={{ lat: Number(lat), lng: Number(lng) }}
      icon={{
        url: rotateIcon(Number(deg)),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(34, 34),
        scaledSize: new window.google.maps.Size(68, 68),
      }}
      onClick={() => handleSetOpen(open)}
      label={{
        text: shipName,
        className: 'marico-map-marker-label',
      }}
    >
      {open && (
        <InfoWindow
          position={{ lat: Number(lat), lng: Number(lng) }}
          onCloseClick={() => handleSetOpen(open)}
        >
          <div>
            <div>{intl.formatMessage({ id: msgId.date }) + ' : ' + date.split('-').join('/')}</div>
            <div>{getLatText(Number(lat)) + ' ' + getLonText(Number(lng))}</div>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
}
