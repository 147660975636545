import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'app/store';
import { Ship } from 'models/ships';
import { SensorDataList, SensorData, PositionPort, PositionStarboard } from 'models/data';
import { getDiagnosisListItemsAsync, getDiagnosisDataAsync } from 'api/maricoApiDiagnosis';
import colors from 'resources/colors';
import { DiagnosisListItem } from 'models/diagnosis';
import { ErrorResult, getErrorResult } from 'models/error';
import { ChartSeries } from 'models/chart';
import { RootState } from 'app/rootReducer';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

/**
 * Diagnosis状態モデル
 */
interface DiagnosisState {
  /** 診断結果リスト */
  diagnosisListItems: DiagnosisListItem[] | undefined;
  /** 現在選択中の診断結果 */
  selectedDiagnosis: DiagnosisListItem | undefined;
  /** 現在表示中の診断結果 */
  currentDiagnosis: DiagnosisListItem | undefined;
  /** 診断結果リスト取得中状態 */
  listFetching: boolean;
  /** 診断結果リスト取得エラー */
  listFetchError: ErrorResult | undefined;
  /** 診断結果データ取得中状態 */
  dataFetching: boolean;
  /** 診断結果データ取得エラー */
  dataFetchError: ErrorResult | undefined;
  /** チャート系列リスト */
  chartSeriesList: ChartSeries[] | undefined;
  /** 確定済みチャート系列リスト */
  confirmedChartSeriesList: ChartSeries[] | undefined;
  /** チャート系列の変更状態 */
  chartSeriesChanged: boolean;
  /** チャート系列開閉アップデート状態 */
  chartLegendExpandUpdated: boolean;
}

/**
 * Diagnosis 初期状態
 */
const initialState: DiagnosisState = {
  diagnosisListItems: undefined,
  selectedDiagnosis: undefined,
  currentDiagnosis: undefined,
  listFetching: false,
  listFetchError: undefined,
  dataFetching: false,
  dataFetchError: undefined,
  chartSeriesList: undefined,
  confirmedChartSeriesList: undefined,
  chartSeriesChanged: false,
  chartLegendExpandUpdated: false,
};

export const diagnosis = createSlice({
  name: 'diagnosis',
  initialState,
  reducers: {
    /**
     * 診断結果リスト取得を開始する。
     */
    startListFetch: (state) => {
      state.listFetching = true;
    },
    /**
     * 診断結果リストを設定する。
     */
    setListItems: (state, { payload }: PayloadAction<DiagnosisListItem[]>) => {
      state.diagnosisListItems = payload;
      state.listFetching = false;
    },
    /**
     * 診断結果リスト取得エラーを設定する。
     */
    setListFetchError: (state, { payload }: PayloadAction<ErrorResult>) => {
      state.listFetchError = payload;
      state.listFetching = false;
    },
    /**
     * 診断結果リスト取得を開始する。
     */
    startDataFetch: (state) => {
      state.dataFetching = true;
    },
    /**
     * センサーデータを設定する。
     */
    setSensorDataList: (
      state,
      action: PayloadAction<{
        diagnosisListItem: DiagnosisListItem | undefined;
        sensorDataList: SensorDataList | undefined;
      }>
    ) => {
      const diagnosisListItem = action.payload.diagnosisListItem;
      const sensorDataList = action.payload.sensorDataList;
      if (sensorDataList != null) {
        // センサーデータからグラフ用データに変換
        const chartSeriesList: ChartSeries[] = [];

        for (const key in sensorDataList.sensors) {
          const baseSensor = sensorDataList.sensors[key];
          if (
            !chartSeriesList.some(
              (x) => x.sensorGroup.sensorGroupName === baseSensor.sensorGroup.sensorGroupName
            )
          ) {
            // 系列追加
            // 同センサーグループのセンサーを取得
            const sensors: SensorData[] = [];
            for (const x in sensorDataList.sensors) {
              if (baseSensor.sensorGroup === sensorDataList.sensors[x].sensorGroup) {
                sensors.push(sensorDataList.sensors[x]);
              }
            }

            // 2以上ある場合、センサーグループ名が異常
            if (sensors.length > 2) {
              throw new Error(
                'sensor group name is invalid. ' + sensors[0].sensorGroup.sensorGroupName
              );
            }

            // 右舷と左舷をそれぞれ設定
            let portSensor = undefined;
            let starboardSensor = undefined;
            sensors.forEach((x) => {
              if (x.sensor.position === PositionPort) {
                portSensor = x;
              } else if (x.sensor.position === PositionStarboard) {
                starboardSensor = x;
              } else {
                // どちらでもない場合は両方に設定
                portSensor = x;
                starboardSensor = x;
              }
            });

            if (portSensor != null || starboardSensor != null) {
              const color = colors.chart.lines[chartSeriesList.length] || colors.chart.lines[0];
              const labelColor =
                colors.chart.labels[chartSeriesList.length] || colors.chart.labels[0];
              const timezoneOffset = dayjs().utcOffset() * 60000;
              chartSeriesList.push({
                color: color,
                labelColor: labelColor,
                portSensorData: portSensor,
                starboardSensorData: starboardSensor,
                timeData: sensorDataList.logdate.map((date) => date - timezoneOffset),
                sensorGroup: baseSensor.sensorGroup,
                range: {
                  min: baseSensor.sensorGroup.displayLowerLimit,
                  max: baseSensor.sensorGroup.displayUpperLimit,
                },
              });
            }
          }
        }

        state.chartSeriesList = chartSeriesList;
      } else {
        state.chartSeriesList = undefined;
      }

      state.currentDiagnosis = diagnosisListItem;
      state.dataFetching = false;
    },
    /**
     * 診断結果データ取得エラーを設定する。
     */
    setDataFetchError: (state, { payload }: PayloadAction<ErrorResult | undefined>) => {
      state.dataFetchError = payload;
      state.dataFetching = false;
    },
    /**
     * 選択中の診断結果データを設定する。
     */
    setSelectedDiagnosis: (state, { payload }: PayloadAction<DiagnosisListItem | undefined>) => {
      if (state.selectedDiagnosis != null && payload != null) {
        if (state.selectedDiagnosis.diagnosisId !== payload.diagnosisId) {
          state.chartSeriesList = undefined;
          state.selectedDiagnosis = payload;
        }
      } else {
        state.chartSeriesList = undefined;
        state.selectedDiagnosis = payload;
      }
    },
    /**
     * チャート系列のレンジを設定する。
     */
    setChartSeriesRange: (
      state,
      { payload }: PayloadAction<{ chartSeries: ChartSeries; min: number; max: number }>
    ) => {
      state.chartSeriesChanged = true;
      const chartSeries = state.chartSeriesList?.filter((x) => x !== payload.chartSeries)[0];
      if (chartSeries != null) {
        chartSeries.range.min = payload.min;
        chartSeries.range.max = payload.max;
      }
    },
    /**
     * チャート系列開閉アップデート状態を設定する。
     */
    setChartLegendExpandUpdated: (state, { payload }: PayloadAction<boolean>) => {
      state.chartLegendExpandUpdated = payload;
    },
    /**
     * Diagnosisを初期化する。
     */
    clearDiagnosis: (state) => {
      state.diagnosisListItems = initialState.diagnosisListItems;
      state.selectedDiagnosis = initialState.selectedDiagnosis;
      state.currentDiagnosis = initialState.currentDiagnosis;
      state.listFetching = initialState.listFetching;
      state.listFetchError = initialState.listFetchError;
      state.dataFetching = initialState.dataFetching;
      state.dataFetchError = initialState.dataFetchError;
      state.chartSeriesList = initialState.chartSeriesList;
      state.confirmedChartSeriesList = initialState.confirmedChartSeriesList;
      state.chartSeriesChanged = initialState.chartSeriesChanged;
      state.chartLegendExpandUpdated = initialState.chartLegendExpandUpdated;
    },
  },
});

export const {
  setListFetchError,
  setDataFetchError,
  setSensorDataList,
  setSelectedDiagnosis,
  setChartSeriesRange,
  setChartLegendExpandUpdated,
  clearDiagnosis,
} = diagnosis.actions;

/**
 * 診断結果リストを取得する。
 */
export const fetchDiagnosisListItems = (): AppThunk => async (dispatch) => {
  dispatch(diagnosis.actions.startListFetch());
  try {
    const diagnosisListItems = await getDiagnosisListItemsAsync();
    dispatch(diagnosis.actions.setListItems(diagnosisListItems));
  } catch (error) {
    dispatch(diagnosis.actions.setListFetchError(getErrorResult(error)));
  }
};

/**
 * 診断結果データを取得する。
 * @param diagnosisListItem 診断結果
 */
export const fetchDiagnosisData =
  (ships: Ship[], diagnosisListItem: DiagnosisListItem): AppThunk =>
  async (dispatch) => {
    const ship = ships.find((x) =>
      x.machines.find((y) => y.machineId === diagnosisListItem.machineId)
    );
    const machine = ship && ship.machines.find((x) => x.machineId === diagnosisListItem.machineId);
    if (machine != null) {
      dispatch(diagnosis.actions.startDataFetch());
      try {
        const sensorDataList = await getDiagnosisDataAsync(machine, diagnosisListItem);
        dispatch(diagnosis.actions.setSensorDataList({ diagnosisListItem, sensorDataList }));
      } catch (error) {
        dispatch(diagnosis.actions.setDataFetchError(getErrorResult(error)));
      }
    }
  };

const diagnosisState = (state: RootState) => state.diagnosis;

const selectDiagnosisListItems = createSelector(diagnosisState, (x) => x.diagnosisListItems);
const selectListFetching = createSelector(diagnosisState, (x) => x.listFetching);
const selectListFetchError = createSelector(diagnosisState, (x) => x.listFetchError);
const selectDataFetching = createSelector(diagnosisState, (x) => x.dataFetching);
const selectDataFetchError = createSelector(diagnosisState, (x) => x.dataFetchError);
const selectSelectedDiagnosis = createSelector(diagnosisState, (x) => x.selectedDiagnosis);
const selectCurrentDiagnosis = createSelector(diagnosisState, (x) => x.currentDiagnosis);
const selectChartSeriesList = createSelector(diagnosisState, (x) => x.chartSeriesList);
const selectChartLegendExpandUpdated = createSelector(
  diagnosisState,
  (x) => x.chartLegendExpandUpdated
);

export const useDiagnosisListItems = () => useSelector(selectDiagnosisListItems);
export const useDiagnosisListFetching = () => useSelector(selectListFetching);
export const useDiagnosisListFetchError = () => useSelector(selectListFetchError);
export const useDiagnosisDataFetching = () => useSelector(selectDataFetching);
export const useDiagnosisDataFetchError = () => useSelector(selectDataFetchError);
export const useDiagnosisSelectedDiagnosis = () => useSelector(selectSelectedDiagnosis);
export const useDiagnosisCurrentDiagnosis = () => useSelector(selectCurrentDiagnosis);
export const useDiagnosisChartSeriesList = () => useSelector(selectChartSeriesList);
export const useDiagnosisChartLegendExpandUpdated = () =>
  useSelector(selectChartLegendExpandUpdated);

export default diagnosis.reducer;
