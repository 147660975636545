import React from 'react';
import { Ship } from 'models/ships';
import colors from 'resources/colors';
import msgId from 'resources/intl';

import { MimicContainer } from './mimic/MimicContainer';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { CustomScrollBars } from 'components/CustomScrollBars';
import { TrendChartContainer } from './trend/TrendChartContainer';
import { CommonSensorData } from 'models/data';
import { Grid, styled, Typography } from '@mui/material';
import { theme } from 'resources/theme';
import dimens from 'resources/dimens';
import { ConfirmedTrendChartCondition } from './trend/trendChartSlice';
import constants from 'resources/constants';

const RootDiv = styled('div')({
  pointerEvents: 'auto',
  position: 'absolute',
  top: 0,
  background: colors.chart.chartContainer.background,
  height: '100%',
  width: '100%',
});

const SummaryTitleGrid = styled(Grid)({
  background: colors.listItem.subHeader,
  padding: theme.spacing(1),
  width: '100%',
});

interface ChartContainerProp {
  ships: Ship[];
  startDate: string;
  endDate: string;
  trendOnly: boolean;
  left: number;
  width: number;
  height: number;
  thinnedData: boolean;
  commonSensorData: CommonSensorData;
  condition: ConfirmedTrendChartCondition | undefined;
  resetRange?: boolean;
  setResetRange?: () => void;
}

export function ChartContainer(props: ChartContainerProp): JSX.Element {
  const {
    ships,
    trendOnly,
    left,
    width,
    height,
    thinnedData,
    commonSensorData,
    condition,
    resetRange,
    setResetRange,
  } = props;
  const intl = useIntl();
  const startDate = thinnedData
    ? dayjs(props.startDate).utc().format(constants.dateFormat.YYYYMMDDHHmmss)
    : dayjs(props.startDate).utc().format(constants.dateFormat.YYYYMMDDHHmmssSSS).substring(0, 21);
  const endDate = thinnedData
    ? dayjs(props.endDate).utc().format(constants.dateFormat.YYYYMMDDHHmmss)
    : dayjs(props.endDate).utc().format(constants.dateFormat.YYYYMMDDHHmmssSSS).substring(0, 21);

  const chartHeight =
    height - 33 < dimens.chart.trend.height ? dimens.chart.trend.height : height - 33;

  const ship: Ship = {
    ...ships[0],
    machines: ships[0].machines.filter((x) => x.machineId === commonSensorData.machineId),
  };

  return (
    <RootDiv sx={{ left: left, width: width }}>
      <CustomScrollBars thumbColor={colors.scrollBar.thumb.dark} autoHide={false}>
        <RootDiv
          key={ship.shipId}
          sx={{
            width:
              width < dimens.shipState.chartContainer.width
                ? dimens.shipState.chartContainer.width
                : width,
          }}
        >
          {!trendOnly && (
            <>
              <SummaryTitleGrid container direction="column">
                <Typography variant="body2" noWrap sx={{ width: '100%' }}>
                  {intl.formatMessage({ id: msgId.shipName }) +
                    ' : ' +
                    ship.name +
                    ' - ' +
                    ship.machines[0].name}
                </Typography>
                <Typography variant="body2" noWrap>
                  {intl.formatMessage({ id: msgId.periodResult }) +
                    ' : ' +
                    startDate +
                    ' - ' +
                    endDate}
                </Typography>
              </SummaryTitleGrid>

              <MimicContainer
                sx={{ width: '100%' }}
                width={
                  width < dimens.shipState.chartContainer.width
                    ? dimens.shipState.chartContainer.width
                    : width
                }
                machine={ship.machines[0]}
                commonSensorData={commonSensorData}
              />
            </>
          )}
          <TrendChartContainer
            ship={ship}
            startDate={props.startDate}
            endDate={props.endDate}
            width={
              width < dimens.shipState.chartContainer.width
                ? dimens.shipState.chartContainer.width
                : width
            }
            height={trendOnly ? chartHeight : dimens.chart.trend.height}
            trendOnly={trendOnly}
            thinnedData={thinnedData}
            condition={condition}
            resetRange={resetRange}
            setResetRange={setResetRange}
          />
        </RootDiv>
      </CustomScrollBars>
    </RootDiv>
  );
}
