import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
// import {PlotMouseEvent, PlotDatum} from "plotly.js"
import { AppThunk } from 'app/store';
import { PositionPort, SensorDataList } from 'models/data';
import { Machine, SensorGroup } from 'models/ships';
import { ChartSeries } from 'models/chart';
import colors from 'resources/colors';
import { ErrorResult, getErrorResult } from 'models/error';
import { getSensorDataAsync } from 'api/maricoApiData';
import { getSensorThinnedDataAsync } from 'api/maricoApiThinnedData';
import { RootState } from 'app/rootReducer';
import config from 'resources/config';
import { dumpPastDataDaySelectGraphData } from 'utils/dumpData';
import { useSelector } from 'react-redux';
import { roundValue } from 'utils/misc';

/**
 * トレンドチャートの管理情報
 */
export interface TrendChartContext {
  /** タイトル */
  title: string;
  /** チャート系列リスト */
  chartSeriesList: ChartSeries[];
  /** 確定済みチャート系列リスト */
  confirmedChartSeriesList: ChartSeries[];
  /** チャート系列の変更状態 */
  chartSeriesChanged: boolean;
}

/**
 * ConfirmedTrendChartCondition インターフェース
 */
export interface ConfirmedTrendChartCondition {
  chartId: string;
  thinnedData: boolean;
  machine: Machine;
  sensorNames: string[];
  startDate: string;
  endDate?: string;
  applied?: boolean;
}

/**
 * TrendChartState インターフェース
 */
export interface TrendChartState {
  /** 取得中状態 */
  fetching: boolean;
  /** 取得エラー */
  fetchError: ErrorResult | undefined;
  /** トレンドチャートコンテキストリスト */
  contexts: { [chartId: string]: TrendChartContext };
  /** トレンドチャート検索条件 */
  confirmedCondition: ConfirmedTrendChartCondition | undefined;
  /** チャート系列リスト */
  tempContexts: { [chartId: string]: TrendChartContext };
  /** チャート系列開閉アップデート状態 */
  chartLegendExpandUpdated: boolean;
}

/**
 * TrendChartState の初期状態
 */
const initialState: TrendChartState = {
  fetching: false,
  fetchError: undefined,
  contexts: {},
  confirmedCondition: undefined,
  tempContexts: {},
  chartLegendExpandUpdated: false,
};

export const trendChart = createSlice({
  name: 'shipStatePastDataTrendChart',
  initialState,
  reducers: {
    /**
     * チャートを追加する。
     */
    addChart: (state, action: PayloadAction<{ chartId: string; title: string }>) => {
      state.contexts[action.payload.chartId] = {
        title: action.payload.title,
        chartSeriesList: [],
        confirmedChartSeriesList: [],
        chartSeriesChanged: false,
      };
    },
    /**
     * チャートを削除する。
     */
    removeChart: (state, action: PayloadAction<{ chartId: string }>) => {
      if (state.contexts[action.payload.chartId]) {
        delete state.contexts[action.payload.chartId];
      }
    },
    /**
     * タイトルを設定する。
     */
    setTitle: (state, action: PayloadAction<{ chartId: string; title: string }>) => {
      const context = state.contexts[action.payload.chartId];
      context.title = action.payload.title;
    },
    /**
     * チャート系列を追加する。
     */
    addChartSeries: (
      state,
      action: PayloadAction<{ chartId: string; sensorGroup: SensorGroup }>
    ) => {
      const context = state.contexts[action.payload.chartId];
      const sensorGroup = action.payload.sensorGroup;

      context.chartSeriesChanged = true;
      const color = colors.chart.lines[context.chartSeriesList.length] || colors.chart.lines[0];
      const labelColor =
        colors.chart.labels[context.chartSeriesList.length] || colors.chart.labels[0];
      const min =
        sensorGroup.displayUpperLimit === 360 || sensorGroup.displayUpperLimit === 359.9
          ? roundValue(sensorGroup.displayLowerLimit - 180, sensorGroup.displayRoundingPosition)
          : sensorGroup.displayLowerLimit;
      const max =
        sensorGroup.displayUpperLimit === 360 || sensorGroup.displayUpperLimit === 359.9
          ? roundValue(sensorGroup.displayUpperLimit - 180, sensorGroup.displayRoundingPosition)
          : sensorGroup.displayUpperLimit;
      context.chartSeriesList.push({
        color: color,
        labelColor: labelColor,
        range: { min: min, max: max },
        sensorGroup: sensorGroup,
        timeData: undefined,
        portSensorData: undefined,
        starboardSensorData: undefined,
      });
    },
    /**
     * チャート系列を削除する。
     */
    removeChartSeries: (
      state,
      action: PayloadAction<{ chartId: string; chartSeries: ChartSeries }>
    ) => {
      const context = state.contexts[action.payload.chartId];
      const chartSeries = action.payload.chartSeries;
      context.chartSeriesChanged = true;
      const filterSeriesList = context.chartSeriesList.filter(
        (x) => x.sensorGroup.sensorGroupName !== chartSeries.sensorGroup.sensorGroupName
      );
      context.chartSeriesList = filterSeriesList.map((x, i) => {
        x.color = colors.chart.lines[i];

        return x;
      });
    },
    /**
     * チャート系列のレンジを変更する。
     */
    setChartSeriesRange: (
      state,
      action: PayloadAction<{ chartId: string; chartSeries: ChartSeries; min: number; max: number }>
    ) => {
      const context = state.contexts[action.payload.chartId];
      context.chartSeriesChanged = true;
      const chartSeries = context.chartSeriesList.find(
        (x) =>
          x.sensorGroup.sensorGroupName === action.payload.chartSeries.sensorGroup.sensorGroupName
      );
      if (chartSeries != null) {
        chartSeries.range.min = action.payload.min;
        chartSeries.range.max = action.payload.max;
      }
    },
    /**
     * チャート系列開閉アップデート状態を設定する。
     */
    setChartLegendExpandUpdated: (state, { payload }: PayloadAction<boolean>) => {
      state.chartLegendExpandUpdated = payload;
    },
    /**
     * 変更したチャート系列を破棄する。
     */
    resetChartSeriesList: (state, action: PayloadAction<{ chartId: string }>) => {
      const context = state.contexts[action.payload.chartId];
      context.chartSeriesChanged = false;
      context.chartSeriesList = context.confirmedChartSeriesList;
    },
    /**
     * 取得の開始を設定する。
     */
    startFetch: (state, action: PayloadAction<boolean>) => {
      state.fetching = action.payload;
    },
    /**
     * センサーデータを設定する。
     */
    setSensorData: (
      state,
      action: PayloadAction<{ chartId: string; sensorDataList: SensorDataList }>
    ) => {
      const context = state.contexts[action.payload.chartId];
      const sensorDataList = action.payload.sensorDataList;
      context.chartSeriesChanged = false;
      // 時刻データ取得
      const timeData = sensorDataList.logdate;

      // 系列にセンサーデータを割り当て
      context.chartSeriesList.forEach((series) => {
        series.sensorGroup.sensors.forEach((sensor) => {
          Object.keys(sensorDataList.sensors).forEach((sensorName) => {
            if (sensorName === sensor.sensorName) {
              if (sensor.position === PositionPort) {
                // 左舷
                series.portSensorData = sensorDataList.sensors[sensor.sensorName];
              } else if (sensor.position === 'Starboard') {
                // 右舷
                series.starboardSensorData = sensorDataList.sensors[sensor.sensorName];
              } else {
                // 左舷右舷の区別がないものは両方
                series.portSensorData = sensorDataList.sensors[sensor.sensorName];
                series.starboardSensorData = sensorDataList.sensors[sensor.sensorName];
              }

              series.timeData = timeData;
            }
          });
        });
      });
      context.confirmedChartSeriesList = context.chartSeriesList;
      state.fetching = false;
    },
    /**
     * センサーデータを設定する。
     */
    setUpdateSensorData: (
      state,
      action: PayloadAction<{ chartId: string; sensorDataList: SensorDataList }>
    ) => {
      const context = state.contexts[action.payload.chartId];
      const sensorDataList = action.payload.sensorDataList;

      // 時刻データ取得
      const timeData = sensorDataList.logdate;

      // 系列にセンサーデータを割り当て
      context.confirmedChartSeriesList.forEach((series) => {
        series.sensorGroup.sensors.forEach((sensor) => {
          Object.keys(sensorDataList.sensors).forEach((sensorName) => {
            if (sensorName === sensor.sensorName) {
              if (sensor.position === PositionPort) {
                // 左舷
                series.portSensorData = sensorDataList.sensors[sensor.sensorName];
              } else if (sensor.position === 'Starboard') {
                // 右舷
                series.starboardSensorData = sensorDataList.sensors[sensor.sensorName];
              } else {
                // 左舷右舷の区別がないものは両方
                series.portSensorData = sensorDataList.sensors[sensor.sensorName];
                series.starboardSensorData = sensorDataList.sensors[sensor.sensorName];
              }

              series.timeData = timeData;

              context.chartSeriesList.forEach((sSeries) => {
                sSeries.sensorGroup.sensors.forEach((sSensor) => {
                  if (sensor.sensorName === sSensor.sensorName) {
                    sSeries.portSensorData = series.portSensorData;
                    sSeries.starboardSensorData = series.starboardSensorData;
                    sSeries.timeData = series.timeData;
                  }
                });
              });
            }
          });
        });
      });

      state.fetching = false;
    },
    /**
     * 変更したチャート系列を反映する。
     */
    applyDataSeries: (state, action: PayloadAction<{ chartId: string }>) => {
      const context = state.contexts[action.payload.chartId];
      context.chartSeriesChanged = false;
      context.confirmedChartSeriesList = context.chartSeriesList;
      state.fetching = false;
    },
    /**
     * 取得エラー
     */
    setFetchError: (state, action: PayloadAction<ErrorResult | undefined>) => {
      state.fetchError = action.payload;
      state.fetching = false;
    },
    /**
     * トレンド検索の条件保持
     */
    setConfirmedCondition: (
      state,
      action: PayloadAction<ConfirmedTrendChartCondition | undefined>
    ) => {
      state.confirmedCondition = action.payload;
    },
    /**
     * トレンド検索の条件保持
     */
    setCopyToTempContexts: (state) => {
      Object.keys(state.contexts).forEach((key) => {
        state.tempContexts[key] = {
          ...state.contexts[key],
        };
      });
    },
    /**
     * トレンド検索の条件保持
     */
    setCopyFromTempContexts: (state, action: PayloadAction<{ chartId: string }>) => {
      state.contexts[action.payload.chartId] = {
        ...state.tempContexts[action.payload.chartId],
      };
    },
    /**
     * shipStatePastDataTrendChartをクリアする。
     */
    clearShipStatePastDataTrendChart: (state) => {
      state.fetching = initialState.fetching;
      state.fetchError = initialState.fetchError;
      state.contexts = { ...initialState.contexts };
      state.confirmedCondition = initialState.confirmedCondition;
      state.chartLegendExpandUpdated = initialState.chartLegendExpandUpdated;
    },
  },
});

export const {
  addChart,
  removeChart,
  setTitle,
  addChartSeries,
  removeChartSeries,
  setChartSeriesRange,
  setChartLegendExpandUpdated,
  resetChartSeriesList,
  setFetchError,
  clearShipStatePastDataTrendChart,
  setConfirmedCondition,
  setCopyToTempContexts,
  setCopyFromTempContexts,
} = trendChart.actions;

/**
 * センサーデータを取得する。
 * @param chartId チャート番号
 * @param machine 機械
 * @param startDate 開始日時
 * @param endDate 終了日時
 * @param chartSeriesList チャート凡例
 * @param confirmedChartSeriesList 確定済みチャート凡例
 */
export const applyChartSeriesList =
  (
    chartId: string,
    machine: Machine,
    startDate: string,
    endDate: string,
    chartSeriesList: ChartSeries[],
    confirmedChartSeriesList: ChartSeries[],
    thinnedData: boolean,
    diff?: boolean
  ): AppThunk =>
  async (dispatch) => {
    dispatch(trendChart.actions.startFetch(true));
    try {
      // // 日時変更のあるデータを取得するため、取得していないセンサー名の配列を作成するのをやめる
      // const sensorNames: string[] = [];
      // chartSeriesList.forEach((x) =>
      //   x.sensorGroup.sensors.forEach((y) => {
      //     sensorNames.push(y.sensorName);
      //   })
      // );
      // 取得していないセンサー名の配列を作成
      const chartSeries = chartSeriesList;
      // if (diff) {
      //   chartSeries = chartSeriesList.filter((x) =>
      //     confirmedChartSeriesList.every(
      //       (y) => y.sensorGroup.sensorGroupName !== x.sensorGroup.sensorGroupName
      //     )
      //   );
      // }
      const sensorNames: string[] = [];
      chartSeries.forEach((x) =>
        x.sensorGroup.sensors.forEach((y) => {
          sensorNames.push(y.sensorName);
        })
      );

      if (sensorNames.length > 0) {
        // データ取得
        let sensorDataList;
        if (thinnedData) {
          sensorDataList = await getSensorThinnedDataAsync(
            machine,
            sensorNames,
            startDate.substring(0, 10)
          );
        } else {
          sensorDataList = await getSensorDataAsync(
            machine,
            sensorNames,
            startDate,
            endDate ? endDate : ''
          );
        }
        dispatch(trendChart.actions.setSensorData({ chartId, sensorDataList }));
        if (thinnedData) {
          dispatch(trendChart.actions.setCopyToTempContexts());
        }

        if (config.valueInspectionLog) {
          dumpPastDataDaySelectGraphData(sensorNames, sensorDataList);
        }
      } else {
        // 削除のみ
        dispatch(trendChart.actions.applyDataSeries({ chartId }));
        if (thinnedData) {
          dispatch(trendChart.actions.setCopyToTempContexts());
        }
      }
      sensorNames.length = 0;
      chartSeriesList.forEach((x) =>
        x.sensorGroup.sensors.forEach((y) => {
          sensorNames.push(y.sensorName);
        })
      );
      dispatch(
        trendChart.actions.setConfirmedCondition({
          chartId: chartId,
          machine: machine,
          sensorNames: sensorNames,
          startDate: startDate,
          endDate: endDate,
          thinnedData: thinnedData,
          applied: true,
        })
      );
    } catch (error) {
      dispatch(trendChart.actions.setFetchError(getErrorResult(error)));
    }
  };

/**
 * センサーデータを取得する。
 * @param startDate 開始日時
 * @param endDate 終了日時

*/
export const updateApplyChartSeriesList =
  (condition: ConfirmedTrendChartCondition): AppThunk =>
  async (dispatch) => {
    const { machine, sensorNames, startDate, thinnedData, endDate, chartId } = condition;
    dispatch(trendChart.actions.startFetch(true));
    try {
      if (condition.sensorNames.length > 0) {
        // データ取得
        let sensorDataList;
        if (thinnedData) {
          sensorDataList = await getSensorThinnedDataAsync(
            machine,
            sensorNames,
            startDate.substring(0, 10)
          );
        } else {
          sensorDataList = await getSensorDataAsync(
            machine,
            sensorNames,
            startDate,
            endDate ? endDate : ''
          );
        }
        dispatch(trendChart.actions.setUpdateSensorData({ chartId, sensorDataList }));
        if (thinnedData) {
          dispatch(trendChart.actions.setCopyToTempContexts());
        }
      } else {
        dispatch(trendChart.actions.startFetch(false));
        if (thinnedData) {
          dispatch(trendChart.actions.setCopyToTempContexts());
        }
      }
    } catch (error) {
      dispatch(trendChart.actions.setFetchError(getErrorResult(error)));
    }
  };

const pastDataTrendChartState = (state: RootState) => state.pastDataTrendChart;
const selectFetching = createSelector(pastDataTrendChartState, (x) => x.fetching);
const selectFetchError = createSelector(pastDataTrendChartState, (x) => x.fetchError);
const selectContexts = createSelector(pastDataTrendChartState, (x) => x.contexts);
const selectConfirmedCondition = createSelector(
  pastDataTrendChartState,
  (x) => x.confirmedCondition
);
const selectChartLegendExpandUpdated = createSelector(
  pastDataTrendChartState,
  (x) => x.chartLegendExpandUpdated
);

export const usePastDataTrendChartFetching = () => useSelector(selectFetching);
export const usePastDataTrendChartFetchError = () => useSelector(selectFetchError);
export const usePastDataTrendChartContexts = () => useSelector(selectContexts);
export const usePastDataTrendChartConfirmedCondition = () => useSelector(selectConfirmedCondition);
export const usePastDataTrendChartLegendExpandUpdated = () =>
  useSelector(selectChartLegendExpandUpdated);

export default trendChart.reducer;
