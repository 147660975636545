/* eslint-disable no-irregular-whitespace */const msgId = {
  /** REX-CMS */
  appName: 'app_name',
  /** OK */
  ok: 'ok',
  /** キャンセル */
  cancel: 'cancel',
  /** エラー */
  error: 'error',
  /** 中止 */
  abort: 'abort',
  /** リセット */
  filterReset: 'filter_reset',
  /** クリア */
  clear: 'clear',
  /** ダウンロード */
  download: 'download',
  /** ログイン */
  login: 'login',
  /** ログアウト */
  logout: 'logout',
  /** メールアドレス */
  mailAddress: 'mail_address',
  /** ログインID */
  accountName: 'account_name',
  /** パスワード */
  password: 'password',
  /** インポート */
  import: 'import',
  /** グループ */
  accountGroup: 'account_group',
  /** ステータス */
  accountStatus: 'account_status',
  /** 登録日時 */
  createdAt: 'created_at',
  /** 更新日時 */
  updatedAt: 'updated_at',
  /** 新規登録 */
  newRegistration: 'new_registration',
  /** 削除 */
  delete: 'delete',
  /** 登録 */
  registration: 'registration',
  /** 送信 */
  send: 'send',
  /** 認証 */
  authentication: 'authentication',
  /** 認証コード */
  authCode: 'auth_code',
  /** 日時 */
  date: 'date',
  /** ログインに失敗しました。\nログインIDまたはパスワードの入力に誤りがあるか、登録されていません。 */
  errorMessageSigninFailed: 'error_message_signin_failed',
  /** サーバ側がメンテナンス中、またはエラーが発生しました。\nしばらく経ってから再度操作してください。 */
  errorMessageServer: 'error_message_server',
  /** 仮パスワードの有効期限が切れています。\n管理者にお問い合わせください。 */
  errorMessageTemporaryPasswordExpired: 'error_message_temporary_password_expired',
  /** 正常な処理ができませんでした。 */
  errorMessageOther: 'error_message_other',
  /** 認証に失敗しました。再度ログインしてください。 */
  errorMessageAuth: 'error_message_auth',
  /** 権限がありません。 */
  errorMessageForbidden: 'error_message_forbidden',
  /** データがありません。 */
  errorMessageNoData: 'error_message_no_data',
  /** パスワードを忘れた方は */
  loginPasswordResetLink: 'login_password_reset_link',
  /** お問い合わせは */
  loginContactLink: 'login_contact_link',
  /** こちら */
  linkHere: 'link_here',
  /** バージョン */
  version: 'version',
  /** ビルド番号 */
  buildNumber: 'build_number',
  /** ホーム */
  dashboard: 'dashboard',
  /** 船舶状態 */
  sensorDataBrowsing: 'sensor_data_browsing',
  /** データ分析 */
  analysisDataBrowsing: 'analysis_data_browsing',
  /** システム提案 */
  systemProposal: 'system_proposal',
  /** 設定 */
  generalSettings: 'general_settings',
  /** お問い合わせ */
  contactSupport: 'contact_support',
  /** システム設定 */
  systemSettings: 'system_settings',
  /** 管理者設定 */
  administratorSettings: 'administrator_settings',
  /** アカウント管理 */
  accountManagement: 'account_management',
  /** グループ管理 */
  groupManagement: 'group_management',
  /** 船舶管理 */
  shipManagement: 'ship_management',
  /** アクセス権限管理 */
  authorityManagement: 'authority_management',
  /** センサー診断閾値管理 */
  sensorDiagnosisThresholdsManagement: 'sensor_diagnosis_thresholds_management',
  /** 試験データ値管理 */
  testDataManagement: 'test_data_management',
  /** アカウント新規登録 */
  accountNewRegistration: 'account_new_registration',
  /** グループ新規登録 */
  accountGroupNewRegistration: 'account_group_new_registration',
  /** グループ情報 */
  accountGroupInformation: 'account_group_information',
  /** 船舶新規登録 */
  shipNewRegistration: 'ship_new_registration',
  /** 船舶情報 */
  shipInformation: 'ship_information',
  /** パスワードリセット */
  accountPasswordReset: 'account_password_reset',
  /** センサーアクセス権限設定 */
  sensorAccessAuthoritySettings: 'sensor_access_authority_settings',
  /** センサーアクセス権限を設定します。\nインポートするCSVファイルを設定してください。\n※形式が一致しない場合は正常にインポートできません。 */
  sensorAccessAuthoritySettingsDescription: 'sensor_access_authority_settings_description',
  /** 分析メニューアクセス権限設定 */
  analysisMenuAuthoritySettings: 'analysis_menu_authority_settings',
  /** 分析メニューアクセス権限を設定します。\nインポートするCSVファイルを設定してください。\n※形式が一致しない場合は正常にインポートできません。 */
  analysisMenuAuthoritySettingsDescription: 'analysis_menu_authority_settings_description',
  /** センサー診断閾値設定 */
  sensorDiagnosisThresholdsSettings: 'sensor_diagnosis_thresholds_settings',
  /** センサー診断閾値を設定します。\nインポートするCSVファイルを設定してください。\n※形式が一致しない場合は正常にインポートできません。 */
  sensorDiagnosisThresholdsDescription: 'sensor_diagnosis_thresholds_description',
  /** 主機出荷時工場試験データ値設定 */
  megFactoryTestDataSettings: 'meg_factory_test_data_settings',
  /** 主機出荷時工場試験データ値を設定します。\nインポートするCSVファイルを設定してください。\n※形式が一致しない場合は正常にインポートできません。 */
  megFactoryTestDataSettingsDescription: 'meg_factory_test_data_settings_description',
  /** 海上運転データ値設定 */
  maritimeDrivingDataSettings: 'maritime_driving_data_settings',
  /** 海上運転データ値を設定します。\nインポートするCSVファイルを設定してください。\n※形式が一致しない場合は正常にインポートできません。 */
  maritimeDrivingDataSettingsDescription: 'maritime_driving_data_settings_description',
  /** 添付ファイル */
  attachmentFiles: 'attachment_files',
  /** ファイルをドラッグ＆ドロップするか、または「ファイル」選択から設定してください。 */
  attachmentFilesGuiide: 'attachment_files_guiide',
  /** ファイル選択 */
  fileSelect: 'file_select',
  /** ファイルが対応していないため正常にアップロードできません。 */
  errorMessageFileUpload: 'error_message_file_upload',
  /** ログインID検索 */
  searchAccountName: 'search_account_name',
  /** グループ名検索 */
  searchAccountGroupName: 'search_account_group_name',
  /** グループID */
  accountGroupId: 'account_group_id',
  /** グループ名 */
  accountGroupName: 'account_group_name',
  /** グループURL */
  accountGroupUrl: 'account_group_url',
  /** 権限 */
  authority: 'authority',
  /** 船舶ID */
  shipId: 'ship_id',
  /** 推進機製造番号 */
  propulsionSerialNumber: 'propulsion_serial_number',
  /** 機械ID */
  machineId: 'machine_id',
  /** PLC製造番号 */
  plcSerialNumber: 'plc_serial_number',
  /** 必須 */
  required: 'required',
  /** 設定済み */
  configured: 'configured',
  /** {min}～{max}文字の半角英数混在 */
  passwordRequirement: 'password_requirement',
  /** 舶用機械IoT\nデータ可視化システム */
  headerTitle: 'header_title',
  /** 削除しますか？ */
  confirmMessageDelete: 'confirm_message_delete',
  /** ログインIDを入力してください。\n登録済みのメールアドレスに認証コードを送信します。 */
  guideMessageResetPassword1: 'guide_message_reset_password1',
  /** 本画面は閉じずにメールをご確認いただき、認証コードを入力してください。 */
  guideMessageSendAuthCode1: 'guide_message_send_auth_code1',
  /** メールが届かない場合は、ログインIDと登録済みのメールアドレスをご確認の上、再度お試しください。 */
  guideMessageSendAuthCode2: 'guide_message_send_auth_code2',
  /** パスワードを入力してください。 */
  guideMessageInputPassword: 'guide_message_input_password',
  /** 初期パスワードが設定されているためパスワードを変更してください。 */
  guideMessageInitialPassword1: 'guide_message_initial_password1',
  /** 現在のパスワードと新しいパスワードを入力してください。 */
  guideMessageInitialPassword2: 'guide_message_initial_password2',
  /** 入力してください。ログインIDは変更できません。 */
  guideMessageEditAccount: 'guide_message_edit_account',
  /** 入力してください。グループIDは変更できません。 */
  guideMessageEditAccountGroup: 'guide_message_edit_account_group',
  /** アクセス権限で設定されている船舶や機械は削除できません。 */
  guideMessageEditShip: 'guide_message_edit_ship',
  /** グループロゴを設定する場合は、\n635×170サイズのPNG画像を設定してください。\n※形式が一致しない場合はアップロードできません。 */
  guideMessageEditAccountGroupLogo: 'guide_message_edit_account_group_logo',
  /** 機械追加 */
  addMachine: 'add_machine',
  /** リアルタイムAIS */
  mapModeCurrentLocation: 'map_mode_current_location',
  /** 過去データ */
  mapModePreviousData: 'map_mode_previous_data',
  /** 過去データ+AIS */
  mapModePreviousDataAis: 'map_mode_previous_data_ais',
  /** アカウント情報 */
  accountInformation: 'account_information',
  /** 船舶表示設定 */
  shipDisplaySettings: 'ship_display_settings',
  /** 気象海象情報表示設定 */
  marineWeatherDisplaySettings: 'marine_weather_display_settings',
  /** 船舶選択 */
  shipSelection: 'ship_selection',
  /** 船舶一覧 */
  shipList: 'ship_list',
  /** 地図表示 */
  mapVisibility: 'map_visibility',
  /** 船舶状況 */
  shipStatus: 'ship_status',
  /** 船舶名検索 */
  shipNameSearch: 'ship_name_search',
  /** 条件検索 */
  filteredSearch: 'filtered_search',
  /** 期間 */
  period: 'period',
  /** 期間別 */
  periodUnit: 'period_unit',
  /** データ表示条件設定 */
  dataSearchConditionSetting: 'data_search_condition_setting',
  /** すべて解除 */
  allClear: 'all_clear',
  /** 表示条件を設定する。検索期間は最大1日以内としてください。 */
  searchConditionsEnabled: 'search_conditions_enabled',
  /** 表示条件を設定しない。検索期間は最大1日以内としてください。 */
  searchConditionsDisabled: 'search_conditions_disabled',
  /** スクリーニング単位 */
  screeningUnits: 'screening_units',
  /** 主機回転数 */
  megRotationSpeed: 'meg_rotation_speed',
  /** 主機負荷率 */
  megLoadFactor: 'meg_load_factor',
  /** 主機ラック位置 */
  megRackPosition: 'meg_rack_position',
  /** 上から優先順位の高い順で検索します。優先順位は並び替えできます。 */
  guideShipStatusConditions: 'guide_ship_status_conditions',
  /** 分 */
  minute: 'minute',
  /** 分 */
  minutes: 'minutes',
  /** 時間 */
  hour: 'hour',
  /** 時間 */
  hours: 'hours',
  /** 指定した条件に該当するデータを指定の時間間隔で抜き出します。 */
  guideScreeningUnits: 'guide_screening_units',
  /** 指定した期間別でデータ分析結果を表示します。 */
  guidePeriodUnits: 'guide_period_units',
  /** センサー名 */
  sensorName: 'sensor_name',
  /** データ上限値 */
  dataUpperLimit: 'data_upper_limit',
  /** データ下限値 */
  dataLowerLimit: 'data_lower_limit',
  /** 追加 */
  add: 'add',
  /** 決定 */
  decision: 'decision',
  /** 検索結果はありません。 */
  noResults: 'no_results',
  /** 表示条件変更 */
  changeSearchConditions: 'change_search_conditions',
  /** 表示結果選択 */
  resultSelection: 'result_selection',
  /** 開始日時(UTC) */
  startDate: 'start_date',
  /** 終了日時(UTC) */
  endDate: 'end_date',
  /** cms_t@khi.co.jp */
  contactSupportMailTo: 'contact_support_mail_to',
  /** 【REX-CMS】問い合わせ */
  contactSupportMailTitle: 'contact_support_mail_title',
  /** お名前：%0A%0Aご所属：%0A%0Aお問い合わせ内容：%0A */
  contactSupportMailBody: 'contact_support_mail_body',
  /** 新規グラフ */
  trendDefaultTitle: 'trend_default_title',
  /** 主要項目 */
  trendMainItem: 'trend_main_item',
  /** グラフ削除 */
  trendGraphDelete: 'trend_graph_delete',
  /** 比較 */
  trendGraphCompare: 'trend_graph_compare',
  /** CSV出力 */
  trendCsvOutput: 'trend_csv_output',
  /** 更新 */
  trendButtonUpdate: 'trend_button_update',
  /** 左舷 */
  trendLabelPort: 'trend_label_port',
  /** 右舷 */
  trendLabelStbd: 'trend_label_stbd',
  /** 最小値 */
  trendAggregateMin: 'trend_aggregate_min',
  /** 最大値 */
  trendAggregateMax: 'trend_aggregate_max',
  /** 中央値 */
  trendAggregateMed: 'trend_aggregate_med',
  /** 平均値 */
  trendAggregateAve: 'trend_aggregate_ave',
  /** Y軸範囲 */
  trendYaxisRange: 'trend_yaxis_range',
  /** min */
  trendRangeMin: 'trend_range_min',
  /** max */
  trendRangeMax: 'trend_range_max',
  /** センサー追加 */
  trendSensorAdd: 'trend_sensor_add',
  /** グラフ新規作成 */
  trendNewTrendGraph: 'trend_new_trend_graph',
  /** 警報 */
  alertTitle: 'alert_title',
  /** 異常発生日時(UTC) */
  alertStartDate: 'alert_start_date',
  /** 船舶名 */
  shipName: 'ship_name',
  /** 機械名 */
  machineName: 'machine_name',
  /** 船舶 */
  ship: 'ship',
  /** 詳細 */
  details: 'details',
  /** 種別 */
  alertStatus: 'alert_status',
  /** センサー名 */
  alertSensorName: 'alert_sensor_name',
  /** 件数 */
  alertCount: 'alert_count',
  /** 注意 */
  diagnosisStatusCaution: 'diagnosis_status_Caution',
  /** 警告 */
  diagnosisStatusWarning: 'diagnosis_status_Warning',
  /** 故障 */
  diagnosisStatusFault: 'diagnosis_status_Fault',
  /** Active */
  aisStatusActive: 'ais_status_active',
  /** Not active */
  aisStatusNotActive: 'ais_status_not_active',
  /** Abnormal */
  aisStatusAbnormal: 'ais_status_abnormal',
  /** Restricted */
  aisStatusRestricted: 'ais_status_restricted',
  /** Fishing */
  aisStatusFishing: 'ais_status_fishing',
  /** Unknown */
  aisStatusUnknown: 'ais_status_unknown',
  /** Undefined */
  aisStatusUndefined: 'ais_status_undefined',
  /** 航行中 */
  aisStatusTooltipUnderWayUsingEngine: 'ais_status_tooltip_under_way_using_engine',
  /** 錨泊中 */
  aisStatusTooltipAtAnchor: 'ais_status_tooltip_at_anchor',
  /** 運転不自由 */
  aisStatusTooltipNotUnderCommand: 'ais_status_tooltip_not_under_command',
  /** 操縦性能制限 */
  aisStatusTooltipRestrictedManeuverability: 'ais_status_tooltip_restricted_maneuverability',
  /** 喫水制限 */
  aisStatusTooltipConstrainedByHerDraught: 'ais_status_tooltip_constrained_by_her_draught',
  /** 係留中 */
  aisStatusTooltipMoored: 'ais_status_tooltip_moored',
  /** 座礁中 */
  aisStatusTooltipAground: 'ais_status_tooltip_aground',
  /** 漁労中 */
  aisStatusTooltipEngagedInFishing: 'ais_status_tooltip_engaged_in_fishing',
  /** 帆走中 */
  aisStatusTooltipUnderWaySailing: 'ais_status_tooltip_under_way_sailing',
  /** 曳船中 */
  aisStatusTooltipTowingAstern: 'ais_status_tooltip_towing_astern',
  /** 押船もしくは横付け曳船中 */
  aisStatusTooltipPushingAheadOrTowingAlongside: 'ais_status_tooltip_pushing_ahead_or_towing_alongside',
  /** 未定義 */
  aisStatusTooltipUndefined: 'ais_status_tooltip_undefined',
  /** knot */
  aisVesSpeedUnit: 'ais_ves_speed_unit',
  /** ° */
  aisDirectionUnit: 'ais_direction_unit',
  /** 表示しない */
  marineWeatherTypeNone: 'marine_weather_type_none',
  /** 風向風速 */
  marineWeatherTypeWindDirWindSpeed: 'marine_weather_type_wind_dir_wind_speed',
  /** 有義波高 */
  marineWeatherTypeSigHeight: 'marine_weather_type_sig_height',
  /** うねり高さ/向き */
  marineWeatherTypeSwellHeightSwellDir: 'marine_weather_type_swell_height_swell_dir',
  /** うねり周期/向き */
  marineWeatherTypeSwellPeriodSwellDir: 'marine_weather_type_swell_period_swell_dir',
  /** うねり高さ */
  swellHeight: 'swell_height',
  /** 向き */
  swellDir: 'swell_dir',
  /** うねり周期 */
  swellPeriod: 'swell_period',
  /** 主機給排気温度 */
  MimicTcInfoTitle: 'MimicTcInfoTitle',
  /** ℃ */
  MimicTcInfoUnit: 'MimicTcInfoUnit',
  /** 日別 */
  daily: 'daily',
  /** 週別 */
  weekly: 'weekly',
  /** 月別 */
  monthly: 'monthly',
  /** その他 */
  other: 'other',
  /** 最大値 */
  statisticalValueMax: 'statistical_value_max',
  /** 最小値 */
  statisticalValueMin: 'statistical_value_min',
  /** 平均値 */
  statisticalValueAverage: 'statistical_value_average',
  /** 中央値 */
  statisticalValueMedian: 'statistical_value_median',
  /** 分散値 */
  statisticalValueVariance: 'statistical_value_variance',
  /** 最頻値 */
  statisticalValueMode: 'statistical_value_mode',
  /** 要素区分設定 */
  analysisSectionSettings: 'analysis_section_settings',
  /** 船速分析 */
  AnalysisMenuShipSpeed: 'AnalysisMenuShipSpeed',
  /** 航路分析 */
  AnalysisMenuRoute: 'AnalysisMenuRoute',
  /** 負荷分析 */
  AnalysisMenuLoadAnalysis: 'AnalysisMenuLoadAnalysis',
  /** 排気温度分析 */
  AnalysisMenuExhaustTemperatureAnalysis: 'AnalysisMenuExhaustTemperatureAnalysis',
  /** 日別負荷割合分析 */
  AnalysisMenuLoadBandAnalysis: 'AnalysisMenuLoadBandAnalysis',
  /** 負荷分析 */
  AnalysisMenuLoadAnalysisAux: 'AnalysisMenuLoadAnalysisAux',
  /** 排気温度分析 */
  AnalysisMenuExhaustTemperatureAnalysisAux: 'AnalysisMenuExhaustTemperatureAnalysisAux',
  /** 日別負荷割合分析 */
  AnalysisMenuLoadBandAnalysisAux: 'AnalysisMenuLoadBandAnalysisAux',
  /** アクチュエータ操作量分析(旋回、CPP) */
  AnalysisMenuPropulsionOperationAnalysis: 'AnalysisMenuPropulsionOperationAnalysis',
  /** 油圧系運転時間診断 */
  AnalysisMenuHydraulicOperatingTime: 'AnalysisMenuHydraulicOperatingTime',
  /** ベベルギヤ診断 */
  AnalysisMenuGearAnomalyDetection: 'AnalysisMenuGearAnomalyDetection',
  /** 軸受診断 */
  AnalysisMenuBearingAnomalyDetection: 'AnalysisMenuBearingAnomalyDetection',
  /** 燃費診断(主機減速運転) */
  AnalysisMenuFuelConsumptionDetection: 'AnalysisMenuFuelConsumptionDetection',
  /** その他 */
  AnalysisMenuOther: 'AnalysisMenuOther',
  /** 船体 */
  AnalysisSectionVes: 'AnalysisSectionVes',
  /** 主機 */
  AnalysisSectionMeg: 'AnalysisSectionMeg',
  /** 補機 */
  AnalysisSectionAux: 'AnalysisSectionAux',
  /** レックスペラ */
  AnalysisSectionRex: 'AnalysisSectionRex',
  /** その他 */
  AnalysisSectionOther: 'AnalysisSectionOther',
  /** 左舷 主機 回転数A */
  MegSpdP1: 'MegSpdP1',
  /** 左舷 主機 燃料ポンプラックレベルA */
  MegFuelRackLvP1: 'MegFuelRackLvP1',
  /** 左舷 主機 出力馬力A */
  MegHpP1: 'MegHpP1',
  /** 左舷 主機 過給器回転数A */
  MegTCSpdP1: 'MegTCSpdP1',
  /** 右舷 主機 回転数A */
  MegSpdS1: 'MegSpdS1',
  /** 右舷 主機 燃料ポンプラックレベルA */
  MegFuelRackLvS1: 'MegFuelRackLvS1',
  /** 右舷 主機 出力馬力A */
  MegHpS1: 'MegHpS1',
  /** 右舷 主機 過給器回転数A */
  MegTCSpdS1: 'MegTCSpdS1',
  /** 左舷 主機 燃料消費量A */
  MegFuelOilConsumP1: 'MegFuelOilConsumP1',
  /** 右舷 主機 燃料消費量A */
  MegFuelOilConsumS1: 'MegFuelOilConsumS1',
  /** 左舷 Rex 潤滑油コンタミセンサA */
  RexLOContamSensorP1: 'RexLOContamSensorP1',
  /** 右舷 Rex 潤滑油コンタミセンサA */
  RexLOContamSensorS1: 'RexLOContamSensorS1',
  /** 左舷 Rex 左旋回圧力B */
  RexLtStrPressP1RIO: 'RexLtStrPressP1RIO',
  /** 右舷 Rex 左旋回圧力B */
  RexLtStrPressS1RIO: 'RexLtStrPressS1RIO',
  /** 左舷 Rex 右旋回圧力B */
  RexRtStrPressP1RIO: 'RexRtStrPressP1RIO',
  /** 右舷 Rex 右旋回圧力B */
  RexRtStrPressS1RIO: 'RexRtStrPressS1RIO',
  /** 左舷 Rex 潤滑油圧力B */
  RexLOPressP1RIO: 'RexLOPressP1RIO',
  /** 右舷 Rex 潤滑油圧力B */
  RexLOPressS1RIO: 'RexLOPressS1RIO',
  /** 左舷 Rex 旋回ポンプ吐出圧力B */
  RexDelPressStrPumpP1RIO: 'RexDelPressStrPumpP1RIO',
  /** 右舷 Rex 旋回ポンプ吐出圧力B */
  RexDelPressStrPumpS1RIO: 'RexDelPressStrPumpS1RIO',
  /** 左舷 Rex 潤滑油水分量B */
  RexLOCondP1RIO: 'RexLOCondP1RIO',
  /** 右舷 Rex 潤滑油水分量B */
  RexLOCondS1RIO: 'RexLOCondS1RIO',
  /** 左舷 Rex クラッチ制御元圧力B */
  RexClutchContpressP1RIO: 'RexClutchContpressP1RIO',
  /** 右舷 Rex クラッチ制御元圧力B */
  RexClutchContpressS1RIO: 'RexClutchContpressS1RIO',
  /** 左舷 Rex クラッチ作動圧力B */
  RexClutchWorkpressP1RIO: 'RexClutchWorkpressP1RIO',
  /** 右舷 Rex クラッチ作動圧力B */
  RexClutchWorkpressS1RIO: 'RexClutchWorkpressS1RIO',
  /** 左舷 Rex CPP　前進変節圧力B */
  RexCPPAHPressP1RIO: 'RexCPPAHPressP1RIO',
  /** 右舷 Rex CPP　前進変節圧力B */
  RexCPPAHPressS1RIO: 'RexCPPAHPressS1RIO',
  /** 左舷 Rex CPP　後進変節圧力B */
  RexCPPASPressP1RIO: 'RexCPPASPressP1RIO',
  /** 右舷 Rex CPP　後進変節圧力B */
  RexCPPASPressS1RIO: 'RexCPPASPressS1RIO',
  /** 左舷 Rex 旋回油水分量B */
  RexStrOilCondWatersatP1RIO: 'RexStrOilCondWatersatP1RIO',
  /** 右舷 Rex 旋回油水分量B */
  RexStrOilCondWatersatS1RIO: 'RexStrOilCondWatersatS1RIO',
  /** 左舷 Rex CPP制御ポンプ吐出圧力B */
  RexDelPressCPPContpumpP1RIO: 'RexDelPressCPPContpumpP1RIO',
  /** 右舷 Rex CPP制御ポンプ吐出圧力B */
  RexDelPressCPPContpumpS1RIO: 'RexDelPressCPPContpumpS1RIO',
  /** 左舷 Rex チャージポンプ吐出圧力B */
  RexDelPressChargepumpP1RIO: 'RexDelPressChargepumpP1RIO',
  /** 右舷 Rex チャージポンプ吐出圧力B */
  RexDelPressChargepumpS1RIO: 'RexDelPressChargepumpS1RIO',
  /** 左舷 Rex スリップ潤滑油ポンプ吐出圧力B */
  RexDelPressLOpumpP1RIO: 'RexDelPressLOpumpP1RIO',
  /** 右舷 Rex スリップ潤滑油ポンプ吐出圧力B */
  RexDelPressLOpumpS1RIO: 'RexDelPressLOpumpS1RIO',
  /** スペア 　 スペア */
  Spare1C1: 'Spare1C1',
  /** スペア 　 スペア */
  Spare2C1: 'Spare2C1',
  /** スペア 　 スペア */
  Spare3C1: 'Spare3C1',
  /** スペア 　 スペア */
  Spare4C1: 'Spare4C1',
  /** スペア 　 スペア */
  Spare5C1: 'Spare5C1',
  /** スペア 　 スペア */
  Spare6C1: 'Spare6C1',
  /** 左舷 Rex 潤滑油温度B */
  RexLOTmpP1RIO: 'RexLOTmpP1RIO',
  /** 右舷 Rex 潤滑油温度B */
  RexLOTmpS1RIO: 'RexLOTmpS1RIO',
  /** 左舷 Rex 旋回油温度B */
  RexStrOilTmpP1RIO: 'RexStrOilTmpP1RIO',
  /** 右舷 Rex 旋回油温度B */
  RexStrOilTmpS1RIO: 'RexStrOilTmpS1RIO',
  /** 左舷 Rex 潤滑油コンタミ量(鉄系)B */
  RexLOCondContmFeP1: 'RexLOCondContmFeP1',
  /** 右舷 Rex 潤滑油コンタミ量(鉄系)B */
  RexLOCondContmFeS1: 'RexLOCondContmFeS1',
  /** 左舷 Rex 潤滑油コンタミ量(非鉄系)B */
  RexLOCondContmNFeP1: 'RexLOCondContmNFeP1',
  /** 右舷 Rex 潤滑油コンタミ量(非鉄系)B */
  RexLOCondContmNFeS1: 'RexLOCondContmNFeS1',
  /** 左舷 Rex 旋回油コンタミ量(鉄系)B */
  RexStrOilCondContmFeP1: 'RexStrOilCondContmFeP1',
  /** 右舷 Rex 旋回油コンタミ量(鉄系)B */
  RexStrOilCondContmFeS1: 'RexStrOilCondContmFeS1',
  /** 左舷 Rex 旋回油コンタミ量(非鉄系)B */
  RexStrOilCondContmNFeP1: 'RexStrOilCondContmNFeP1',
  /** 右舷 Rex 旋回油コンタミ量(非鉄系)B */
  RexStrOilCondContmNFeS1: 'RexStrOilCondContmNFeS1',
  /** 左舷 Rex 潤滑油オイルクーラ入口温度B */
  RexLOinTmpofoilcoolerP1RIO: 'RexLOinTmpofoilcoolerP1RIO',
  /** 右舷 Rex 潤滑油オイルクーラ入口温度B */
  RexLOinTmpofoilcoolerS1RIO: 'RexLOinTmpofoilcoolerS1RIO',
  /** 左舷 Rex 潤滑油オイルクーラ出口温度B */
  RexLOoutTmpofoilcoolerP1RIO: 'RexLOoutTmpofoilcoolerP1RIO',
  /** 右舷 Rex 潤滑油オイルクーラ出口温度B */
  RexLOoutTmpofoilcoolerS1RIO: 'RexLOoutTmpofoilcoolerS1RIO',
  /** 左舷 Rex 冷却水オイルクーラ入口温度B */
  RexCWinTmpofoilcoolerP1RIO: 'RexCWinTmpofoilcoolerP1RIO',
  /** 右舷 Rex 冷却水オイルクーラ入口温度B */
  RexCWinTmpofoilcoolerS1RIO: 'RexCWinTmpofoilcoolerS1RIO',
  /** 左舷 Rex 冷却水オイルクーラ出口温度B */
  RexCWoutTmpofoilcoolerP1RIO: 'RexCWoutTmpofoilcoolerP1RIO',
  /** 右舷 Rex 冷却水オイルクーラ出口温度B */
  RexCWoutTmpofoilcoolerS1RIO: 'RexCWoutTmpofoilcoolerS1RIO',
  /** 左舷 Rex 冷却水流量B */
  RexCWflowrateP1RIO: 'RexCWflowrateP1RIO',
  /** 右舷 Rex 冷却水流量B */
  RexCWflowrateS1RIO: 'RexCWflowrateS1RIO',
  /** 左舷 Rex 旋回モータ１ドレン温度B */
  RexTmpofdrainoilHYDMotor1P1RIO: 'RexTmpofdrainoilHYDMotor1P1RIO',
  /** 右舷 Rex 旋回モータ１ドレン温度B */
  RexTmpofdrainoilHYDMotor1S1RIO: 'RexTmpofdrainoilHYDMotor1S1RIO',
  /** 左舷 Rex 旋回モータ２ドレン温度B */
  RexTmpofdrainoilHYDMotor2P1RIO: 'RexTmpofdrainoilHYDMotor2P1RIO',
  /** 右舷 Rex 旋回モータ２ドレン温度B */
  RexTmpofdrainoilHYDMotor2S1RIO: 'RexTmpofdrainoilHYDMotor2S1RIO',
  /** 左舷 Rex 中間軸受軸受ハウジング温度１B */
  RexInttSftTmp1P1RIO: 'RexInttSftTmp1P1RIO',
  /** 左舷 Rex 中間軸受軸受ハウジング温度２B */
  RexInttSftTmp2P1RIO: 'RexInttSftTmp2P1RIO',
  /** 左舷 Rex 中間軸受軸受ハウジング温度３B */
  RexInttSftTmp3P1RIO: 'RexInttSftTmp3P1RIO',
  /** 左舷 Rex 中間軸受軸受ハウジング温度４B */
  RexInttSftTmp4P1RIO: 'RexInttSftTmp4P1RIO',
  /** 左舷 Rex 中間軸受軸受ハウジング温度５B */
  RexInttSftTmp5P1RIO: 'RexInttSftTmp5P1RIO',
  /** 左舷 Rex 中間軸受軸受ハウジング温度６B */
  RexInttSftTmp6P1RIO: 'RexInttSftTmp6P1RIO',
  /** 右舷 Rex 中間軸受軸受ハウジング温度１B */
  RexInttSftTmp1S1RIO: 'RexInttSftTmp1S1RIO',
  /** 右舷 Rex 中間軸受軸受ハウジング温度２B */
  RexInttSftTmp2S1RIO: 'RexInttSftTmp2S1RIO',
  /** 右舷 Rex 中間軸受軸受ハウジング温度３B */
  RexInttSftTmp3S1RIO: 'RexInttSftTmp3S1RIO',
  /** 右舷 Rex 中間軸受軸受ハウジング温度４B */
  RexInttSftTmp4S1RIO: 'RexInttSftTmp4S1RIO',
  /** 右舷 Rex 中間軸受軸受ハウジング温度５B */
  RexInttSftTmp5S1RIO: 'RexInttSftTmp5S1RIO',
  /** 右舷 Rex 中間軸受軸受ハウジング温度６B */
  RexInttSftTmp6S1RIO: 'RexInttSftTmp6S1RIO',
  /** 左舷 Rex 旋回角ハンドルオーダC1 */
  RexStrAngOrderP1: 'RexStrAngOrderP1',
  /** 右舷 Rex 旋回角ハンドルオーダC1 */
  RexStrAngOrderS1: 'RexStrAngOrderS1',
  /** 左舷 Rex 旋回角フィードバックC1 */
  RexStrAngFBP1: 'RexStrAngFBP1',
  /** 右舷 Rex 旋回角フィードバックC1 */
  RexStrAngFBS1: 'RexStrAngFBS1',
  /** 左舷 Rex プロペラ回転数オーダC1 */
  RexPropSpdOrderP1: 'RexPropSpdOrderP1',
  /** 右舷 Rex プロペラ回転数オーダC1 */
  RexPropSpdOrderS1: 'RexPropSpdOrderS1',
  /** 左舷 Rex プロペラ回転数フィードバックC1 */
  RexPropSpdFBP1: 'RexPropSpdFBP1',
  /** 右舷 Rex プロペラ回転数フィードバックC1 */
  RexPropSpdFBS1: 'RexPropSpdFBS1',
  /** 左舷 Rex CPP翼角オーダC1 */
  RexCPPBladeAngOrderP1: 'RexCPPBladeAngOrderP1',
  /** 右舷 Rex CPP翼角オーダC1 */
  RexCPPBladeAngOrderS1: 'RexCPPBladeAngOrderS1',
  /** 左舷 Rex CPP翼角フィードバックC1 */
  RexCPPBladeAngFBP1: 'RexCPPBladeAngFBP1',
  /** 右舷 Rex CPP翼角フィードバックC1 */
  RexCPPBladeAngFBS1: 'RexCPPBladeAngFBS1',
  /** 左舷 主機 回転数オーダC1 */
  MegSpdorderP1: 'MegSpdorderP1',
  /** 右舷 主機 回転数オーダC1 */
  MegSpdorderS1: 'MegSpdorderS1',
  /** 左舷 主機 回転数フィードバックC1 */
  MegSpdFBP1: 'MegSpdFBP1',
  /** 右舷 主機 回転数フィードバックC1 */
  MegSpdFBS1: 'MegSpdFBS1',
  /** 左舷 Rex 旋回制御比例弁A出力C1 */
  RexStrPropValveAoutP1: 'RexStrPropValveAoutP1',
  /** 右舷 Rex 旋回制御比例弁A出力C1 */
  RexStrPropValveAoutS1: 'RexStrPropValveAoutS1',
  /** 左舷 Rex 旋回制御比例弁B出力C1 */
  RexStrPropValveBoutP1: 'RexStrPropValveBoutP1',
  /** 右舷 Rex 旋回制御比例弁B出力C1 */
  RexStrPropValveBoutS1: 'RexStrPropValveBoutS1',
  /** 左舷 Rex 旋回制御電磁弁A出力C1 */
  RexStrSolValveAoutP1: 'RexStrSolValveAoutP1',
  /** 右舷 Rex 旋回制御電磁弁A出力C1 */
  RexStrSolValveAoutS1: 'RexStrSolValveAoutS1',
  /** 左舷 Rex 旋回制御電磁弁B出力C1 */
  RexStrSolValveBoutP1: 'RexStrSolValveBoutP1',
  /** 右舷 Rex 旋回制御電磁弁B出力C1 */
  RexStrSolValveBoutS1: 'RexStrSolValveBoutS1',
  /** 左舷 Rex CPP比例弁A出力C1 */
  RexCPPPropValveAoutP1: 'RexCPPPropValveAoutP1',
  /** 右舷 Rex CPP比例弁A出力C1 */
  RexCPPPropValveAoutS1: 'RexCPPPropValveAoutS1',
  /** 左舷 Rex CPP比例弁B出力C1 */
  RexCPPPropValveBoutP1: 'RexCPPPropValveBoutP1',
  /** 右舷 Rex CPP比例弁B出力C1 */
  RexCPPPropValveBoutS1: 'RexCPPPropValveBoutS1',
  /** 左舷 Rex CPP電磁弁出力AHC1 */
  RexCPPSolValveAoutP1: 'RexCPPSolValveAoutP1',
  /** 右舷 Rex CPP電磁弁出力AHC1 */
  RexCPPSolValveAoutS1: 'RexCPPSolValveAoutS1',
  /** 左舷 Rex CPP電磁弁出力ASC1 */
  RexCPPSolValveBoutP1: 'RexCPPSolValveBoutP1',
  /** 右舷 Rex CPP電磁弁出力ASC1 */
  RexCPPSolValveBoutS1: 'RexCPPSolValveBoutS1',
  /** 左舷 Rex クラッチ比例弁出力C1 */
  RexClutchpropValveoutP1: 'RexClutchpropValveoutP1',
  /** 右舷 Rex クラッチ比例弁出力C1 */
  RexClutchpropValveoutS1: 'RexClutchpropValveoutS1',
  /** 左舷 Rex クラッチON/OFF電磁弁出力C1 */
  RexClutchONOFFsolValveoutP1: 'RexClutchONOFFsolValveoutP1',
  /** 右舷 Rex クラッチON/OFF電磁弁出力C1 */
  RexClutchONOFFsolValveoutS1: 'RexClutchONOFFsolValveoutS1',
  /** 左舷 Rex クラッチSLIP電磁弁出力C1 */
  RexClutchSLIPsolValveoutP1: 'RexClutchSLIPsolValveoutP1',
  /** 右舷 Rex クラッチSLIP電磁弁出力C1 */
  RexClutchSLIPsolValveoutS1: 'RexClutchSLIPsolValveoutS1',
  /** 左舷 Rex クラッチSLIP　LO電磁弁出力C1 */
  RexClutchSLIPLOsolValveoutP1: 'RexClutchSLIPLOsolValveoutP1',
  /** 右舷 Rex クラッチSLIP　LO電磁弁出力C1 */
  RexClutchSLIPLOsolValveoutS1: 'RexClutchSLIPLOsolValveoutS1',
  /** 左舷 Rex クラッチ嵌指令C1 */
  RexClutchengageorderP1: 'RexClutchengageorderP1',
  /** 右舷 Rex クラッチ嵌指令C1 */
  RexClutchengageorderS1: 'RexClutchengageorderS1',
  /** 左舷 Rex クラッチ嵌完了(F/B)C1 */
  RexClutchengagedFBP1: 'RexClutchengagedFBP1',
  /** 右舷 Rex クラッチ嵌完了(F/B)C1 */
  RexClutchengagedFBS1: 'RexClutchengagedFBS1',
  /** 左舷 Rex フォロー/ノンフォローC1 */
  RexFollowNonfollowupContP1: 'RexFollowNonfollowupContP1',
  /** 右舷 Rex フォロー/ノンフォローC1 */
  RexFollowNonfollowupContS1: 'RexFollowNonfollowupContS1',
  /** 左舷 Rex システム異常C1 */
  RexSystemAbnomalP1: 'RexSystemAbnomalP1',
  /** 右舷 Rex システム異常C1 */
  RexSystemAbnomalS1: 'RexSystemAbnomalS1',
  /** 左舷 Rex 左旋回圧力C1 */
  RexLtStrPressP1COM: 'RexLtStrPressP1COM',
  /** 右舷 Rex 左旋回圧力C1 */
  RexLtStrPressS1COM: 'RexLtStrPressS1COM',
  /** 左舷 Rex 右旋回圧力C1 */
  RexRtStrPressP1COM: 'RexRtStrPressP1COM',
  /** 右舷 Rex 右旋回圧力C1 */
  RexRtStrPressS1COM: 'RexRtStrPressS1COM',
  /** 左舷 Rex 潤滑油圧力C1 */
  RexLOPressP1RCOM: 'RexLOPressP1RCOM',
  /** 右舷 Rex 潤滑油圧力C1 */
  RexLOPressS1RCOM: 'RexLOPressS1RCOM',
  /** 左舷 Rex 潤滑油温度C1 */
  RexLOTmpP1RCOM: 'RexLOTmpP1RCOM',
  /** 右舷 Rex 潤滑油温度C1 */
  RexLOTmpS1RCOM: 'RexLOTmpS1RCOM',
  /** 左舷 Rex 旋回ポンプ吐出圧力C1 */
  RexDelPressStrPumpP1RCOM: 'RexDelPressStrPumpP1RCOM',
  /** 右舷 Rex 旋回ポンプ吐出圧力C1 */
  RexDelPressStrPumpS1RCOM: 'RexDelPressStrPumpS1RCOM',
  /** 左舷 Rex 潤滑油水分量C1 */
  RexLOCondP1COM: 'RexLOCondP1COM',
  /** 右舷 Rex 潤滑油水分量C1 */
  RexLOCondS1COM: 'RexLOCondS1COM',
  /** 左舷 Rex 潤滑油コンタミ量１C1 */
  RexLOCond1ContamP1: 'RexLOCond1ContamP1',
  /** 右舷 Rex 潤滑油コンタミ量１C1 */
  RexLOCond1ContamS1: 'RexLOCond1ContamS1',
  /** 左舷 Rex 潤滑油コンタミ量２C1 */
  RexLOCond2ContamP1: 'RexLOCond2ContamP1',
  /** 右舷 Rex 潤滑油コンタミ量２C1 */
  RexLOCond2ContamS1: 'RexLOCond2ContamS1',
  /** 左舷 Rex クラッチ制御元圧力C1 */
  RexClutchContpressP1RCOM: 'RexClutchContpressP1RCOM',
  /** 右舷 Rex クラッチ制御元圧力C1 */
  RexClutchContpressS1RCOM: 'RexClutchContpressS1RCOM',
  /** 左舷 Rex クラッチ作動圧力C1 */
  RexClutchWorkingpressP1RCOM: 'RexClutchWorkingpressP1RCOM',
  /** 右舷 Rex クラッチ作動圧力C1 */
  RexClutchWorkingpressS1RCOM: 'RexClutchWorkingpressS1RCOM',
  /** 左舷 Rex CPP　前進変節圧力C1 */
  RexCPPAHPressP1RCOM: 'RexCPPAHPressP1RCOM',
  /** 右舷 Rex CPP　前進変節圧力C1 */
  RexCPPAHPressS1RCOM: 'RexCPPAHPressS1RCOM',
  /** 左舷 Rex CPP　後進変節圧力C1 */
  RexCPPASPressP1RCOM: 'RexCPPASPressP1RCOM',
  /** 右舷 Rex CPP　後進変節圧力C1 */
  RexCPPASPressS1RCOM: 'RexCPPASPressS1RCOM',
  /** 左舷 Rex 旋回油温度C1 */
  RexStrOilTmpP1RCOM: 'RexStrOilTmpP1RCOM',
  /** 右舷 Rex 旋回油温度C1 */
  RexStrOilTmpS1RCOM: 'RexStrOilTmpS1RCOM',
  /** 左舷 Rex 旋回油水分量C1 */
  RexStrOilCondWatersatP1COM: 'RexStrOilCondWatersatP1COM',
  /** 右舷 Rex 旋回油水分量C1 */
  RexStrOilCondWatersatS1COM: 'RexStrOilCondWatersatS1COM',
  /** 左舷 Rex 旋回油コンタミ量C1 */
  RexStrOilCondContamP1: 'RexStrOilCondContamP1',
  /** 右舷 Rex 旋回油コンタミ量C1 */
  RexStrOilCondContamS1: 'RexStrOilCondContamS1',
  /** 左舷 Rex CPP制御ポンプ吐出圧力C1 */
  RexDelPressCPPContpumpP1COM: 'RexDelPressCPPContpumpP1COM',
  /** 右舷 Rex CPP制御ポンプ吐出圧力C1 */
  RexDelPressCPPContpumpS1COM: 'RexDelPressCPPContpumpS1COM',
  /** 左舷 Rex チャージポンプ吐出圧力C1 */
  RexDelPressChargepumpP1COM: 'RexDelPressChargepumpP1COM',
  /** 右舷 Rex チャージポンプ吐出圧力C1 */
  RexDelPressChargepumpS1COM: 'RexDelPressChargepumpS1COM',
  /** 左舷 Rex スリップ潤滑油ポンプ吐出圧力C1 */
  RexDelPressLOpumpP1COM: 'RexDelPressLOpumpP1COM',
  /** 右舷 Rex スリップ潤滑油ポンプ吐出圧力C1 */
  RexDelPressLOpumpS1COM: 'RexDelPressLOpumpS1COM',
  /** 左舷 Rex 潤滑油オイルクーラ入口温度C1 */
  RexLOinTmpofoilcoolerP1COM: 'RexLOinTmpofoilcoolerP1COM',
  /** 右舷 Rex 潤滑油オイルクーラ入口温度C1 */
  RexLOinTmpofoilcoolerS1COM: 'RexLOinTmpofoilcoolerS1COM',
  /** 左舷 Rex 潤滑油オイルクーラ出口温度C1 */
  RexLOoutTmpofoilcoolerP1COM: 'RexLOoutTmpofoilcoolerP1COM',
  /** 右舷 Rex 潤滑油オイルクーラ出口温度C1 */
  RexLOoutTmpofoilcoolerS1COM: 'RexLOoutTmpofoilcoolerS1COM',
  /** 左舷 Rex 冷却水オイルクーラ入口温度C1 */
  RexCWinTmpofoilcoolerP1COM: 'RexCWinTmpofoilcoolerP1COM',
  /** 右舷 Rex 冷却水オイルクーラ入口温度C1 */
  RexCWinTmpofoilcoolerS1COM: 'RexCWinTmpofoilcoolerS1COM',
  /** 左舷 Rex 冷却水オイルクーラ出口温度C1 */
  RexCWoutTmpofoilcoolerP1COM: 'RexCWoutTmpofoilcoolerP1COM',
  /** 右舷 Rex 冷却水オイルクーラ出口温度C1 */
  RexCWoutTmpofoilcoolerS1COM: 'RexCWoutTmpofoilcoolerS1COM',
  /** 左舷 Rex 冷却水流量C1 */
  RexCWflowrateP1COM: 'RexCWflowrateP1COM',
  /** 右舷 Rex 冷却水流量C1 */
  RexCWflowrateS1COM: 'RexCWflowrateS1COM',
  /** 左舷 Rex 旋回モータ１ドレン温度C1 */
  RexTmpofdrainoilHYDMotor1P1COM: 'RexTmpofdrainoilHYDMotor1P1COM',
  /** 右舷 Rex 旋回モータ１ドレン温度C1 */
  RexTmpofdrainoilHYDMotor1S1COM: 'RexTmpofdrainoilHYDMotor1S1COM',
  /** 左舷 Rex 旋回モータ２ドレン温度C1 */
  RexTmpofdrainoilHYDMotor2P1COM: 'RexTmpofdrainoilHYDMotor2P1COM',
  /** 右舷 Rex 旋回モータ２ドレン温度C1 */
  RexTmpofdrainoilHYDMotor2S1COM: 'RexTmpofdrainoilHYDMotor2S1COM',
  /** 左舷 Rex 中間軸受軸受ハウジング温度１C1 */
  RexInttSftTmp1P1COM: 'RexInttSftTmp1P1COM',
  /** 左舷 Rex 中間軸受軸受ハウジング温度２C1 */
  RexInttSftTmp2P1COM: 'RexInttSftTmp2P1COM',
  /** 左舷 Rex 中間軸受軸受ハウジング温度３C1 */
  RexInttSftTmp3P1COM: 'RexInttSftTmp3P1COM',
  /** 左舷 Rex 中間軸受軸受ハウジング温度４C1 */
  RexInttSftTmp4P1COM: 'RexInttSftTmp4P1COM',
  /** 左舷 Rex 中間軸受軸受ハウジング温度５C1 */
  RexInttSftTmp5P1COM: 'RexInttSftTmp5P1COM',
  /** 左舷 Rex 中間軸受軸受ハウジング温度６C1 */
  RexInttSftTmp6P1COM: 'RexInttSftTmp6P1COM',
  /** 右舷 Rex 中間軸受軸受ハウジング温度１C1 */
  RexInttSftTmp1S1COM: 'RexInttSftTmp1S1COM',
  /** 右舷 Rex 中間軸受軸受ハウジング温度２C1 */
  RexInttSftTmp2S1COM: 'RexInttSftTmp2S1COM',
  /** 右舷 Rex 中間軸受軸受ハウジング温度３C1 */
  RexInttSftTmp3S1COM: 'RexInttSftTmp3S1COM',
  /** 右舷 Rex 中間軸受軸受ハウジング温度４C1 */
  RexInttSftTmp4S1COM: 'RexInttSftTmp4S1COM',
  /** 右舷 Rex 中間軸受軸受ハウジング温度５C1 */
  RexInttSftTmp5S1COM: 'RexInttSftTmp5S1COM',
  /** 右舷 Rex 中間軸受軸受ハウジング温度６C1 */
  RexInttSftTmp6S1COM: 'RexInttSftTmp6S1COM',
  /** 左舷 主機 回転数C2 */
  MegSpdP1COM: 'MegSpdP1COM',
  /** 右舷 主機 回転数C2 */
  MegSpdS1COM: 'MegSpdS1COM',
  /** 左舷 主機 燃料ポンプラックレベルC2 */
  MegFuelRackLvP1COM: 'MegFuelRackLvP1COM',
  /** 右舷 主機 燃料ポンプラックレベルC2 */
  MegFuelRackLvS1COM: 'MegFuelRackLvS1COM',
  /** 左舷 主機 馬力C2 */
  MegHpP1COM: 'MegHpP1COM',
  /** 右舷 主機 馬力C2 */
  MegHpS1COM: 'MegHpS1COM',
  /** 左舷 主機 過給器出口圧力C2 */
  MegTCoutletpressP1COM: 'MegTCoutletpressP1COM',
  /** 右舷 主機 過給器出口圧力C2 */
  MegTCoutletpressS1COM: 'MegTCoutletpressS1COM',
  /** 左舷 主機 過給器回転数C2 */
  MegTCSpdP1COM: 'MegTCSpdP1COM',
  /** 右舷 主機 過給器回転数C2 */
  MegTCSpdS1COM: 'MegTCSpdS1COM',
  /** 左舷 主機 過給器過給圧C2 */
  MegTCbstpressP1COM: 'MegTCbstpressP1COM',
  /** 右舷 主機 過給器過給圧C2 */
  MegTCbstpressS1COM: 'MegTCbstpressS1COM',
  /** 左舷 主機 潤滑油圧力C2 */
  MegLOpressP1COM: 'MegLOpressP1COM',
  /** 右舷 主機 潤滑油圧力C2 */
  MegLOpressS1COM: 'MegLOpressS1COM',
  /** 左舷 主機 潤滑油温度C2 */
  MegLOTmpP1COM: 'MegLOTmpP1COM',
  /** 右舷 主機 潤滑油温度C2 */
  MegLOTmpS1COM: 'MegLOTmpS1COM',
  /** 左舷 主機 排気温度No.1シリンダC2 */
  MegNo1EXTGASTmpP1COM: 'MegNo1EXTGASTmpP1COM',
  /** 右舷 主機 排気温度No.1シリンダC2 */
  MegNo1EXTGASTmpS1COM: 'MegNo1EXTGASTmpS1COM',
  /** 左舷 主機 排気温度No.2シリンダC2 */
  MegNo2EXTGASTmpP1COM: 'MegNo2EXTGASTmpP1COM',
  /** 右舷 主機 排気温度No.2シリンダC2 */
  MegNo2EXTGASTmpS1COM: 'MegNo2EXTGASTmpS1COM',
  /** 左舷 主機 排気温度No.3シリンダC2 */
  MegNo3EXTGASTmpP1COM: 'MegNo3EXTGASTmpP1COM',
  /** 右舷 主機 排気温度No.3シリンダC2 */
  MegNo3EXTGASTmpS1COM: 'MegNo3EXTGASTmpS1COM',
  /** 左舷 主機 排気温度No.4シリンダC2 */
  MegNo4EXTGASTmpP1COM: 'MegNo4EXTGASTmpP1COM',
  /** 右舷 主機 排気温度No.4シリンダC2 */
  MegNo4EXTGASTmpS1COM: 'MegNo4EXTGASTmpS1COM',
  /** 左舷 主機 排気温度No.5シリンダC2 */
  MegNo5EXTGASTmpP1COM: 'MegNo5EXTGASTmpP1COM',
  /** 右舷 主機 排気温度No.5シリンダC2 */
  MegNo5EXTGASTmpS1COM: 'MegNo5EXTGASTmpS1COM',
  /** 左舷 主機 排気温度No.6シリンダC2 */
  MegNo6EXTGASTmpP1COM: 'MegNo6EXTGASTmpP1COM',
  /** 右舷 主機 排気温度No.6シリンダC2 */
  MegNo6EXTGASTmpS1COM: 'MegNo6EXTGASTmpS1COM',
  /** 左舷 主機 排気温度T/C入口1C2 */
  MegNo7EXTGASTmpP1COM: 'MegNo7EXTGASTmpP1COM',
  /** 右舷 主機 排気温度T/C入口1C2 */
  MegNo7EXTGASTmpS1COM: 'MegNo7EXTGASTmpS1COM',
  /** 左舷 主機 排気温度T/C入口2C2 */
  MegNo8EXTGASTmpP1COM: 'MegNo8EXTGASTmpP1COM',
  /** 右舷 主機 排気温度T/C入口2C2 */
  MegNo8EXTGASTmpS1COM: 'MegNo8EXTGASTmpS1COM',
  /** 左舷 主機 排気温度T/C出口C2 */
  MegNo9EXTGASTmpP1COM: 'MegNo9EXTGASTmpP1COM',
  /** 右舷 主機 排気温度T/C出口C2 */
  MegNo9EXTGASTmpS1COM: 'MegNo9EXTGASTmpS1COM',
  /** 左舷 主機 燃料消費率C2 */
  MegFuelconsRateP1: 'MegFuelconsRateP1',
  /** 右舷 主機 燃料消費率C2 */
  MegFuelconsRateS1: 'MegFuelconsRateS1',
  /** 発電機1 補器 発電機出力C2 */
  GENo1OutpwrC1: 'GENo1OutpwrC1',
  /** 発電機2 補器 発電機出力C2 */
  GENo2OutpwrC1: 'GENo2OutpwrC1',
  /** 発電機3 補器 発電機出力C2 */
  GENo3OutpwrC1: 'GENo3OutpwrC1',
  /** 発電機4 補器 発電機出力C2 */
  GENo4OutpwrC1: 'GENo4OutpwrC1',
  /** 左舷 Rex 潤滑油圧C2 */
  RexLOPressP1MCOM: 'RexLOPressP1MCOM',
  /** 右舷 Rex 潤滑油圧C2 */
  RexLOPressS1MCOM: 'RexLOPressS1MCOM',
  /** 左舷 Rex クラッチ制御圧力C2 */
  RexClutchWorkpressP1MCOM: 'RexClutchWorkpressP1MCOM',
  /** 右舷 Rex クラッチ制御圧力C2 */
  RexClutchWorkpressS1MCOM: 'RexClutchWorkpressS1MCOM',
  /** 左舷 Rex クラッチ制御元圧力C2 */
  RexClutchContpressP1MCOM: 'RexClutchContpressP1MCOM',
  /** 右舷 Rex クラッチ制御元圧力C2 */
  RexClutchContpressS1MCOM: 'RexClutchContpressS1MCOM',
  /** 左舷 Rex 潤滑油温度C2 */
  RexLOTmpP1MCOM: 'RexLOTmpP1MCOM',
  /** 右舷 Rex 潤滑油温度C2 */
  RexLOTmpS1MCOM: 'RexLOTmpS1MCOM',
  /** 左舷 Rex 旋回ポンプ吐出圧C2 */
  RexDelPressStrPumpP1MCOM: 'RexDelPressStrPumpP1MCOM',
  /** 右舷 Rex 旋回ポンプ吐出圧C2 */
  RexDelPressStrPumpS1MCOM: 'RexDelPressStrPumpS1MCOM',
  /** 左舷 Rex CPP作動圧(AH)C2 */
  RexCPPAHPressP1MCOM: 'RexCPPAHPressP1MCOM',
  /** 右舷 Rex CPP作動圧(AH)C2 */
  RexCPPAHPressS1MCOM: 'RexCPPAHPressS1MCOM',
  /** 左舷 Rex CPP作動圧(AS)C2 */
  RexCPPASPressP1MCOM: 'RexCPPASPressP1MCOM',
  /** 右舷 Rex CPP作動圧(AS)C2 */
  RexCPPASPressS1MCOM: 'RexCPPASPressS1MCOM',
  /** 左舷 Rex 旋回油温度C2 */
  RexStrOilTmpP1MCOM: 'RexStrOilTmpP1MCOM',
  /** 右舷 Rex 旋回油温度C2 */
  RexStrOilTmpS1MCOM: 'RexStrOilTmpS1MCOM',
  /** 船体情報 船体 緯度C2 */
  VesLatC1MCOM: 'VesLatC1MCOM',
  /** 船体情報 船体 経度C2 */
  VesLonC1MCOM: 'VesLonC1MCOM',
  /** 船体情報 船体 船速(SOG)C2 */
  VesSpdSOGC1MCOM: 'VesSpdSOGC1MCOM',
  /** 船体情報 船体 針路(真方位)C2 */
  VesCourseTrueDirC1MCOM: 'VesCourseTrueDirC1MCOM',
  /** 船体情報 船体 回頭速度(Magnetic variation)C2 */
  VesTurnSpdC1: 'VesTurnSpdC1',
  /** 船体情報 船体 船首方向C2 */
  VesHeadingC1: 'VesHeadingC1',
  /** 船体情報 船体 相対風向C2 */
  VesWindDirRelC1: 'VesWindDirRelC1',
  /** 船体情報 船体 相対風速C2 */
  VesWindSpdRelC1MCOM: 'VesWindSpdRelC1MCOM',
  /** 船体情報 船体 相対流速(船体長さ方向)C2 */
  VesWatervelocityLengthDirC1: 'VesWatervelocityLengthDirC1',
  /** 船体情報 船体 相対流速(船体横方向)C2 */
  VesWatervelocityTransvergeDirC1: 'VesWatervelocityTransvergeDirC1',
  /** 船体情報 船体 緯度C3 */
  VesLatC1GCOM: 'VesLatC1GCOM',
  /** 船体情報 船体 経度C3 */
  VesLonC1GCOM: 'VesLonC1GCOM',
  /** 船体情報 船体 船速(SOG)C3 */
  VesSpdSogC1GCOM: 'VesSpdSogC1GCOM',
  /** 船体情報 船体 針路(真方位)C3 */
  VesCourseTrueDirC1GCOM: 'VesCourseTrueDirC1GCOM',
  /** 船体情報 船体 流速(船体長さ方向)C3 */
  VesLonwaterSpdC1: 'VesLonwaterSpdC1',
  /** 船体情報 船体 流速(船体横方向)C3 */
  VesTransversewaterSpdC1: 'VesTransversewaterSpdC1',
  /** 船体情報 船体 船速(船体長さ方向)C3 */
  VesLongroundSpdC1: 'VesLongroundSpdC1',
  /** 船体情報 船体 船速(船体横方向)C3 */
  VesTransversegroundSpdC1: 'VesTransversegroundSpdC1',
  /** 船体情報 船体 風向(角度)C3 */
  VesWindDirmagnitudeindgreeC1: 'VesWindDirmagnitudeindgreeC1',
  /** 船体情報 船体 風向(方向)C3 */
  VesWindDirLRofbowC1: 'VesWindDirLRofbowC1',
  /** 船体情報 船体 風速C3 */
  VesWindSpdRelC1GCOM: 'VesWindSpdRelC1GCOM',
  /** 左舷 主機 回転数B */
  MegSpdP1RIO: 'MegSpdP1RIO',
  /** 右舷 主機 回転数B */
  MegSpdS1RIO: 'MegSpdS1RIO',
  /** 左舷 主機 燃料ポンプラックレベルB */
  MegFuelRackLvP1RIO: 'MegFuelRackLvP1RIO',
  /** 右舷 主機 燃料ポンプラックレベルB */
  MegFuelRackLvS1RIO: 'MegFuelRackLvS1RIO',
  /** 左舷 主機 馬力B */
  MegHpP1RIO: 'MegHpP1RIO',
  /** 右舷 主機 馬力B */
  MegHpS1RIO: 'MegHpS1RIO',
  /** 左舷 主機 過給器出口圧力B */
  MegTCoutpressP1RIO: 'MegTCoutpressP1RIO',
  /** 右舷 主機 過給器出口圧力B */
  MegTCoutpressS1RIO: 'MegTCoutpressS1RIO',
  /** 左舷 主機 過給器回転数B */
  MegTCSpdP1RIO: 'MegTCSpdP1RIO',
  /** 右舷 主機 過給器回転数B */
  MegTCSpdS1RIO: 'MegTCSpdS1RIO',
  /** 左舷 主機 過給器過給圧B */
  MegTCboostpressP1RIO: 'MegTCboostpressP1RIO',
  /** 右舷 主機 過給器過給圧B */
  MegTCboostpressS1RIO: 'MegTCboostpressS1RIO',
  /** 左舷 主機 潤滑油圧力B */
  MegLOpressP1RIO: 'MegLOpressP1RIO',
  /** 右舷 主機 潤滑油圧力B */
  MegLOpressS1RIO: 'MegLOpressS1RIO',
  /** スペア 　 SPARE */
  Spare7C1: 'Spare7C1',
  /** スペア 　 SPARE */
  Spare8C1: 'Spare8C1',
  /** 左舷 主機 潤滑油温度B */
  MegLOTmpP1RIO: 'MegLOTmpP1RIO',
  /** 右舷 主機 潤滑油温度B */
  MegLOTmpS1RIO: 'MegLOTmpS1RIO',
  /** 左舷 主機 排気温度No.1シリンダB */
  MegNo1EXTGASTmpP1RIO: 'MegNo1EXTGASTmpP1RIO',
  /** 右舷 主機 排気温度No.1シリンダB */
  MegNo1EXTGASTmpS1RIO: 'MegNo1EXTGASTmpS1RIO',
  /** 左舷 主機 排気温度No.2シリンダB */
  MegNo2EXTGASTmpP1RIO: 'MegNo2EXTGASTmpP1RIO',
  /** 右舷 主機 排気温度No.2シリンダB */
  MegNo2EXTGASTmpS1RIO: 'MegNo2EXTGASTmpS1RIO',
  /** 左舷 主機 排気温度No.3シリンダB */
  MegNo3EXTGASTmpP1RIO: 'MegNo3EXTGASTmpP1RIO',
  /** 右舷 主機 排気温度No.3シリンダB */
  MegNo3EXTGASTmpS1RIO: 'MegNo3EXTGASTmpS1RIO',
  /** 左舷 主機 排気温度No.4シリンダB */
  MegNo4EXTGASTmpP1RIO: 'MegNo4EXTGASTmpP1RIO',
  /** 右舷 主機 排気温度No.4シリンダB */
  MegNo4EXTGASTmpS1RIO: 'MegNo4EXTGASTmpS1RIO',
  /** 左舷 主機 排気温度No.5シリンダB */
  MegNo5EXTGASTmpP1RIO: 'MegNo5EXTGASTmpP1RIO',
  /** 右舷 主機 排気温度No.5シリンダB */
  MegNo5EXTGASTmpS1RIO: 'MegNo5EXTGASTmpS1RIO',
  /** 左舷 主機 排気温度No.6シリンダB */
  MegNo6EXTGASTmpP1RIO: 'MegNo6EXTGASTmpP1RIO',
  /** 右舷 主機 排気温度No.6シリンダB */
  MegNo6EXTGASTmpS1RIO: 'MegNo6EXTGASTmpS1RIO',
  /** 左舷 主機 排気温度T/C入口1B */
  MegNo7EXTGASTmpP1RIO: 'MegNo7EXTGASTmpP1RIO',
  /** 右舷 主機 排気温度T/C入口1B */
  MegNo7EXTGASTmpS1RIO: 'MegNo7EXTGASTmpS1RIO',
  /** 左舷 主機 排気温度T/C入口2B */
  MegNo8EXTGASTmpP1RIO: 'MegNo8EXTGASTmpP1RIO',
  /** 右舷 主機 排気温度T/C入口2B */
  MegNo8EXTGASTmpS1RIO: 'MegNo8EXTGASTmpS1RIO',
  /** 左舷 主機 排気温度T/C出口B */
  MegNo9EXTGASTmpP1RIO: 'MegNo9EXTGASTmpP1RIO',
  /** 右舷 主機 排気温度T/C出口B */
  MegNo9EXTGASTmpS1RIO: 'MegNo9EXTGASTmpS1RIO',
  /** 左舷 主機 給気温度B */
  MegInletAirTmpP1: 'MegInletAirTmpP1',
  /** 右舷 主機 給気温度B */
  MegInletAirTmpS1: 'MegInletAirTmpS1',
  /** スペア 　 SPARE */
  Spare9C1: 'Spare9C1',
  /** スペア 　 SPARE */
  Spare10C1: 'Spare10C1',
  /** 船体情報 船体 緯度 */
  VesLat: 'VesLat',
  /** 船体情報 船体 経度 */
  VesLon: 'VesLon',
  /** 船体情報 船体 船速(SOG) */
  VesSog: 'VesSog',
  /** 船体情報 船体 進路(COG) */
  VesCog: 'VesCog',
  /** 船体情報 船体 流速(船体長さ方向) */
  VesLonWaterSpd: 'VesLonWaterSpd',
  /** 船体情報 船体 流速(船体横方向) */
  VesTransverseWaterSpd: 'VesTransverseWaterSpd',
  /** 船体情報 船体 船速(船体長さ方向) */
  VesLonGroundSpd: 'VesLonGroundSpd',
  /** 船体情報 船体 船速(船体横方向) */
  VesTransverseGroundSpd: 'VesTransverseGroundSpd',
  /** 船体情報 船体 針路(Heading) */
  VesHeading: 'VesHeading',
  /** 船体情報 船体 対水船速 */
  VesLogSpeed: 'VesLogSpeed',
  /** 船体情報 船体 風向(角度) */
  VesWindDirMagnitudMagDeg: 'VesWindDirMagnitudMagDeg',
  /** 船体情報 船体 風向(方向) */
  VesWindDirLR: 'VesWindDirLR',
  /** 船体情報 船体 風速 */
  VesWindSpd: 'VesWindSpd',
  /** 右舷 Rex クラッチON/OFF電磁弁出力 */
  RexCLOnOffsolValveOutS: 'RexCLOnOffsolValveOutS',
  /** 左舷 Rex クラッチON/OFF電磁弁出力 */
  RexCLOnOffsolValveOutP: 'RexCLOnOffsolValveOutP',
  /** 右舷 Rex クラッチSLIP電磁弁出力 */
  RexCLSlipSolValveOutS: 'RexCLSlipSolValveOutS',
  /** 左舷 Rex クラッチSLIP電磁弁出力 */
  RexCLSlipSolValveOutP: 'RexCLSlipSolValveOutP',
  /** 右舷 Rex クラッチSLIP　LO電磁弁出力 */
  RexCLSlipLoSolValveOutS: 'RexCLSlipLoSolValveOutS',
  /** 左舷 Rex クラッチSLIP　LO電磁弁出力 */
  RexCLSlipLoSolValveOutP: 'RexCLSlipLoSolValveOutP',
  /** 右舷 Rex クラッチ嵌指令 */
  RexClutchEngOrderS: 'RexClutchEngOrderS',
  /** 左舷 Rex クラッチ嵌指令 */
  RexClutchEngOrderP: 'RexClutchEngOrderP',
  /** 右舷 Rex クラッチ嵌完了(F/B) */
  RexClutchEngFbS: 'RexClutchEngFbS',
  /** 左舷 Rex クラッチ嵌完了(F/B) */
  RexClutchEngFbP: 'RexClutchEngFbP',
  /** 右舷 Rex フォロー/ノンフォロー */
  RexFuNfuCtrlS: 'RexFuNfuCtrlS',
  /** 左舷 Rex フォロー/ノンフォロー */
  RexFuNfuCtrlP: 'RexFuNfuCtrlP',
  /** 右舷 Rex システム異常 */
  RexSysAbnomalS: 'RexSysAbnomalS',
  /** 左舷 Rex システム異常 */
  RexSysAbnomalP: 'RexSysAbnomalP',
  /** 右舷 Rex クラッチスリップ(F/B) */
  RexCLSlipFbS: 'RexCLSlipFbS',
  /** 左舷 Rex クラッチスリップ(F/B) */
  RexCLSlipFbP: 'RexCLSlipFbP',
  /** 右舷 Rex 回転数ハンドル中立位置 */
  RexSpdOrderHandleNtrlPosS: 'RexSpdOrderHandleNtrlPosS',
  /** 左舷 Rex 回転数ハンドル中立位置 */
  RexSpdOrderHandleNtrlPosP: 'RexSpdOrderHandleNtrlPosP',
  /** 右舷 Rex クラッチON/OFF制御中 */
  RexCLOnOffUndrCtrlS: 'RexCLOnOffUndrCtrlS',
  /** 左舷 Rex クラッチON/OFF制御中 */
  RexCLOnOffUndrCtrlP: 'RexCLOnOffUndrCtrlP',
  /** 右舷 Rex REX操縦位置 */
  RexCtrlPosS: 'RexCtrlPosS',
  /** 左舷 Rex REX操縦位置 */
  RexCtrlPosP: 'RexCtrlPosP',
  /** 右舷 Rex 旋回角ハンドルオーダ */
  RexStrAngOrderS: 'RexStrAngOrderS',
  /** 右舷 Rex 旋回角フィードバック */
  RexStrAngFbS: 'RexStrAngFbS',
  /** 右舷 Rex プロペラ回転数フィードバック */
  RexPropSpdFbS: 'RexPropSpdFbS',
  /** 左舷 Rex 旋回角ハンドルオーダ */
  RexStrAngOrderP: 'RexStrAngOrderP',
  /** 左舷 Rex 旋回角フィードバック */
  RexStrAngFbP: 'RexStrAngFbP',
  /** 左舷 Rex プロペラ回転数フィードバック */
  RexPropSpdFbP: 'RexPropSpdFbP',
  /** 右舷 Rex 左旋回圧力 */
  RexLtStrPressS: 'RexLtStrPressS',
  /** 右舷 Rex 右旋回圧力 */
  RexRtStrPressS: 'RexRtStrPressS',
  /** 右舷 Rex 潤滑油圧力 */
  RexLoPressS: 'RexLoPressS',
  /** 右舷 Rex 潤滑油温度 */
  RexLoTempS: 'RexLoTempS',
  /** 右舷 Rex 旋回油温度 */
  RexStrOilTempS: 'RexStrOilTempS',
  /** 右舷 Rex クラッチ制御元圧力 */
  RexCLCtrlPressS: 'RexCLCtrlPressS',
  /** 右舷 Rex クラッチ作動圧力 */
  RexCLWorkPressS: 'RexCLWorkPressS',
  /** 右舷 Rex チャージポンプ吐出圧力 */
  RexDelPressChgPumpS: 'RexDelPressChgPumpS',
  /** 右舷 Rex スリップ潤滑油ポンプ吐出圧力 */
  RexDelPressCLSlipLoPumpS: 'RexDelPressCLSlipLoPumpS',
  /** 左舷 Rex 左旋回圧力 */
  RexLtStrPressP: 'RexLtStrPressP',
  /** 左舷 Rex 右旋回圧力 */
  RexRtStrPressP: 'RexRtStrPressP',
  /** 左舷 Rex 潤滑油圧力 */
  RexLOPressP: 'RexLOPressP',
  /** 左舷 Rex 潤滑油温度 */
  RexLoTempP: 'RexLoTempP',
  /** 左舷 Rex 旋回油温度 */
  RexStrOilTempP: 'RexStrOilTempP',
  /** 左舷 Rex クラッチ制御元圧力 */
  RexCLCtrlPressP: 'RexCLCtrlPressP',
  /** 左舷 Rex クラッチ作動圧力 */
  RexCLWorkPressP: 'RexCLWorkPressP',
  /** 左舷 Rex チャージポンプ吐出圧力 */
  RexDelPressChgPumpP: 'RexDelPressChgPumpP',
  /** 左舷 Rex スリップ潤滑油ポンプ吐出圧力 */
  RexDelPressCLSlipLoPumpP: 'RexDelPressCLSlipLoPumpP',
  /** 右舷 Rex プロペラ回転数オーダ */
  RexPropSpdOrderS: 'RexPropSpdOrderS',
  /** 左舷 Rex プロペラ回転数オーダ */
  RexPropSpdOrderP: 'RexPropSpdOrderP',
  /** 右舷 Rex 旋回制御比例弁A出力 */
  RexStrvvAOutS: 'RexStrvvAOutS',
  /** 左舷 Rex 旋回制御比例弁A出力 */
  RexStrvvAOutP: 'RexStrvvAOutP',
  /** 右舷 Rex 旋回制御比例弁B出力 */
  RexStrvvBOutS: 'RexStrvvBOutS',
  /** 左舷 Rex 旋回制御比例弁B出力 */
  RexStrvvBOutP: 'RexStrvvBOutP',
  /** 右舷 Rex クラッチ比例弁出力 */
  RexCLPropvvOutS: 'RexCLPropvvOutS',
  /** 左舷 Rex クラッチ比例弁出力 */
  RexCLPropvvOutP: 'RexCLPropvvOutP',
  /** 右舷 Rex プロペラ回転数ハンドル位置 */
  RexPropSpdHandlePosS: 'RexPropSpdHandlePosS',
  /** 左舷 Rex プロペラ回転数ハンドル位置 */
  RexPropSpdHandlePosP: 'RexPropSpdHandlePosP',
  /** スペア Rex スペア */
  RexSpare1: 'RexSpare1',
  /** スペア Rex スペア */
  RexSpare2: 'RexSpare2',
  /** スペア Rex スペア */
  RexSpare3: 'RexSpare3',
  /** スペア Rex スペア */
  RexSpare4: 'RexSpare4',
  /** スペア Rex スペア */
  RexSpare5: 'RexSpare5',
  /** スペア Rex スペア */
  RexSpare6: 'RexSpare6',
  /** スペア Rex スペア */
  RexSpare7: 'RexSpare7',
  /** スペア Rex スペア */
  RexSpare8: 'RexSpare8',
  /** スペア Rex スペア */
  RexSpare9: 'RexSpare9',
  /** スペア Rex スペア */
  RexSpare10: 'RexSpare10',
  /** スペア Rex スペア */
  RexSpare11: 'RexSpare11',
  /** スペア Rex スペア */
  RexSpare12: 'RexSpare12',
  /** スペア Rex スペア */
  RexSpare13: 'RexSpare13',
  /** スペア Rex スペア */
  RexSpare14: 'RexSpare14',
  /** スペア Rex スペア */
  RexSpare15: 'RexSpare15',
  /** スペア Rex スペア */
  RexSpare16: 'RexSpare16',
  /** スペア Rex スペア */
  RexSpare17: 'RexSpare17',
  /** スペア Rex スペア */
  RexSpare18: 'RexSpare18',
  /** スペア Rex スペア */
  RexSpare19: 'RexSpare19',
  /** スペア Rex スペア */
  RexSpare20: 'RexSpare20',
  /** スペア Rex スペア */
  RexSpare21: 'RexSpare21',
  /** スペア Rex スペア */
  RexSpare22: 'RexSpare22',
  /** スペア Rex スペア */
  RexSpare23: 'RexSpare23',
  /** スペア Rex スペア */
  RexSpare24: 'RexSpare24',
  /** スペア Rex スペア */
  RexSpare25: 'RexSpare25',
  /** スペア Rex スペア */
  RexSpare26: 'RexSpare26',
  /** スペア Rex スペア */
  RexSpare27: 'RexSpare27',
  /** スペア Rex スペア */
  RexSpare28: 'RexSpare28',
  /** スペア Rex スペア */
  RexSpare29: 'RexSpare29',
  /** スペア Rex スペア */
  RexSpare30: 'RexSpare30',
  /** 右舷 主機 運転信号 */
  EngRunSigS: 'EngRunSigS',
  /** 右舷 主機 ハンドルポテンショ信号 */
  EngHandlePosSigS: 'EngHandlePosSigS',
  /** 右舷 主機 回転数 */
  EngSpdS: 'EngSpdS',
  /** 右舷 主機 過給器回転数#1(RAW) */
  EngTcSpdRaw1S: 'EngTcSpdRaw1S',
  /** 右舷 主機 過給器回転数#2(RAW) */
  EngTcSpdRaw2S: 'EngTcSpdRaw2S',
  /** 右舷 主機 燃料ポンプラックレベル */
  EngFuelRackLvS: 'EngFuelRackLvS',
  /** 右舷 主機 回転数負荷率 */
  EngSpdLoadRatioS: 'EngSpdLoadRatioS',
  /** 右舷 主機 馬力(定格負荷率) */
  EngSftHpS: 'EngSftHpS',
  /** 右舷 主機 出力 */
  EngOutPwrS: 'EngOutPwrS',
  /** 右舷 主機 潤滑油圧力 */
  EngLoPressS: 'EngLoPressS',
  /** 右舷 主機 冷却清水圧力 */
  EngCoolFleshWaterPressS: 'EngCoolFleshWaterPressS',
  /** 右舷 主機 冷却海水圧力 */
  EngCoolSeaWaterPressS: 'EngCoolSeaWaterPressS',
  /** 右舷 主機 燃料油圧力 */
  EngFoPressS: 'EngFoPressS',
  /** 右舷 主機 給気圧力 */
  EngChargedAirPressS: 'EngChargedAirPressS',
  /** 右舷 主機 給気温度 */
  EngChargedAirTempS: 'EngChargedAirTempS',
  /** 右舷 主機 潤滑油入口温度 */
  EngLoInTempS: 'EngLoInTempS',
  /** 右舷 主機 冷却清水出口温度 */
  EngCoolFleshWaterOutTempS: 'EngCoolFleshWaterOutTempS',
  /** 右舷 主機 排気温度No.1シリンダ */
  EngNo1ExtGasTempS: 'EngNo1ExtGasTempS',
  /** 右舷 主機 排気温度No.2シリンダ */
  EngNo2ExtGasTempS: 'EngNo2ExtGasTempS',
  /** 右舷 主機 排気温度No.3シリンダ */
  EngNo3ExtGasTempS: 'EngNo3ExtGasTempS',
  /** 右舷 主機 排気温度No.4シリンダ */
  EngNo4ExtGasTempS: 'EngNo4ExtGasTempS',
  /** 右舷 主機 排気温度No.5シリンダ */
  EngNo5ExtGasTempS: 'EngNo5ExtGasTempS',
  /** 右舷 主機 排気温度No.6シリンダ */
  EngNo6ExtGasTempS: 'EngNo6ExtGasTempS',
  /** 右舷 主機 排ガス出口平均温度 */
  EngAveExtGasTempS: 'EngAveExtGasTempS',
  /** 右舷 主機 T/C入口排気温度(145) */
  EngTcInTemp145S: 'EngTcInTemp145S',
  /** 右舷 主機 T/C入口排気温度(236) */
  EngTcInTemp236S: 'EngTcInTemp236S',
  /** 右舷 主機 T/C出口排気温度 */
  EngTcOutTempS: 'EngTcOutTempS',
  /** 左舷 主機 運転信号 */
  EngRunSigP: 'EngRunSigP',
  /** 左舷 主機 ハンドルポテンショ信号 */
  EngHandlePosSigP: 'EngHandlePosSigP',
  /** 左舷 主機 回転数 */
  EngSpdP: 'EngSpdP',
  /** 左舷 主機 過給器回転数#1(RAW) */
  EngTcSpdRaw1P: 'EngTcSpdRaw1P',
  /** 左舷 主機 過給器回転数#2(RAW) */
  EngTcSpdRaw2P: 'EngTcSpdRaw2P',
  /** 左舷 主機 燃料ポンプラックレベル */
  EngFuelRackLvP: 'EngFuelRackLvP',
  /** 左舷 主機 回転数負荷率 */
  EngSpdLoadRatioP: 'EngSpdLoadRatioP',
  /** 左舷 主機 馬力(定格負荷率) */
  EngSftHpP: 'EngSftHpP',
  /** 左舷 主機 出力 */
  EngOutPwrP: 'EngOutPwrP',
  /** 左舷 主機 潤滑油圧力 */
  EngLoPressP: 'EngLoPressP',
  /** 左舷 主機 冷却清水圧力 */
  EngCoolFleshWaterPressP: 'EngCoolFleshWaterPressP',
  /** 左舷 主機 冷却海水圧力 */
  EngCoolSeaWaterPressP: 'EngCoolSeaWaterPressP',
  /** 左舷 主機 燃料油圧力 */
  EngFoPressP: 'EngFoPressP',
  /** 左舷 主機 給気圧力 */
  EngChargedAirPressP: 'EngChargedAirPressP',
  /** 左舷 主機 給気温度 */
  EngChargedAirTempP: 'EngChargedAirTempP',
  /** 左舷 主機 潤滑油入口温度 */
  EngLoInTempP: 'EngLoInTempP',
  /** 左舷 主機 冷却清水出口温度 */
  EngCoolFleshWaterOutTempP: 'EngCoolFleshWaterOutTempP',
  /** 左舷 主機 排気温度No.1シリンダ */
  EngNo1ExtGasTempP: 'EngNo1ExtGasTempP',
  /** 左舷 主機 排気温度No.2シリンダ */
  EngNo2ExtGasTempP: 'EngNo2ExtGasTempP',
  /** 左舷 主機 排気温度No.3シリンダ */
  EngNo3ExtGasTempP: 'EngNo3ExtGasTempP',
  /** 左舷 主機 排気温度No.4シリンダ */
  EngNo4ExtGasTempP: 'EngNo4ExtGasTempP',
  /** 左舷 主機 排気温度No.5シリンダ */
  EngNo5ExtGasTempP: 'EngNo5ExtGasTempP',
  /** 左舷 主機 排気温度No.6シリンダ */
  EngNo6ExtGasTempP: 'EngNo6ExtGasTempP',
  /** 左舷 主機 排ガス出口平均温度 */
  EngAveExtGasTempP: 'EngAveExtGasTempP',
  /** 左舷 主機 T/C入口排気温度(145) */
  EngTcInTemp145P: 'EngTcInTemp145P',
  /** 左舷 主機 T/C入口排気温度(236) */
  EngTcInTemp236P: 'EngTcInTemp236P',
  /** 左舷 主機 T/C出口排気温度 */
  EngTcOutTempP: 'EngTcOutTempP',
  /** 右舷 主機 操作場所 */
  EngCntlPosS: 'EngCntlPosS',
  /** 左舷 主機 操作場所 */
  EngCntlPosP: 'EngCntlPosP',
  /** スペア 主機 スペア */
  EngSapre1: 'EngSapre1',
  /** スペア 主機 スペア */
  EngSapre2: 'EngSapre2',
  /** スペア 主機 スペア */
  EngSapre3: 'EngSapre3',
  /** スペア 主機 スペア */
  EngSapre4: 'EngSapre4',
  /** スペア 主機 スペア */
  EngSapre5: 'EngSapre5',
  /** スペア 主機 スペア */
  EngSapre6: 'EngSapre6',
  /** スペア 主機 スペア */
  EngSapre7: 'EngSapre7',
  /** スペア 主機 スペア */
  EngSapre8: 'EngSapre8',
  /** スペア 主機 スペア */
  EngSapre9: 'EngSapre9',
  /** スペア 主機 スペア */
  EngSapre10: 'EngSapre10',
  /** 機関室 主機 室温 */
  EgnRoomTemp: 'EgnRoomTemp',
  /** 機関室 主機 主機燃料流量（本日）#1(RAW) */
  EnRMeFoFlowDayRaw1: 'EnRMeFoFlowDayRaw1',
  /** 機関室 主機 主機燃料流量（本日）#2(RAW) */
  EnRMeFoFlowDayRaw2: 'EnRMeFoFlowDayRaw2',
  /** 機関室 主機 主機燃料流量（当月）#1(RAW) */
  EnRMeFoFlowMonthRaw1: 'EnRMeFoFlowMonthRaw1',
  /** 機関室 主機 主機燃料流量（当月）#2(RAW) */
  EnRMeFoFlowMonthRaw2: 'EnRMeFoFlowMonthRaw2',
  /** 機関室 主機 主機燃料流量（累計）#1(RAW) */
  EnRMeFoFlowTotalRaw1: 'EnRMeFoFlowTotalRaw1',
  /** 機関室 主機 主機燃料流量（累計）#2(RAW) */
  EnRMeFoFlowTotalRaw2: 'EnRMeFoFlowTotalRaw2',
  /** 機関室 主機 発電機燃料流量（本日）#1(RAW) */
  EnRGeFoFlowDayRaw1: 'EnRGeFoFlowDayRaw1',
  /** 機関室 主機 発電機燃料流量（本日）#2(RAW) */
  EnRGeFoFlowDayRaw2: 'EnRGeFoFlowDayRaw2',
  /** 機関室 主機 発電機燃料流量（当月）#1(RAW) */
  EnRGeFoFlowMonthRaw1: 'EnRGeFoFlowMonthRaw1',
  /** 機関室 主機 発電機燃料流量（当月）#2(RAW) */
  EnRGeFoFlowMonthRaw2: 'EnRGeFoFlowMonthRaw2',
  /** 機関室 主機 発電機燃料流量（累計）#1(RAW) */
  EnRGeFoFlowTotalRaw1: 'EnRGeFoFlowTotalRaw1',
  /** 機関室 主機 発電機燃料流量（累計）#2(RAW) */
  EnRGeFoFlowTotalRaw2: 'EnRGeFoFlowTotalRaw2',
  /** 機関室 主機 燃料瞬時消費量 */
  EnRInstantFoCnsmpt: 'EnRInstantFoCnsmpt',
  /** 右舷 主機 過給機回転数上昇 */
  EngTcSpdHighS: 'EngTcSpdHighS',
  /** 右舷 主機 過速度停止 */
  EngSDOverSpdS: 'EngSDOverSpdS',
  /** 右舷 主機 潤滑油圧力低下停止 */
  EngSDLoPressLowS: 'EngSDLoPressLowS',
  /** 右舷 主機 手動非常停止 */
  EngSDManualS: 'EngSDManualS',
  /** 右舷 主機 電子ガバナCPU異常 */
  EngGovCpuAbnlS: 'EngGovCpuAbnlS',
  /** 右舷 主機 電子ガバナFB信号喪失 */
  EngGovFbSigLostS: 'EngGovFbSigLostS',
  /** 右舷 主機 潤滑油圧力低下 */
  EngLoPressLowS: 'EngLoPressLowS',
  /** 右舷 主機 冷却清水圧力低下 */
  EngCoolFleshWaterPressLowS: 'EngCoolFleshWaterPressLowS',
  /** 右舷 主機 冷却海水圧力低下 */
  EngCoolSeaWaterPressLowS: 'EngCoolSeaWaterPressLowS',
  /** 右舷 主機 潤滑油濾器差圧上昇 */
  EngLoFilterDifPressHighS: 'EngLoFilterDifPressHighS',
  /** 右舷 主機 潤滑油入口温度上昇 */
  EngLoInTempHighS: 'EngLoInTempHighS',
  /** 右舷 主機 冷却清水出口温度上昇 */
  EngCoolWaterOutTempHighS: 'EngCoolWaterOutTempHighS',
  /** 右舷 主機 排気温度1高温警報 */
  EngNo1ExtGasTempHighS: 'EngNo1ExtGasTempHighS',
  /** 右舷 主機 排気温度2高温警報 */
  EngNo2ExtGasTempHighS: 'EngNo2ExtGasTempHighS',
  /** 右舷 主機 排気温度3高温警報 */
  EngNo3ExtGasTempHighS: 'EngNo3ExtGasTempHighS',
  /** 右舷 主機 排気温度4高温警報 */
  EngNo4ExtGasTempHighS: 'EngNo4ExtGasTempHighS',
  /** 右舷 主機 排気温度5高温警報 */
  EngNo5ExtGasTempHighS: 'EngNo5ExtGasTempHighS',
  /** 右舷 主機 排気温度6高温警報 */
  EngNo6ExtGasTempHighS: 'EngNo6ExtGasTempHighS',
  /** 右舷 主機 過給機入口温度(145)上昇 */
  EngTcInTemp145HighS: 'EngTcInTemp145HighS',
  /** 右舷 主機 過給機入口温度(236)上昇 */
  EngTcInTemp236HighS: 'EngTcInTemp236HighS',
  /** 右舷 主機 過給機出口温度上昇 */
  EngTcOutTempHighS: 'EngTcOutTempHighS',
  /** 右舷 主機 電子ガバナ速度設定信号断 */
  EngGovSpdOrderSigFailS: 'EngGovSpdOrderSigFailS',
  /** 右舷 主機 主機関燃料高圧管漏油 */
  EngFoHighPressOilLeakS: 'EngFoHighPressOilLeakS',
  /** 右舷 主機 スピードリレー異常 */
  EngSpdRelayAbnlS: 'EngSpdRelayAbnlS',
  /** 右舷 主機 主機関制御回路電源喪失 */
  EngCtrlCircuitPwrFailS: 'EngCtrlCircuitPwrFailS',
  /** 右舷 主機 主機関安全回路電源喪失 */
  EngSafetyCircuitPwrFailS: 'EngSafetyCircuitPwrFailS',
  /** 右舷 主機 主機関始動不可 */
  EngStartImpossibleS: 'EngStartImpossibleS',
  /** 右舷 主機 主機関始動失敗 */
  EngStartFailS: 'EngStartFailS',
  /** 右舷 主機 AC電源喪失 */
  EngAcPwrLostS: 'EngAcPwrLostS',
  /** 右舷 主機 AC電源回路異常 */
  EngAcPwrAbnlS: 'EngAcPwrAbnlS',
  /** 左舷 主機 過給機回転数上昇 */
  EngTcSpdHighP: 'EngTcSpdHighP',
  /** 左舷 主機 過速度停止 */
  EngSDOverSpdP: 'EngSDOverSpdP',
  /** 左舷 主機 潤滑油圧力低下停止 */
  EngSDLoPressLowP: 'EngSDLoPressLowP',
  /** 左舷 主機 手動非常停止 */
  EngSDManualP: 'EngSDManualP',
  /** 左舷 主機 電子ガバナCPU異常 */
  EngGovCpuAbnlP: 'EngGovCpuAbnlP',
  /** 左舷 主機 電子ガバナFB信号喪失 */
  EngGovFbSigLostP: 'EngGovFbSigLostP',
  /** 左舷 主機 潤滑油圧力低下 */
  EngLoPressLowP: 'EngLoPressLowP',
  /** 左舷 主機 冷却清水圧力低下 */
  EngCoolFleshWaterPressLowP: 'EngCoolFleshWaterPressLowP',
  /** 左舷 主機 冷却海水圧力低下 */
  EngCoolSeaWaterPressLowP: 'EngCoolSeaWaterPressLowP',
  /** 左舷 主機 潤滑油濾器差圧上昇 */
  EngLoFilterDifPressHighP: 'EngLoFilterDifPressHighP',
  /** 左舷 主機 潤滑油入口温度上昇 */
  EngLoInTempHighP: 'EngLoInTempHighP',
  /** 左舷 主機 冷却清水出口温度上昇 */
  EngCoolWaterOutTempHighP: 'EngCoolWaterOutTempHighP',
  /** 左舷 主機 排気温度1高温警報 */
  EngNo1ExtGasTempHighP: 'EngNo1ExtGasTempHighP',
  /** 左舷 主機 排気温度2高温警報 */
  EngNo2ExtGasTempHighP: 'EngNo2ExtGasTempHighP',
  /** 左舷 主機 排気温度3高温警報 */
  EngNo3ExtGasTempHighP: 'EngNo3ExtGasTempHighP',
  /** 左舷 主機 排気温度4高温警報 */
  EngNo4ExtGasTempHighP: 'EngNo4ExtGasTempHighP',
  /** 左舷 主機 排気温度5高温警報 */
  EngNo5ExtGasTempHighP: 'EngNo5ExtGasTempHighP',
  /** 左舷 主機 排気温度6高温警報 */
  EngNo6ExtGasTempHighP: 'EngNo6ExtGasTempHighP',
  /** 左舷 主機 過給機入口温度(145)上昇 */
  EngTcInTemp145HighP: 'EngTcInTemp145HighP',
  /** 左舷 主機 過給機入口温度(236)上昇 */
  EngTcInTemp236HighP: 'EngTcInTemp236HighP',
  /** 左舷 主機 過給機出口温度上昇 */
  EngTcOutTempHighP: 'EngTcOutTempHighP',
  /** 左舷 主機 電子ガバナ速度設定信号断 */
  EngGovSpdOrderSigFailP: 'EngGovSpdOrderSigFailP',
  /** 左舷 主機 主機関燃料高圧管漏油 */
  EngFoHighPressOilLeakP: 'EngFoHighPressOilLeakP',
  /** 左舷 主機 スピードリレー異常 */
  EngSpdRelayAbnlP: 'EngSpdRelayAbnlP',
  /** 左舷 主機 主機関制御回路電源喪失 */
  EngCtrlCircuitPwrFailP: 'EngCtrlCircuitPwrFailP',
  /** 左舷 主機 主機関安全回路電源喪失 */
  EngSafetyCircuitPwrFailP: 'EngSafetyCircuitPwrFailP',
  /** 左舷 主機 主機関始動不可 */
  EngStartImpossibleP: 'EngStartImpossibleP',
  /** 左舷 主機 主機関始動失敗 */
  EngStartFailP: 'EngStartFailP',
  /** 左舷 主機 AC電源喪失 */
  EngAcPwrLostP: 'EngAcPwrLostP',
  /** 左舷 主機 AC電源回路異常 */
  EngAcPwrAbnlP: 'EngAcPwrAbnlP',
  /** 機関室 主機 主機関制御空気元弁閉塞 */
  EnRCntlAirValveClose: 'EnRCntlAirValveClose',
  /** 機関室 主機 主機関制御空気圧力低下 */
  EnRCntlAirPressLow: 'EnRCntlAirPressLow',
  /** 機関室 主機 DC電源喪失 */
  EnRDcPowerFail: 'EnRDcPowerFail',
  /** 機関室 主機 電子ガバナDC電源喪失 */
  EnRDcPowerGovFail: 'EnRDcPowerGovFail',
  /** 機関室 主機 DC電源喪失電源電圧低下 */
  EnRDcPowerVoltLow: 'EnRDcPowerVoltLow',
  /** 機関室 主機 主空気槽圧力低下 */
  EnRAirResorverPressLow: 'EnRAirResorverPressLow',
  /** 機関室 主機 始動空気圧力低下 */
  EnRStartAirPressLow: 'EnRStartAirPressLow',
  /** 機関室 主機 モニタAC電源喪失 */
  EnRMonitorAcPwrFail: 'EnRMonitorAcPwrFail',
  /** 機関室 主機 モニタAC電源回路異常 */
  EnRMonitorAcPwrAbnl: 'EnRMonitorAcPwrAbnl',
  /** 右舷 主機 操舵室始動準備条件 */
  EngStartConditWhS: 'EngStartConditWhS',
  /** 右舷 主機 警報盤始動準備条件 */
  EngStartConditApS: 'EngStartConditApS',
  /** 左舷 主機 操舵室始動準備条件 */
  EngStartConditWhP: 'EngStartConditWhP',
  /** 左舷 主機 警報盤始動準備条件 */
  EngStartConditApP: 'EngStartConditApP',
  /** 右舷 Rex チャージポンプ吐出圧力低下 */
  RexChgPumpPressLowS: 'RexChgPumpPressLowS',
  /** 右舷 Rex 潤滑油圧力低下 */
  RexLoPressLowS: 'RexLoPressLowS',
  /** 右舷 Rex クラッチ制御圧力低下 */
  RexCLCtrlPressLowS: 'RexCLCtrlPressLowS',
  /** 右舷 Rex クラッチスリップ潤滑油圧力低下 */
  RexCLSlipLoPumpPressLowS: 'RexCLSlipLoPumpPressLowS',
  /** 右舷 Rex 旋回油温度上昇 */
  RexStrOilTempHighS: 'RexStrOilTempHighS',
  /** 右舷 Rex 潤滑油温度上昇 */
  RexLoTempHighS: 'RexLoTempHighS',
  /** 右舷 Rex 潤滑油油面低下 */
  RexLoLvLowS: 'RexLoLvLowS',
  /** 右舷 Rex 旋回油タンク油面低下 */
  ResStrOilLvLowS: 'ResStrOilLvLowS',
  /** 左舷 Rex チャージポンプ吐出圧力低下 */
  RexChgPumpPressLowP: 'RexChgPumpPressLowP',
  /** 左舷 Rex 潤滑油圧力低下 */
  RexLoPressLowP: 'RexLoPressLowP',
  /** 左舷 Rex クラッチ制御圧力低下 */
  RexCLCtrlPressLowP: 'RexCLCtrlPressLowP',
  /** 左舷 Rex クラッチスリップ潤滑油圧力低下 */
  RexCLSlipLoPumpPressLowP: 'RexCLSlipLoPumpPressLowP',
  /** 左舷 Rex 旋回油温度上昇 */
  RexStrOilTempHighP: 'RexStrOilTempHighP',
  /** 左舷 Rex 潤滑油温度上昇 */
  RexLoTempHighP: 'RexLoTempHighP',
  /** 左舷 Rex 潤滑油油面低下 */
  RexLoLvLowP: 'RexLoLvLowP',
  /** 左舷 Rex 旋回油タンク油面低下 */
  ResStrOilLvLowP: 'ResStrOilLvLowP',
  /** 右舷 Rex 補助ポンプ過負荷 */
  RexAuxPumpOverloadS: 'RexAuxPumpOverloadS',
  /** 右舷 Rex 補助ポンプ無電源 */
  RexAuxPumpNoPwrS: 'RexAuxPumpNoPwrS',
  /** 左舷 Rex 補助ポンプ過負荷 */
  RexAuxPumpOverloadP: 'RexAuxPumpOverloadP',
  /** 左舷 Rex 補助ポンプ無電源 */
  RexAuxPumpNoPwrP: 'RexAuxPumpNoPwrP',
  /** 共通 Rex REX AMS通信異常　メイン */
  RexAmsComAbnlMain: 'RexAmsComAbnlMain',
  /** 共通 Rex REX AMS通信異常　サブ */
  RexAmsComAbnlSub: 'RexAmsComAbnlSub',
  /** 共通 Rex DRV異常 */
  RexDrvAbnl: 'RexDrvAbnl',
  /** 共通 Rex 制御システム異常 */
  RexCtrlSysAbnl: 'RexCtrlSysAbnl',
  /** 共通 Rex 通信異常 */
  RexComAbnl: 'RexComAbnl',
  /** 共通 Rex 電源異常 */
  RexPwrSuppyAbnl: 'RexPwrSuppyAbnl',
  /** 共通 Rex AC電源異常 */
  RexAcPwrAbnl: 'RexAcPwrAbnl',
  /** 共通 Rex DC電源異常 */
  RexDcPwrAbnl: 'RexDcPwrAbnl',
  /** 発電機1 補器 No.1潤滑油圧力低下 */
  GenLoPressLow1: 'GenLoPressLow1',
  /** 発電機1 補器 No.1海水圧力低下 */
  GenSeaWaterPressLow1: 'GenSeaWaterPressLow1',
  /** 発電機1 補器 No.1冷却水総出口温度上昇 */
  GenCoolWaterOutPressHigh1: 'GenCoolWaterOutPressHigh1',
  /** 発電機2 補器 No.2潤滑油圧力低下 */
  GenLoPressLow2: 'GenLoPressLow2',
  /** 発電機2 補器 No.2海水圧力低下 */
  GenSeaWaterPressLow2: 'GenSeaWaterPressLow2',
  /** 発電機2 補器 No.2冷却水総出口温度上昇 */
  GenCoolWaterOutPressHigh2: 'GenCoolWaterOutPressHigh2',
  /** 発電機1 補器 1次冷却水タンク液面低下 */
  GenCoolWaterTankLvLow1: 'GenCoolWaterTankLvLow1',
  /** 発電機1 補器 No.1排気温度1高温警報 */
  GenNo1ExtGasTemp1: 'GenNo1ExtGasTemp1',
  /** 発電機1 補器 No.1排気温度2高温警報 */
  GenNo2ExtGasTemp1: 'GenNo2ExtGasTemp1',
  /** 発電機1 補器 No.1排気温度3高温警報 */
  GenNo3ExtGasTemp1: 'GenNo3ExtGasTemp1',
  /** 発電機1 補器 No.1排気温度4高温警報 */
  GenNo4ExtGasTemp1: 'GenNo4ExtGasTemp1',
  /** 発電機1 補器 No.1排気温度5高温警報 */
  GenNo5ExtGasTemp1: 'GenNo5ExtGasTemp1',
  /** 発電機1 補器 No.1排気温度6高温警報 */
  GenNo6ExtGasTemp1: 'GenNo6ExtGasTemp1',
  /** 発電機2 補器 No.2排気温度1高温警報 */
  GenNo1ExtGasTemp2: 'GenNo1ExtGasTemp2',
  /** 発電機2 補器 No.2排気温度2高温警報 */
  GenNo2ExtGasTemp2: 'GenNo2ExtGasTemp2',
  /** 発電機2 補器 No.2排気温度3高温警報 */
  GenNo3ExtGasTemp2: 'GenNo3ExtGasTemp2',
  /** 発電機2 補器 No.2排気温度4高温警報 */
  GenNo4ExtGasTemp2: 'GenNo4ExtGasTemp2',
  /** 発電機2 補器 No.2排気温度5高温警報 */
  GenNo5ExtGasTemp2: 'GenNo5ExtGasTemp2',
  /** 発電機2 補器 No.2排気温度6高温警報 */
  GenNo6ExtGasTemp2: 'GenNo6ExtGasTemp2',
  /** 機関室 主機 セントラル冷却海水圧力低下 */
  EnRCentCoolSeaWaterPressLow: 'EnRCentCoolSeaWaterPressLow',
  /** 機関室 主機 FOサービスタンク液面異常 */
  EnRFoServTankLvAbnl: 'EnRFoServTankLvAbnl',
  /** 機関室 主機 甲板機械作動油タンク液面低下 */
  EnRDeckMachyHydOilTankLvLow: 'EnRDeckMachyHydOilTankLvLow',
  /** 機関室 主機 廃油タンク液面上昇 */
  EnRWasteOilTankLvHigh: 'EnRWasteOilTankLvHigh',
  /** 機関室 主機 FO緊急遮断弁閉塞 */
  EnRFoEmgShutValveClose: 'EnRFoEmgShutValveClose',
  /** 機関室 主機 甲板機械作動油温度上昇 */
  EnRDeckMachyHydOilTempHigh: 'EnRDeckMachyHydOilTempHigh',
  /** 機関室 主機 機関室ビルジ液面上昇 */
  EnRBilgeTankLvHigh: 'EnRBilgeTankLvHigh',
  /** 右舷 主機 過給器回転数 */
  EngTcSpdS: 'EngTcSpdS',
  /** 左舷 主機 過給器回転数 */
  EngTcSpdP: 'EngTcSpdP',
  /** 機関室 主機 主機燃料流量（本日） */
  EnRMeFoFlowDay: 'EnRMeFoFlowDay',
  /** 機関室 主機 主機燃料流量（当月） */
  EnRMeFoFlowMonth: 'EnRMeFoFlowMonth',
  /** 機関室 主機 主機燃料流量（累計） */
  EnRMeFoFlowTotal: 'EnRMeFoFlowTotal',
  /** 機関室 主機 発電機燃料流量（本日） */
  EnRGeFoFlowDay: 'EnRGeFoFlowDay',
  /** 機関室 主機 発電機燃料流量（当月） */
  EnRGeFoFlowMonth: 'EnRGeFoFlowMonth',
  /** 機関室 主機 発電機燃料流量（累計） */
  MnRGeFoFlowTotal: 'MnRGeFoFlowTotal',
  /** 回転数A */
  MegSpdA: 'MegSpdA',
  /** 燃料ポンプラックレベルA */
  MegFuelRackLvA: 'MegFuelRackLvA',
  /** 出力馬力A */
  MegHpA: 'MegHpA',
  /** 過給器回転数A */
  MegTCSpdA: 'MegTCSpdA',
  /** 燃料消費量A */
  MegFuelOilConsumA: 'MegFuelOilConsumA',
  /** 潤滑油コンタミセンサA */
  RexLOContamSensorA: 'RexLOContamSensorA',
  /** 左旋回圧力B */
  RexLtStrPressB: 'RexLtStrPressB',
  /** 右旋回圧力B */
  RexRtStrPressB: 'RexRtStrPressB',
  /** 潤滑油圧力B */
  RexLOPressB: 'RexLOPressB',
  /** 旋回ポンプ吐出圧力B */
  RexDelPressStrPumpB: 'RexDelPressStrPumpB',
  /** 潤滑油水分量B */
  RexLOCondB: 'RexLOCondB',
  /** クラッチ制御元圧力B */
  RexClutchContpressB: 'RexClutchContpressB',
  /** クラッチ作動圧力B */
  RexClutchWorkpressB: 'RexClutchWorkpressB',
  /** CPP　前進変節圧力B */
  RexCPPAHPressB: 'RexCPPAHPressB',
  /** CPP　後進変節圧力B */
  RexCPPASPressB: 'RexCPPASPressB',
  /** 旋回油水分量B */
  RexStrOilCondWatersatB: 'RexStrOilCondWatersatB',
  /** CPP制御ポンプ吐出圧力B */
  RexDelPressCPPContpumpB: 'RexDelPressCPPContpumpB',
  /** チャージポンプ吐出圧力B */
  RexDelPressChargepumpB: 'RexDelPressChargepumpB',
  /** スリップ潤滑油ポンプ吐出圧力B */
  RexDelPressLOpumpB: 'RexDelPressLOpumpB',
  /** スペア */
  Spare: 'Spare',
  /** 潤滑油温度B */
  RexLOTmpB: 'RexLOTmpB',
  /** 旋回油温度B */
  RexStrOilTmpB: 'RexStrOilTmpB',
  /** 潤滑油コンタミ量(鉄系)B */
  RexLOCondContmFeB: 'RexLOCondContmFeB',
  /** 潤滑油コンタミ量(非鉄系)B */
  RexLOCondContmNFeB: 'RexLOCondContmNFeB',
  /** 旋回油コンタミ量(鉄系)B */
  RexStrOilCondContmFeB: 'RexStrOilCondContmFeB',
  /** 旋回油コンタミ量(非鉄系)B */
  RexStrOilCondContmNFeB: 'RexStrOilCondContmNFeB',
  /** 潤滑油オイルクーラ入口温度B */
  RexLOinTmpofoilcoolerB: 'RexLOinTmpofoilcoolerB',
  /** 潤滑油オイルクーラ出口温度B */
  RexLOoutTmpofoilcoolerB: 'RexLOoutTmpofoilcoolerB',
  /** 冷却水オイルクーラ入口温度B */
  RexCWinTmpofoilcoolerB: 'RexCWinTmpofoilcoolerB',
  /** 冷却水オイルクーラ出口温度B */
  RexCWoutTmpofoilcoolerB: 'RexCWoutTmpofoilcoolerB',
  /** 冷却水流量B */
  RexCwflowrateB: 'RexCwflowrateB',
  /** 旋回モータ１ドレン温度B */
  RexTmpofdrainoilHYDMotor1B: 'RexTmpofdrainoilHYDMotor1B',
  /** 旋回モータ２ドレン温度B */
  RexTmpofdrainoilHYDMotor2B: 'RexTmpofdrainoilHYDMotor2B',
  /** 中間軸受軸受ハウジング温度１B */
  RexInttSftTmp1B: 'RexInttSftTmp1B',
  /** 中間軸受軸受ハウジング温度２B */
  RexInttSftTmp2B: 'RexInttSftTmp2B',
  /** 中間軸受軸受ハウジング温度３B */
  RexInttSftTmp3B: 'RexInttSftTmp3B',
  /** 中間軸受軸受ハウジング温度４B */
  RexInttSftTmp4B: 'RexInttSftTmp4B',
  /** 中間軸受軸受ハウジング温度５B */
  RexInttSftTmp5B: 'RexInttSftTmp5B',
  /** 中間軸受軸受ハウジング温度６B */
  RexInttSftTmp6B: 'RexInttSftTmp6B',
  /** 旋回角ハンドルオーダC1 */
  RexStrAngOrderC1: 'RexStrAngOrderC1',
  /** 旋回角フィードバックC1 */
  RexStrAngFBC1: 'RexStrAngFBC1',
  /** プロペラ回転数オーダC1 */
  RexPropSpdOrderC1: 'RexPropSpdOrderC1',
  /** プロペラ回転数フィードバックC1 */
  RexPropSpdFBC1: 'RexPropSpdFBC1',
  /** CPP翼角オーダC1 */
  RexCPPBladeAngOrderC1: 'RexCPPBladeAngOrderC1',
  /** CPP翼角フィードバックC1 */
  RexCPPBladeAngFBC1: 'RexCPPBladeAngFBC1',
  /** 回転数オーダC1 */
  MegSpdorderC1: 'MegSpdorderC1',
  /** 回転数フィードバックC1 */
  MegSpdFBC1: 'MegSpdFBC1',
  /** 旋回制御比例弁A出力C1 */
  RexStrPropValveAoutC1: 'RexStrPropValveAoutC1',
  /** 旋回制御比例弁B出力C1 */
  RexStrPropValveBoutC1: 'RexStrPropValveBoutC1',
  /** 旋回制御電磁弁A出力C1 */
  RexStrSolValveAoutC1: 'RexStrSolValveAoutC1',
  /** 旋回制御電磁弁B出力C1 */
  RexStrSolValveBoutC1: 'RexStrSolValveBoutC1',
  /** CPP比例弁A出力C1 */
  RexCPPPropValveAoutC1: 'RexCPPPropValveAoutC1',
  /** CPP比例弁B出力C1 */
  RexCPPPropValveBoutC1: 'RexCPPPropValveBoutC1',
  /** CPP電磁弁出力AHC1 */
  RexCPPSolValveAoutC1: 'RexCPPSolValveAoutC1',
  /** CPP電磁弁出力ASC1 */
  RexCPPSolValveBoutC1: 'RexCPPSolValveBoutC1',
  /** クラッチ比例弁出力C1 */
  RexClutchpropValveoutC1: 'RexClutchpropValveoutC1',
  /** クラッチON/OFF電磁弁出力C1 */
  RexClutchONOFFsolValveoutC1: 'RexClutchONOFFsolValveoutC1',
  /** クラッチSLIP電磁弁出力C1 */
  RexClutchSLIPsolValveoutC1: 'RexClutchSLIPsolValveoutC1',
  /** クラッチSLIP　LO電磁弁出力C1 */
  RexClutchSLIPLOsolValveoutC1: 'RexClutchSLIPLOsolValveoutC1',
  /** クラッチ嵌指令C1 */
  RexClutchengageorderC1: 'RexClutchengageorderC1',
  /** クラッチ嵌完了(F/B)C1 */
  RexClutchengagedFBC1: 'RexClutchengagedFBC1',
  /** フォロー/ノンフォローC1 */
  RexFollowNonfollowupContC1: 'RexFollowNonfollowupContC1',
  /** システム異常C1 */
  RexSystemAbnomalC1: 'RexSystemAbnomalC1',
  /** 左旋回圧力C1 */
  RexLtStrPressC1: 'RexLtStrPressC1',
  /** 右旋回圧力C1 */
  RexRtStrPressC1: 'RexRtStrPressC1',
  /** 潤滑油圧力C1 */
  RexLOPressC1: 'RexLOPressC1',
  /** 潤滑油温度C1 */
  RexLOTmpC1: 'RexLOTmpC1',
  /** 旋回ポンプ吐出圧力C1 */
  RexDelPressStrPumpC1: 'RexDelPressStrPumpC1',
  /** 潤滑油水分量C1 */
  RexLOCondC1: 'RexLOCondC1',
  /** 潤滑油コンタミ量１C1 */
  RexLOCond1ContamC1: 'RexLOCond1ContamC1',
  /** 潤滑油コンタミ量２C1 */
  RexLOCond2ContamC1: 'RexLOCond2ContamC1',
  /** クラッチ制御元圧力C1 */
  RexClutchContpressC1: 'RexClutchContpressC1',
  /** クラッチ作動圧力C1 */
  RexClutchWorkingpressC1: 'RexClutchWorkingpressC1',
  /** CPP　前進変節圧力C1 */
  RexCPPAHPressC1: 'RexCPPAHPressC1',
  /** CPP　後進変節圧力C1 */
  RexCPPASPressC1: 'RexCPPASPressC1',
  /** 旋回油温度C1 */
  RexStrOilTmpC1: 'RexStrOilTmpC1',
  /** 旋回油水分量C1 */
  RexStrOilCondWatersatC1: 'RexStrOilCondWatersatC1',
  /** 旋回油コンタミ量C1 */
  RexStrOilCondContamC1: 'RexStrOilCondContamC1',
  /** CPP制御ポンプ吐出圧力C1 */
  RexDelPressCPPContpumpC1: 'RexDelPressCPPContpumpC1',
  /** チャージポンプ吐出圧力C1 */
  RexDelPressChargepumpC1: 'RexDelPressChargepumpC1',
  /** スリップ潤滑油ポンプ吐出圧力C1 */
  RexDelPressLOpumpC1: 'RexDelPressLOpumpC1',
  /** 潤滑油オイルクーラ入口温度C1 */
  RexLOinTmpofoilcoolerC1: 'RexLOinTmpofoilcoolerC1',
  /** 潤滑油オイルクーラ出口温度C1 */
  RexLOoutTmpofoilcoolerC1: 'RexLOoutTmpofoilcoolerC1',
  /** 冷却水オイルクーラ入口温度C1 */
  RexCWinTmpofoilcoolerC1: 'RexCWinTmpofoilcoolerC1',
  /** 冷却水オイルクーラ出口温度C1 */
  RexCWoutTmpofoilcoolerC1: 'RexCWoutTmpofoilcoolerC1',
  /** 冷却水流量C1 */
  RexCWflowrateC1: 'RexCWflowrateC1',
  /** 旋回モータ１ドレン温度C1 */
  RexTmpofdrainoilHYDMotor1C1: 'RexTmpofdrainoilHYDMotor1C1',
  /** 旋回モータ２ドレン温度C1 */
  RexTmpofdrainoilHYDMotor2C1: 'RexTmpofdrainoilHYDMotor2C1',
  /** 中間軸受軸受ハウジング温度１C1 */
  RexInttSftTmp1C1: 'RexInttSftTmp1C1',
  /** 中間軸受軸受ハウジング温度２C1 */
  RexInttSftTmp2C1: 'RexInttSftTmp2C1',
  /** 中間軸受軸受ハウジング温度３C1 */
  RexInttSftTmp3C1: 'RexInttSftTmp3C1',
  /** 中間軸受軸受ハウジング温度４C1 */
  RexInttSftTmp4C1: 'RexInttSftTmp4C1',
  /** 中間軸受軸受ハウジング温度５C1 */
  RexInttSftTmp5C1: 'RexInttSftTmp5C1',
  /** 中間軸受軸受ハウジング温度６C1 */
  RexInttSftTmp6C1: 'RexInttSftTmp6C1',
  /** 回転数C2 */
  MegSpdC2: 'MegSpdC2',
  /** 燃料ポンプラックレベルC2 */
  MegFuelRackLvC2: 'MegFuelRackLvC2',
  /** 馬力C2 */
  MegHpC2: 'MegHpC2',
  /** 過給器出口圧力C2 */
  MegTCoutletpressC2: 'MegTCoutletpressC2',
  /** 過給器回転数C2 */
  MegTCSpdC2: 'MegTCSpdC2',
  /** 過給器過給圧C2 */
  MegTCbstpressC2: 'MegTCbstpressC2',
  /** 潤滑油圧力C2 */
  MegLOpressC2: 'MegLOpressC2',
  /** 潤滑油温度C2 */
  MegLOTmpC2: 'MegLOTmpC2',
  /** 排気温度No.1シリンダC2 */
  MegNo1EXTGASTmpC2: 'MegNo1EXTGASTmpC2',
  /** 排気温度No.2シリンダC2 */
  MegNo2EXTGASTmpC2: 'MegNo2EXTGASTmpC2',
  /** 排気温度No.3シリンダC2 */
  MegNo3EXTGASTmpC2: 'MegNo3EXTGASTmpC2',
  /** 排気温度No.4シリンダC2 */
  MegNo4EXTGASTmpC2: 'MegNo4EXTGASTmpC2',
  /** 排気温度No.5シリンダC2 */
  MegNo5EXTGASTmpC2: 'MegNo5EXTGASTmpC2',
  /** 排気温度No.6シリンダC2 */
  MegNo6EXTGASTmpC2: 'MegNo6EXTGASTmpC2',
  /** 排気温度T/C入口1C2 */
  MegNo7EXTGASTmpC2: 'MegNo7EXTGASTmpC2',
  /** 排気温度T/C入口2C2 */
  MegNo8EXTGASTmpC2: 'MegNo8EXTGASTmpC2',
  /** 排気温度T/C出口C2 */
  MegNo9EXTGASTmpC2: 'MegNo9EXTGASTmpC2',
  /** 燃料消費率C2 */
  MegFuelconsRateC2: 'MegFuelconsRateC2',
  /** 発電機出力C2 */
  GENo1OutpwrC2: 'GENo1OutpwrC2',
  /** 発電機出力C2 */
  GENo2OutpwrC2: 'GENo2OutpwrC2',
  /** 発電機出力C2 */
  GENo3OutpwrC2: 'GENo3OutpwrC2',
  /** 発電機出力C2 */
  GENo4OutpwrC2: 'GENo4OutpwrC2',
  /** 潤滑油圧C2 */
  RexLOPressC2: 'RexLOPressC2',
  /** クラッチ制御圧力C2 */
  RexClutchWorkpressC2: 'RexClutchWorkpressC2',
  /** クラッチ制御元圧力C2 */
  RexClutchContpressC2: 'RexClutchContpressC2',
  /** 潤滑油温度C2 */
  RexLOTmpC2: 'RexLOTmpC2',
  /** 旋回ポンプ吐出圧C2 */
  RexDelPressStrPumpC2: 'RexDelPressStrPumpC2',
  /** CPP作動圧(AH)C2 */
  RexCPPAHPressC2: 'RexCPPAHPressC2',
  /** CPP作動圧(AS)C2 */
  RexCPPASPressC2: 'RexCPPASPressC2',
  /** 旋回油温度C2 */
  RexStrOilTmpC2: 'RexStrOilTmpC2',
  /** 座標C2 */
  VesGeoC1MCOMC2: 'VesGeoC1MCOMC2',
  /** 船速(SOG)C2 */
  VesSpdSOGC1MCOMC2: 'VesSpdSOGC1MCOMC2',
  /** 針路(真方位)C2 */
  VesCourseTrueDirC1MCOMC2: 'VesCourseTrueDirC1MCOMC2',
  /** 回頭速度(Magnetic variation)C2 */
  VesTurnSpdC2: 'VesTurnSpdC2',
  /** 船首方向C2 */
  VesHeadingC2: 'VesHeadingC2',
  /** 相対風向C2 */
  VesWindDirRelC2: 'VesWindDirRelC2',
  /** 相対風速C2 */
  VesWindSpdRelC2: 'VesWindSpdRelC2',
  /** 相対流速(船体長さ方向)C2 */
  VesWatervelocityLengthDirC2: 'VesWatervelocityLengthDirC2',
  /** 相対流速(船体横方向)C2 */
  VesWatervelocityTransvergeDirC2: 'VesWatervelocityTransvergeDirC2',
  /** 座標C3 */
  VesGeoC3: 'VesGeoC3',
  /** 船速(SOG)C3 */
  VesSpdSogC3: 'VesSpdSogC3',
  /** 針路(真方位)C3 */
  VesCourseTrueDirC3: 'VesCourseTrueDirC3',
  /** 流速(船体長さ方向)C3 */
  VesLonwaterSpdC3: 'VesLonwaterSpdC3',
  /** 流速(船体横方向)C3 */
  VesTransversewaterSpdC3: 'VesTransversewaterSpdC3',
  /** 船速(船体長さ方向)C3 */
  VesLongroundSpdC3: 'VesLongroundSpdC3',
  /** 船速(船体横方向)C3 */
  VesTransversegroundSpdC3: 'VesTransversegroundSpdC3',
  /** 風向(角度)C3 */
  VesWindDirmagnitudeindgreeC3: 'VesWindDirmagnitudeindgreeC3',
  /** 風向(方向)C3 */
  VesWindDirLRofbowC3: 'VesWindDirLRofbowC3',
  /** 風速C3 */
  VesWindSpdRelC3: 'VesWindSpdRelC3',
  /** 回転数B */
  MegSpdB: 'MegSpdB',
  /** 燃料ポンプラックレベルB */
  MegFuelRackLvB: 'MegFuelRackLvB',
  /** 馬力B */
  MegHpB: 'MegHpB',
  /** 過給器出口圧力B */
  MegTCoutpressB: 'MegTCoutpressB',
  /** 過給器回転数B */
  MegTCSpdB: 'MegTCSpdB',
  /** 過給器過給圧B */
  MegTCboostpressB: 'MegTCboostpressB',
  /** 潤滑油圧力B */
  MegLOpressB: 'MegLOpressB',
  /** 潤滑油温度B */
  MegLOTmpB: 'MegLOTmpB',
  /** 排気温度No.1シリンダB */
  MegNo1EXTGASTmpB: 'MegNo1EXTGASTmpB',
  /** 排気温度No.2シリンダB */
  MegNo2EXTGASTmpB: 'MegNo2EXTGASTmpB',
  /** 排気温度No.3シリンダB */
  MegNo3EXTGASTmpB: 'MegNo3EXTGASTmpB',
  /** 排気温度No.4シリンダB */
  MegNo4EXTGASTmpB: 'MegNo4EXTGASTmpB',
  /** 排気温度No.5シリンダB */
  MegNo5EXTGASTmpB: 'MegNo5EXTGASTmpB',
  /** 排気温度No.6シリンダB */
  MegNo6EXTGASTmpB: 'MegNo6EXTGASTmpB',
  /** 排気温度T/C入口1B */
  MegNo7EXTGASTmpB: 'MegNo7EXTGASTmpB',
  /** 排気温度T/C入口2B */
  MegNo8EXTGASTmpB: 'MegNo8EXTGASTmpB',
  /** 排気温度T/C出口B */
  MegNo9EXTGASTmpB: 'MegNo9EXTGASTmpB',
  /** 給気温度B */
  MegInletAirTmpB: 'MegInletAirTmpB',
  /** 座標 */
  VesGeo: 'VesGeo',
  /** 船速(SOG) */
  VesSogG: 'VesSogG',
  /** 進路(COG) */
  VesCogG: 'VesCogG',
  /** 流速(船体長さ方向) */
  VesLonWaterSpdG: 'VesLonWaterSpdG',
  /** 流速(船体横方向) */
  VesTransverseWaterSpdG: 'VesTransverseWaterSpdG',
  /** 船速(船体長さ方向) */
  VesLonGroundSpdG: 'VesLonGroundSpdG',
  /** 船速(船体横方向) */
  VesTransverseGroundSpdG: 'VesTransverseGroundSpdG',
  /** 針路(Heading) */
  VesHeadingG: 'VesHeadingG',
  /** 対水船速 */
  VesLogSpeedG: 'VesLogSpeedG',
  /** 風向(角度) */
  VesWindDirMagnitudMagDegG: 'VesWindDirMagnitudMagDegG',
  /** 風向(方向) */
  VesWindDirLRG: 'VesWindDirLRG',
  /** 風速 */
  VesWindSpdG: 'VesWindSpdG',
  /** クラッチON/OFF電磁弁出力 */
  RexCLOnOffsolValveOut: 'RexCLOnOffsolValveOut',
  /** クラッチSLIP電磁弁出力 */
  RexCLSlipSolValveOut: 'RexCLSlipSolValveOut',
  /** クラッチSLIP　LO電磁弁出力 */
  RexCLSlipLoSolValveOut: 'RexCLSlipLoSolValveOut',
  /** クラッチ嵌指令 */
  RexClutchEngOrder: 'RexClutchEngOrder',
  /** クラッチ嵌完了(F/B) */
  RexClutchEngFB: 'RexClutchEngFB',
  /** フォロー/ノンフォロー */
  RexFuNfuControl: 'RexFuNfuControl',
  /** システム異常 */
  RexSysAbnomal: 'RexSysAbnomal',
  /** クラッチスリップ(F/B) */
  RexCLSlipFb: 'RexCLSlipFb',
  /** 回転数ハンドル中立位置 */
  RexSpdOrdHandleNtrlPos: 'RexSpdOrdHandleNtrlPos',
  /** クラッチON/OFF制御中 */
  RexCLOnOffUndrCtrl: 'RexCLOnOffUndrCtrl',
  /** REX操縦位置 */
  RexCtrlPos: 'RexCtrlPos',
  /** 旋回角ハンドルオーダ */
  RexStrAngOrder: 'RexStrAngOrder',
  /** 旋回角フィードバック */
  RexStrAngFb: 'RexStrAngFb',
  /** プロペラ回転数フィードバック */
  RexPropSpdFb: 'RexPropSpdFb',
  /** 左旋回圧力 */
  RexLtStrPress: 'RexLtStrPress',
  /** 右旋回圧力 */
  RexRtStrPress: 'RexRtStrPress',
  /** 潤滑油圧力 */
  RexLoPress: 'RexLoPress',
  /** 潤滑油温度 */
  RexLoTemp: 'RexLoTemp',
  /** 旋回油温度 */
  RexStrOilTemp: 'RexStrOilTemp',
  /** クラッチ制御元圧力 */
  RexCLCtrlPress: 'RexCLCtrlPress',
  /** クラッチ作動圧力 */
  RexCLWorkPress: 'RexCLWorkPress',
  /** チャージポンプ吐出圧力 */
  RexDelPressChgPump: 'RexDelPressChgPump',
  /** スリップ潤滑油ポンプ吐出圧力 */
  RexDelPressCLSlipLoPump: 'RexDelPressCLSlipLoPump',
  /** プロペラ回転数オーダ */
  RexPropSpdOrder: 'RexPropSpdOrder',
  /** 旋回制御比例弁A出力 */
  RexStrvvAOut: 'RexStrvvAOut',
  /** 旋回制御比例弁B出力 */
  RexStrvvBOut: 'RexStrvvBOut',
  /** クラッチ比例弁出力 */
  RexCLPropvvOut: 'RexCLPropvvOut',
  /** プロペラ回転数ハンドル位置 */
  RexPropSpdHandlePos: 'RexPropSpdHandlePos',
  /** 運転信号 */
  EngRunSig: 'EngRunSig',
  /** ハンドルポテンショ信号 */
  EngHandlePosSig: 'EngHandlePosSig',
  /** 回転数 */
  EngSpd: 'EngSpd',
  /** 過給器回転数#1(RAW) */
  EngTcSpdRaw1: 'EngTcSpdRaw1',
  /** 過給器回転数#2(RAW) */
  EngTcSpdRaw2: 'EngTcSpdRaw2',
  /** 燃料ポンプラックレベル */
  EngFuelRackLv: 'EngFuelRackLv',
  /** 回転数負荷率 */
  EngSpdLoadRatio: 'EngSpdLoadRatio',
  /** 馬力(定格負荷率) */
  EngSftHp: 'EngSftHp',
  /** 出力 */
  EngOutPwr: 'EngOutPwr',
  /** 潤滑油圧力 */
  EngLoPress: 'EngLoPress',
  /** 冷却清水圧力 */
  EngCoolFleshWaterPress: 'EngCoolFleshWaterPress',
  /** 冷却海水圧力 */
  EngCoolSeaWaterPress: 'EngCoolSeaWaterPress',
  /** 燃料油圧力 */
  EngFoPress: 'EngFoPress',
  /** 給気圧力 */
  EngChargedAirPress: 'EngChargedAirPress',
  /** 給気温度 */
  EngChargedAirTemp: 'EngChargedAirTemp',
  /** 潤滑油入口温度 */
  EngLoInTemp: 'EngLoInTemp',
  /** 冷却清水出口温度 */
  EngCoolFleshWaterOutTemp: 'EngCoolFleshWaterOutTemp',
  /** 排気温度No.1シリンダ */
  EngNo1ExtGasTemp: 'EngNo1ExtGasTemp',
  /** 排気温度No.2シリンダ */
  EngNo2ExtGasTemp: 'EngNo2ExtGasTemp',
  /** 排気温度No.3シリンダ */
  EngNo3ExtGasTemp: 'EngNo3ExtGasTemp',
  /** 排気温度No.4シリンダ */
  EngNo4ExtGasTemp: 'EngNo4ExtGasTemp',
  /** 排気温度No.5シリンダ */
  EngNo5ExtGasTemp: 'EngNo5ExtGasTemp',
  /** 排気温度No.6シリンダ */
  EngNo6ExtGasTemp: 'EngNo6ExtGasTemp',
  /** 排ガス出口平均温度 */
  EngAveExtGasTemp: 'EngAveExtGasTemp',
  /** T/C入口排気温度(145) */
  EngTcInTemp145: 'EngTcInTemp145',
  /** T/C入口排気温度(236) */
  EngTcInTemp236: 'EngTcInTemp236',
  /** T/C出口排気温度 */
  EngTcOutTemp: 'EngTcOutTemp',
  /** 操作場所 */
  EngCntlPos: 'EngCntlPos',
  /** 室温 */
  EnRTemp: 'EnRTemp',
  /** 主機燃料流量（本日）#1(RAW) */
  EnRMeFoFlowDayRaw1G: 'EnRMeFoFlowDayRaw1G',
  /** 主機燃料流量（本日）#2(RAW) */
  EnRMeFoFlowDayRaw2G: 'EnRMeFoFlowDayRaw2G',
  /** 主機燃料流量（当月）#1(RAW) */
  EnRMeFoFlowMonthRaw1G: 'EnRMeFoFlowMonthRaw1G',
  /** 主機燃料流量（当月）#2(RAW) */
  EnRMeFoFlowMonthRaw2G: 'EnRMeFoFlowMonthRaw2G',
  /** 主機燃料流量（累計）#1(RAW) */
  EnRMeFoFlowTotalRaw1G: 'EnRMeFoFlowTotalRaw1G',
  /** 主機燃料流量（累計）#2(RAW) */
  EnRMeFoFlowTotalRaw2G: 'EnRMeFoFlowTotalRaw2G',
  /** 発電機燃料流量（本日）#1(RAW) */
  EnRGeFoFlowDayRaw1G: 'EnRGeFoFlowDayRaw1G',
  /** 発電機燃料流量（本日）#2(RAW) */
  EnRGeFoFlowDayRaw2G: 'EnRGeFoFlowDayRaw2G',
  /** 発電機燃料流量（当月）#1(RAW) */
  EnRGeFoFlowMonthRaw1G: 'EnRGeFoFlowMonthRaw1G',
  /** 発電機燃料流量（当月）#2(RAW) */
  EnRGeFoFlowMonthRaw2G: 'EnRGeFoFlowMonthRaw2G',
  /** 発電機燃料流量（累計）#1(RAW) */
  EnRGeFoFlowTotalRaw1G: 'EnRGeFoFlowTotalRaw1G',
  /** 発電機燃料流量（累計）#2(RAW) */
  EnRGeFoFlowTotalRaw2G: 'EnRGeFoFlowTotalRaw2G',
  /** 燃料瞬時消費量 */
  EnRInstantFoCnsmptG: 'EnRInstantFoCnsmptG',
  /** 過給機回転数上昇 */
  EngTcSpdHigh: 'EngTcSpdHigh',
  /** 過速度停止 */
  EngSDOverSpd: 'EngSDOverSpd',
  /** 潤滑油圧力低下停止 */
  EngSDLoPressLow: 'EngSDLoPressLow',
  /** 手動非常停止 */
  EngSDManual: 'EngSDManual',
  /** 電子ガバナCPU異常 */
  EngGovCpuAbnl: 'EngGovCpuAbnl',
  /** 電子ガバナFB信号喪失 */
  EngGovFbSigLost: 'EngGovFbSigLost',
  /** 潤滑油圧力低下 */
  EngLoPressLow: 'EngLoPressLow',
  /** 冷却清水圧力低下 */
  EngCoolFleshWaterPressLow: 'EngCoolFleshWaterPressLow',
  /** 冷却海水圧力低下 */
  EngCoolSeaWaterPressLow: 'EngCoolSeaWaterPressLow',
  /** 潤滑油濾器差圧上昇 */
  EngLoFilterDifPressHigh: 'EngLoFilterDifPressHigh',
  /** 潤滑油入口温度上昇 */
  EngLoInTempHigh: 'EngLoInTempHigh',
  /** 冷却清水出口温度上昇 */
  EngCoolWaterOutTempHigh: 'EngCoolWaterOutTempHigh',
  /** 排気温度1高温警報 */
  EngNo1ExtGasTempHigh: 'EngNo1ExtGasTempHigh',
  /** 排気温度2高温警報 */
  EngNo2ExtGasTempHigh: 'EngNo2ExtGasTempHigh',
  /** 排気温度3高温警報 */
  EngNo3ExtGasTempHigh: 'EngNo3ExtGasTempHigh',
  /** 排気温度4高温警報 */
  EngNo4ExtGasTempHigh: 'EngNo4ExtGasTempHigh',
  /** 排気温度5高温警報 */
  EngNo5ExtGasTempHigh: 'EngNo5ExtGasTempHigh',
  /** 排気温度6高温警報 */
  EngNo6ExtGasTempHigh: 'EngNo6ExtGasTempHigh',
  /** 過給機入口温度(145)上昇 */
  EngTcInTemp145High: 'EngTcInTemp145High',
  /** 過給機入口温度(236)上昇 */
  EngTcInTemp236High: 'EngTcInTemp236High',
  /** 過給機出口温度上昇 */
  EngTcOutTempHigh: 'EngTcOutTempHigh',
  /** 電子ガバナ速度設定信号断 */
  EngGovSpdOrderSigFail: 'EngGovSpdOrderSigFail',
  /** 主機関燃料高圧管漏油 */
  EngFoHighPressOilLeak: 'EngFoHighPressOilLeak',
  /** スピードリレー異常 */
  EngSpdRelayAbnl: 'EngSpdRelayAbnl',
  /** 主機関制御回路電源喪失 */
  EngCtrlCircuitPwrFail: 'EngCtrlCircuitPwrFail',
  /** 主機関安全回路電源喪失 */
  EngSafetyCircuitPwrFail: 'EngSafetyCircuitPwrFail',
  /** 主機関始動不可 */
  EngStartImpossible: 'EngStartImpossible',
  /** 主機関始動失敗 */
  EngStartFail: 'EngStartFail',
  /** AC電源喪失 */
  EngAcPwrLost: 'EngAcPwrLost',
  /** AC電源回路異常 */
  EngAcPwrAbnl: 'EngAcPwrAbnl',
  /** 主機関制御空気元弁閉塞 */
  EnRCntlAirValveCloseG: 'EnRCntlAirValveCloseG',
  /** 主機関制御空気圧力低下 */
  EnRCntlAirPressLowG: 'EnRCntlAirPressLowG',
  /** DC電源喪失 */
  EnRDcPowerFailG: 'EnRDcPowerFailG',
  /** 電子ガバナDC電源喪失 */
  EnRDcPowerGovFailG: 'EnRDcPowerGovFailG',
  /** DC電源喪失電源電圧低下 */
  EnRDcPowerVoltLowG: 'EnRDcPowerVoltLowG',
  /** 主空気槽圧力低下 */
  EnRAirResorverPressLowG: 'EnRAirResorverPressLowG',
  /** 始動空気圧力低下 */
  EnRStartAirPressLowG: 'EnRStartAirPressLowG',
  /** モニタAC電源喪失 */
  EnRMonitorAcPwrFailG: 'EnRMonitorAcPwrFailG',
  /** モニタAC電源回路異常 */
  EnRMonitorAcPwrAbnlG: 'EnRMonitorAcPwrAbnlG',
  /** 操舵室始動準備条件 */
  EngStartConditWh: 'EngStartConditWh',
  /** 警報盤始動準備条件 */
  EngStartConditAp: 'EngStartConditAp',
  /** チャージポンプ吐出圧力低下 */
  RexChgPumpPressLow: 'RexChgPumpPressLow',
  /** 潤滑油圧力低下 */
  RexLoPressLow: 'RexLoPressLow',
  /** クラッチ制御圧力低下 */
  RexCLCtrlPressLow: 'RexCLCtrlPressLow',
  /** クラッチスリップ潤滑油圧力低下 */
  RexCLSlipLoPumpPressLow: 'RexCLSlipLoPumpPressLow',
  /** 旋回油温度上昇 */
  RexStrOilTempHigh: 'RexStrOilTempHigh',
  /** 潤滑油温度上昇 */
  RexLoTempHigh: 'RexLoTempHigh',
  /** 潤滑油油面低下 */
  RexLoLvLow: 'RexLoLvLow',
  /** 旋回油タンク油面低下 */
  ResStrOilLvLow: 'ResStrOilLvLow',
  /** 補助ポンプ過負荷 */
  RexAuxPumpOverload: 'RexAuxPumpOverload',
  /** 補助ポンプ無電源 */
  RexAuxPumpNoPwr: 'RexAuxPumpNoPwr',
  /** REX AMS通信異常　メイン */
  RexAmsComAbnlMainG: 'RexAmsComAbnlMainG',
  /** REX AMS通信異常　サブ */
  RexAmsComAbnlSubG: 'RexAmsComAbnlSubG',
  /** DRV異常 */
  RexDrvAbnlG: 'RexDrvAbnlG',
  /** 制御システム異常 */
  RexCtrlSysAbnlG: 'RexCtrlSysAbnlG',
  /** 通信異常 */
  RexComAbnlG: 'RexComAbnlG',
  /** 電源異常 */
  RexPwrSuppyAbnlG: 'RexPwrSuppyAbnlG',
  /** AC電源異常 */
  RexAcPwrAbnlG: 'RexAcPwrAbnlG',
  /** DC電源異常 */
  RexDcPwrAbnlG: 'RexDcPwrAbnlG',
  /** No.1潤滑油圧力低下 */
  GenLoPressLow1G: 'GenLoPressLow1G',
  /** No.1海水圧力低下 */
  GenSeaWaterPressLow1G: 'GenSeaWaterPressLow1G',
  /** No.1冷却水総出口温度上昇 */
  GenCoolWaterOutPressHigh1G: 'GenCoolWaterOutPressHigh1G',
  /** No.2潤滑油圧力低下 */
  GenLoPressLow2G: 'GenLoPressLow2G',
  /** No.2海水圧力低下 */
  GenSeaWaterPressLow2G: 'GenSeaWaterPressLow2G',
  /** No.2冷却水総出口温度上昇 */
  GenCoolWaterOutPressHigh2G: 'GenCoolWaterOutPressHigh2G',
  /** 1次冷却水タンク液面低下 */
  GenCoolWaterTankLvLow1G: 'GenCoolWaterTankLvLow1G',
  /** No.1排気温度1高温警報 */
  GenNo1ExtGasTemp1G: 'GenNo1ExtGasTemp1G',
  /** No.1排気温度2高温警報 */
  GenNo2ExtGasTemp1G: 'GenNo2ExtGasTemp1G',
  /** No.1排気温度3高温警報 */
  GenNo3ExtGasTemp1G: 'GenNo3ExtGasTemp1G',
  /** No.1排気温度4高温警報 */
  GenNo4ExtGasTemp1G: 'GenNo4ExtGasTemp1G',
  /** No.1排気温度5高温警報 */
  GenNo5ExtGasTemp1G: 'GenNo5ExtGasTemp1G',
  /** No.1排気温度6高温警報 */
  GenNo6ExtGasTemp1G: 'GenNo6ExtGasTemp1G',
  /** No.2排気温度1高温警報 */
  GenNo1ExtGasTemp2G: 'GenNo1ExtGasTemp2G',
  /** No.2排気温度2高温警報 */
  GenNo2ExtGasTemp2G: 'GenNo2ExtGasTemp2G',
  /** No.2排気温度3高温警報 */
  GenNo3ExtGasTemp2G: 'GenNo3ExtGasTemp2G',
  /** No.2排気温度4高温警報 */
  GenNo4ExtGasTemp2G: 'GenNo4ExtGasTemp2G',
  /** No.2排気温度5高温警報 */
  GenNo5ExtGasTemp2G: 'GenNo5ExtGasTemp2G',
  /** No.2排気温度6高温警報 */
  GenNo6ExtGasTemp2G: 'GenNo6ExtGasTemp2G',
  /** セントラル冷却海水圧力低下 */
  EnRCentCoolSeaWaterPressLowG: 'EnRCentCoolSeaWaterPressLowG',
  /** FOサービスタンク液面異常 */
  EnRFoServTankLvAbnlG: 'EnRFoServTankLvAbnlG',
  /** 甲板機械作動油タンク液面低下 */
  EnRDeckMachyHydOilTankLvLowG: 'EnRDeckMachyHydOilTankLvLowG',
  /** 廃油タンク液面上昇 */
  EnRWasteOilTankLvHighG: 'EnRWasteOilTankLvHighG',
  /** FO緊急遮断弁閉塞 */
  EnRFoEmgShutValveCloseG: 'EnRFoEmgShutValveCloseG',
  /** 甲板機械作動油温度上昇 */
  EnRDeckMachyHydOilTempHighG: 'EnRDeckMachyHydOilTempHighG',
  /** 機関室ビルジ液面上昇 */
  EnRBilgeTankLvHighG: 'EnRBilgeTankLvHighG',
  /** 過給器回転数 */
  EngTcSpd: 'EngTcSpd',
  /** 主機燃料流量（本日） */
  EnRMeFoFlowDayG: 'EnRMeFoFlowDayG',
  /** 主機燃料流量（当月） */
  EnRMeFoFlowMonthG: 'EnRMeFoFlowMonthG',
  /** 主機燃料流量（累計） */
  EnRMeFoFlowTotalG: 'EnRMeFoFlowTotalG',
  /** 発電機燃料流量（本日） */
  EnRGeFoFlowDayG: 'EnRGeFoFlowDayG',
  /** 発電機燃料流量（当月） */
  EnRGeFoFlowMonthG: 'EnRGeFoFlowMonthG',
  /** 発電機燃料流量（累計） */
  MnRGeFoFlowTotalG: 'MnRGeFoFlowTotalG',
  /** 左舷 BHP */
  AnalysisMegBhpP1: 'AnalysisMegBhpP1',
  /** 右舷 BHP */
  AnalysisMegBhpS1: 'AnalysisMegBhpS1',
  /** 左舷 回転数A */
  AnalysisMegSpdP1: 'AnalysisMegSpdP1',
  /** 右舷 回転数A */
  AnalysisMegSpdS1: 'AnalysisMegSpdS1',
  /** 左舷 回転数 */
  AnalysisEngSpdP: 'AnalysisEngSpdP',
  /** 右舷 回転数 */
  AnalysisEngSpdS: 'AnalysisEngSpdS',
  /** 船速 */
  AnalysisVesSpdSog: 'AnalysisVesSpdSog',
  /** No1 */
  AnalysisMegNo1EXTGASTmpP1: 'AnalysisMegNo1EXTGASTmpP1',
  /** No2 */
  AnalysisMegNo2EXTGASTmpP1: 'AnalysisMegNo2EXTGASTmpP1',
  /** No3 */
  AnalysisMegNo3EXTGASTmpP1: 'AnalysisMegNo3EXTGASTmpP1',
  /** No4 */
  AnalysisMegNo4EXTGASTmpP1: 'AnalysisMegNo4EXTGASTmpP1',
  /** No5 */
  AnalysisMegNo5EXTGASTmpP1: 'AnalysisMegNo5EXTGASTmpP1',
  /** No6 */
  AnalysisMegNo6EXTGASTmpP1: 'AnalysisMegNo6EXTGASTmpP1',
  /** No7 */
  AnalysisMegNo7EXTGASTmpP1: 'AnalysisMegNo7EXTGASTmpP1',
  /** No8 */
  AnalysisMegNo8EXTGASTmpP1: 'AnalysisMegNo8EXTGASTmpP1',
  /** No9 */
  AnalysisMegNo9EXTGASTmpP1: 'AnalysisMegNo9EXTGASTmpP1',
  /** 給気 */
  AnalysisMegInletAirTmpP1: 'AnalysisMegInletAirTmpP1',
  /** 主機負荷率 */
  AnalysisMegHp: 'AnalysisMegHp',
  /** 0%以上-20%未満 */
  AnalysisMegHpLevel1: 'AnalysisMegHpLevel1',
  /** 20%以上-40%未満 */
  AnalysisMegHpLevel2: 'AnalysisMegHpLevel2',
  /** 40%以上-60%未満 */
  AnalysisMegHpLevel3: 'AnalysisMegHpLevel3',
  /** 60%以上-80%未満 */
  AnalysisMegHpLevel4: 'AnalysisMegHpLevel4',
  /** 80%以上-100%未満 */
  AnalysisMegHpLevel5: 'AnalysisMegHpLevel5',
  /** 100%以上 */
  AnalysisMegHpLevel6: 'AnalysisMegHpLevel6',
  /** 旋回操作量 */
  AnalysisRexStrAngFB: 'AnalysisRexStrAngFB',
  /** CPP操作量 */
  AnalysisRexCPPBladeAngFB: 'AnalysisRexCPPBladeAngFB',
  /** BHP/軸 */
  AnalysisMegBhpYTitle: 'AnalysisMegBhpYTitle',
  /** 排気温度 */
  AnalysisMegEXTGASTmpYTitle: 'AnalysisMegEXTGASTmpYTitle',
  /** 給気温度 */
  AnalysisMegInletAirTmpYTitle: 'AnalysisMegInletAirTmpYTitle',
  /** シリンダ名 */
  AnalysisMegEXTGASTmpXTitle: 'AnalysisMegEXTGASTmpXTitle',
  /** 運転時間 */
  AnalysisMenuLoadBandAnalysisYTitle: 'AnalysisMenuLoadBandAnalysisYTitle',
  /** 日付 */
  AnalysisMenuLoadBandAnalysisXTitle: 'AnalysisMenuLoadBandAnalysisXTitle',
  /** 操作量 */
  AnalysisRexAngXTitle: 'AnalysisRexAngXTitle',
  /** 日時 */
  AnalysisHoverInfoLabelDate: 'AnalysisHoverInfoLabelDate',
  /** No.1&lt;br&gt;シリンダ */
  AnalysisMegTempNo1Label: 'AnalysisMegTempNo1Label',
  /** No.2&lt;br&gt;シリンダ */
  AnalysisMegTempNo2Label: 'AnalysisMegTempNo2Label',
  /** No.3&lt;br&gt;シリンダ */
  AnalysisMegTempNo3Label: 'AnalysisMegTempNo3Label',
  /** No.4&lt;br&gt;シリンダ */
  AnalysisMegTempNo4Label: 'AnalysisMegTempNo4Label',
  /** No.5&lt;br&gt;シリンダ */
  AnalysisMegTempNo5Label: 'AnalysisMegTempNo5Label',
  /** No.6&lt;br&gt;シリンダ */
  AnalysisMegTempNo6Label: 'AnalysisMegTempNo6Label',
  /** T/C入口1&lt;br&gt;(S1,3,5) */
  AnalysisMegTempNo7Label: 'AnalysisMegTempNo7Label',
  /** T/C入口1&lt;br&gt;(S2,4,6) */
  AnalysisMegTempNo8Label: 'AnalysisMegTempNo8Label',
  /** T/C出口 */
  AnalysisMegTempNo9Label: 'AnalysisMegTempNo9Label',
  /** 給気 */
  AnalysisMegTempInLabel: 'AnalysisMegTempInLabel',
  /** 総運転時間 */
  AnalysisTotalDrivingTimeLabel: 'AnalysisTotalDrivingTimeLabel',
  /** 主機回転数A */
  MimicMegSpdP1: 'MimicMegSpdP1',
  /** LO圧力C2 */
  MimicMegLOpressP1COM: 'MimicMegLOpressP1COM',
  /** T/C回転数A */
  MimicMegTCSpdP1: 'MimicMegTCSpdP1',
  /** T/C過給圧C2 */
  MimicMegTCbstpressP1COM: 'MimicMegTCbstpressP1COM',
  /** LO温度C2 */
  MimicMegLOTmpP1COM: 'MimicMegLOTmpP1COM',
  /** 燃料\nポンプラックA */
  MimicMegFuelRackLvP1: 'MimicMegFuelRackLvP1',
  /** 出力馬力A */
  MimicMegHpP1: 'MimicMegHpP1',
  /** No.1C2 */
  MimicMegNo1EXTGASTmpP1COM: 'MimicMegNo1EXTGASTmpP1COM',
  /** No.2C2 */
  MimicMegNo2EXTGASTmpP1COM: 'MimicMegNo2EXTGASTmpP1COM',
  /** No.3C2 */
  MimicMegNo3EXTGASTmpP1COM: 'MimicMegNo3EXTGASTmpP1COM',
  /** No.4C2 */
  MimicMegNo4EXTGASTmpP1COM: 'MimicMegNo4EXTGASTmpP1COM',
  /** No.5C2 */
  MimicMegNo5EXTGASTmpP1COM: 'MimicMegNo5EXTGASTmpP1COM',
  /** No.6C2 */
  MimicMegNo6EXTGASTmpP1COM: 'MimicMegNo6EXTGASTmpP1COM',
  /** T/C入口1C2 */
  MimicMegNo7EXTGASTmpP1COM: 'MimicMegNo7EXTGASTmpP1COM',
  /** T/C入口2C2 */
  MimicMegNo8EXTGASTmpP1COM: 'MimicMegNo8EXTGASTmpP1COM',
  /** T/C出口C2 */
  MimicMegNo9EXTGASTmpP1COM: 'MimicMegNo9EXTGASTmpP1COM',
  /** LO圧力C2 */
  MimicRexLOPressP1MCOM: 'MimicRexLOPressP1MCOM',
  /** LO温度B */
  MimicRexLOTmpP1RIO: 'MimicRexLOTmpP1RIO',
  /** 旋回角指令C1 */
  MimicRexStrAngOrderP1: 'MimicRexStrAngOrderP1',
  /** 旋回角F/B C1 */
  MimicRexStrAngFBP1: 'MimicRexStrAngFBP1',
  /** CPP指令C1 */
  MimicRexCPPBladeAngOrderP1: 'MimicRexCPPBladeAngOrderP1',
  /** CPP F/B C1 */
  MimicRexCPPBladeAngFBP1: 'MimicRexCPPBladeAngFBP1',
  /** 主機回転数A */
  MimicMegSpdS1: 'MimicMegSpdS1',
  /** LO圧力C2 */
  MimicMegLOpressS1COM: 'MimicMegLOpressS1COM',
  /** T/C回転数A */
  MimicMegTCSpdS1: 'MimicMegTCSpdS1',
  /** T/C過給圧C2 */
  MimicMegTCbstpressS1COM: 'MimicMegTCbstpressS1COM',
  /** LO温度C2 */
  MimicMegLOTmpS1COM: 'MimicMegLOTmpS1COM',
  /** 燃料\nポンプラックA */
  MimicMegFuelRackLvS1: 'MimicMegFuelRackLvS1',
  /** 出力馬力A */
  MimicMegHpS1: 'MimicMegHpS1',
  /** No.1C2 */
  MimicMegNo1EXTGASTmpS1COM: 'MimicMegNo1EXTGASTmpS1COM',
  /** No.2C2 */
  MimicMegNo2EXTGASTmpS1COM: 'MimicMegNo2EXTGASTmpS1COM',
  /** No.3C2 */
  MimicMegNo3EXTGASTmpS1COM: 'MimicMegNo3EXTGASTmpS1COM',
  /** No.4C2 */
  MimicMegNo4EXTGASTmpS1COM: 'MimicMegNo4EXTGASTmpS1COM',
  /** No.5C2 */
  MimicMegNo5EXTGASTmpS1COM: 'MimicMegNo5EXTGASTmpS1COM',
  /** No.6C2 */
  MimicMegNo6EXTGASTmpS1COM: 'MimicMegNo6EXTGASTmpS1COM',
  /** T/C入口1C2 */
  MimicMegNo7EXTGASTmpS1COM: 'MimicMegNo7EXTGASTmpS1COM',
  /** T/C入口2C2 */
  MimicMegNo8EXTGASTmpS1COM: 'MimicMegNo8EXTGASTmpS1COM',
  /** T/C出口C2 */
  MimicMegNo9EXTGASTmpS1COM: 'MimicMegNo9EXTGASTmpS1COM',
  /** LO圧力C2 */
  MimicRexLOPressS1MCOM: 'MimicRexLOPressS1MCOM',
  /** LO温度B */
  MimicRexLOTmpS1RIO: 'MimicRexLOTmpS1RIO',
  /** 旋回角指令C1 */
  MimicRexStrAngOrderS1: 'MimicRexStrAngOrderS1',
  /** 旋回角F/B C1 */
  MimicRexStrAngFBS1: 'MimicRexStrAngFBS1',
  /** CPP指令C1 */
  MimicRexCPPBladeAngOrderS1: 'MimicRexCPPBladeAngOrderS1',
  /** CPP F/B C1 */
  MimicRexCPPBladeAngFBS1: 'MimicRexCPPBladeAngFBS1',
  /** T/C過給圧B */
  MimicMegTCboostpressP1RIO: 'MimicMegTCboostpressP1RIO',
  /** 給気B */
  MimicMegInletAirTmpP1: 'MimicMegInletAirTmpP1',
  /** No.1B */
  MimicMegNo1EXTGASTmpP1RIO: 'MimicMegNo1EXTGASTmpP1RIO',
  /** No.2B */
  MimicMegNo2EXTGASTmpP1RIO: 'MimicMegNo2EXTGASTmpP1RIO',
  /** No.3B */
  MimicMegNo3EXTGASTmpP1RIO: 'MimicMegNo3EXTGASTmpP1RIO',
  /** No.4B */
  MimicMegNo4EXTGASTmpP1RIO: 'MimicMegNo4EXTGASTmpP1RIO',
  /** No.5B */
  MimicMegNo5EXTGASTmpP1RIO: 'MimicMegNo5EXTGASTmpP1RIO',
  /** No.6B */
  MimicMegNo6EXTGASTmpP1RIO: 'MimicMegNo6EXTGASTmpP1RIO',
  /** T/C入口1B */
  MimicMegNo7EXTGASTmpP1RIO: 'MimicMegNo7EXTGASTmpP1RIO',
  /** T/C入口2B */
  MimicMegNo8EXTGASTmpP1RIO: 'MimicMegNo8EXTGASTmpP1RIO',
  /** T/C出口B */
  MimicMegNo9EXTGASTmpP1RIO: 'MimicMegNo9EXTGASTmpP1RIO',
  /** T/C過給圧B */
  MimicMegTCboostpressS1RIO: 'MimicMegTCboostpressS1RIO',
  /** 給気B */
  MimicMegInletAirTmpS1: 'MimicMegInletAirTmpS1',
  /** No.1B */
  MimicMegNo1EXTGASTmpS1RIO: 'MimicMegNo1EXTGASTmpS1RIO',
  /** No.2B */
  MimicMegNo2EXTGASTmpS1RIO: 'MimicMegNo2EXTGASTmpS1RIO',
  /** No.3B */
  MimicMegNo3EXTGASTmpS1RIO: 'MimicMegNo3EXTGASTmpS1RIO',
  /** No.4B */
  MimicMegNo4EXTGASTmpS1RIO: 'MimicMegNo4EXTGASTmpS1RIO',
  /** No.5B */
  MimicMegNo5EXTGASTmpS1RIO: 'MimicMegNo5EXTGASTmpS1RIO',
  /** No.6B */
  MimicMegNo6EXTGASTmpS1RIO: 'MimicMegNo6EXTGASTmpS1RIO',
  /** T/C入口1B */
  MimicMegNo7EXTGASTmpS1RIO: 'MimicMegNo7EXTGASTmpS1RIO',
  /** T/C入口2B */
  MimicMegNo8EXTGASTmpS1RIO: 'MimicMegNo8EXTGASTmpS1RIO',
  /** T/C出口B */
  MimicMegNo9EXTGASTmpS1RIO: 'MimicMegNo9EXTGASTmpS1RIO',
  /** 主機回転数 */
  MimicEngSpdP: 'MimicEngSpdP',
  /** 燃料\nポンプラック */
  MimicEngFuelRackLvP: 'MimicEngFuelRackLvP',
  /** 出力馬力 */
  MimicEngSftHpP: 'MimicEngSftHpP',
  /** T/C回転数 */
  MimicEngTcSpdP: 'MimicEngTcSpdP',
  /** 主機回転数 */
  MimicEngSpdS: 'MimicEngSpdS',
  /** 燃料\nポンプラック */
  MimicEngFuelRackLvS: 'MimicEngFuelRackLvS',
  /** 出力馬力 */
  MimicEngSftHpS: 'MimicEngSftHpS',
  /** T/C回転数 */
  MimicEngTcSpdS: 'MimicEngTcSpdS',
  /** 旋回角指令 */
  MimicRexStrAngOrderP: 'MimicRexStrAngOrderP',
  /** 旋回角指令 */
  MimicRexStrAngOrderS: 'MimicRexStrAngOrderS',
  /** 旋回角F/B */
  MimicRexStrAngFbP: 'MimicRexStrAngFbP',
  /** 旋回角F/B */
  MimicRexStrAngFbS: 'MimicRexStrAngFbS',
  /** クラッチ嵌完了(F/B) */
  MimicRexClutchEngFbP: 'MimicRexClutchEngFbP',
  /** クラッチ嵌完了(F/B) */
  MimicRexClutchEngFbS: 'MimicRexClutchEngFbS',
  /** T/C過給圧 */
  MimicEngChargedAirPressP: 'MimicEngChargedAirPressP',
  /** T/C過給圧 */
  MimicEngChargedAirPressS: 'MimicEngChargedAirPressS',
  /** LO圧力 */
  MimicEngLoPressP: 'MimicEngLoPressP',
  /** LO圧力 */
  MimicEngLoPressS: 'MimicEngLoPressS',
  /** LO温度 */
  MimicEngLoInTempP: 'MimicEngLoInTempP',
  /** LO温度 */
  MimicEngLoInTempS: 'MimicEngLoInTempS',
  /** No.1 */
  MimicEngNo1ExtGasTempP: 'MimicEngNo1ExtGasTempP',
  /** No.1 */
  MimicEngNo1ExtGasTempS: 'MimicEngNo1ExtGasTempS',
  /** No.2 */
  MimicEngNo2ExtGasTempP: 'MimicEngNo2ExtGasTempP',
  /** No.2 */
  MimicEngNo2ExtGasTempS: 'MimicEngNo2ExtGasTempS',
  /** No.3 */
  MimicEngNo3ExtGasTempP: 'MimicEngNo3ExtGasTempP',
  /** No.3 */
  MimicEngNo3ExtGasTempS: 'MimicEngNo3ExtGasTempS',
  /** No.4 */
  MimicEngNo4ExtGasTempP: 'MimicEngNo4ExtGasTempP',
  /** No.4 */
  MimicEngNo4ExtGasTempS: 'MimicEngNo4ExtGasTempS',
  /** No.5 */
  MimicEngNo5ExtGasTempP: 'MimicEngNo5ExtGasTempP',
  /** No.5 */
  MimicEngNo5ExtGasTempS: 'MimicEngNo5ExtGasTempS',
  /** No.6 */
  MimicEngNo6ExtGasTempP: 'MimicEngNo6ExtGasTempP',
  /** No.6 */
  MimicEngNo6ExtGasTempS: 'MimicEngNo6ExtGasTempS',
  /** T/C入口1 */
  MimicEngTcInTemp145P: 'MimicEngTcInTemp145P',
  /** T/C入口1 */
  MimicEngTcInTemp145S: 'MimicEngTcInTemp145S',
  /** T/C入口2 */
  MimicEngTcInTemp236P: 'MimicEngTcInTemp236P',
  /** T/C入口2 */
  MimicEngTcInTemp236S: 'MimicEngTcInTemp236S',
  /** T/C出口 */
  MimicEngTcOutTempP: 'MimicEngTcOutTempP',
  /** T/C出口 */
  MimicEngTcOutTempS: 'MimicEngTcOutTempS',
  /** LO圧力 */
  MimicRexLOPressP: 'MimicRexLOPressP',
  /** LO圧力 */
  MimicRexLoPressS: 'MimicRexLoPressS',
  /** LO温度 */
  MimicRexLoTempP: 'MimicRexLoTempP',
  /** LO温度 */
  MimicRexLoTempS: 'MimicRexLoTempS',
  /** 給気 */
  MimicEngChargedAirTempP: 'MimicEngChargedAirTempP',
  /** 給気 */
  MimicEngChargedAirTempS: 'MimicEngChargedAirTempS',
  /** 確認 */
  confirm: 'confirm',
  /** ログアウトしますか？ */
  logoutConfirmMessage: 'logout_confirm_message',
  /** 東経 */
  shipInfoLonEast: 'ship_info_lon_east',
  /** 西経 */
  shipInfoLonWest: 'ship_info_lon_west',
  /** 北緯 */
  shipInfoLatNorth: 'ship_info_lat_north',
  /** 南緯 */
  shipInfoLatSouth: 'ship_info_lat_south',
  /** 緯度 */
  shipInfoLatitude: 'ship_info_latitude',
  /** 経度 */
  shipInfoLongitude: 'ship_info_longitude',
  /** 船速 */
  shipInfoSpeed: 'ship_info_speed',
  /** 船首方位角 */
  shipInfoDirection: 'ship_info_direction',
  /** 風向 */
  shipInfoWindDirection: 'ship_info_wind_direction',
  /** 風速 */
  shipInfoWindSpeed: 'ship_info_wind_speed',
  /** 度 */
  shipInfoUnitDegrees: 'ship_info_unit_degrees',
  /** 分 */
  shipInfoUnitMinutes: 'ship_info_unit_minutes',
  /** 検索期間(UTC) */
  periodSearch: 'period_search',
  /** 表示期間(UTC) */
  periodResult: 'period_result',
  /** 主機 回転数A */
  TrendMegSpdP1: 'TrendMegSpdP1',
  /** 主機 燃料ポンプラックレベルA */
  TrendMegFuelRackLvP1: 'TrendMegFuelRackLvP1',
  /** 主機 出力馬力A */
  TrendMegHpP1: 'TrendMegHpP1',
  /** 主機 過給器回転数A */
  TrendMegTCSpdP1: 'TrendMegTCSpdP1',
  /** 主機 回転数A */
  TrendMegSpdS1: 'TrendMegSpdS1',
  /** 主機 燃料ポンプラックレベルA */
  TrendMegFuelRackLvS1: 'TrendMegFuelRackLvS1',
  /** 主機 出力馬力A */
  TrendMegHpS1: 'TrendMegHpS1',
  /** 主機 過給器回転数A */
  TrendMegTCSpdS1: 'TrendMegTCSpdS1',
  /** 主機 燃料消費量A */
  TrendMegFuelOilConsumP1: 'TrendMegFuelOilConsumP1',
  /** 主機 燃料消費量A */
  TrendMegFuelOilConsumS1: 'TrendMegFuelOilConsumS1',
  /** Rex 潤滑油コンタミセンサA */
  TrendRexLOContamSensorP1: 'TrendRexLOContamSensorP1',
  /** Rex 潤滑油コンタミセンサA */
  TrendRexLOContamSensorS1: 'TrendRexLOContamSensorS1',
  /** Rex 左旋回圧力B */
  TrendRexLtStrPressP1RIO: 'TrendRexLtStrPressP1RIO',
  /** Rex 左旋回圧力B */
  TrendRexLtStrPressS1RIO: 'TrendRexLtStrPressS1RIO',
  /** Rex 右旋回圧力B */
  TrendRexRtStrPressP1RIO: 'TrendRexRtStrPressP1RIO',
  /** Rex 右旋回圧力B */
  TrendRexRtStrPressS1RIO: 'TrendRexRtStrPressS1RIO',
  /** Rex 潤滑油圧力B */
  TrendRexLOPressP1RIO: 'TrendRexLOPressP1RIO',
  /** Rex 潤滑油圧力B */
  TrendRexLOPressS1RIO: 'TrendRexLOPressS1RIO',
  /** Rex 旋回ポンプ吐出圧力B */
  TrendRexDelPressStrPumpP1RIO: 'TrendRexDelPressStrPumpP1RIO',
  /** Rex 旋回ポンプ吐出圧力B */
  TrendRexDelPressStrPumpS1RIO: 'TrendRexDelPressStrPumpS1RIO',
  /** Rex 潤滑油水分量B */
  TrendRexLOCondP1RIO: 'TrendRexLOCondP1RIO',
  /** Rex 潤滑油水分量B */
  TrendRexLOCondS1RIO: 'TrendRexLOCondS1RIO',
  /** Rex クラッチ制御元圧力B */
  TrendRexClutchContpressP1RIO: 'TrendRexClutchContpressP1RIO',
  /** Rex クラッチ制御元圧力B */
  TrendRexClutchContpressS1RIO: 'TrendRexClutchContpressS1RIO',
  /** Rex クラッチ作動圧力B */
  TrendRexClutchWorkpressP1RIO: 'TrendRexClutchWorkpressP1RIO',
  /** Rex クラッチ作動圧力B */
  TrendRexClutchWorkpressS1RIO: 'TrendRexClutchWorkpressS1RIO',
  /** Rex CPP　前進変節圧力B */
  TrendRexCPPAHPressP1RIO: 'TrendRexCPPAHPressP1RIO',
  /** Rex CPP　前進変節圧力B */
  TrendRexCPPAHPressS1RIO: 'TrendRexCPPAHPressS1RIO',
  /** Rex CPP　後進変節圧力B */
  TrendRexCPPASPressP1RIO: 'TrendRexCPPASPressP1RIO',
  /** Rex CPP　後進変節圧力B */
  TrendRexCPPASPressS1RIO: 'TrendRexCPPASPressS1RIO',
  /** Rex 旋回油水分量B */
  TrendRexStrOilCondWatersatP1RIO: 'TrendRexStrOilCondWatersatP1RIO',
  /** Rex 旋回油水分量B */
  TrendRexStrOilCondWatersatS1RIO: 'TrendRexStrOilCondWatersatS1RIO',
  /** Rex CPP制御ポンプ吐出圧力B */
  TrendRexDelPressCPPContpumpP1RIO: 'TrendRexDelPressCPPContpumpP1RIO',
  /** Rex CPP制御ポンプ吐出圧力B */
  TrendRexDelPressCPPContpumpS1RIO: 'TrendRexDelPressCPPContpumpS1RIO',
  /** Rex チャージポンプ吐出圧力B */
  TrendRexDelPressChargepumpP1RIO: 'TrendRexDelPressChargepumpP1RIO',
  /** Rex チャージポンプ吐出圧力B */
  TrendRexDelPressChargepumpS1RIO: 'TrendRexDelPressChargepumpS1RIO',
  /** Rex スリップ潤滑油ポンプ吐出圧力B */
  TrendRexDelPressLOpumpP1RIO: 'TrendRexDelPressLOpumpP1RIO',
  /** Rex スリップ潤滑油ポンプ吐出圧力B */
  TrendRexDelPressLOpumpS1RIO: 'TrendRexDelPressLOpumpS1RIO',
  /** 　 スペア */
  TrendSpare1C1: 'TrendSpare1C1',
  /** 　 スペア */
  TrendSpare2C1: 'TrendSpare2C1',
  /** 　 スペア */
  TrendSpare3C1: 'TrendSpare3C1',
  /** 　 スペア */
  TrendSpare4C1: 'TrendSpare4C1',
  /** 　 スペア */
  TrendSpare5C1: 'TrendSpare5C1',
  /** 　 スペア */
  TrendSpare6C1: 'TrendSpare6C1',
  /** Rex 潤滑油温度B */
  TrendRexLOTmpP1RIO: 'TrendRexLOTmpP1RIO',
  /** Rex 潤滑油温度B */
  TrendRexLOTmpS1RIO: 'TrendRexLOTmpS1RIO',
  /** Rex 旋回油温度B */
  TrendRexStrOilTmpP1RIO: 'TrendRexStrOilTmpP1RIO',
  /** Rex 旋回油温度B */
  TrendRexStrOilTmpS1RIO: 'TrendRexStrOilTmpS1RIO',
  /** Rex 潤滑油コンタミ量(鉄系)B */
  TrendRexLOCondContmFeP1: 'TrendRexLOCondContmFeP1',
  /** Rex 潤滑油コンタミ量(鉄系)B */
  TrendRexLOCondContmFeS1: 'TrendRexLOCondContmFeS1',
  /** Rex 潤滑油コンタミ量(非鉄系)B */
  TrendRexLOCondContmNFeP1: 'TrendRexLOCondContmNFeP1',
  /** Rex 潤滑油コンタミ量(非鉄系)B */
  TrendRexLOCondContmNFeS1: 'TrendRexLOCondContmNFeS1',
  /** Rex 旋回油コンタミ量(鉄系)B */
  TrendRexStrOilCondContmFeP1: 'TrendRexStrOilCondContmFeP1',
  /** Rex 旋回油コンタミ量(鉄系)B */
  TrendRexStrOilCondContmFeS1: 'TrendRexStrOilCondContmFeS1',
  /** Rex 旋回油コンタミ量(非鉄系)B */
  TrendRexStrOilCondContmNFeP1: 'TrendRexStrOilCondContmNFeP1',
  /** Rex 旋回油コンタミ量(非鉄系)B */
  TrendRexStrOilCondContmNFeS1: 'TrendRexStrOilCondContmNFeS1',
  /** Rex 潤滑油オイルクーラ入口温度B */
  TrendRexLOinTmpofoilcoolerP1RIO: 'TrendRexLOinTmpofoilcoolerP1RIO',
  /** Rex 潤滑油オイルクーラ入口温度B */
  TrendRexLOinTmpofoilcoolerS1RIO: 'TrendRexLOinTmpofoilcoolerS1RIO',
  /** Rex 潤滑油オイルクーラ出口温度B */
  TrendRexLOoutTmpofoilcoolerP1RIO: 'TrendRexLOoutTmpofoilcoolerP1RIO',
  /** Rex 潤滑油オイルクーラ出口温度B */
  TrendRexLOoutTmpofoilcoolerS1RIO: 'TrendRexLOoutTmpofoilcoolerS1RIO',
  /** Rex 冷却水オイルクーラ入口温度B */
  TrendRexCWinTmpofoilcoolerP1RIO: 'TrendRexCWinTmpofoilcoolerP1RIO',
  /** Rex 冷却水オイルクーラ入口温度B */
  TrendRexCWinTmpofoilcoolerS1RIO: 'TrendRexCWinTmpofoilcoolerS1RIO',
  /** Rex 冷却水オイルクーラ出口温度B */
  TrendRexCWoutTmpofoilcoolerP1RIO: 'TrendRexCWoutTmpofoilcoolerP1RIO',
  /** Rex 冷却水オイルクーラ出口温度B */
  TrendRexCWoutTmpofoilcoolerS1RIO: 'TrendRexCWoutTmpofoilcoolerS1RIO',
  /** Rex 冷却水流量B */
  TrendRexCWflowrateP1RIO: 'TrendRexCWflowrateP1RIO',
  /** Rex 冷却水流量B */
  TrendRexCWflowrateS1RIO: 'TrendRexCWflowrateS1RIO',
  /** Rex 旋回モータ１ドレン温度B */
  TrendRexTmpofdrainoilHYDMotor1P1RIO: 'TrendRexTmpofdrainoilHYDMotor1P1RIO',
  /** Rex 旋回モータ１ドレン温度B */
  TrendRexTmpofdrainoilHYDMotor1S1RIO: 'TrendRexTmpofdrainoilHYDMotor1S1RIO',
  /** Rex 旋回モータ２ドレン温度B */
  TrendRexTmpofdrainoilHYDMotor2P1RIO: 'TrendRexTmpofdrainoilHYDMotor2P1RIO',
  /** Rex 旋回モータ２ドレン温度B */
  TrendRexTmpofdrainoilHYDMotor2S1RIO: 'TrendRexTmpofdrainoilHYDMotor2S1RIO',
  /** Rex 中間軸受軸受ハウジング温度１B */
  TrendRexInttSftTmp1P1RIO: 'TrendRexInttSftTmp1P1RIO',
  /** Rex 中間軸受軸受ハウジング温度２B */
  TrendRexInttSftTmp2P1RIO: 'TrendRexInttSftTmp2P1RIO',
  /** Rex 中間軸受軸受ハウジング温度３B */
  TrendRexInttSftTmp3P1RIO: 'TrendRexInttSftTmp3P1RIO',
  /** Rex 中間軸受軸受ハウジング温度４B */
  TrendRexInttSftTmp4P1RIO: 'TrendRexInttSftTmp4P1RIO',
  /** Rex 中間軸受軸受ハウジング温度５B */
  TrendRexInttSftTmp5P1RIO: 'TrendRexInttSftTmp5P1RIO',
  /** Rex 中間軸受軸受ハウジング温度６B */
  TrendRexInttSftTmp6P1RIO: 'TrendRexInttSftTmp6P1RIO',
  /** Rex 中間軸受軸受ハウジング温度１B */
  TrendRexInttSftTmp1S1RIO: 'TrendRexInttSftTmp1S1RIO',
  /** Rex 中間軸受軸受ハウジング温度２B */
  TrendRexInttSftTmp2S1RIO: 'TrendRexInttSftTmp2S1RIO',
  /** Rex 中間軸受軸受ハウジング温度３B */
  TrendRexInttSftTmp3S1RIO: 'TrendRexInttSftTmp3S1RIO',
  /** Rex 中間軸受軸受ハウジング温度４B */
  TrendRexInttSftTmp4S1RIO: 'TrendRexInttSftTmp4S1RIO',
  /** Rex 中間軸受軸受ハウジング温度５B */
  TrendRexInttSftTmp5S1RIO: 'TrendRexInttSftTmp5S1RIO',
  /** Rex 中間軸受軸受ハウジング温度６B */
  TrendRexInttSftTmp6S1RIO: 'TrendRexInttSftTmp6S1RIO',
  /** Rex 旋回角ハンドルオーダC1 */
  TrendRexStrAngOrderP1: 'TrendRexStrAngOrderP1',
  /** Rex 旋回角ハンドルオーダC1 */
  TrendRexStrAngOrderS1: 'TrendRexStrAngOrderS1',
  /** Rex 旋回角フィードバックC1 */
  TrendRexStrAngFBP1: 'TrendRexStrAngFBP1',
  /** Rex 旋回角フィードバックC1 */
  TrendRexStrAngFBS1: 'TrendRexStrAngFBS1',
  /** Rex プロペラ回転数オーダC1 */
  TrendRexPropSpdOrderP1: 'TrendRexPropSpdOrderP1',
  /** Rex プロペラ回転数オーダC1 */
  TrendRexPropSpdOrderS1: 'TrendRexPropSpdOrderS1',
  /** Rex プロペラ回転数フィードバックC1 */
  TrendRexPropSpdFBP1: 'TrendRexPropSpdFBP1',
  /** Rex プロペラ回転数フィードバックC1 */
  TrendRexPropSpdFBS1: 'TrendRexPropSpdFBS1',
  /** Rex CPP翼角オーダC1 */
  TrendRexCPPBladeAngOrderP1: 'TrendRexCPPBladeAngOrderP1',
  /** Rex CPP翼角オーダC1 */
  TrendRexCPPBladeAngOrderS1: 'TrendRexCPPBladeAngOrderS1',
  /** Rex CPP翼角フィードバックC1 */
  TrendRexCPPBladeAngFBP1: 'TrendRexCPPBladeAngFBP1',
  /** Rex CPP翼角フィードバックC1 */
  TrendRexCPPBladeAngFBS1: 'TrendRexCPPBladeAngFBS1',
  /** 主機 回転数オーダC1 */
  TrendMegSpdorderP1: 'TrendMegSpdorderP1',
  /** 主機 回転数オーダC1 */
  TrendMegSpdorderS1: 'TrendMegSpdorderS1',
  /** 主機 回転数フィードバックC1 */
  TrendMegSpdFBP1: 'TrendMegSpdFBP1',
  /** 主機 回転数フィードバックC1 */
  TrendMegSpdFBS1: 'TrendMegSpdFBS1',
  /** Rex 旋回制御比例弁A出力C1 */
  TrendRexStrPropValveAoutP1: 'TrendRexStrPropValveAoutP1',
  /** Rex 旋回制御比例弁A出力C1 */
  TrendRexStrPropValveAoutS1: 'TrendRexStrPropValveAoutS1',
  /** Rex 旋回制御比例弁B出力C1 */
  TrendRexStrPropValveBoutP1: 'TrendRexStrPropValveBoutP1',
  /** Rex 旋回制御比例弁B出力C1 */
  TrendRexStrPropValveBoutS1: 'TrendRexStrPropValveBoutS1',
  /** Rex 旋回制御電磁弁A出力C1 */
  TrendRexStrSolValveAoutP1: 'TrendRexStrSolValveAoutP1',
  /** Rex 旋回制御電磁弁A出力C1 */
  TrendRexStrSolValveAoutS1: 'TrendRexStrSolValveAoutS1',
  /** Rex 旋回制御電磁弁B出力C1 */
  TrendRexStrSolValveBoutP1: 'TrendRexStrSolValveBoutP1',
  /** Rex 旋回制御電磁弁B出力C1 */
  TrendRexStrSolValveBoutS1: 'TrendRexStrSolValveBoutS1',
  /** Rex CPP比例弁A出力C1 */
  TrendRexCPPPropValveAoutP1: 'TrendRexCPPPropValveAoutP1',
  /** Rex CPP比例弁A出力C1 */
  TrendRexCPPPropValveAoutS1: 'TrendRexCPPPropValveAoutS1',
  /** Rex CPP比例弁B出力C1 */
  TrendRexCPPPropValveBoutP1: 'TrendRexCPPPropValveBoutP1',
  /** Rex CPP比例弁B出力C1 */
  TrendRexCPPPropValveBoutS1: 'TrendRexCPPPropValveBoutS1',
  /** Rex CPP電磁弁出力AHC1 */
  TrendRexCPPSolValveAoutP1: 'TrendRexCPPSolValveAoutP1',
  /** Rex CPP電磁弁出力AHC1 */
  TrendRexCPPSolValveAoutS1: 'TrendRexCPPSolValveAoutS1',
  /** Rex CPP電磁弁出力ASC1 */
  TrendRexCPPSolValveBoutP1: 'TrendRexCPPSolValveBoutP1',
  /** Rex CPP電磁弁出力ASC1 */
  TrendRexCPPSolValveBoutS1: 'TrendRexCPPSolValveBoutS1',
  /** Rex クラッチ比例弁出力C1 */
  TrendRexClutchpropValveoutP1: 'TrendRexClutchpropValveoutP1',
  /** Rex クラッチ比例弁出力C1 */
  TrendRexClutchpropValveoutS1: 'TrendRexClutchpropValveoutS1',
  /** Rex クラッチON/OFF電磁弁出力C1 */
  TrendRexClutchONOFFsolValveoutP1: 'TrendRexClutchONOFFsolValveoutP1',
  /** Rex クラッチON/OFF電磁弁出力C1 */
  TrendRexClutchONOFFsolValveoutS1: 'TrendRexClutchONOFFsolValveoutS1',
  /** Rex クラッチSLIP電磁弁出力C1 */
  TrendRexClutchSLIPsolValveoutP1: 'TrendRexClutchSLIPsolValveoutP1',
  /** Rex クラッチSLIP電磁弁出力C1 */
  TrendRexClutchSLIPsolValveoutS1: 'TrendRexClutchSLIPsolValveoutS1',
  /** Rex クラッチSLIP　LO電磁弁出力C1 */
  TrendRexClutchSLIPLOsolValveoutP1: 'TrendRexClutchSLIPLOsolValveoutP1',
  /** Rex クラッチSLIP　LO電磁弁出力C1 */
  TrendRexClutchSLIPLOsolValveoutS1: 'TrendRexClutchSLIPLOsolValveoutS1',
  /** Rex クラッチ嵌指令C1 */
  TrendRexClutchengageorderP1: 'TrendRexClutchengageorderP1',
  /** Rex クラッチ嵌指令C1 */
  TrendRexClutchengageorderS1: 'TrendRexClutchengageorderS1',
  /** Rex クラッチ嵌完了(F/B)C1 */
  TrendRexClutchengagedFBP1: 'TrendRexClutchengagedFBP1',
  /** Rex クラッチ嵌完了(F/B)C1 */
  TrendRexClutchengagedFBS1: 'TrendRexClutchengagedFBS1',
  /** Rex フォロー/ノンフォローC1 */
  TrendRexFollowNonfollowupContP1: 'TrendRexFollowNonfollowupContP1',
  /** Rex フォロー/ノンフォローC1 */
  TrendRexFollowNonfollowupContS1: 'TrendRexFollowNonfollowupContS1',
  /** Rex システム異常C1 */
  TrendRexSystemAbnomalP1: 'TrendRexSystemAbnomalP1',
  /** Rex システム異常C1 */
  TrendRexSystemAbnomalS1: 'TrendRexSystemAbnomalS1',
  /** Rex 左旋回圧力C1 */
  TrendRexLtStrPressP1COM: 'TrendRexLtStrPressP1COM',
  /** Rex 左旋回圧力C1 */
  TrendRexLtStrPressS1COM: 'TrendRexLtStrPressS1COM',
  /** Rex 右旋回圧力C1 */
  TrendRexRtStrPressP1COM: 'TrendRexRtStrPressP1COM',
  /** Rex 右旋回圧力C1 */
  TrendRexRtStrPressS1COM: 'TrendRexRtStrPressS1COM',
  /** Rex 潤滑油圧力C1 */
  TrendRexLOPressP1RCOM: 'TrendRexLOPressP1RCOM',
  /** Rex 潤滑油圧力C1 */
  TrendRexLOPressS1RCOM: 'TrendRexLOPressS1RCOM',
  /** Rex 潤滑油温度C1 */
  TrendRexLOTmpP1RCOM: 'TrendRexLOTmpP1RCOM',
  /** Rex 潤滑油温度C1 */
  TrendRexLOTmpS1RCOM: 'TrendRexLOTmpS1RCOM',
  /** Rex 旋回ポンプ吐出圧力C1 */
  TrendRexDelPressStrPumpP1RCOM: 'TrendRexDelPressStrPumpP1RCOM',
  /** Rex 旋回ポンプ吐出圧力C1 */
  TrendRexDelPressStrPumpS1RCOM: 'TrendRexDelPressStrPumpS1RCOM',
  /** Rex 潤滑油水分量C1 */
  TrendRexLOCondP1COM: 'TrendRexLOCondP1COM',
  /** Rex 潤滑油水分量C1 */
  TrendRexLOCondS1COM: 'TrendRexLOCondS1COM',
  /** Rex 潤滑油コンタミ量１C1 */
  TrendRexLOCond1ContamP1: 'TrendRexLOCond1ContamP1',
  /** Rex 潤滑油コンタミ量１C1 */
  TrendRexLOCond1ContamS1: 'TrendRexLOCond1ContamS1',
  /** Rex 潤滑油コンタミ量２C1 */
  TrendRexLOCond2ContamP1: 'TrendRexLOCond2ContamP1',
  /** Rex 潤滑油コンタミ量２C1 */
  TrendRexLOCond2ContamS1: 'TrendRexLOCond2ContamS1',
  /** Rex クラッチ制御元圧力C1 */
  TrendRexClutchContpressP1RCOM: 'TrendRexClutchContpressP1RCOM',
  /** Rex クラッチ制御元圧力C1 */
  TrendRexClutchContpressS1RCOM: 'TrendRexClutchContpressS1RCOM',
  /** Rex クラッチ作動圧力C1 */
  TrendRexClutchWorkingpressP1RCOM: 'TrendRexClutchWorkingpressP1RCOM',
  /** Rex クラッチ作動圧力C1 */
  TrendRexClutchWorkingpressS1RCOM: 'TrendRexClutchWorkingpressS1RCOM',
  /** Rex CPP　前進変節圧力C1 */
  TrendRexCPPAHPressP1RCOM: 'TrendRexCPPAHPressP1RCOM',
  /** Rex CPP　前進変節圧力C1 */
  TrendRexCPPAHPressS1RCOM: 'TrendRexCPPAHPressS1RCOM',
  /** Rex CPP　後進変節圧力C1 */
  TrendRexCPPASPressP1RCOM: 'TrendRexCPPASPressP1RCOM',
  /** Rex CPP　後進変節圧力C1 */
  TrendRexCPPASPressS1RCOM: 'TrendRexCPPASPressS1RCOM',
  /** Rex 旋回油温度C1 */
  TrendRexStrOilTmpP1RCOM: 'TrendRexStrOilTmpP1RCOM',
  /** Rex 旋回油温度C1 */
  TrendRexStrOilTmpS1RCOM: 'TrendRexStrOilTmpS1RCOM',
  /** Rex 旋回油水分量C1 */
  TrendRexStrOilCondWatersatP1COM: 'TrendRexStrOilCondWatersatP1COM',
  /** Rex 旋回油水分量C1 */
  TrendRexStrOilCondWatersatS1COM: 'TrendRexStrOilCondWatersatS1COM',
  /** Rex 旋回油コンタミ量C1 */
  TrendRexStrOilCondContamP1: 'TrendRexStrOilCondContamP1',
  /** Rex 旋回油コンタミ量C1 */
  TrendRexStrOilCondContamS1: 'TrendRexStrOilCondContamS1',
  /** Rex CPP制御ポンプ吐出圧力C1 */
  TrendRexDelPressCPPContpumpP1COM: 'TrendRexDelPressCPPContpumpP1COM',
  /** Rex CPP制御ポンプ吐出圧力C1 */
  TrendRexDelPressCPPContpumpS1COM: 'TrendRexDelPressCPPContpumpS1COM',
  /** Rex チャージポンプ吐出圧力C1 */
  TrendRexDelPressChargepumpP1COM: 'TrendRexDelPressChargepumpP1COM',
  /** Rex チャージポンプ吐出圧力C1 */
  TrendRexDelPressChargepumpS1COM: 'TrendRexDelPressChargepumpS1COM',
  /** Rex スリップ潤滑油ポンプ吐出圧力C1 */
  TrendRexDelPressLOpumpP1COM: 'TrendRexDelPressLOpumpP1COM',
  /** Rex スリップ潤滑油ポンプ吐出圧力C1 */
  TrendRexDelPressLOpumpS1COM: 'TrendRexDelPressLOpumpS1COM',
  /** Rex 潤滑油オイルクーラ入口温度C1 */
  TrendRexLOinTmpofoilcoolerP1COM: 'TrendRexLOinTmpofoilcoolerP1COM',
  /** Rex 潤滑油オイルクーラ入口温度C1 */
  TrendRexLOinTmpofoilcoolerS1COM: 'TrendRexLOinTmpofoilcoolerS1COM',
  /** Rex 潤滑油オイルクーラ出口温度C1 */
  TrendRexLOoutTmpofoilcoolerP1COM: 'TrendRexLOoutTmpofoilcoolerP1COM',
  /** Rex 潤滑油オイルクーラ出口温度C1 */
  TrendRexLOoutTmpofoilcoolerS1COM: 'TrendRexLOoutTmpofoilcoolerS1COM',
  /** Rex 冷却水オイルクーラ入口温度C1 */
  TrendRexCWinTmpofoilcoolerP1COM: 'TrendRexCWinTmpofoilcoolerP1COM',
  /** Rex 冷却水オイルクーラ入口温度C1 */
  TrendRexCWinTmpofoilcoolerS1COM: 'TrendRexCWinTmpofoilcoolerS1COM',
  /** Rex 冷却水オイルクーラ出口温度C1 */
  TrendRexCWoutTmpofoilcoolerP1COM: 'TrendRexCWoutTmpofoilcoolerP1COM',
  /** Rex 冷却水オイルクーラ出口温度C1 */
  TrendRexCWoutTmpofoilcoolerS1COM: 'TrendRexCWoutTmpofoilcoolerS1COM',
  /** Rex 冷却水流量C1 */
  TrendRexCWflowrateP1COM: 'TrendRexCWflowrateP1COM',
  /** Rex 冷却水流量C1 */
  TrendRexCWflowrateS1COM: 'TrendRexCWflowrateS1COM',
  /** Rex 旋回モータ１ドレン温度C1 */
  TrendRexTmpofdrainoilHYDMotor1P1COM: 'TrendRexTmpofdrainoilHYDMotor1P1COM',
  /** Rex 旋回モータ１ドレン温度C1 */
  TrendRexTmpofdrainoilHYDMotor1S1COM: 'TrendRexTmpofdrainoilHYDMotor1S1COM',
  /** Rex 旋回モータ２ドレン温度C1 */
  TrendRexTmpofdrainoilHYDMotor2P1COM: 'TrendRexTmpofdrainoilHYDMotor2P1COM',
  /** Rex 旋回モータ２ドレン温度C1 */
  TrendRexTmpofdrainoilHYDMotor2S1COM: 'TrendRexTmpofdrainoilHYDMotor2S1COM',
  /** Rex 中間軸受軸受ハウジング温度１C1 */
  TrendRexInttSftTmp1P1COM: 'TrendRexInttSftTmp1P1COM',
  /** Rex 中間軸受軸受ハウジング温度２C1 */
  TrendRexInttSftTmp2P1COM: 'TrendRexInttSftTmp2P1COM',
  /** Rex 中間軸受軸受ハウジング温度３C1 */
  TrendRexInttSftTmp3P1COM: 'TrendRexInttSftTmp3P1COM',
  /** Rex 中間軸受軸受ハウジング温度４C1 */
  TrendRexInttSftTmp4P1COM: 'TrendRexInttSftTmp4P1COM',
  /** Rex 中間軸受軸受ハウジング温度５C1 */
  TrendRexInttSftTmp5P1COM: 'TrendRexInttSftTmp5P1COM',
  /** Rex 中間軸受軸受ハウジング温度６C1 */
  TrendRexInttSftTmp6P1COM: 'TrendRexInttSftTmp6P1COM',
  /** Rex 中間軸受軸受ハウジング温度１C1 */
  TrendRexInttSftTmp1S1COM: 'TrendRexInttSftTmp1S1COM',
  /** Rex 中間軸受軸受ハウジング温度２C1 */
  TrendRexInttSftTmp2S1COM: 'TrendRexInttSftTmp2S1COM',
  /** Rex 中間軸受軸受ハウジング温度３C1 */
  TrendRexInttSftTmp3S1COM: 'TrendRexInttSftTmp3S1COM',
  /** Rex 中間軸受軸受ハウジング温度４C1 */
  TrendRexInttSftTmp4S1COM: 'TrendRexInttSftTmp4S1COM',
  /** Rex 中間軸受軸受ハウジング温度５C1 */
  TrendRexInttSftTmp5S1COM: 'TrendRexInttSftTmp5S1COM',
  /** Rex 中間軸受軸受ハウジング温度６C1 */
  TrendRexInttSftTmp6S1COM: 'TrendRexInttSftTmp6S1COM',
  /** 主機 回転数C2 */
  TrendMegSpdP1COM: 'TrendMegSpdP1COM',
  /** 主機 回転数C2 */
  TrendMegSpdS1COM: 'TrendMegSpdS1COM',
  /** 主機 燃料ポンプラックレベルC2 */
  TrendMegFuelRackLvP1COM: 'TrendMegFuelRackLvP1COM',
  /** 主機 燃料ポンプラックレベルC2 */
  TrendMegFuelRackLvS1COM: 'TrendMegFuelRackLvS1COM',
  /** 主機 馬力C2 */
  TrendMegHpP1COM: 'TrendMegHpP1COM',
  /** 主機 馬力C2 */
  TrendMegHpS1COM: 'TrendMegHpS1COM',
  /** 主機 過給器出口圧力C2 */
  TrendMegTCoutletpressP1COM: 'TrendMegTCoutletpressP1COM',
  /** 主機 過給器出口圧力C2 */
  TrendMegTCoutletpressS1COM: 'TrendMegTCoutletpressS1COM',
  /** 主機 過給器回転数C2 */
  TrendMegTCSpdP1COM: 'TrendMegTCSpdP1COM',
  /** 主機 過給器回転数C2 */
  TrendMegTCSpdS1COM: 'TrendMegTCSpdS1COM',
  /** 主機 過給器過給圧C2 */
  TrendMegTCbstpressP1COM: 'TrendMegTCbstpressP1COM',
  /** 主機 過給器過給圧C2 */
  TrendMegTCbstpressS1COM: 'TrendMegTCbstpressS1COM',
  /** 主機 潤滑油圧力C2 */
  TrendMegLOpressP1COM: 'TrendMegLOpressP1COM',
  /** 主機 潤滑油圧力C2 */
  TrendMegLOpressS1COM: 'TrendMegLOpressS1COM',
  /** 主機 潤滑油温度C2 */
  TrendMegLOTmpP1COM: 'TrendMegLOTmpP1COM',
  /** 主機 潤滑油温度C2 */
  TrendMegLOTmpS1COM: 'TrendMegLOTmpS1COM',
  /** 主機 排気温度No.1シリンダC2 */
  TrendMegNo1EXTGASTmpP1COM: 'TrendMegNo1EXTGASTmpP1COM',
  /** 主機 排気温度No.1シリンダC2 */
  TrendMegNo1EXTGASTmpS1COM: 'TrendMegNo1EXTGASTmpS1COM',
  /** 主機 排気温度No.2シリンダC2 */
  TrendMegNo2EXTGASTmpP1COM: 'TrendMegNo2EXTGASTmpP1COM',
  /** 主機 排気温度No.2シリンダC2 */
  TrendMegNo2EXTGASTmpS1COM: 'TrendMegNo2EXTGASTmpS1COM',
  /** 主機 排気温度No.3シリンダC2 */
  TrendMegNo3EXTGASTmpP1COM: 'TrendMegNo3EXTGASTmpP1COM',
  /** 主機 排気温度No.3シリンダC2 */
  TrendMegNo3EXTGASTmpS1COM: 'TrendMegNo3EXTGASTmpS1COM',
  /** 主機 排気温度No.4シリンダC2 */
  TrendMegNo4EXTGASTmpP1COM: 'TrendMegNo4EXTGASTmpP1COM',
  /** 主機 排気温度No.4シリンダC2 */
  TrendMegNo4EXTGASTmpS1COM: 'TrendMegNo4EXTGASTmpS1COM',
  /** 主機 排気温度No.5シリンダC2 */
  TrendMegNo5EXTGASTmpP1COM: 'TrendMegNo5EXTGASTmpP1COM',
  /** 主機 排気温度No.5シリンダC2 */
  TrendMegNo5EXTGASTmpS1COM: 'TrendMegNo5EXTGASTmpS1COM',
  /** 主機 排気温度No.6シリンダC2 */
  TrendMegNo6EXTGASTmpP1COM: 'TrendMegNo6EXTGASTmpP1COM',
  /** 主機 排気温度No.6シリンダC2 */
  TrendMegNo6EXTGASTmpS1COM: 'TrendMegNo6EXTGASTmpS1COM',
  /** 主機 排気温度T/C入口1C2 */
  TrendMegNo7EXTGASTmpP1COM: 'TrendMegNo7EXTGASTmpP1COM',
  /** 主機 排気温度T/C入口1C2 */
  TrendMegNo7EXTGASTmpS1COM: 'TrendMegNo7EXTGASTmpS1COM',
  /** 主機 排気温度T/C入口2C2 */
  TrendMegNo8EXTGASTmpP1COM: 'TrendMegNo8EXTGASTmpP1COM',
  /** 主機 排気温度T/C入口2C2 */
  TrendMegNo8EXTGASTmpS1COM: 'TrendMegNo8EXTGASTmpS1COM',
  /** 主機 排気温度T/C出口C2 */
  TrendMegNo9EXTGASTmpP1COM: 'TrendMegNo9EXTGASTmpP1COM',
  /** 主機 排気温度T/C出口C2 */
  TrendMegNo9EXTGASTmpS1COM: 'TrendMegNo9EXTGASTmpS1COM',
  /** 主機 燃料消費率C2 */
  TrendMegFuelconsRateP1: 'TrendMegFuelconsRateP1',
  /** 主機 燃料消費率C2 */
  TrendMegFuelconsRateS1: 'TrendMegFuelconsRateS1',
  /** 補器 発電機出力C2 */
  TrendGENo1OutpwrC1: 'TrendGENo1OutpwrC1',
  /** 補器 発電機出力C2 */
  TrendGENo2OutpwrC1: 'TrendGENo2OutpwrC1',
  /** 補器 発電機出力C2 */
  TrendGENo3OutpwrC1: 'TrendGENo3OutpwrC1',
  /** 補器 発電機出力C2 */
  TrendGENo4OutpwrC1: 'TrendGENo4OutpwrC1',
  /** Rex 潤滑油圧C2 */
  TrendRexLOPressP1MCOM: 'TrendRexLOPressP1MCOM',
  /** Rex 潤滑油圧C2 */
  TrendRexLOPressS1MCOM: 'TrendRexLOPressS1MCOM',
  /** Rex クラッチ制御圧力C2 */
  TrendRexClutchWorkpressP1MCOM: 'TrendRexClutchWorkpressP1MCOM',
  /** Rex クラッチ制御圧力C2 */
  TrendRexClutchWorkpressS1MCOM: 'TrendRexClutchWorkpressS1MCOM',
  /** Rex クラッチ制御元圧力C2 */
  TrendRexClutchContpressP1MCOM: 'TrendRexClutchContpressP1MCOM',
  /** Rex クラッチ制御元圧力C2 */
  TrendRexClutchContpressS1MCOM: 'TrendRexClutchContpressS1MCOM',
  /** Rex 潤滑油温度C2 */
  TrendRexLOTmpP1MCOM: 'TrendRexLOTmpP1MCOM',
  /** Rex 潤滑油温度C2 */
  TrendRexLOTmpS1MCOM: 'TrendRexLOTmpS1MCOM',
  /** Rex 旋回ポンプ吐出圧C2 */
  TrendRexDelPressStrPumpP1MCOM: 'TrendRexDelPressStrPumpP1MCOM',
  /** Rex 旋回ポンプ吐出圧C2 */
  TrendRexDelPressStrPumpS1MCOM: 'TrendRexDelPressStrPumpS1MCOM',
  /** Rex CPP作動圧(AH)C2 */
  TrendRexCPPAHPressP1MCOM: 'TrendRexCPPAHPressP1MCOM',
  /** Rex CPP作動圧(AH)C2 */
  TrendRexCPPAHPressS1MCOM: 'TrendRexCPPAHPressS1MCOM',
  /** Rex CPP作動圧(AS)C2 */
  TrendRexCPPASPressP1MCOM: 'TrendRexCPPASPressP1MCOM',
  /** Rex CPP作動圧(AS)C2 */
  TrendRexCPPASPressS1MCOM: 'TrendRexCPPASPressS1MCOM',
  /** Rex 旋回油温度C2 */
  TrendRexStrOilTmpP1MCOM: 'TrendRexStrOilTmpP1MCOM',
  /** Rex 旋回油温度C2 */
  TrendRexStrOilTmpS1MCOM: 'TrendRexStrOilTmpS1MCOM',
  /** 船体 緯度C2 */
  TrendVesLatC1MCOM: 'TrendVesLatC1MCOM',
  /** 船体 経度C2 */
  TrendVesLonC1MCOM: 'TrendVesLonC1MCOM',
  /** 船体 船速(SOG)C2 */
  TrendVesSpdSOGC1MCOM: 'TrendVesSpdSOGC1MCOM',
  /** 船体 針路(真方位)C2 */
  TrendVesCourseTrueDirC1MCOM: 'TrendVesCourseTrueDirC1MCOM',
  /** 船体 回頭速度(Magnetic variation)C2 */
  TrendVesTurnSpdC1: 'TrendVesTurnSpdC1',
  /** 船体 船首方向C2 */
  TrendVesHeadingC1: 'TrendVesHeadingC1',
  /** 船体 相対風向C2 */
  TrendVesWindDirRelC1: 'TrendVesWindDirRelC1',
  /** 船体 相対風速C2 */
  TrendVesWindSpdRelC1MCOM: 'TrendVesWindSpdRelC1MCOM',
  /** 船体 相対流速(船体長さ方向)C2 */
  TrendVesWatervelocityLengthDirC1: 'TrendVesWatervelocityLengthDirC1',
  /** 船体 相対流速(船体横方向)C2 */
  TrendVesWatervelocityTransvergeDirC1: 'TrendVesWatervelocityTransvergeDirC1',
  /** 船体 緯度C3 */
  TrendVesLatC1GCOM: 'TrendVesLatC1GCOM',
  /** 船体 経度C3 */
  TrendVesLonC1GCOM: 'TrendVesLonC1GCOM',
  /** 船体 船速(SOG)C3 */
  TrendVesSpdSogC1GCOM: 'TrendVesSpdSogC1GCOM',
  /** 船体 針路(真方位)C3 */
  TrendVesCourseTrueDirC1GCOM: 'TrendVesCourseTrueDirC1GCOM',
  /** 船体 流速(船体長さ方向)C3 */
  TrendVesLonwaterSpdC1: 'TrendVesLonwaterSpdC1',
  /** 船体 流速(船体横方向)C3 */
  TrendVesTransversewaterSpdC1: 'TrendVesTransversewaterSpdC1',
  /** 船体 船速(船体長さ方向)C3 */
  TrendVesLongroundSpdC1: 'TrendVesLongroundSpdC1',
  /** 船体 船速(船体横方向)C3 */
  TrendVesTransversegroundSpdC1: 'TrendVesTransversegroundSpdC1',
  /** 船体 風向(角度)C3 */
  TrendVesWindDirmagnitudeindgreeC1: 'TrendVesWindDirmagnitudeindgreeC1',
  /** 船体 風向(方向)C3 */
  TrendVesWindDirLRofbowC1: 'TrendVesWindDirLRofbowC1',
  /** 船体 風速C3 */
  TrendVesWindSpdRelC1GCOM: 'TrendVesWindSpdRelC1GCOM',
  /** 主機 回転数B */
  TrendMegSpdP1RIO: 'TrendMegSpdP1RIO',
  /** 主機 回転数B */
  TrendMegSpdS1RIO: 'TrendMegSpdS1RIO',
  /** 主機 燃料ポンプラックレベルB */
  TrendMegFuelRackLvP1RIO: 'TrendMegFuelRackLvP1RIO',
  /** 主機 燃料ポンプラックレベルB */
  TrendMegFuelRackLvS1RIO: 'TrendMegFuelRackLvS1RIO',
  /** 主機 馬力B */
  TrendMegHpP1RIO: 'TrendMegHpP1RIO',
  /** 主機 馬力B */
  TrendMegHpS1RIO: 'TrendMegHpS1RIO',
  /** 主機 過給器出口圧力B */
  TrendMegTCoutpressP1RIO: 'TrendMegTCoutpressP1RIO',
  /** 主機 過給器出口圧力B */
  TrendMegTCoutpressS1RIO: 'TrendMegTCoutpressS1RIO',
  /** 主機 過給器回転数B */
  TrendMegTCSpdP1RIO: 'TrendMegTCSpdP1RIO',
  /** 主機 過給器回転数B */
  TrendMegTCSpdS1RIO: 'TrendMegTCSpdS1RIO',
  /** 主機 過給器過給圧B */
  TrendMegTCboostpressP1RIO: 'TrendMegTCboostpressP1RIO',
  /** 主機 過給器過給圧B */
  TrendMegTCboostpressS1RIO: 'TrendMegTCboostpressS1RIO',
  /** 主機 潤滑油圧力B */
  TrendMegLOpressP1RIO: 'TrendMegLOpressP1RIO',
  /** 主機 潤滑油圧力B */
  TrendMegLOpressS1RIO: 'TrendMegLOpressS1RIO',
  /** 　 SPARE */
  TrendSpare7C1: 'TrendSpare7C1',
  /** 　 SPARE */
  TrendSpare8C1: 'TrendSpare8C1',
  /** 主機 潤滑油温度B */
  TrendMegLOTmpP1RIO: 'TrendMegLOTmpP1RIO',
  /** 主機 潤滑油温度B */
  TrendMegLOTmpS1RIO: 'TrendMegLOTmpS1RIO',
  /** 主機 排気温度No.1シリンダB */
  TrendMegNo1EXTGASTmpP1RIO: 'TrendMegNo1EXTGASTmpP1RIO',
  /** 主機 排気温度No.1シリンダB */
  TrendMegNo1EXTGASTmpS1RIO: 'TrendMegNo1EXTGASTmpS1RIO',
  /** 主機 排気温度No.2シリンダB */
  TrendMegNo2EXTGASTmpP1RIO: 'TrendMegNo2EXTGASTmpP1RIO',
  /** 主機 排気温度No.2シリンダB */
  TrendMegNo2EXTGASTmpS1RIO: 'TrendMegNo2EXTGASTmpS1RIO',
  /** 主機 排気温度No.3シリンダB */
  TrendMegNo3EXTGASTmpP1RIO: 'TrendMegNo3EXTGASTmpP1RIO',
  /** 主機 排気温度No.3シリンダB */
  TrendMegNo3EXTGASTmpS1RIO: 'TrendMegNo3EXTGASTmpS1RIO',
  /** 主機 排気温度No.4シリンダB */
  TrendMegNo4EXTGASTmpP1RIO: 'TrendMegNo4EXTGASTmpP1RIO',
  /** 主機 排気温度No.4シリンダB */
  TrendMegNo4EXTGASTmpS1RIO: 'TrendMegNo4EXTGASTmpS1RIO',
  /** 主機 排気温度No.5シリンダB */
  TrendMegNo5EXTGASTmpP1RIO: 'TrendMegNo5EXTGASTmpP1RIO',
  /** 主機 排気温度No.5シリンダB */
  TrendMegNo5EXTGASTmpS1RIO: 'TrendMegNo5EXTGASTmpS1RIO',
  /** 主機 排気温度No.6シリンダB */
  TrendMegNo6EXTGASTmpP1RIO: 'TrendMegNo6EXTGASTmpP1RIO',
  /** 主機 排気温度No.6シリンダB */
  TrendMegNo6EXTGASTmpS1RIO: 'TrendMegNo6EXTGASTmpS1RIO',
  /** 主機 排気温度T/C入口1B */
  TrendMegNo7EXTGASTmpP1RIO: 'TrendMegNo7EXTGASTmpP1RIO',
  /** 主機 排気温度T/C入口1B */
  TrendMegNo7EXTGASTmpS1RIO: 'TrendMegNo7EXTGASTmpS1RIO',
  /** 主機 排気温度T/C入口2B */
  TrendMegNo8EXTGASTmpP1RIO: 'TrendMegNo8EXTGASTmpP1RIO',
  /** 主機 排気温度T/C入口2B */
  TrendMegNo8EXTGASTmpS1RIO: 'TrendMegNo8EXTGASTmpS1RIO',
  /** 主機 排気温度T/C出口B */
  TrendMegNo9EXTGASTmpP1RIO: 'TrendMegNo9EXTGASTmpP1RIO',
  /** 主機 排気温度T/C出口B */
  TrendMegNo9EXTGASTmpS1RIO: 'TrendMegNo9EXTGASTmpS1RIO',
  /** 主機 給気温度B */
  TrendMegInletAirTmpP1: 'TrendMegInletAirTmpP1',
  /** 主機 給気温度B */
  TrendMegInletAirTmpS1: 'TrendMegInletAirTmpS1',
  /** 　 SPARE */
  TrendSpare9C1: 'TrendSpare9C1',
  /** 　 SPARE */
  TrendSpare10C1: 'TrendSpare10C1',
  /** 船体 緯度 */
  TrendVesLat: 'TrendVesLat',
  /** 船体 経度 */
  TrendVesLon: 'TrendVesLon',
  /** 船体 船速(SOG) */
  TrendVesSog: 'TrendVesSog',
  /** 船体 進路(COG) */
  TrendVesCog: 'TrendVesCog',
  /** 船体 流速(船体長さ方向) */
  TrendVesLonWaterSpd: 'TrendVesLonWaterSpd',
  /** 船体 流速(船体横方向) */
  TrendVesTransverseWaterSpd: 'TrendVesTransverseWaterSpd',
  /** 船体 船速(船体長さ方向) */
  TrendVesLonGroundSpd: 'TrendVesLonGroundSpd',
  /** 船体 船速(船体横方向) */
  TrendVesTransverseGroundSpd: 'TrendVesTransverseGroundSpd',
  /** 船体 針路(Heading) */
  TrendVesHeading: 'TrendVesHeading',
  /** 船体 対水船速 */
  TrendVesLogSpeed: 'TrendVesLogSpeed',
  /** 船体 風向(角度) */
  TrendVesWindDirMagnitudMagDeg: 'TrendVesWindDirMagnitudMagDeg',
  /** 船体 風向(方向) */
  TrendVesWindDirLR: 'TrendVesWindDirLR',
  /** 船体 風速 */
  TrendVesWindSpd: 'TrendVesWindSpd',
  /** Rex クラッチON/OFF電磁弁出力 */
  TrendRexCLOnOffsolValveOutS: 'TrendRexCLOnOffsolValveOutS',
  /** Rex クラッチON/OFF電磁弁出力 */
  TrendRexCLOnOffsolValveOutP: 'TrendRexCLOnOffsolValveOutP',
  /** Rex クラッチSLIP電磁弁出力 */
  TrendRexCLSlipSolValveOutS: 'TrendRexCLSlipSolValveOutS',
  /** Rex クラッチSLIP電磁弁出力 */
  TrendRexCLSlipSolValveOutP: 'TrendRexCLSlipSolValveOutP',
  /** Rex クラッチSLIP　LO電磁弁出力 */
  TrendRexCLSlipLoSolValveOutS: 'TrendRexCLSlipLoSolValveOutS',
  /** Rex クラッチSLIP　LO電磁弁出力 */
  TrendRexCLSlipLoSolValveOutP: 'TrendRexCLSlipLoSolValveOutP',
  /** Rex クラッチ嵌指令 */
  TrendRexClutchEngOrderS: 'TrendRexClutchEngOrderS',
  /** Rex クラッチ嵌指令 */
  TrendRexClutchEngOrderP: 'TrendRexClutchEngOrderP',
  /** Rex クラッチ嵌完了(F/B) */
  TrendRexClutchEngFbS: 'TrendRexClutchEngFbS',
  /** Rex クラッチ嵌完了(F/B) */
  TrendRexClutchEngFbP: 'TrendRexClutchEngFbP',
  /** Rex フォロー/ノンフォロー */
  TrendRexFuNfuCtrlS: 'TrendRexFuNfuCtrlS',
  /** Rex フォロー/ノンフォロー */
  TrendRexFuNfuCtrlP: 'TrendRexFuNfuCtrlP',
  /** Rex システム異常 */
  TrendRexSysAbnomalS: 'TrendRexSysAbnomalS',
  /** Rex システム異常 */
  TrendRexSysAbnomalP: 'TrendRexSysAbnomalP',
  /** Rex クラッチスリップ(F/B) */
  TrendRexCLSlipFbS: 'TrendRexCLSlipFbS',
  /** Rex クラッチスリップ(F/B) */
  TrendRexCLSlipFbP: 'TrendRexCLSlipFbP',
  /** Rex 回転数ハンドル中立位置 */
  TrendRexSpdOrderHandleNtrlPosS: 'TrendRexSpdOrderHandleNtrlPosS',
  /** Rex 回転数ハンドル中立位置 */
  TrendRexSpdOrderHandleNtrlPosP: 'TrendRexSpdOrderHandleNtrlPosP',
  /** Rex クラッチON/OFF制御中 */
  TrendRexCLOnOffUndrCtrlS: 'TrendRexCLOnOffUndrCtrlS',
  /** Rex クラッチON/OFF制御中 */
  TrendRexCLOnOffUndrCtrlP: 'TrendRexCLOnOffUndrCtrlP',
  /** Rex REX操縦位置 */
  TrendRexCtrlPosS: 'TrendRexCtrlPosS',
  /** Rex REX操縦位置 */
  TrendRexCtrlPosP: 'TrendRexCtrlPosP',
  /** Rex 旋回角ハンドルオーダ */
  TrendRexStrAngOrderS: 'TrendRexStrAngOrderS',
  /** Rex 旋回角フィードバック */
  TrendRexStrAngFbS: 'TrendRexStrAngFbS',
  /** Rex プロペラ回転数フィードバック */
  TrendRexPropSpdFbS: 'TrendRexPropSpdFbS',
  /** Rex 旋回角ハンドルオーダ */
  TrendRexStrAngOrderP: 'TrendRexStrAngOrderP',
  /** Rex 旋回角フィードバック */
  TrendRexStrAngFbP: 'TrendRexStrAngFbP',
  /** Rex プロペラ回転数フィードバック */
  TrendRexPropSpdFbP: 'TrendRexPropSpdFbP',
  /** Rex 左旋回圧力 */
  TrendRexLtStrPressS: 'TrendRexLtStrPressS',
  /** Rex 右旋回圧力 */
  TrendRexRtStrPressS: 'TrendRexRtStrPressS',
  /** Rex 潤滑油圧力 */
  TrendRexLoPressS: 'TrendRexLoPressS',
  /** Rex 潤滑油温度 */
  TrendRexLoTempS: 'TrendRexLoTempS',
  /** Rex 旋回油温度 */
  TrendRexStrOilTempS: 'TrendRexStrOilTempS',
  /** Rex クラッチ制御元圧力 */
  TrendRexCLCtrlPressS: 'TrendRexCLCtrlPressS',
  /** Rex クラッチ作動圧力 */
  TrendRexCLWorkPressS: 'TrendRexCLWorkPressS',
  /** Rex チャージポンプ吐出圧力 */
  TrendRexDelPressChgPumpS: 'TrendRexDelPressChgPumpS',
  /** Rex スリップ潤滑油ポンプ吐出圧力 */
  TrendRexDelPressCLSlipLoPumpS: 'TrendRexDelPressCLSlipLoPumpS',
  /** Rex 左旋回圧力 */
  TrendRexLtStrPressP: 'TrendRexLtStrPressP',
  /** Rex 右旋回圧力 */
  TrendRexRtStrPressP: 'TrendRexRtStrPressP',
  /** Rex 潤滑油圧力 */
  TrendRexLOPressP: 'TrendRexLOPressP',
  /** Rex 潤滑油温度 */
  TrendRexLoTempP: 'TrendRexLoTempP',
  /** Rex 旋回油温度 */
  TrendRexStrOilTempP: 'TrendRexStrOilTempP',
  /** Rex クラッチ制御元圧力 */
  TrendRexCLCtrlPressP: 'TrendRexCLCtrlPressP',
  /** Rex クラッチ作動圧力 */
  TrendRexCLWorkPressP: 'TrendRexCLWorkPressP',
  /** Rex チャージポンプ吐出圧力 */
  TrendRexDelPressChgPumpP: 'TrendRexDelPressChgPumpP',
  /** Rex スリップ潤滑油ポンプ吐出圧力 */
  TrendRexDelPressCLSlipLoPumpP: 'TrendRexDelPressCLSlipLoPumpP',
  /** Rex プロペラ回転数オーダ */
  TrendRexPropSpdOrderS: 'TrendRexPropSpdOrderS',
  /** Rex プロペラ回転数オーダ */
  TrendRexPropSpdOrderP: 'TrendRexPropSpdOrderP',
  /** Rex 旋回制御比例弁A出力 */
  TrendRexStrvvAOutS: 'TrendRexStrvvAOutS',
  /** Rex 旋回制御比例弁A出力 */
  TrendRexStrvvAOutP: 'TrendRexStrvvAOutP',
  /** Rex 旋回制御比例弁B出力 */
  TrendRexStrvvBOutS: 'TrendRexStrvvBOutS',
  /** Rex 旋回制御比例弁B出力 */
  TrendRexStrvvBOutP: 'TrendRexStrvvBOutP',
  /** Rex クラッチ比例弁出力 */
  TrendRexCLPropvvOutS: 'TrendRexCLPropvvOutS',
  /** Rex クラッチ比例弁出力 */
  TrendRexCLPropvvOutP: 'TrendRexCLPropvvOutP',
  /** Rex プロペラ回転数ハンドル位置 */
  TrendRexPropSpdHandlePosS: 'TrendRexPropSpdHandlePosS',
  /** Rex プロペラ回転数ハンドル位置 */
  TrendRexPropSpdHandlePosP: 'TrendRexPropSpdHandlePosP',
  /** Rex スペア */
  TrendRexSpare1: 'TrendRexSpare1',
  /** Rex スペア */
  TrendRexSpare2: 'TrendRexSpare2',
  /** Rex スペア */
  TrendRexSpare3: 'TrendRexSpare3',
  /** Rex スペア */
  TrendRexSpare4: 'TrendRexSpare4',
  /** Rex スペア */
  TrendRexSpare5: 'TrendRexSpare5',
  /** Rex スペア */
  TrendRexSpare6: 'TrendRexSpare6',
  /** Rex スペア */
  TrendRexSpare7: 'TrendRexSpare7',
  /** Rex スペア */
  TrendRexSpare8: 'TrendRexSpare8',
  /** Rex スペア */
  TrendRexSpare9: 'TrendRexSpare9',
  /** Rex スペア */
  TrendRexSpare10: 'TrendRexSpare10',
  /** Rex スペア */
  TrendRexSpare11: 'TrendRexSpare11',
  /** Rex スペア */
  TrendRexSpare12: 'TrendRexSpare12',
  /** Rex スペア */
  TrendRexSpare13: 'TrendRexSpare13',
  /** Rex スペア */
  TrendRexSpare14: 'TrendRexSpare14',
  /** Rex スペア */
  TrendRexSpare15: 'TrendRexSpare15',
  /** Rex スペア */
  TrendRexSpare16: 'TrendRexSpare16',
  /** Rex スペア */
  TrendRexSpare17: 'TrendRexSpare17',
  /** Rex スペア */
  TrendRexSpare18: 'TrendRexSpare18',
  /** Rex スペア */
  TrendRexSpare19: 'TrendRexSpare19',
  /** Rex スペア */
  TrendRexSpare20: 'TrendRexSpare20',
  /** Rex スペア */
  TrendRexSpare21: 'TrendRexSpare21',
  /** Rex スペア */
  TrendRexSpare22: 'TrendRexSpare22',
  /** Rex スペア */
  TrendRexSpare23: 'TrendRexSpare23',
  /** Rex スペア */
  TrendRexSpare24: 'TrendRexSpare24',
  /** Rex スペア */
  TrendRexSpare25: 'TrendRexSpare25',
  /** Rex スペア */
  TrendRexSpare26: 'TrendRexSpare26',
  /** Rex スペア */
  TrendRexSpare27: 'TrendRexSpare27',
  /** Rex スペア */
  TrendRexSpare28: 'TrendRexSpare28',
  /** Rex スペア */
  TrendRexSpare29: 'TrendRexSpare29',
  /** Rex スペア */
  TrendRexSpare30: 'TrendRexSpare30',
  /** 主機 運転信号 */
  TrendEngRunSigS: 'TrendEngRunSigS',
  /** 主機 ハンドルポテンショ信号 */
  TrendEngHandlePosSigS: 'TrendEngHandlePosSigS',
  /** 主機 回転数 */
  TrendEngSpdS: 'TrendEngSpdS',
  /** 主機 過給器回転数#1(RAW) */
  TrendEngTcSpdRaw1S: 'TrendEngTcSpdRaw1S',
  /** 主機 過給器回転数#2(RAW) */
  TrendEngTcSpdRaw2S: 'TrendEngTcSpdRaw2S',
  /** 主機 燃料ポンプラックレベル */
  TrendEngFuelRackLvS: 'TrendEngFuelRackLvS',
  /** 主機 回転数負荷率 */
  TrendEngSpdLoadRatioS: 'TrendEngSpdLoadRatioS',
  /** 主機 馬力(定格負荷率) */
  TrendEngSftHpS: 'TrendEngSftHpS',
  /** 主機 出力 */
  TrendEngOutPwrS: 'TrendEngOutPwrS',
  /** 主機 潤滑油圧力 */
  TrendEngLoPressS: 'TrendEngLoPressS',
  /** 主機 冷却清水圧力 */
  TrendEngCoolFleshWaterPressS: 'TrendEngCoolFleshWaterPressS',
  /** 主機 冷却海水圧力 */
  TrendEngCoolSeaWaterPressS: 'TrendEngCoolSeaWaterPressS',
  /** 主機 燃料油圧力 */
  TrendEngFoPressS: 'TrendEngFoPressS',
  /** 主機 給気圧力 */
  TrendEngChargedAirPressS: 'TrendEngChargedAirPressS',
  /** 主機 給気温度 */
  TrendEngChargedAirTempS: 'TrendEngChargedAirTempS',
  /** 主機 潤滑油入口温度 */
  TrendEngLoInTempS: 'TrendEngLoInTempS',
  /** 主機 冷却清水出口温度 */
  TrendEngCoolFleshWaterOutTempS: 'TrendEngCoolFleshWaterOutTempS',
  /** 主機 排気温度No.1シリンダ */
  TrendEngNo1ExtGasTempS: 'TrendEngNo1ExtGasTempS',
  /** 主機 排気温度No.2シリンダ */
  TrendEngNo2ExtGasTempS: 'TrendEngNo2ExtGasTempS',
  /** 主機 排気温度No.3シリンダ */
  TrendEngNo3ExtGasTempS: 'TrendEngNo3ExtGasTempS',
  /** 主機 排気温度No.4シリンダ */
  TrendEngNo4ExtGasTempS: 'TrendEngNo4ExtGasTempS',
  /** 主機 排気温度No.5シリンダ */
  TrendEngNo5ExtGasTempS: 'TrendEngNo5ExtGasTempS',
  /** 主機 排気温度No.6シリンダ */
  TrendEngNo6ExtGasTempS: 'TrendEngNo6ExtGasTempS',
  /** 主機 排ガス出口平均温度 */
  TrendEngAveExtGasTempS: 'TrendEngAveExtGasTempS',
  /** 主機 T/C入口排気温度(145) */
  TrendEngTcInTemp145S: 'TrendEngTcInTemp145S',
  /** 主機 T/C入口排気温度(236) */
  TrendEngTcInTemp236S: 'TrendEngTcInTemp236S',
  /** 主機 T/C出口排気温度 */
  TrendEngTcOutTempS: 'TrendEngTcOutTempS',
  /** 主機 運転信号 */
  TrendEngRunSigP: 'TrendEngRunSigP',
  /** 主機 ハンドルポテンショ信号 */
  TrendEngHandlePosSigP: 'TrendEngHandlePosSigP',
  /** 主機 回転数 */
  TrendEngSpdP: 'TrendEngSpdP',
  /** 主機 過給器回転数#1(RAW) */
  TrendEngTcSpdRaw1P: 'TrendEngTcSpdRaw1P',
  /** 主機 過給器回転数#2(RAW) */
  TrendEngTcSpdRaw2P: 'TrendEngTcSpdRaw2P',
  /** 主機 燃料ポンプラックレベル */
  TrendEngFuelRackLvP: 'TrendEngFuelRackLvP',
  /** 主機 回転数負荷率 */
  TrendEngSpdLoadRatioP: 'TrendEngSpdLoadRatioP',
  /** 主機 馬力(定格負荷率) */
  TrendEngSftHpP: 'TrendEngSftHpP',
  /** 主機 出力 */
  TrendEngOutPwrP: 'TrendEngOutPwrP',
  /** 主機 潤滑油圧力 */
  TrendEngLoPressP: 'TrendEngLoPressP',
  /** 主機 冷却清水圧力 */
  TrendEngCoolFleshWaterPressP: 'TrendEngCoolFleshWaterPressP',
  /** 主機 冷却海水圧力 */
  TrendEngCoolSeaWaterPressP: 'TrendEngCoolSeaWaterPressP',
  /** 主機 燃料油圧力 */
  TrendEngFoPressP: 'TrendEngFoPressP',
  /** 主機 給気圧力 */
  TrendEngChargedAirPressP: 'TrendEngChargedAirPressP',
  /** 主機 給気温度 */
  TrendEngChargedAirTempP: 'TrendEngChargedAirTempP',
  /** 主機 潤滑油入口温度 */
  TrendEngLoInTempP: 'TrendEngLoInTempP',
  /** 主機 冷却清水出口温度 */
  TrendEngCoolFleshWaterOutTempP: 'TrendEngCoolFleshWaterOutTempP',
  /** 主機 排気温度No.1シリンダ */
  TrendEngNo1ExtGasTempP: 'TrendEngNo1ExtGasTempP',
  /** 主機 排気温度No.2シリンダ */
  TrendEngNo2ExtGasTempP: 'TrendEngNo2ExtGasTempP',
  /** 主機 排気温度No.3シリンダ */
  TrendEngNo3ExtGasTempP: 'TrendEngNo3ExtGasTempP',
  /** 主機 排気温度No.4シリンダ */
  TrendEngNo4ExtGasTempP: 'TrendEngNo4ExtGasTempP',
  /** 主機 排気温度No.5シリンダ */
  TrendEngNo5ExtGasTempP: 'TrendEngNo5ExtGasTempP',
  /** 主機 排気温度No.6シリンダ */
  TrendEngNo6ExtGasTempP: 'TrendEngNo6ExtGasTempP',
  /** 主機 排ガス出口平均温度 */
  TrendEngAveExtGasTempP: 'TrendEngAveExtGasTempP',
  /** 主機 T/C入口排気温度(145) */
  TrendEngTcInTemp145P: 'TrendEngTcInTemp145P',
  /** 主機 T/C入口排気温度(236) */
  TrendEngTcInTemp236P: 'TrendEngTcInTemp236P',
  /** 主機 T/C出口排気温度 */
  TrendEngTcOutTempP: 'TrendEngTcOutTempP',
  /** 主機 操作場所 */
  TrendEngCntlPosS: 'TrendEngCntlPosS',
  /** 主機 操作場所 */
  TrendEngCntlPosP: 'TrendEngCntlPosP',
  /** 主機 スペア */
  TrendEngSapre1: 'TrendEngSapre1',
  /** 主機 スペア */
  TrendEngSapre2: 'TrendEngSapre2',
  /** 主機 スペア */
  TrendEngSapre3: 'TrendEngSapre3',
  /** 主機 スペア */
  TrendEngSapre4: 'TrendEngSapre4',
  /** 主機 スペア */
  TrendEngSapre5: 'TrendEngSapre5',
  /** 主機 スペア */
  TrendEngSapre6: 'TrendEngSapre6',
  /** 主機 スペア */
  TrendEngSapre7: 'TrendEngSapre7',
  /** 主機 スペア */
  TrendEngSapre8: 'TrendEngSapre8',
  /** 主機 スペア */
  TrendEngSapre9: 'TrendEngSapre9',
  /** 主機 スペア */
  TrendEngSapre10: 'TrendEngSapre10',
  /** 主機 室温 */
  TrendEgnRoomTemp: 'TrendEgnRoomTemp',
  /** 主機 主機燃料流量（本日）#1(RAW) */
  TrendEnRMeFoFlowDayRaw1: 'TrendEnRMeFoFlowDayRaw1',
  /** 主機 主機燃料流量（本日）#2(RAW) */
  TrendEnRMeFoFlowDayRaw2: 'TrendEnRMeFoFlowDayRaw2',
  /** 主機 主機燃料流量（当月）#1(RAW) */
  TrendEnRMeFoFlowMonthRaw1: 'TrendEnRMeFoFlowMonthRaw1',
  /** 主機 主機燃料流量（当月）#2(RAW) */
  TrendEnRMeFoFlowMonthRaw2: 'TrendEnRMeFoFlowMonthRaw2',
  /** 主機 主機燃料流量（累計）#1(RAW) */
  TrendEnRMeFoFlowTotalRaw1: 'TrendEnRMeFoFlowTotalRaw1',
  /** 主機 主機燃料流量（累計）#2(RAW) */
  TrendEnRMeFoFlowTotalRaw2: 'TrendEnRMeFoFlowTotalRaw2',
  /** 主機 発電機燃料流量（本日）#1(RAW) */
  TrendEnRGeFoFlowDayRaw1: 'TrendEnRGeFoFlowDayRaw1',
  /** 主機 発電機燃料流量（本日）#2(RAW) */
  TrendEnRGeFoFlowDayRaw2: 'TrendEnRGeFoFlowDayRaw2',
  /** 主機 発電機燃料流量（当月）#1(RAW) */
  TrendEnRGeFoFlowMonthRaw1: 'TrendEnRGeFoFlowMonthRaw1',
  /** 主機 発電機燃料流量（当月）#2(RAW) */
  TrendEnRGeFoFlowMonthRaw2: 'TrendEnRGeFoFlowMonthRaw2',
  /** 主機 発電機燃料流量（累計）#1(RAW) */
  TrendEnRGeFoFlowTotalRaw1: 'TrendEnRGeFoFlowTotalRaw1',
  /** 主機 発電機燃料流量（累計）#2(RAW) */
  TrendEnRGeFoFlowTotalRaw2: 'TrendEnRGeFoFlowTotalRaw2',
  /** 主機 燃料瞬時消費量 */
  TrendEnRInstantFoCnsmpt: 'TrendEnRInstantFoCnsmpt',
  /** 主機 過給機回転数上昇 */
  TrendEngTcSpdHighS: 'TrendEngTcSpdHighS',
  /** 主機 過速度停止 */
  TrendEngSDOverSpdS: 'TrendEngSDOverSpdS',
  /** 主機 潤滑油圧力低下停止 */
  TrendEngSDLoPressLowS: 'TrendEngSDLoPressLowS',
  /** 主機 手動非常停止 */
  TrendEngSDManualS: 'TrendEngSDManualS',
  /** 主機 電子ガバナCPU異常 */
  TrendEngGovCpuAbnlS: 'TrendEngGovCpuAbnlS',
  /** 主機 電子ガバナFB信号喪失 */
  TrendEngGovFbSigLostS: 'TrendEngGovFbSigLostS',
  /** 主機 潤滑油圧力低下 */
  TrendEngLoPressLowS: 'TrendEngLoPressLowS',
  /** 主機 冷却清水圧力低下 */
  TrendEngCoolFleshWaterPressLowS: 'TrendEngCoolFleshWaterPressLowS',
  /** 主機 冷却海水圧力低下 */
  TrendEngCoolSeaWaterPressLowS: 'TrendEngCoolSeaWaterPressLowS',
  /** 主機 潤滑油濾器差圧上昇 */
  TrendEngLoFilterDifPressHighS: 'TrendEngLoFilterDifPressHighS',
  /** 主機 潤滑油入口温度上昇 */
  TrendEngLoInTempHighS: 'TrendEngLoInTempHighS',
  /** 主機 冷却清水出口温度上昇 */
  TrendEngCoolWaterOutTempHighS: 'TrendEngCoolWaterOutTempHighS',
  /** 主機 排気温度1高温警報 */
  TrendEngNo1ExtGasTempHighS: 'TrendEngNo1ExtGasTempHighS',
  /** 主機 排気温度2高温警報 */
  TrendEngNo2ExtGasTempHighS: 'TrendEngNo2ExtGasTempHighS',
  /** 主機 排気温度3高温警報 */
  TrendEngNo3ExtGasTempHighS: 'TrendEngNo3ExtGasTempHighS',
  /** 主機 排気温度4高温警報 */
  TrendEngNo4ExtGasTempHighS: 'TrendEngNo4ExtGasTempHighS',
  /** 主機 排気温度5高温警報 */
  TrendEngNo5ExtGasTempHighS: 'TrendEngNo5ExtGasTempHighS',
  /** 主機 排気温度6高温警報 */
  TrendEngNo6ExtGasTempHighS: 'TrendEngNo6ExtGasTempHighS',
  /** 主機 過給機入口温度(145)上昇 */
  TrendEngTcInTemp145HighS: 'TrendEngTcInTemp145HighS',
  /** 主機 過給機入口温度(236)上昇 */
  TrendEngTcInTemp236HighS: 'TrendEngTcInTemp236HighS',
  /** 主機 過給機出口温度上昇 */
  TrendEngTcOutTempHighS: 'TrendEngTcOutTempHighS',
  /** 主機 電子ガバナ速度設定信号断 */
  TrendEngGovSpdOrderSigFailS: 'TrendEngGovSpdOrderSigFailS',
  /** 主機 主機関燃料高圧管漏油 */
  TrendEngFoHighPressOilLeakS: 'TrendEngFoHighPressOilLeakS',
  /** 主機 スピードリレー異常 */
  TrendEngSpdRelayAbnlS: 'TrendEngSpdRelayAbnlS',
  /** 主機 主機関制御回路電源喪失 */
  TrendEngCtrlCircuitPwrFailS: 'TrendEngCtrlCircuitPwrFailS',
  /** 主機 主機関安全回路電源喪失 */
  TrendEngSafetyCircuitPwrFailS: 'TrendEngSafetyCircuitPwrFailS',
  /** 主機 主機関始動不可 */
  TrendEngStartImpossibleS: 'TrendEngStartImpossibleS',
  /** 主機 主機関始動失敗 */
  TrendEngStartFailS: 'TrendEngStartFailS',
  /** 主機 AC電源喪失 */
  TrendEngAcPwrLostS: 'TrendEngAcPwrLostS',
  /** 主機 AC電源回路異常 */
  TrendEngAcPwrAbnlS: 'TrendEngAcPwrAbnlS',
  /** 主機 過給機回転数上昇 */
  TrendEngTcSpdHighP: 'TrendEngTcSpdHighP',
  /** 主機 過速度停止 */
  TrendEngSDOverSpdP: 'TrendEngSDOverSpdP',
  /** 主機 潤滑油圧力低下停止 */
  TrendEngSDLoPressLowP: 'TrendEngSDLoPressLowP',
  /** 主機 手動非常停止 */
  TrendEngSDManualP: 'TrendEngSDManualP',
  /** 主機 電子ガバナCPU異常 */
  TrendEngGovCpuAbnlP: 'TrendEngGovCpuAbnlP',
  /** 主機 電子ガバナFB信号喪失 */
  TrendEngGovFbSigLostP: 'TrendEngGovFbSigLostP',
  /** 主機 潤滑油圧力低下 */
  TrendEngLoPressLowP: 'TrendEngLoPressLowP',
  /** 主機 冷却清水圧力低下 */
  TrendEngCoolFleshWaterPressLowP: 'TrendEngCoolFleshWaterPressLowP',
  /** 主機 冷却海水圧力低下 */
  TrendEngCoolSeaWaterPressLowP: 'TrendEngCoolSeaWaterPressLowP',
  /** 主機 潤滑油濾器差圧上昇 */
  TrendEngLoFilterDifPressHighP: 'TrendEngLoFilterDifPressHighP',
  /** 主機 潤滑油入口温度上昇 */
  TrendEngLoInTempHighP: 'TrendEngLoInTempHighP',
  /** 主機 冷却清水出口温度上昇 */
  TrendEngCoolWaterOutTempHighP: 'TrendEngCoolWaterOutTempHighP',
  /** 主機 排気温度1高温警報 */
  TrendEngNo1ExtGasTempHighP: 'TrendEngNo1ExtGasTempHighP',
  /** 主機 排気温度2高温警報 */
  TrendEngNo2ExtGasTempHighP: 'TrendEngNo2ExtGasTempHighP',
  /** 主機 排気温度3高温警報 */
  TrendEngNo3ExtGasTempHighP: 'TrendEngNo3ExtGasTempHighP',
  /** 主機 排気温度4高温警報 */
  TrendEngNo4ExtGasTempHighP: 'TrendEngNo4ExtGasTempHighP',
  /** 主機 排気温度5高温警報 */
  TrendEngNo5ExtGasTempHighP: 'TrendEngNo5ExtGasTempHighP',
  /** 主機 排気温度6高温警報 */
  TrendEngNo6ExtGasTempHighP: 'TrendEngNo6ExtGasTempHighP',
  /** 主機 過給機入口温度(145)上昇 */
  TrendEngTcInTemp145HighP: 'TrendEngTcInTemp145HighP',
  /** 主機 過給機入口温度(236)上昇 */
  TrendEngTcInTemp236HighP: 'TrendEngTcInTemp236HighP',
  /** 主機 過給機出口温度上昇 */
  TrendEngTcOutTempHighP: 'TrendEngTcOutTempHighP',
  /** 主機 電子ガバナ速度設定信号断 */
  TrendEngGovSpdOrderSigFailP: 'TrendEngGovSpdOrderSigFailP',
  /** 主機 主機関燃料高圧管漏油 */
  TrendEngFoHighPressOilLeakP: 'TrendEngFoHighPressOilLeakP',
  /** 主機 スピードリレー異常 */
  TrendEngSpdRelayAbnlP: 'TrendEngSpdRelayAbnlP',
  /** 主機 主機関制御回路電源喪失 */
  TrendEngCtrlCircuitPwrFailP: 'TrendEngCtrlCircuitPwrFailP',
  /** 主機 主機関安全回路電源喪失 */
  TrendEngSafetyCircuitPwrFailP: 'TrendEngSafetyCircuitPwrFailP',
  /** 主機 主機関始動不可 */
  TrendEngStartImpossibleP: 'TrendEngStartImpossibleP',
  /** 主機 主機関始動失敗 */
  TrendEngStartFailP: 'TrendEngStartFailP',
  /** 主機 AC電源喪失 */
  TrendEngAcPwrLostP: 'TrendEngAcPwrLostP',
  /** 主機 AC電源回路異常 */
  TrendEngAcPwrAbnlP: 'TrendEngAcPwrAbnlP',
  /** 主機 主機関制御空気元弁閉塞 */
  TrendEnRCntlAirValveClose: 'TrendEnRCntlAirValveClose',
  /** 主機 主機関制御空気圧力低下 */
  TrendEnRCntlAirPressLow: 'TrendEnRCntlAirPressLow',
  /** 主機 DC電源喪失 */
  TrendEnRDcPowerFail: 'TrendEnRDcPowerFail',
  /** 主機 電子ガバナDC電源喪失 */
  TrendEnRDcPowerGovFail: 'TrendEnRDcPowerGovFail',
  /** 主機 DC電源喪失電源電圧低下 */
  TrendEnRDcPowerVoltLow: 'TrendEnRDcPowerVoltLow',
  /** 主機 主空気槽圧力低下 */
  TrendEnRAirResorverPressLow: 'TrendEnRAirResorverPressLow',
  /** 主機 始動空気圧力低下 */
  TrendEnRStartAirPressLow: 'TrendEnRStartAirPressLow',
  /** 主機 モニタAC電源喪失 */
  TrendEnRMonitorAcPwrFail: 'TrendEnRMonitorAcPwrFail',
  /** 主機 モニタAC電源回路異常 */
  TrendEnRMonitorAcPwrAbnl: 'TrendEnRMonitorAcPwrAbnl',
  /** 主機 操舵室始動準備条件 */
  TrendEngStartConditWhS: 'TrendEngStartConditWhS',
  /** 主機 警報盤始動準備条件 */
  TrendEngStartConditApS: 'TrendEngStartConditApS',
  /** 主機 操舵室始動準備条件 */
  TrendEngStartConditWhP: 'TrendEngStartConditWhP',
  /** 主機 警報盤始動準備条件 */
  TrendEngStartConditApP: 'TrendEngStartConditApP',
  /** Rex チャージポンプ吐出圧力低下 */
  TrendRexChgPumpPressLowS: 'TrendRexChgPumpPressLowS',
  /** Rex 潤滑油圧力低下 */
  TrendRexLoPressLowS: 'TrendRexLoPressLowS',
  /** Rex クラッチ制御圧力低下 */
  TrendRexCLCtrlPressLowS: 'TrendRexCLCtrlPressLowS',
  /** Rex クラッチスリップ潤滑油圧力低下 */
  TrendRexCLSlipLoPumpPressLowS: 'TrendRexCLSlipLoPumpPressLowS',
  /** Rex 旋回油温度上昇 */
  TrendRexStrOilTempHighS: 'TrendRexStrOilTempHighS',
  /** Rex 潤滑油温度上昇 */
  TrendRexLoTempHighS: 'TrendRexLoTempHighS',
  /** Rex 潤滑油油面低下 */
  TrendRexLoLvLowS: 'TrendRexLoLvLowS',
  /** Rex 旋回油タンク油面低下 */
  TrendResStrOilLvLowS: 'TrendResStrOilLvLowS',
  /** Rex チャージポンプ吐出圧力低下 */
  TrendRexChgPumpPressLowP: 'TrendRexChgPumpPressLowP',
  /** Rex 潤滑油圧力低下 */
  TrendRexLoPressLowP: 'TrendRexLoPressLowP',
  /** Rex クラッチ制御圧力低下 */
  TrendRexCLCtrlPressLowP: 'TrendRexCLCtrlPressLowP',
  /** Rex クラッチスリップ潤滑油圧力低下 */
  TrendRexCLSlipLoPumpPressLowP: 'TrendRexCLSlipLoPumpPressLowP',
  /** Rex 旋回油温度上昇 */
  TrendRexStrOilTempHighP: 'TrendRexStrOilTempHighP',
  /** Rex 潤滑油温度上昇 */
  TrendRexLoTempHighP: 'TrendRexLoTempHighP',
  /** Rex 潤滑油油面低下 */
  TrendRexLoLvLowP: 'TrendRexLoLvLowP',
  /** Rex 旋回油タンク油面低下 */
  TrendResStrOilLvLowP: 'TrendResStrOilLvLowP',
  /** Rex 補助ポンプ過負荷 */
  TrendRexAuxPumpOverloadS: 'TrendRexAuxPumpOverloadS',
  /** Rex 補助ポンプ無電源 */
  TrendRexAuxPumpNoPwrS: 'TrendRexAuxPumpNoPwrS',
  /** Rex 補助ポンプ過負荷 */
  TrendRexAuxPumpOverloadP: 'TrendRexAuxPumpOverloadP',
  /** Rex 補助ポンプ無電源 */
  TrendRexAuxPumpNoPwrP: 'TrendRexAuxPumpNoPwrP',
  /** Rex REX AMS通信異常　メイン */
  TrendRexAmsComAbnlMain: 'TrendRexAmsComAbnlMain',
  /** Rex REX AMS通信異常　サブ */
  TrendRexAmsComAbnlSub: 'TrendRexAmsComAbnlSub',
  /** Rex DRV異常 */
  TrendRexDrvAbnl: 'TrendRexDrvAbnl',
  /** Rex 制御システム異常 */
  TrendRexCtrlSysAbnl: 'TrendRexCtrlSysAbnl',
  /** Rex 通信異常 */
  TrendRexComAbnl: 'TrendRexComAbnl',
  /** Rex 電源異常 */
  TrendRexPwrSuppyAbnl: 'TrendRexPwrSuppyAbnl',
  /** Rex AC電源異常 */
  TrendRexAcPwrAbnl: 'TrendRexAcPwrAbnl',
  /** Rex DC電源異常 */
  TrendRexDcPwrAbnl: 'TrendRexDcPwrAbnl',
  /** 補器 No.1潤滑油圧力低下 */
  TrendGenLoPressLow1: 'TrendGenLoPressLow1',
  /** 補器 No.1海水圧力低下 */
  TrendGenSeaWaterPressLow1: 'TrendGenSeaWaterPressLow1',
  /** 補器 No.1冷却水総出口温度上昇 */
  TrendGenCoolWaterOutPressHigh1: 'TrendGenCoolWaterOutPressHigh1',
  /** 補器 No.2潤滑油圧力低下 */
  TrendGenLoPressLow2: 'TrendGenLoPressLow2',
  /** 補器 No.2海水圧力低下 */
  TrendGenSeaWaterPressLow2: 'TrendGenSeaWaterPressLow2',
  /** 補器 No.2冷却水総出口温度上昇 */
  TrendGenCoolWaterOutPressHigh2: 'TrendGenCoolWaterOutPressHigh2',
  /** 補器 1次冷却水タンク液面低下 */
  TrendGenCoolWaterTankLvLow1: 'TrendGenCoolWaterTankLvLow1',
  /** 補器 No.1排気温度1高温警報 */
  TrendGenNo1ExtGasTemp1: 'TrendGenNo1ExtGasTemp1',
  /** 補器 No.1排気温度2高温警報 */
  TrendGenNo2ExtGasTemp1: 'TrendGenNo2ExtGasTemp1',
  /** 補器 No.1排気温度3高温警報 */
  TrendGenNo3ExtGasTemp1: 'TrendGenNo3ExtGasTemp1',
  /** 補器 No.1排気温度4高温警報 */
  TrendGenNo4ExtGasTemp1: 'TrendGenNo4ExtGasTemp1',
  /** 補器 No.1排気温度5高温警報 */
  TrendGenNo5ExtGasTemp1: 'TrendGenNo5ExtGasTemp1',
  /** 補器 No.1排気温度6高温警報 */
  TrendGenNo6ExtGasTemp1: 'TrendGenNo6ExtGasTemp1',
  /** 補器 No.2排気温度1高温警報 */
  TrendGenNo1ExtGasTemp2: 'TrendGenNo1ExtGasTemp2',
  /** 補器 No.2排気温度2高温警報 */
  TrendGenNo2ExtGasTemp2: 'TrendGenNo2ExtGasTemp2',
  /** 補器 No.2排気温度3高温警報 */
  TrendGenNo3ExtGasTemp2: 'TrendGenNo3ExtGasTemp2',
  /** 補器 No.2排気温度4高温警報 */
  TrendGenNo4ExtGasTemp2: 'TrendGenNo4ExtGasTemp2',
  /** 補器 No.2排気温度5高温警報 */
  TrendGenNo5ExtGasTemp2: 'TrendGenNo5ExtGasTemp2',
  /** 補器 No.2排気温度6高温警報 */
  TrendGenNo6ExtGasTemp2: 'TrendGenNo6ExtGasTemp2',
  /** 主機 セントラル冷却海水圧力低下 */
  TrendEnRCentCoolSeaWaterPressLow: 'TrendEnRCentCoolSeaWaterPressLow',
  /** 主機 FOサービスタンク液面異常 */
  TrendEnRFoServTankLvAbnl: 'TrendEnRFoServTankLvAbnl',
  /** 主機 甲板機械作動油タンク液面低下 */
  TrendEnRDeckMachyHydOilTankLvLow: 'TrendEnRDeckMachyHydOilTankLvLow',
  /** 主機 廃油タンク液面上昇 */
  TrendEnRWasteOilTankLvHigh: 'TrendEnRWasteOilTankLvHigh',
  /** 主機 FO緊急遮断弁閉塞 */
  TrendEnRFoEmgShutValveClose: 'TrendEnRFoEmgShutValveClose',
  /** 主機 甲板機械作動油温度上昇 */
  TrendEnRDeckMachyHydOilTempHigh: 'TrendEnRDeckMachyHydOilTempHigh',
  /** 主機 機関室ビルジ液面上昇 */
  TrendEnRBilgeTankLvHigh: 'TrendEnRBilgeTankLvHigh',
  /** 主機 過給器回転数 */
  TrendEngTcSpdS: 'TrendEngTcSpdS',
  /** 主機 過給器回転数 */
  TrendEngTcSpdP: 'TrendEngTcSpdP',
  /** 主機 主機燃料流量（本日） */
  TrendEnRMeFoFlowDay: 'TrendEnRMeFoFlowDay',
  /** 主機 主機燃料流量（当月） */
  TrendEnRMeFoFlowMonth: 'TrendEnRMeFoFlowMonth',
  /** 主機 主機燃料流量（累計） */
  TrendEnRMeFoFlowTotal: 'TrendEnRMeFoFlowTotal',
  /** 主機 発電機燃料流量（本日） */
  TrendEnRGeFoFlowDay: 'TrendEnRGeFoFlowDay',
  /** 主機 発電機燃料流量（当月） */
  TrendEnRGeFoFlowMonth: 'TrendEnRGeFoFlowMonth',
  /** 主機 発電機燃料流量（累計） */
  TrendMnRGeFoFlowTotal: 'TrendMnRGeFoFlowTotal',
  /** 主機 */
  Meg: 'Meg',
  /** Rex */
  Rex: 'Rex',
  /** 船体 */
  Shp: 'Shp',
  /**   */
  None: 'None',
  /** 補器 */
  Aux: 'Aux',
  /** LO温度C2 */
  MimicRexLOTmpP1MCOM: 'MimicRexLOTmpP1MCOM',
  /** LO温度C2 */
  MimicRexLOTmpS1MCOM: 'MimicRexLOTmpS1MCOM',
  /** LO圧力B */
  MimicRexLOPressP1RIO: 'MimicRexLOPressP1RIO',
  /** LO圧力B */
  MimicRexLOPressS1RIO: 'MimicRexLOPressS1RIO',
  /** レポート出力 */
  homeReportShipTitle: 'home_report_ship_title',
  /** アラームレポート */
  homeReportAlarmLabel: 'home_report_alarm_label',
  /** 運航レポート */
  homeReportOperationLabel: 'home_report_operation_label',
  /** 出力期間(UTC) */
  homeReportDateLabel: 'home_report_date_label',
  /** ダウンロード */
  homeReportButtonDownload: 'home_report_button_download',
  /** 期間は{days}日以内にしてください。 */
  homeReportPeriodRangeError: 'home_report_period_range_error',
  /** ※ダウンロードには数分かかることがあります。 */
  homeReportNoticeMessage: 'home_report_notice_message',
  /** アラームレポート */
  ReportTypeAlarm: 'ReportTypeAlarm',
  /** 運航レポート */
  ReportTypeOperation: 'ReportTypeOperation',
  /** 完了しました。 */
  ReportResultCompleted: 'ReportResultCompleted',
  /** 処理に失敗しました。 */
  ReportResultError: 'ReportResultError',
  /** 指定された期間にレポートはありません。 */
  ReportResultNoData: 'ReportResultNoData',
  /** 入力に誤りがあります。 */
  errorInputText: 'error_input_text',
  /** 認証コードが間違っているか、有効期限が過ぎています。 */
  errorAuthCode: 'error_auth_code',
  /** ログインIDの形式が正しくありません。 */
  errorLoginIdFormat: 'error_login_id_format',
  /** 使用できる文字は、小文字の英数字、「.」「_」「-」です。 */
  errorLoginIdInvalidCharacter: 'error_login_id_invalid_character',
  /** 形式が正しくありません。 */
  errorMailAddressFormat: 'error_mail_address_format',
  /** メールアドレスが一致しません。 */
  errorMailAddressMismatch: 'error_mail_address_mismatch',
  /** 大文字、小文字、数字を含めた8~32字で入力してください。 */
  errorPasswordRequirement: 'error_password_requirement',
  /** 使用できない文字が入力されています。 */
  errorPasswordFormat: 'error_password_format',
  /** パスワードが一致しません。 */
  errorPasswordMismatch: 'error_password_mismatch',
  /** ログインIDが間違っています。 */
  errorLoginId: 'error_login_id',
  /** 使用できる文字は、小文字の英数字、「.」「_」「-」です。 */
  errorGroupUrlInvalidCharacter: 'error_group_url_invalid_character',
  /** パスワードを変更しました。 */
  messageChangePasswordSuccess: 'message_change_password_success',
  /** メールアドレスを変更しました。 */
  messageChangeMailAddressSuccess: 'message_change_mail_address_success',
  /** システム管理者 */
  authorityAdminitrators: 'authority_adminitrators',
  /** 開発者 */
  authorityDevelopers: 'authority_developers',
  /** 運用者 */
  authorityOperators: 'authority_operators',
  /** ユーザー */
  authorityUsers: 'authority_users',
  /** ゲスト */
  authorityGuests: 'authority_guests',
  /** 管理ユーザー */
  authorityAdminUsers: 'authority_admin_users',
  /** すべて選択 */
  thinnedShipsAllCheck: 'thinned_ships_all_check',
  /** プロペラ異物巻込み分析(日別) */
  AnalysisMenuPropellerForeignMatterEntrainmentAnalysis: 'AnalysisMenuPropellerForeignMatterEntrainmentAnalysis',
  /** 分析 */
  analysisTabAnalysis: 'analysis_tab_analysis',
  /** プロペラ巻込み */
  analysisTabPropeller: 'analysis_tab_propeller',
  /** 変化点スコア */
  analysisPropellerChangeScore: 'analysis_propeller_change_score',
  /** 燃料ポンプラックレベル[mm] */
  analysisPropellerPumpLack: 'analysis_propeller_pump_lack',
  /** T/C回転数[rpm] */
  analysisPropellerTurbocharger: 'analysis_propeller_turbocharger',
  /** 左旋回圧力[MPa] */
  analysisPropellerLeftPress: 'analysis_propeller_left_press',
  /** 右旋回圧力[MPa] */
  analysisPropellerRightPress: 'analysis_propeller_right_press',
  /** CPP指令[deg] */
  analysisPropellerCppOrder: 'analysis_propeller_cpp_order',
  /** CPP F/B[deg] */
  analysisPropellerCppFeedback: 'analysis_propeller_cpp_feedback',
  /** 旋回角指令[deg] */
  analysisPropellerTurnOrder: 'analysis_propeller_turn_order',
  /** 旋回角F/B[deg] */
  analysisPropellerTurnFeedback: 'analysis_propeller_turn_feedback',
  /** 主機回転数[rpm] */
  analysisPropellerMegEngineSpeed: 'analysis_propeller_meg_engine_speed',
  /** リセット */
  dxDataGridFilterRowResetOperationText: 'dxDataGridFilterRowResetOperationText',
  /** と等しい */
  dxDataGridFilterRowOperationEquals: 'dxDataGridFilterRowOperationEquals',
  /** と等しくない */
  dxDataGridFilterRowOperationNotEquals: 'dxDataGridFilterRowOperationNotEquals',
  /** 未満 */
  dxDataGridFilterRowOperationLess: 'dxDataGridFilterRowOperationLess',
  /** 以下 */
  dxDataGridFilterRowOperationLessOrEquals: 'dxDataGridFilterRowOperationLessOrEquals',
  /** 超過 */
  dxDataGridFilterRowOperationGreater: 'dxDataGridFilterRowOperationGreater',
  /** 以上 */
  dxDataGridFilterRowOperationGreaterOrEquals: 'dxDataGridFilterRowOperationGreaterOrEquals',
  /** で始まる */
  dxDataGridFilterRowOperationStartsWith: 'dxDataGridFilterRowOperationStartsWith',
  /** を含む */
  dxDataGridFilterRowOperationContains: 'dxDataGridFilterRowOperationContains',
  /** を含まない */
  dxDataGridFilterRowOperationNotContains: 'dxDataGridFilterRowOperationNotContains',
  /** で終わる */
  dxDataGridFilterRowOperationEndsWith: 'dxDataGridFilterRowOperationEndsWith',
  /** 期間 */
  dxDataGridFilterRowOperationBetween: 'dxDataGridFilterRowOperationBetween',
  /** 開始 */
  dxDataGridFilterRowOperationBetweenStartText: 'dxDataGridFilterRowOperationBetweenStartText',
  /** 終了 */
  dxDataGridFilterRowOperationBetweenEndText: 'dxDataGridFilterRowOperationBetweenEndText',
  /** 【！注意！】\n{date} 時点において、\nAISからの位置情報を取得できませんでした。\n現在マップ上に表示されている位置情報は、\n実際の位置情報と大きく異なる可能性があります。 */
  realtimeAisNotIncludeLastResponseWarning: 'realtime_ais_not_include_last_response_warning',
  /** 現在のパスワード  */
  accountChangePasswordNow: 'account_change_password_now',
  /** 新規パスワード(8～32文字の半角英数混在)  */
  accountChangePasswordNew: 'account_change_password_new',
  /** 新規パスワード(確認用)  */
  accountChangePasswordConfirm: 'account_change_password_confirm',
  /** 新パスワードと一致しません。 */
  accountChangePasswordConfirmDifferent: 'account_change_password_confirm_different',
  /** パスワードの変更に失敗しました。 */
  accountChangePasswordError: 'account_change_password_error',
  /** 完了しました。 */
  resultCompleted: 'result_completed',
  /** 変更 */
  labelEdit: 'label_edit',
  /** データ構造ID */
  dataFormatId: 'data_format_id',
  /** ファイルが対応していません。 */
  fileIsNotSupported: 'file_is_not_supported',
  /** 入力してください。 */
  messageInputRequired: 'message_input_required',
  /** 635×170サイズのPNG画像を設定してください。 */
  messageSelectPngImage: 'message_select_png_image',
  /** 1～100文字以内で入力してください。 */
  errorMessageLengthError100: 'error_message_length_error_100',
  /** 1～50文字以内で入力してください。 */
  errorMessageLengthError50: 'error_message_length_error_50',
  /** 1～15文字以内で入力してください。 */
  errorMessageLengthError15: 'error_message_length_error_15',
  /** 0～100文字以内で入力してください。 */
  errorMessageLengthError0To100: 'error_message_length_error_0_to_100',
  /** 設定されているため削除できません。 */
  errorMessageRelationError: 'error_message_relation_error',
  /** 登録済み */
  passwordPlaceholder: 'password_placeholder',
  /** 形式が正しくないか既に登録されているため登録できません。 */
  errorMessageParameterRequirements: 'error_message_parameter_requirements',
  /** 登録されている機械も削除されます。 削除しますか？ */
  confirmMessageShipDelete: 'confirm_message_ship_delete',
  /** パスワード(8～32文字の半角英数混在) */
  adminAccountPassword: 'admin_account_password',
  /** アカウント情報の確認と変更ができます。 */
  guideMessageAccountInformation: 'guide_message_account_information',
  /** 変更するメールアドレスを入力してください。 */
  guideMessageEditMailAddress1: 'guide_message_edit_mail_address1',
  /** 変更するメールアドレスに認証コードを送信します。 */
  guideMessageEditMailAddress2: 'guide_message_edit_mail_address2',
  /** メールアドレス変更 */
  accountInformationMailTitle1: 'account_information_mail_title1',
  /** メールアドレス(確認用)  */
  mailAddressConfirm: 'mail_address_confirm',
  /** 認証コード入力 */
  accountInformationMailTitle2: 'account_information_mail_title2',
  /** パスワード変更 */
  accountChangePasswordTitle: 'account_change_password_title',
  /** 許可されていないユーザーです。 */
  errorLoginIdNotAuthorized: 'error_login_id_not_authorized',
  /** 上部ピニオン */
  rexGearAnomalyDetectionUpperMain: 'rex_gear_anomaly_detection_upper_main',
  /** 上部ホイール */
  rexGearAnomalyDetectionUpperVertical: 'rex_gear_anomaly_detection_upper_vertical',
  /** 下部ピニオン */
  rexGearAnomalyDetectionLowerVertical: 'rex_gear_anomaly_detection_lower_vertical',
  /** 下部ホイール */
  rexGearAnomalyDetectionLowerPropeller: 'rex_gear_anomaly_detection_lower_propeller',
  /** 前日より減少 */
  rexGearAnomalyDetectionDown: 'rex_gear_anomaly_detection_down',
  /** 前日と同じ */
  rexGearAnomalyDetectionEqual: 'rex_gear_anomaly_detection_equal',
  /** 前日より増加 */
  rexGearAnomalyDetectionUp: 'rex_gear_anomaly_detection_up',
  /** 危険 */
  labelRisk: 'label_risk',
  /** 正常 */
  labelNormal: 'label_normal',
  /** A02 */
  rexAnalysisSensorA02: 'rex_analysis_sensor_a02',
  /** A05 */
  rexAnalysisSensorA05: 'rex_analysis_sensor_a05',
  /** A08 */
  rexAnalysisSensorA08: 'rex_analysis_sensor_a08',
  /** A09 */
  rexAnalysisSensorA09: 'rex_analysis_sensor_a09',
  /** A11 */
  rexAnalysisSensorA11: 'rex_analysis_sensor_a11',
  /** A13 */
  rexAnalysisSensorA13: 'rex_analysis_sensor_a13',
  /** A14 */
  rexAnalysisSensorA14: 'rex_analysis_sensor_a14',
  /** A16 */
  rexAnalysisSensorA16: 'rex_analysis_sensor_a16',
  /** A17 */
  rexAnalysisSensorA17: 'rex_analysis_sensor_a17',
  /** 既に使用されているグループURLです。 */
  errorMessageDuplicateGroupUrl: 'error_message_duplicate_group_url',
  /** ファイルの形式またはサイズが対応していません。 */
  logoFileIsNotSupported: 'logo_file_is_not_supported',
  /** グループロゴ */
  groupLogo: 'group_logo',
  /** 表示条件クリア */
  clearSearchConditions: 'clear_search_conditions',
  /** 現在のパスワードが間違っています。 */
  errorCurrentPasswordMismatch: 'error_current_password_mismatch',
  /** 警報はありません。 */
  emptyDiagnosisList: 'empty_diagnosis_list',
  /** 全画面 */
  fullScreen: 'full_screen',
  /** 全画面解除 */
  fullScreenExit: 'full_screen_exit',
  /** ユーザーアクセス権限管理 */
  accountAuthorityManagement: 'account_authority_management',
  /** ユーザーアクセス権限情報 */
  accountAuthorityInformation: 'account_authority_information',
  /** 権限を変更してください。\nログインIDは変更できません。 */
  guideMessageEditAccountAuthority: 'guide_message_edit_account_authority',
  /** 管理ユーザー : メンテナンス期間を登録、編集、削除ができます。\nユーザー : 設定されたメンテナンス期間の閲覧のみできます。 */
  accountAuthorityDescription: 'account_authority_description',
  /** メンテナンス */
  maintenanceTitle: 'maintenance_title',
  /** 機器の調整や定期ドックなど、設定したメンテナンス期間が表示されます。 */
  maintenanceSubtitle: 'maintenance_subtitle',
  /** 備考 */
  remarks: 'remarks',
  /** 入力してください。\n機器の調整や定期ドックなど、メンテナンスした期間を登録してください。 */
  guideMessageEditMaintenance: 'guide_message_edit_maintenance',
  /** メンテナンス期間(UTC) */
  maintenanceDateLabel: 'maintenance_date_label',
  /** メンテナンス情報 */
  maintenanceInformation: 'maintenance_information',
  /** メンテナンス新規登録 */
  maintenanceNewRegistration: 'maintenance_new_registration',
  /** データ再計算登録 */
  dataRecalcRegistration: 'data_recalc_registration',
  /** データ変換期間(UTC) */
  dataRecalcDateLabel: 'data_recalc_date_label',
  /** ステータス */
  status: 'status',
  /** 入力してください。\n登録後はキャンセルおよび編集や削除もできません。 */
  guideMessageEditDataRecalc: 'guide_message_edit_data_recalc',
  /** 実行中はキャンセルできません。\nデータ再計算を実行しますか？ */
  confirmMessageDataRecalc: 'confirm_message_data_recalc',
  /** 正常 */
  dataRecalcStatusCompleted: 'data_recalc_status_Completed',
  /** 失敗 */
  dataRecalcStatusFailed: 'data_recalc_status_Failed',
  /** 処理中 */
  dataRecalcStatusProcessing: 'data_recalc_status_Processing',
  /** 待機中 */
  dataRecalcStatusWaiting: 'data_recalc_status_Waiting',
  /** 開始日(UTC) */
  startDay: 'start_day',
  /** 終了日(UTC) */
  endDay: 'end_day',
  /** 期間は180日以内にしてください。 */
  errorMessageDataRecalcRange: 'error_message_data_recalc_range',
  /** メンテナンスはありません。 */
  emptyMaintenanceList: 'empty_maintenance_list',
  /** 設定されたメンテナンス期間と警報発生期間が一部重複しております。メンテナンス期間をご確認ください。 */
  warningMessagePartialMaintenance: 'warning_message_partial_maintenance',
  /** 設定された期間と警報の発生期間が一部重複しております。このままメンテナンス期間を登録してもよろしいですか？ */
  questionMessagePartialMaintenance: 'question_message_partial_maintenance',
  /** 軸受7 */
  rexBearingAnomalyDetectionBearing7: 'rex_bearing_anomaly_detection_bearing7',
  /** 軸受8 */
  rexBearingAnomalyDetectionBearing8: 'rex_bearing_anomaly_detection_bearing8',
  /** 内輪 */
  rexBearingAnomalyDetectionInner: 'rex_bearing_anomaly_detection_inner',
  /** 外輪 */
  rexBearingAnomalyDetectionOuter: 'rex_bearing_anomaly_detection_outer',
  /** データ出力結果 */
  analysisDataResults: 'analysis_data_results',
  /** 出力 */
  analysisResultsOutput: 'analysis_results_output',
  /** 選択する船舶/ユニットは1つのみとしてください。 */
  analysisSelectOtherError: 'analysis_select_other_error',
  /** 回転数 */
  analysisFuelConsumptionMegSpd: 'analysis_fuel_consumption_meg_spd',
  /** 速力 */
  analysisFuelConsumptionVesSpd: 'analysis_fuel_consumption_ves_spd',
  /** 航行距離(減速運転中)[km] */
  analysisFuelConsumptionDisOperation: 'analysis_fuel_consumption_dis_operation',
  /** 減速運転時間[ｈ] */
  analysisFuelConsumptionTimeOperation: 'analysis_fuel_consumption_time_operation',
  /** 燃料消費量(減速運転中)[L] */
  analysisFuelConsumptionOil: 'analysis_fuel_consumption_oil',
  /** 平均速力[knot] */
  analysisFuelConsumptionSog: 'analysis_fuel_consumption_sog',
  /** 燃料/航行距離[L/km] */
  analysisFuelConsumptionFuelDistance: 'analysis_fuel_consumption_fuel_distance',
  /** 燃料/減速運転時間[L/h] */
  analysisFuelConsumptionFuelTime: 'analysis_fuel_consumption_fuel_time',
  /** 総運転時間(作業時間を除く)[h] */
  analysisFuelConsumptionTotalTime: 'analysis_fuel_consumption_total_time',
  /** 減速運転時間[h] */
  analysisFuelConsumptionTimeForLow: 'analysis_fuel_consumption_time_for_low',
  /** 減速運転比率[%] */
  analysisFuelConsumptionRateOfLow: 'analysis_fuel_consumption_rate_of_low',
  /** SOG[knot] */
  analysisFuelConsumptionCsvSog: 'analysis_fuel_consumption_csv_sog',
  /** M/E speed Port[rpm] */
  analysisFuelConsumptionCsvSpeedPort: 'analysis_fuel_consumption_csv_speed_port',
  /** M/E speed Stbd[rpm] */
  analysisFuelConsumptionCsvSpeedStbd: 'analysis_fuel_consumption_csv_speed_stbd',
  /** Fuel oil consumption Port[L] */
  analysisFuelConsumptionCsvFuelOilConsumptionPort: 'analysis_fuel_consumption_csv_fuel_oil_consumption_port',
  /** Status */
  analysisFuelConsumptionCsvStatus: 'analysis_fuel_consumption_csv_status',
  /** Date */
  analysisFuelConsumptionCsvDate: 'analysis_fuel_consumption_csv_date',
  /** 期間は31日以内にしてください。 */
  analysisSelectOtherDateError: 'analysis_select_other_date_error',
  /** Fuel oil consumption Stbd[L] */
  analysisFuelConsumptionCsvFuelOilConsumptionStbd: 'analysis_fuel_consumption_csv_fuel_oil_consumption_stbd',
  /** Fuel oil consumption[L] */
  analysisFuelConsumptionCsvFuelOilConsumption: 'analysis_fuel_consumption_csv_fuel_oil_consumption',
  /** メンテナンス中です。 */
  underMaintenance: 'under_maintenance'
}

export default msgId;
