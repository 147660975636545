import dayjs, { Dayjs } from 'dayjs';
import { Ship } from 'models/ships';
import constants from 'resources/constants';

/**
 * 指定時間待機する。
 *
 * @param timeInMilles 待機時間[ms]
 */
export async function sleep(timeInMilles: number): Promise<unknown> {
  return new Promise((r) => setTimeout(r, timeInMilles));
}

/**
 * UUIDを生成する。
 *
 * @returns UUID
 */
export function generateUUID(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16; // random number between 0 and 16

    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

/**
 * 値を指定した桁数で四捨五入した結果を取得する。
 *
 * @param value 値
 * @param fractionDigits 指定桁数
 */
export function roundValue(value: number, fractionDigits: number): number {
  if (isNaN(value) || value === null) {
    return 0;
  }

  if (isNaN(fractionDigits) || fractionDigits === null) {
    fractionDigits = 0;
  }

  const v = Math.round(value * 10 ** fractionDigits) / 10 ** fractionDigits;

  return Object.is(v, -0) ? 0 : v;
}

/**
 * 値を指定した桁数で四捨五入した結果を文字列で取得する。
 *
 * @param value 値
 * @param fractionDigits 指定桁数
 * @param isNaNCheck isNaN Check
 */
export function roundValueToString(
  value: number | undefined,
  fractionDigits: number,
  isNaNCheck?: boolean
): string {
  if (isNaNCheck) {
    if (value == null || isNaN(value)) {
      return fractionDigits == 0 ? constants.invalid.text : constants.invalid.value;
    } else {
      return roundValue(value, fractionDigits).toFixed(fractionDigits);
    }
  } else {
    if (value == null) {
      return fractionDigits == 0 ? constants.invalid.text : constants.invalid.value;
    } else {
      return roundValue(value, fractionDigits).toFixed(fractionDigits);
    }
  }
}

/**
 * フィルター船舶名に船舶名が部分一致する船舶リストを返す。
 * @param ships 船舶リスト
 * @param filterShipName フィルター船舶名
 */
export function doFilterShips(ships: Ship[] | undefined, filterShipName: string): Ship[] {
  if (ships != null && ships.length > 0) {
    const filterText = filterShipName.trim();
    if (filterText.length > 0) {
      return ships.filter(
        (x) => x.name.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1
      );
      // const filters = filterText.split(' ');

      // return ships.filter((x) =>
      //   filters.some((y) => x.name.toLocaleLowerCase().indexOf(y.toLocaleLowerCase()) > -1)
      // );
    } else {
      return ships;
    }
  } else {
    return [];
  }
}

/**
 * 当日の23:59:59.999999の時刻を持つ日付を取得する。
 *
 * @param timeInMilles 待機時間[ms]
 */
export function getTodayLimit(): Dayjs {
  const todayLimit = dayjs().format(constants.dateFormat.YYYYMMDD) + ' 23:59:59.999999';

  return dayjs(todayLimit);
}
