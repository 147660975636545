import React from 'react';
import { InputBaseProps, InputLabelProps, SxProps, TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';

interface SmallTextFieldProps {
  id: string;
  label: string;
  value: string | number | undefined;
  fullWidth: boolean;
  sx?: SxProps;
  disabled?: boolean;
  autoComplete?: string;
  error?: boolean;
  helperText?: string;
  required?: boolean;
  variant: 'standard' | 'outlined' | 'filled';
  multiline: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
  InputLabelProps?: Partial<InputLabelProps>;
  InputProps?: Partial<InputBaseProps>;
}

export function SmallTextField(props: SmallTextFieldProps): JSX.Element {
  const {
    id,
    label,
    value,
    fullWidth,
    sx,
    disabled,
    autoComplete,
    error,
    helperText,
    required,
    variant,
    multiline,
    onChange,
    onKeyPress,
    onFocus,
    onBlur,
    InputLabelProps,
    InputProps,
  } = props;
  const intl = useIntl();

  let _label;
  if (required === true) {
    _label = label + ' [' + intl.formatMessage({ id: msgId.required }) + ']';
  } else {
    _label = label;
  }

  return (
    <TextField
      label={_label}
      id={id}
      name={id}
      sx={sx}
      value={value}
      fullWidth={fullWidth}
      variant={variant}
      size="small"
      disabled={disabled}
      autoComplete={autoComplete}
      error={error}
      helperText={helperText}
      onChange={onChange}
      onKeyPress={onKeyPress}
      onFocus={onFocus}
      onBlur={onBlur}
      InputLabelProps={InputLabelProps}
      InputProps={InputProps}
      multiline={multiline}
    />
  );
}

SmallTextField.defaultProps = {
  fullWidth: false,
  variant: 'outlined',
  multiline: false,
};
