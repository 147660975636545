import { combineReducers } from '@reduxjs/toolkit';

import commonReducer from './commonSlice';
import displaySettingsReducer from './displaySettingsSlice';
import accountReducer from '../features/login/accountSlice';

import mainDrawerReduser from '../features/drawer/mainDrawerSlice';
import appBarReducer from '../features/appBar/mainAppBarSlice';

import diagnosisReducer from '../features/contents/dashboard/diagnosis/diagnosisSlice';
import maintenanceReducer from '../features/contents/dashboard/maintenance/maintenanceSlice';
import reportConditionReducer from '../features/contents/dashboard/report/reportSlice';

import pastDataReducer from '../features/contents/shipState/pastData/pastDataSlice';
import pastDataSearchReducer from '../features/contents/shipState/pastData/search/searchSlice';
import pastDataTimeSliderReducer from '../features/contents/shipState/pastData/timeSlider/timeSliderSlice';
import pastDataMapReducer from '../features/contents/shipState/pastData/map/mapSlice';
import pastDataTrendChartReducer from '../features/contents/shipState/pastData/chart/trend/trendChartSlice';

import realtimeAisDataReducer from '../features/contents/shipState/realtimeAisData/realtimeAisDataSlice';
import realtimeAisDataMapReducer from '../features/contents/shipState/realtimeAisData/map/mapSlice';

import analysisReducer from '../features/contents/analysis/analysisSlice';
import analysisSearchReducer from '../features/contents/analysis/search/searchSlice';

import generalSettingsReducer from '../features/contents/generalSettings/generalSettingsSlice';

import adminSettingsReducer from '../features/contents/adminSettings/adminSettingsSlice';
import systemSettingsReducer from '../features/contents/systemSettings/systemSettingsSlice';
import accountInformationReducer from '../features/main/accountInformation/accountInformationSlice';

const rootReducer = combineReducers({
  common: commonReducer,
  displaySettings: displaySettingsReducer,
  account: accountReducer,
  mainDrawer: mainDrawerReduser,
  appBar: appBarReducer,
  diagnosis: diagnosisReducer,
  maintenance: maintenanceReducer,
  report: reportConditionReducer,
  pastData: pastDataReducer,
  pastDataSearch: pastDataSearchReducer,
  pastDataTimeSlider: pastDataTimeSliderReducer,
  pastDataMap: pastDataMapReducer,
  pastDataTrendChart: pastDataTrendChartReducer,
  realtimeAisData: realtimeAisDataReducer,
  realtimeAisDataMap: realtimeAisDataMapReducer,
  analysis: analysisReducer,
  analysisSearch: analysisSearchReducer,
  generalSettings: generalSettingsReducer,
  adminSettings: adminSettingsReducer,
  systemSettings: systemSettingsReducer,
  accountInformation: accountInformationReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
