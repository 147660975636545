import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import {
  EditDialog,
  EditDialogContents,
  EditDialogFooterButtonGrid,
  EditDialogInputFieldGrid,
  EditDialogRootGrid,
  EditDialogTitleGrid,
  EditDialogWideInputFieldGrid,
} from 'components/EditDialog';
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  styled,
  Typography,
  FormGroup,
  Box,
} from '@mui/material';
import { NormalButton } from 'components/NormalButton';
import { theme } from 'resources/theme';
import { AlertDialog } from 'components/AlertDialog';
import dimens from 'resources/dimens';
import { CustomScrollBars } from 'components/CustomScrollBars';
import colors from 'resources/colors';
import { FadeLoading } from 'components/FadeLoading';
import { DatePicker } from 'components/DatePicker';
import constants from 'resources/constants';
import dayjs from 'dayjs';
import {
  registrationDataRecalc,
  setDataRecalcEditing,
  setEditDataRecalcError,
  setEditDataRecalcResult,
  useAdminSettingsEditDataRecalcResult,
  useAdminSettingsEditMode,
  useAdminSettingsFetching,
  useAdminSettingsShips,
} from '../adminSettingsSlice';
import config from 'resources/config';

const DateRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  background: theme.palette.background.default,
});

interface DataRecalcEditDialogProps {
  contentHeight: number;
}

export function DataRecalcEditDialog(props: DataRecalcEditDialogProps): JSX.Element {
  const { contentHeight } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const ships = useAdminSettingsShips();
  const editMode = useAdminSettingsEditMode();
  const updateResult = useAdminSettingsEditDataRecalcResult();
  const inProgress = useAdminSettingsFetching();
  const [state, setState] = useState<{
    startDate: string;
    endDate: string;
    shipIds: string[];
    startDateError: boolean;
    endDateError: boolean;
    rangeError: boolean;
  }>({
    startDate: '',
    endDate: '',
    shipIds: [],
    startDateError: false,
    endDateError: false,
    rangeError: false,
  });
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false);

  const initialDate = (addMonth: number, addDay: number) => {
    // 時分秒切り捨て
    const unixTime =
      Math.floor(dayjs().add(addDay, 'day').add(addMonth, 'month').unix() / (60 * 60 * 24)) *
      (60 * 60 * 24);
    const d = dayjs.unix(unixTime).format(constants.dateFormat.iso8601);

    return d;
  };

  /**
   * バックボタン押下
   */
  const handleBackClick = () => {
    dispatch(setDataRecalcEditing({ editing: false, dataRecalc: undefined }));
    dispatch(setEditDataRecalcError(undefined));
    setState({
      shipIds: [],
      startDate: initialDate(0, -1),
      endDate: initialDate(0, -1),
      startDateError: false,
      endDateError: false,
      rangeError: false,
    });
  };

  /**
   * 登録ボタン押下時に呼ばれる。
   */
  const handleRegistrationClick = () => {
    setConfirmDialog(true);
  };

  /**
   * 登録実行時に呼ばれる。
   */
  const handleRegistrationClickOk = (result: string) => {
    if (result === 'ok') {
      dispatch(
        registrationDataRecalc({
          startDate: state.startDate,
          endDate: state.endDate,
          shipIds: state.shipIds,
        })
      );
    }
    setConfirmDialog(false);
  };

  /**
   * 開始日付の変更
   * @param date 日付ピッカー
   * @param value 日付
   */
  const handleStartDateChange = (value: string | undefined) => {
    if (value != null) {
      let startDate;
      let startDateError = false;
      let endDateError = false;
      let rangeError = false;

      try {
        startDate = dayjs(value).utc().format(constants.dateFormat.iso8601WithoutHours);
      } catch (e) {
        startDate = initialDate(0, -1);
      }

      const start = dayjs(startDate);
      const end = dayjs(state.endDate);
      if (start > end) {
        startDateError = true;
        endDateError = true;
      }
      if (start < end) {
        const diff = end.diff(start);
        if (diff >= config.dataRecalcRangeLimit) {
          startDateError = true;
          endDateError = true;
          rangeError = true;
        }
      }

      setState({
        ...state,
        startDate: startDate,
        startDateError: startDateError,
        endDateError: endDateError,
        rangeError: rangeError,
      });
    }
  };

  /**
   * 終了日付の変更
   * @param date 日付ピッカー
   * @param value 日付
   */
  const handleEndDateChange = (value: string | undefined) => {
    if (value != null) {
      let endDate;
      let startDateError = false;
      let endDateError = false;
      let rangeError = false;

      try {
        endDate = dayjs(value).utc().format(constants.dateFormat.iso8601WithoutHours);
      } catch (e) {
        endDate = initialDate(0, -1);
      }

      const start = dayjs(state.startDate);
      const end = dayjs(endDate);
      if (start > end) {
        startDateError = true;
        endDateError = true;
      }
      if (start < end) {
        const diff = end.diff(start);
        if (diff >= config.dataRecalcRangeLimit) {
          startDateError = true;
          endDateError = true;
          rangeError = true;
        }
      }

      setState({
        ...state,
        endDate: endDate,
        startDateError: startDateError,
        endDateError: endDateError,
        rangeError: rangeError,
      });
    }
  };

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const shipIds = [...state.shipIds];
    if (event.target.checked && !shipIds.includes(event.target.name)) {
      shipIds.push(event.target.name);
    } else {
      shipIds.splice(shipIds.indexOf(event.target.name), 1);
    }
    setState({
      ...state,
      shipIds: shipIds,
    });
  };

  const handleResultClear = (isSuccess: boolean) => {
    if (isSuccess) {
      dispatch(setDataRecalcEditing({ editing: false, dataRecalc: undefined }));
      dispatch(setEditDataRecalcError(undefined));
      setState({
        startDate: initialDate(0, -1),
        endDate: initialDate(0, -1),
        shipIds: [],
        startDateError: false,
        endDateError: false,
        rangeError: false,
      });
    }
    dispatch(setEditDataRecalcResult(undefined));
  };

  useEffect(() => {
    if (editMode === 'dataRecalc') {
      setState({
        startDate: initialDate(0, -1),
        endDate: initialDate(0, -1),
        shipIds: [],
        startDateError: false,
        endDateError: false,
        rangeError: false,
      });
    }
  }, [dispatch, editMode]);

  useEffect(() => {
    if (updateResult && updateResult.isSuccess && updateResult.isDeleteRequest) {
      dispatch(setDataRecalcEditing({ editing: false, dataRecalc: undefined }));
      dispatch(setEditDataRecalcError(undefined));
      setState({
        startDate: initialDate(0, -1),
        endDate: initialDate(0, -1),
        shipIds: [],
        startDateError: false,
        endDateError: false,
        rangeError: false,
      });
      dispatch(setEditDataRecalcResult(undefined));
    }
  }, [dispatch, updateResult]);

  return (
    <EditDialog
      id="dataRecalcEditDialog"
      open={editMode === 'dataRecalc'}
      onBackClick={handleBackClick}
      title={intl.formatMessage({
        id: msgId.dataRecalcRegistration,
      })}
    >
      <EditDialogContents>
        <CustomScrollBars
          thumbColor={colors.scrollBar.thumb.light}
          height={contentHeight}
          width={dimens.dialogContents.width}
          autoHide={false}
        >
          <EditDialogRootGrid>
            <EditDialogTitleGrid>
              {intl
                .formatMessage({ id: msgId.guideMessageEditDataRecalc })
                .split('\n')
                .map((t, i) => {
                  return (
                    <Typography key={i} variant="h5" align="center">
                      {t}
                    </Typography>
                  );
                })}
            </EditDialogTitleGrid>
            <EditDialogWideInputFieldGrid
              sx={{
                margin: theme.spacing(0),
              }}
            >
              <DateRow>
                <Typography
                  variant="body2"
                  noWrap
                  sx={{ paddingLeft: theme.spacing(1), color: colors.accent }}
                >
                  {intl.formatMessage({ id: msgId.dataRecalcDateLabel })}
                </Typography>
                <DatePicker
                  value={state.startDate}
                  type="date"
                  addMaxDay={-1}
                  displayFormat={constants.dateFormat.YYYYMMDD}
                  onValueChanged={handleStartDateChange}
                  error={state.startDateError || state.rangeError}
                />
                <Typography variant="body2" sx={{ paddingTop: '2px' }}>
                  -
                </Typography>
                <DatePicker
                  value={state.endDate}
                  type="date"
                  addMaxDay={-1}
                  displayFormat={constants.dateFormat.YYYYMMDD}
                  onValueChanged={handleEndDateChange}
                  error={state.endDateError || state.rangeError}
                />
              </DateRow>
            </EditDialogWideInputFieldGrid>
            <EditDialogWideInputFieldGrid>
              {state.rangeError && (
                <Box>
                  {intl
                    .formatMessage({ id: msgId.errorMessageDataRecalcRange })
                    .split('\n')
                    .map((t, i) => {
                      return (
                        <Typography key={i} variant="body2" align="left" color="error">
                          {t}
                        </Typography>
                      );
                    })}
                </Box>
              )}
            </EditDialogWideInputFieldGrid>
            <EditDialogInputFieldGrid
              sx={{
                background: colors.subDrawer.background,
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
                paddingTop: theme.spacing(0.5),
                paddingBottom: theme.spacing(0.5),
              }}
            >
              <FormControl>
                <FormGroup>
                  {ships &&
                    ships.map((ship, index) => {
                      return (
                        <FormControlLabel
                          key={'ship_' + index}
                          control={
                            <Checkbox
                              size="small"
                              sx={{ padding: theme.spacing(0.5) }}
                              checked={state.shipIds.includes(ship.shipId)}
                              onChange={handleCheckChange}
                              name={ship.shipId}
                              disabled={
                                !state.shipIds.includes(ship.shipId) &&
                                state.shipIds.length >= config.dataRecalcRegistraionMaxShips
                              }
                            />
                          }
                          label={
                            <Typography
                              variant="body2"
                              width={dimens.dialogContents.labelMaxWidth}
                              noWrap
                            >
                              {ship.name}
                            </Typography>
                          }
                        />
                      );
                    })}
                </FormGroup>
              </FormControl>
            </EditDialogInputFieldGrid>
            <EditDialogFooterButtonGrid>
              <NormalButton
                sx={{ width: dimens.button.footerWidth }}
                label={intl.formatMessage({ id: msgId.registration })}
                color="primary"
                onClick={() => handleRegistrationClick()}
                disabled={
                  state.shipIds.length === 0 ||
                  state.startDateError ||
                  state.endDateError ||
                  state.rangeError
                }
              />
            </EditDialogFooterButtonGrid>
          </EditDialogRootGrid>
          {updateResult != null && updateResult.showDialog && editMode === 'dataRecalc' && (
            <AlertDialog
              title={intl.formatMessage({
                id: updateResult.isSuccess ? msgId.confirm : msgId.error,
              })}
              message={intl.formatMessage({ id: updateResult.msgId })}
              onClose={() => handleResultClear(updateResult.isSuccess)}
            />
          )}
          {confirmDialog && editMode && (
            <AlertDialog
              title={intl.formatMessage({ id: msgId.confirm })}
              message={intl.formatMessage({ id: msgId.confirmMessageDataRecalc })}
              onClose={(result) => handleRegistrationClickOk(result)}
              negativeButton={intl.formatMessage({ id: msgId.cancel })}
              positiveButton={intl.formatMessage({ id: msgId.ok })}
            />
          )}
        </CustomScrollBars>
      </EditDialogContents>
      <FadeLoading loading={inProgress} />
    </EditDialog>
  );
}
