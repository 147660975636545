import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Box, Grid, Paper, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import dimens from 'resources/dimens';
import msgId from 'resources/intl';
import config from 'resources/config';
import colors from 'resources/colors';
import {
  signIn,
  useAccountSignInError,
  useAccountInProgress,
  useAccountAccountGroupLogoBase64,
  setPasswordResetOpen,
  getAccountGroupsLogoUrl,
  useIsTemporaryPasswordExpired,
  setSignInError,
  useAccountIsSignInError,
} from './accountSlice';
import LoginBack1 from 'resources/assets/LoginBack1.jpg';
import { SmallTextField } from 'components/SmallTextField';
import { PasswordTextField } from 'components/PasswordTextField';
import { LinkText } from 'components/LinkText';
import { MultiLineTypography } from 'components/MultiLineTypography';
import { AccountPasswordResetDialog } from './AccountPasswordResetDialog';
import { AccountPasswordChangeDialog } from './AccountPasswordChangeDialog';
import { AccountMailAddressChangeDialog } from './AccountMailAddressChangeDialog';
import { useLocation } from 'react-router-dom';
import { CustomScrollBars } from 'components/CustomScrollBars';
import { ApiErrorDialog } from 'components/ApiErrorDialog';

const SyledBox = styled(Box)({
  height: '100vh',
  width: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  overflow: 'hidden',
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  alignContent: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(8),
  paddingRight: theme.spacing(8),
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  backdropFilter: 'blur(4px)',
  width: dimens.login.width,
  maxWidth: dimens.login.width,
}));

const StyledLoadingButton = styled(LoadingButton)({
  width: 150,
  height: '40px',
  borderRadius: '20px',
});

interface State {
  mailAddress: string;
  password: string;
}

export function Maintenance(): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();
  const inProgress = useAccountInProgress();
  const isSignInError = useAccountIsSignInError();
  const signInError = useAccountSignInError();
  const isTemporaryPasswordExpired = useIsTemporaryPasswordExpired();
  const location = useLocation();
  const appLogo = useAccountAccountGroupLogoBase64();
  const [contentHeight, setContentHeight] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const [state, setState] = useState<State>({
    mailAddress: '',
    password: '',
  });
  // const [logo, setLogo] = useState(undefined);
  const [backgroundImage, setBackgroundImage] = useState('');

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [prop]: event.target.value });
  };

  const handleLoginClick = () => {
    if (!inProgress) {
      dispatch(signIn(state.mailAddress, state.password));
    }
  };

  const handleSignInError = () => {
    dispatch(setSignInError(undefined));
  };

  if (backgroundImage.length === 0) {
    const bg = [LoginBack1];
    const bgIndex = Math.floor(Math.random() * bg.length);
    setBackgroundImage('url(' + bg[bgIndex] + ')');
  }

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const height = entries[0].contentRect.height;
      setContentHeight(height);
    });
    containerRef.current && resizeObserver.observe(containerRef.current);

    return (): void => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    dispatch(getAccountGroupsLogoUrl(location.pathname.substring(1)));
  }, [dispatch, location]);

  return (
    <SyledBox
      ref={containerRef}
      sx={{
        backgroundImage: backgroundImage,
      }}
    >
      <CustomScrollBars thumbColor={colors.scrollBar.thumb.login}>
        <Grid
          sx={{ height: '100%', minHeight: dimens.login.minHeight }}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          wrap="nowrap"
          spacing={0}
        >
          <Grid item>
            {appLogo && (
              <img
                style={{
                  width: 250,
                  marginBottom: dimens.default.margin * 5,
                  alignContent: 'left',
                }}
                src={appLogo}
                alt="logo"
              />
            )}
            {!appLogo && (
              <div
                style={{
                  width: 250,
                  height: 71,
                  marginBottom: dimens.default.margin * 5,
                  alignContent: 'left',
                }}
              />
            )}
          </Grid>
          <Grid item>
            <StyledPaper>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={0}
              >
                <Grid item sx={{ width: '100%', marginBottom: 3 }}>
                  <Typography
                    variant="h2"
                    sx={{ width: 300, fontWeight: 300, fontFamily: 'Roboto' }}
                  >
                    {intl.formatMessage({ id: msgId.appName })}
                  </Typography>
                </Grid>
                <Grid item sx={{ width: '100%', marginBottom: 3 }}>
                  <SmallTextField
                    label={intl.formatMessage({ id: msgId.accountName })}
                    id="mailAddress"
                    value={state.mailAddress}
                    fullWidth={true}
                    autoComplete="email"
                    onChange={handleChange('mailAddress')}
                  />
                </Grid>
                <Grid item sx={{ width: '100%', marginBottom: 1 }}>
                  <PasswordTextField
                    label={intl.formatMessage({ id: msgId.password })}
                    id="password"
                    value={state.password}
                    fullWidth={true}
                    autoComplete="password"
                    onChange={handleChange('password')}
                  />
                </Grid>
                <Grid item sx={{ width: 320, height: 60, marginBottom: 1 }}>
                  {isSignInError && (
                    <MultiLineTypography
                      text={intl.formatMessage({
                        id: msgId.errorMessageSigninFailed,
                      })}
                      variant="body2"
                      color="error"
                      align="left"
                    />
                  )}
                  {isTemporaryPasswordExpired && (
                    <MultiLineTypography
                      text={intl.formatMessage({ id: msgId.errorMessageTemporaryPasswordExpired })}
                      variant="body2"
                      color="error"
                      align="left"
                    />
                  )}
                </Grid>
                <Grid item sx={{ marginBottom: 2 }}>
                  <MultiLineTypography
                    text={intl.formatMessage({ id: msgId.underMaintenance })}
                    variant="body2"
                    color="error"
                    align="left"
                  />
                </Grid>
                <Grid item sx={{ marginBottom: 4 }}>
                  <StyledLoadingButton
                    type="submit"
                    disabled={true}
                    loading={inProgress}
                    variant="contained"
                    onClick={handleLoginClick}
                  >
                    {intl.formatMessage({ id: msgId.login })}
                  </StyledLoadingButton>
                </Grid>
                <Grid item sx={{ marginBottom: 2 }}>
                  <Typography variant="body2" align="left">
                    {intl.formatMessage({ id: msgId.version }) + ' : ' + config.version}
                  </Typography>
                  <Typography variant="body2" align="left">
                    {intl.formatMessage({ id: msgId.buildNumber }) + ' : ' + config.buildNumber}
                  </Typography>
                </Grid>
              </Grid>
            </StyledPaper>
          </Grid>
        </Grid>
      </CustomScrollBars>
      <AccountPasswordResetDialog />
      <AccountPasswordChangeDialog />
      <AccountMailAddressChangeDialog />
      {signInError != null && signInError.status >= 500 && (
        <ApiErrorDialog error={signInError} onClose={handleSignInError} />
      )}
    </SyledBox>
  );
}
