import msgId from 'resources/intl';
import {
  AuthorityAdministrators,
  AuthorityAdminUsers,
  AuthorityDevelopers,
  AuthorityGuests,
  AuthorityOperators,
  AuthorityUsers,
} from './accounts';

/**
 * 権限リスト
 */
export const Autorities = [
  { id: AuthorityAdministrators, msgId: msgId.authorityAdminitrators },
  { id: AuthorityDevelopers, msgId: msgId.authorityDevelopers },
  { id: AuthorityOperators, msgId: msgId.authorityOperators },
  { id: AuthorityAdminUsers, msgId: msgId.authorityAdminUsers },
  { id: AuthorityUsers, msgId: msgId.authorityUsers },
  { id: AuthorityGuests, msgId: msgId.authorityGuests },
];

/**
 * 機械登録更新モデル
 */
export interface AdminMachine {
  /** 機械ID */
  machineId?: number | string;
  /** 機械名 */
  name?: string;
  /** データフォーマットID */
  dataFormatId?: number;
  /** PLCシリアル番号 */
  serialNumber?: string;
}

/**
 * 船舶登録更新モデル
 */
export interface AdminShip {
  /** 船舶ID */
  shipId: string;
  /** 船舶名 */
  shipName?: string;
  /** 機械リスト */
  machines?: AdminMachine[];
  /** 推進機シリアル番号リスト */
  propulsionSerialNumbers?: string[];
}

/**
 * データ再計算モデル
 */
export interface DataRecalc {
  /** データ再計算ID */
  dataRecalcId: number;
  /** 船舶ID */
  shipId: string;
  /** 船舶名 */
  shipName: string;
  /** 機械ID */
  machineId: number;
  /** 船舶名 */
  machineName: string;
  /** 開始日 */
  startDate: string;
  /** 終了日 */
  endDate: string;
  /** ステータス */
  status: string;
  /** 登録日時 */
  createdAt: string;
  /** 更新日時 */
  updatedAt: string;
}

/**
 * データ再計算登録モデル
 */
export interface AdminDataRecalc {
  /** 開始日 */
  startDate: string;
  /** 終了日 */
  endDate: string;
  /** 船舶IDリスト */
  shipIds: string[];
}

/**
 * 更新結果モデル
 */
export interface UpdateResult {
  /** 結果 */
  isSuccess: boolean;
  /** エラーダイアログでメッセージを表示するかどうか */
  showDialog: boolean;
  /** エラーフィールド名 */
  errorFieldName?: string;
  /** エラーメッセージID(isSuccessがfalseの場合に設定される) */
  msgId?: string;
  /** 削除要求の結果かどうか */
  isDeleteRequest: boolean;
}
