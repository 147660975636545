import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from 'components/Accordion';
import { Ship } from 'models/ships';
import colors from 'resources/colors';
import { AisData, SensorThinnedDataDates } from 'models/data';
import { DiagnosisListItem } from 'models/diagnosis';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  styled,
  SvgIconProps,
  SxProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { theme } from 'resources/theme';
import {
  Build,
  ErrorOutline,
  ExpandMore,
  MyLocation,
  Warning,
  SmsFailed,
} from '@mui/icons-material';
import { ShipStatusCalendar } from './ShipStatusCalendar';
import msgId from 'resources/intl';
import { useIntl } from 'react-intl';

export const MaintenanceIcon = styled((props: SvgIconProps) => (
  <Build htmlColor={colors.icon.maintenance} {...props} />
))({
  width: 16,
  height: 16,
});

export const FaultIcon = styled((props: SvgIconProps) => (
  <Build htmlColor={colors.icon.fault} {...props} />
))({
  width: 16,
  height: 16,
});

export const WarningIcon = styled((props: SvgIconProps) => (
  <ErrorOutline htmlColor={colors.icon.warning} {...props} />
))({
  width: 16,
  height: 16,
});

export const CautionIcon = styled((props: SvgIconProps) => (
  <Warning htmlColor={colors.icon.caution} {...props} />
))({
  width: 16,
  height: 16,
});

export const MaintenanceErrorIcon = styled((props: SvgIconProps) => (
  <SmsFailed htmlColor={colors.icon.caution} {...props} />
))({
  width: 16,
  height: 16,
});

const ShipRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  height: 20,
  paddingLeft: theme.spacing(0.5),
});

const MachineRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  height: 32,
});

const ExpandMoreIcon = styled(ExpandMore)({
  marginRight: 0,
  marginLeft: 0,
});

const MyLocationIcon = styled(MyLocation)({
  width: 18,
  height: 18,
  marginRight: theme.spacing(0.5),
});

const AisStatusLabel = styled(Typography)({
  marginRight: theme.spacing(0.5),
  minWidth: '80px',
  width: '80px',
  height: '100%',
  paddingTop: '1px',
});

interface AisDataStatus {
  label: string;
  tooltip?: string;
  sx?: SxProps;
}

interface ShipItemProp {
  ship: Ship;
  showCalendar?: boolean;
  diagnosisListItems?: DiagnosisListItem[];
  expanded?: boolean;
  onExpandedChange?: (shipId: string, expanded: boolean) => void;
  checked?: boolean;
  onCheckChange?: (shipId: string, machineId: number, checked: boolean) => void;
  onLocation?: (shipId: string) => void;
  aisData?: AisData;
  sensorThinnedDataDatesList?: SensorThinnedDataDates[];
  onClickDay?: (machineId: number, date: string) => void;
  selectValue?: Date | Date[] | null | undefined;
  selectedMachineId?: number;
}

export function ShipItem(props: ShipItemProp): JSX.Element {
  const {
    ship,
    showCalendar,
    diagnosisListItems,
    expanded,
    checked,
    onExpandedChange,
    onCheckChange,
    onLocation,
    aisData,
    sensorThinnedDataDatesList,
    onClickDay,
    selectValue,
    selectedMachineId,
  } = props;
  const intl = useIntl();

  const aisDataStatuses: AisDataStatus[] = [
    /** 0: 航行中 */
    {
      label: msgId.aisStatusActive,
      tooltip: msgId.aisStatusTooltipUnderWayUsingEngine,
      sx: {
        color: colors.aisData.status.active.label,
        background: colors.aisData.status.active.background,
      },
    },
    /** 1: 錨泊中 */
    {
      label: msgId.aisStatusNotActive,
      tooltip: msgId.aisStatusTooltipAtAnchor,
      sx: {
        color: colors.aisData.status.notActive.label,
        background: colors.aisData.status.notActive.background,
        border: `1px solid ${colors.border}`,
      },
    },
    /** 2: 運転不自由 */
    {
      label: msgId.aisStatusAbnormal,
      tooltip: msgId.aisStatusTooltipNotUnderCommand,
      sx: {
        color: colors.aisData.status.abnormal.label,
        background: colors.aisData.status.abnormal.background,
      },
    },
    /** 3: 操縦性能制限 */
    {
      label: msgId.aisStatusRestricted,
      tooltip: msgId.aisStatusTooltipRestrictedManeuverability,
      sx: {
        color: colors.aisData.status.restricted.label,
        background: colors.aisData.status.restricted.background,
      },
    },
    /** 4: 喫水制限 */
    {
      label: msgId.aisStatusRestricted,
      tooltip: msgId.aisStatusTooltipConstrainedByHerDraught,
      sx: {
        color: colors.aisData.status.restricted.label,
        background: colors.aisData.status.restricted.background,
      },
    },
    /** 5: 係留中 */
    {
      label: msgId.aisStatusNotActive,
      tooltip: msgId.aisStatusTooltipMoored,
      sx: {
        color: colors.aisData.status.notActive.label,
        background: colors.aisData.status.notActive.background,
        border: `1px solid ${colors.border}`,
      },
    },
    /** 6: 座礁中 */
    {
      label: msgId.aisStatusAbnormal,
      tooltip: msgId.aisStatusTooltipAground,
      sx: {
        color: colors.aisData.status.abnormal.label,
        background: colors.aisData.status.abnormal.background,
      },
    },
    /** 7: 漁労中 */
    {
      label: msgId.aisStatusFishing,
      tooltip: msgId.aisStatusTooltipEngagedInFishing,
      sx: {
        color: colors.aisData.status.fishing.label,
        background: colors.aisData.status.fishing.background,
      },
    },
    /** 8: 帆走中 */
    {
      label: msgId.aisStatusActive,
      tooltip: msgId.aisStatusTooltipUnderWaySailing,
      sx: {
        color: colors.aisData.status.active.label,
        background: colors.aisData.status.active.background,
      },
    },
    /** 9: --- */
    {
      label: msgId.aisStatusUnknown,
      tooltip: undefined,
      sx: {
        color: colors.aisData.status.unknown.label,
        background: colors.aisData.status.unknown.background,
      },
    },
    /** 10: --- */
    {
      label: msgId.aisStatusUnknown,
      tooltip: undefined,
      sx: {
        color: colors.aisData.status.unknown.label,
        background: colors.aisData.status.unknown.background,
      },
    },
    /** 11: 曳船中 */
    {
      label: msgId.aisStatusActive,
      tooltip: msgId.aisStatusTooltipTowingAstern,
      sx: {
        color: colors.aisData.status.active.label,
        background: colors.aisData.status.active.background,
      },
    },
    /** 12: 押船もしくは横付け曳船中 */
    {
      label: msgId.aisStatusActive,
      tooltip: msgId.aisStatusTooltipPushingAheadOrTowingAlongside,
      sx: {
        color: colors.aisData.status.active.label,
        background: colors.aisData.status.active.background,
      },
    },
    /** 13: --- */
    {
      label: msgId.aisStatusUnknown,
      tooltip: undefined,
      sx: {
        color: colors.aisData.status.unknown.label,
        background: colors.aisData.status.unknown.background,
      },
    },
    /** 14: --- */
    {
      label: msgId.aisStatusUnknown,
      tooltip: undefined,
      sx: {
        color: colors.aisData.status.unknown.label,
        background: colors.aisData.status.unknown.background,
      },
    },
    /** 15: 未定義 */
    {
      label: msgId.aisStatusUndefined,
      tooltip: msgId.aisStatusTooltipUndefined,
      sx: {
        color: colors.aisData.status.undefined.label,
        background: colors.aisData.status.undefined.background,
        border: `1px solid ${colors.border}`,
      },
    },
  ];

  let aisDataStatus: AisDataStatus | undefined = undefined;
  if (aisData) {
    if (aisDataStatuses.length > aisData.status) {
      aisDataStatus = aisDataStatuses[aisData.status];
    } else {
      aisDataStatus = {
        label: msgId.aisStatusUnknown,
        tooltip: undefined,
        sx: {
          color: colors.aisData.status.unknown.label,
          background: colors.aisData.status.unknown.background,
        },
      };
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (shipId: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    if (onExpandedChange != null) {
      onExpandedChange(shipId, newExpanded);
    }
  };

  const handleCheckedChange =
    (shipId: string, machineId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onCheckChange != null) {
        onCheckChange(shipId, machineId, event.target.checked);
      }
    };

  const indeterminate =
    !ship.machines.every((x) => x.checked) && ship.machines.some((x) => x.checked);

  return (
    <Box sx={{ width: '100%' }}>
      <Accordion square expanded={expanded} onChange={handleChange(ship.shipId)}>
        <AccordionSummary aria-controls={ship.shipId + '-content'} id={ship.shipId}>
          <ShipRow>
            {onExpandedChange != null && (
              <IconButton
                size="small"
                sx={
                  expanded
                    ? {
                        paddingLeft: 0,
                        paddingRight: 0,
                        transform: 'rotate(180deg)',
                        transition: theme.transitions.create('transform', {
                          duration: theme.transitions.duration.shortest,
                        }),
                      }
                    : {
                        paddingLeft: 0,
                        paddingRight: 0,
                        transform: 'rotate(0deg)',
                        transition: theme.transitions.create('transform', {
                          duration: theme.transitions.duration.shortest,
                        }),
                      }
                }
                focusRipple={false}
                disableRipple
                disabled={true}
              >
                <ExpandMoreIcon color="primary" />
              </IconButton>
            )}
            <Typography
              sx={
                onExpandedChange
                  ? { marginLeft: theme.spacing(0) }
                  : { marginLeft: theme.spacing(0.5) }
              }
              variant="body2"
              noWrap
            >
              {ship.name}
            </Typography>
            {onLocation && (
              <IconButton
                size="small"
                onFocus={(event) => event.stopPropagation()}
                onClick={(event) => {
                  event.stopPropagation();
                  onLocation(ship.shipId);
                }}
              >
                <MyLocationIcon htmlColor={colors.accent} />
              </IconButton>
            )}
            <div style={{ flexGrow: 1 }} />
            {onCheckChange != null && (
              <FormControlLabel
                sx={{ marginRight: '7px' }}
                label=""
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={
                  <Checkbox
                    checked={checked}
                    indeterminate={indeterminate}
                    onChange={handleCheckedChange(ship.shipId, 0)}
                    color="primary"
                  />
                }
              />
            )}
            {diagnosisListItems
              ?.filter((parent) => parent.listParentId === 0)
              .some((y) => y.status === 'Caution') && (
              <IconButton size="small" disabled>
                <CautionIcon />
              </IconButton>
            )}
            {diagnosisListItems
              ?.filter((parent) => parent.listParentId === 0)
              .some((y) => y.status === 'Warning') && (
              <IconButton size="small" disabled>
                <WarningIcon />
              </IconButton>
            )}
            {diagnosisListItems
              ?.filter((parent) => parent.listParentId === 0)
              .some((y) => y.status === 'Fault') && (
              <IconButton size="small" disabled>
                <FaultIcon />
              </IconButton>
            )}
            {aisDataStatus && aisDataStatus.tooltip !== undefined && (
              <Tooltip
                title={intl.formatMessage({ id: aisDataStatus.tooltip })}
                sx={{ minWidth: '40px' }}
              >
                <AisStatusLabel variant="body2" noWrap align="center" sx={aisDataStatus.sx}>
                  {intl.formatMessage({ id: aisDataStatus.label })}
                </AisStatusLabel>
              </Tooltip>
            )}
            {aisDataStatus && aisDataStatus.tooltip === undefined && (
              <AisStatusLabel variant="body2" noWrap align="center" sx={aisDataStatus.sx}>
                {intl.formatMessage({ id: aisDataStatus.label })}
              </AisStatusLabel>
            )}
          </ShipRow>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction="column">
            {ship.machines.map((machine) => {
              const sensorThinnedDataDates = sensorThinnedDataDatesList?.filter(
                (dataDates) => dataDates.machineId === machine.machineId
              );

              return (
                <Grid container direction="column" key={machine.machineId}>
                  <MachineRow>
                    <Typography
                      sx={
                        onExpandedChange
                          ? { padding: '4px 8px 4px 28px' }
                          : { padding: '4px 8px 4px 24px' }
                      }
                      variant="body2"
                      noWrap
                    >
                      {machine.name}
                    </Typography>
                    <div style={{ flexGrow: 1 }} />
                    {onCheckChange != null && (
                      <FormControlLabel
                        sx={{ marginRight: '7px' }}
                        label=""
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        control={
                          <Checkbox
                            checked={machine.checked}
                            onChange={handleCheckedChange(ship.shipId, machine.machineId)}
                            color="primary"
                          />
                        }
                      />
                    )}
                  </MachineRow>
                  <Divider />
                  {showCalendar && (
                    <Grid>
                      <ShipStatusCalendar
                        diagnosisListItems={diagnosisListItems}
                        sensorThinnedDataDates={
                          sensorThinnedDataDates
                            ? sensorThinnedDataDates.length > 0
                              ? sensorThinnedDataDates[0]
                              : undefined
                            : undefined
                        }
                        onClickDay={(date) => onClickDay && onClickDay(machine.machineId, date)}
                        selectValue={
                          selectedMachineId === machine.machineId ? selectValue : undefined
                        }
                      />
                    </Grid>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
