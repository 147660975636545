import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import ReactDOM from 'react-dom';
import { SliderTime, usePastDataTimeSliderPlaying } from '../../timeSlider/timeSliderSlice';
import AppLogger from 'utils/AppLogger';

import Plotly, {
  PlotType,
  Layout,
  PlotMouseEvent,
  Datum,
  Annotations,
  Shape,
  Padding,
} from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';
import { ChartSeries } from 'models/chart';
import colors from 'resources/colors';
import { styled } from '@mui/material';
import dimens from 'resources/dimens';
import constants from 'resources/constants';
import { roundValueToString } from 'utils/misc';
import { Figure } from 'react-plotly.js';
import dayjs from 'dayjs';

const Plot = createPlotlyComponent(Plotly);
const ActiveLine = true;
const PlotlyRestyle = Plotly.restyle;
const PlotlyRelayout = Plotly.relayout;

const whiteFontColor = '#ddd';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let timerId: any = null;
export interface LayoutChange {
  'xaxis.range[0]': Datum;
  'xaxis.range[1]': Datum;
  'yaxis.range[0]': Datum;
  'yaxis.range[1]': Datum;
  'xaxis.autorange': boolean;
  'yaxis.autorange': boolean;
  index: number;
  minParsent: number;
  maxParsent: number;
}
export interface PlotTitle {
  text: string;
  xref: 'container' | 'paper';
  x: number;
  y: number;
  xanchor: 'auto' | 'left' | 'center' | 'right';
  pad: Partial<Padding>;
}

const RootDiv = styled('div')({
  position: 'relative',
  width: '100%',
  height: dimens.chart.trend.height,
  background: '#000000',
  fontSize: '0',
  border: 'none',
  margin: 0,
});

const GraphValuePanel = styled('div')({
  position: 'absolute',
  fontSize: '8px',
  color: whiteFontColor,
  zIndex: 3,
});

const topLabelHeiht = 13;
const layoutBottomMargin = 30;

export interface TrendGraphProps {
  chartId: string;
  chartSeriesList: ChartSeries[];
  panelHeight: number;
  panelWidth: number;
  resetRange?: boolean;
  setResetRange?: () => void;
}

export function TrendGraph(props: TrendGraphProps): JSX.Element {
  const { chartId, chartSeriesList, panelHeight, panelWidth, resetRange, setResetRange } = props;
  const intl = useIntl();
  const graphPanelWidth = panelWidth;
  const graphPanelHeight = panelHeight / 2;
  const [layoutRange, setLayoutRange] = React.useState<Partial<LayoutChange>>({
    index: 0,
    minParsent: 0,
    maxParsent: 100,
  });
  let doubleClick = false;
  const playing = usePastDataTimeSliderPlaying();

  let startTime = 0;
  let endTime = 0;
  let timeData: number[] = [];
  const timezoneOffset = new Date().getTimezoneOffset() * 60000;
  if (chartSeriesList.length > 0 && chartSeriesList[0].timeData != null) {
    timeData = chartSeriesList[0].timeData.map((value) => value + timezoneOffset);
    startTime = timeData[0];
    endTime = timeData[timeData.length - 1];
  }

  let graphDataL: Plotly.Data[] = [];
  let graphDataR: Plotly.Data[] = [];

  const dumyData = {
    x: [],
    y: [],
    name: '',
    type: 'scatter' as PlotType,
    hoverinfo: 'none',
    line: { color: 'rgba(0,0,0,0)' },
  } as Plotly.Data;

  chartSeriesList.map((series, index) => {
    const ydata =
      series.sensorGroup.displayUpperLimit === 360 || series.sensorGroup.displayUpperLimit === 359.9
        ? series.portSensorData?.data.map((val) => (val > 180 ? val - 360 : val))
        : series.portSensorData?.data;
    graphDataL = [
      ...graphDataL,
      {
        x: timeData,
        y: ydata,
        name: series.sensorGroup.sensorGroupName,
        type: 'scatter' as PlotType,
        hoverinfo: 'none',
        line: { color: series.color, width: 1 },
        yaxis: 'y' + (index !== 0 ? index + 1 : ''),
      },
    ];

    return true;
  });

  chartSeriesList.map((series, index) => {
    const ydata =
      series.sensorGroup.displayUpperLimit === 180 || series.sensorGroup.displayUpperLimit === 359.9
        ? series.starboardSensorData?.data.map((val) => (val > 180 ? val - 360 : val))
        : series.starboardSensorData?.data;
    graphDataR = [
      ...graphDataR,
      {
        x: timeData,
        y: ydata,
        name: series.sensorGroup.sensorGroupName,
        type: 'scatter' as PlotType,
        hoverinfo: 'none',
        line: { color: series.color, width: 1 },
        yaxis: 'y' + (index !== 0 ? index + 1 : ''),
      },
    ];

    return true;
  });

  const layout = {
    title: {
      text: '',
      x: 0,
      xref: 'paper',
      xanchor: 'left',
      font: {
        color: whiteFontColor,
        size: 16,
      },
    },
    template: { themeRef: 'PLOTLY_DARK' },
    height: graphPanelHeight - topLabelHeiht,
    width: graphPanelWidth,
    margin: {
      l: 5,
      r: 5,
      t: 45,
      b: 4,
    },
    xaxis: {
      color: whiteFontColor,
      zeroline: true,
      tickfont: {
        color: whiteFontColor,
        size: 10,
      },
      gridcolor: 'rgba(0,0,0,0)',
      zerolinecolor: colors.chart.legend.border,
      visible: true,
      autorange: true,
      timezone: 'UTC',
      range: [startTime, endTime],
      tickmode: 'auto',
      tickangle: 0,
      nticks: 5,
    },
    yaxis: {
      color: whiteFontColor,
      zeroline: true,
      // range: [0,101],
      tickfont: {
        color: whiteFontColor,
      },
      showticklabels: false,
      gridcolor: colors.chart.legend.border,
      zerolinecolor: colors.chart.legend.border,
      dtick: 10,
    },
    yaxis2: {
      overlaying: 'y',
    },
    yaxis3: {
      overlaying: 'y',
    },
    yaxis4: {
      overlaying: 'y',
    },
    yaxis5: {
      overlaying: 'y',
    },
    yaxis6: {
      overlaying: 'y',
    },
    yaxis7: {
      overlaying: 'y',
    },
    yaxis8: {
      overlaying: 'y',
    },
    yaxis9: {
      overlaying: 'y',
    },
    showlegend: false,
    legend: { borderwidth: 1 },
    paper_bgcolor: colors.chart.legend.background,
    plot_bgcolor: colors.chart.legend.background,
    gridcolor: colors.chart.legend.border,
    font: {
      color: whiteFontColor,
    },
  } as Partial<Layout>;
  if (ActiveLine) {
    layout.hovermode = 'closest';
  }
  const layoutLeft = Object.assign({}, layout);
  const layoutRight = Object.assign({}, layout);
  layoutLeft.title = {
    ...(layoutLeft.title as PlotTitle),
    text: intl.formatMessage({ id: msgId.trendLabelPort }),
    y: 1,
    pad: { t: 6, b: 0, l: 0, r: 0 },
  };
  layoutLeft.yaxis = {
    ...layoutLeft.yaxis,
    mirror: true,
    range: [0, 100],
    linecolor: whiteFontColor,
  };
  layoutLeft.xaxis = {
    ...layoutLeft.xaxis,
    mirror: true,
    showticklabels: false,
    linecolor: whiteFontColor,
    gridcolor: 'rgba(0,0,0,0)',
    zeroline: false,
  };
  layoutRight.title = {
    ...(layoutRight.title as PlotTitle),
    text: intl.formatMessage({ id: msgId.trendLabelStbd }),
    y: 1,
    pad: { t: 6, b: 0, l: 0, r: 0 },
  };
  layoutRight.yaxis = {
    ...layoutRight.yaxis,
    mirror: true,
    range: [0, 100],
    linecolor: whiteFontColor,
  };
  layoutRight.xaxis = {
    ...layoutRight.xaxis,
    mirror: true,
    showticklabels: false,
    linecolor: whiteFontColor,
    gridcolor: 'rgba(0,0,0,0)',
    zeroline: false,
  };
  layoutRight.margin = { ...layoutRight.margin, b: layoutBottomMargin };
  layoutRight.height = graphPanelHeight + topLabelHeiht;
  let layout1: Partial<Layout>[] = [];
  let layout2: Partial<Layout>[] = [];
  const minPercent = layoutRange.minParsent ? layoutRange.minParsent : 0;
  const maxPercent = layoutRange.maxParsent ? layoutRange.maxParsent : 100;
  const annotations: Array<Partial<Annotations>> = [];
  const shapes: Array<Partial<Shape>> = [];

  layout.paper_bgcolor = 'rgba(0,0,0,0)';
  layout.plot_bgcolor = 'rgba(0,0,0,0)';
  layout.xaxis = {
    ...layout.xaxis,
    gridcolor: 'rgba(0,0,0,0)',
    zeroline: false,
    showticklabels: false,
    type: 'date',
    tickformatstops: [
      { dtickrange: [null, 1000], value: '%H:%M:%S.%Lms' },
      { dtickrange: [1000, 60000], value: '%H:%M:%Ss' },
      { dtickrange: [60000, 3600000], value: '%H:%Mm' },
      { dtickrange: [3600000, 86400000], value: '%H:%Mh' },
      { dtickrange: [86400000, 604800000], value: '%m/%d' },
      { dtickrange: [604800000, 'M1'], value: '%Y/%m/%d' },
      { dtickrange: ['M1', 'M12'], value: '%Y/%m/%d' },
      { dtickrange: ['M12', null], value: '%Y/%m' },
    ],
  };
  layout.yaxis = { ...layout.yaxis, gridcolor: 'rgba(0,0,0,0)', zeroline: false, type: 'linear' };

  chartSeriesList.map((series, index) => {
    const range = series.range.max - series.range.min;
    const minVal = Math.round(((range * minPercent) / 100 + series.range.min) * 100) / 100;
    const maxVal = Math.round(((range * maxPercent) / 100 + series.range.min) * 100) / 100;
    const l1 = Object.assign({}, layout);
    if (layoutRange['xaxis.range[0]']) {
      l1.xaxis = {
        ...l1.xaxis,
        gridcolor: colors.chart.legend.border,
        showticklabels: true,
        autorange: false,
        range: [layoutRange['xaxis.range[0]'], layoutRange['xaxis.range[1]']],
      };
    } else {
      l1.xaxis = {
        ...l1.xaxis,
        gridcolor: colors.chart.legend.border,
        showticklabels: true,
        autorange: true,
      };
    }
    const annStart = 0.01;
    const annWidth = (582 / (panelWidth - 10)) * 0.07;
    const annLineWidth = 1;
    const annLabelOffset = 0.005;
    const annotationUnit: Partial<Annotations> = {
      xref: 'paper',
      yref: 'paper',
      x: annStart + annWidth * index,
      xanchor: 'left',
      y: 1,
      yanchor: 'bottom',
      text: series.sensorGroup.displayUnit != null ? series.sensorGroup.displayUnit : '',
      showarrow: false,
      font: {
        size: 9,
        color: series.labelColor,
      },
    };
    const annotationMax: Partial<Annotations> = {
      ...annotationUnit,
      y: 1.0 + (322 / (graphPanelHeight - topLabelHeiht - 49)) * 0.027,
      text: roundValueToString(maxVal, series.sensorGroup.displayRoundingPosition),
    };
    const annotationMid: Partial<Annotations> = {
      ...annotationUnit,
      y: 0.5,
      text: roundValueToString((maxVal + minVal) / 2, series.sensorGroup.displayRoundingPosition),
    };
    const annotationMin: Partial<Annotations> = {
      ...annotationUnit,
      y: 0,
      text: roundValueToString(minVal, series.sensorGroup.displayRoundingPosition),
    };
    const shape: Partial<Shape> = {
      type: 'line',
      xref: 'paper',
      yref: 'paper',
      x0: annStart + annWidth * index,
      y0: 0,
      x1: annStart + annWidth * index,
      y1: 1,
      line: {
        color: series.color,
        width: annLineWidth,
      },
    };
    const shapeMax: Partial<Shape> = {
      ...shape,
      y0: 1,
      x1: annStart + annWidth * index + annLabelOffset,
      y1: 1,
    };
    const shapeMid: Partial<Shape> = {
      ...shapeMax,
      y0: 0.5,
      y1: 0.5,
    };
    const shapeMin: Partial<Shape> = {
      ...shapeMax,
      y0: 1,
      y1: 1,
    };
    annotations.push(annotationUnit);
    annotations.push(annotationMax);
    annotations.push(annotationMid);
    annotations.push(annotationMin);
    shapes.push(shape);
    shapes.push(shapeMax);
    shapes.push(shapeMid);
    shapes.push(shapeMin);

    l1.yaxis = { ...l1.yaxis, range: [minVal, maxVal], showgrid: false };
    const l2 = Object.assign({}, l1);
    layout1 = [...layout1, l1];
    layout2 = [...layout2, l2];

    return true;
  });

  if (playing) {
    const shapeTime: Partial<Shape> = {
      type: 'line',
      xref: 'x',
      yref: 'paper',
      x0: timeData[0] + SliderTime(),
      y0: 0,
      x1: timeData[0] + SliderTime(),
      y1: 1,
      line: {
        color: 'white',
        width: 5,
      },
    };
    shapes.push(shapeTime);
  }

  layout.annotations = annotations;
  layout.shapes = shapes;
  layout1.map((value, index) => {
    switch (index) {
      case 0:
        layout.xaxis = { ...value.xaxis };
        layout.yaxis = { ...value.yaxis };
        break;
      case 1:
        layout.yaxis2 = { ...value.yaxis, overlaying: 'y' };
        break;
      case 2:
        layout.yaxis3 = { ...value.yaxis, overlaying: 'y' };
        break;
      case 3:
        layout.yaxis4 = { ...value.yaxis, overlaying: 'y' };
        break;
      case 4:
        layout.yaxis5 = { ...value.yaxis, overlaying: 'y' };
        break;
      case 5:
        layout.yaxis6 = { ...value.yaxis, overlaying: 'y' };
        break;
      case 6:
        layout.yaxis7 = { ...value.yaxis, overlaying: 'y' };
        break;
      case 7:
        layout.yaxis8 = { ...value.yaxis, overlaying: 'y' };
        break;
      case 8:
        layout.yaxis9 = { ...value.yaxis, overlaying: 'y' };
        break;
      default:
        break;
    }

    return true;
  });

  const plotConfig = { displayModeBar: false, showTips: false };

  function hoverProc(event: PlotMouseEvent) {
    let xval: Datum = null;
    if (event.points.length > 0) {
      xval = event.points[0].x;
      if (xval != null) {
        if (typeof xval === 'string') {
          if (xval.length === 21) {
            xval = dayjs(xval + '00')
              .format(constants.dateFormat.YYYYMMDDHHmmssSSS)
              .substring(0, 21);
          } else {
            xval = dayjs(xval).format(constants.dateFormat.YYYYMMDDHHmmssSSS).substring(0, 21);
          }
        } else {
          xval = dayjs(xval).format(constants.dateFormat.YYYYMMDDHHmmssSSS).substring(0, 21);
        }
      }
    }
    let indexName: string;
    if (xval === null) {
      indexName = '';
    } else {
      indexName = xval.toString().split('-').join('/');
      if (indexName.length !== 21) {
        indexName += '.0';
      }
    }
    const element = event.points[0];

    const divL = React.createElement(
      'div',
      { style: { lineHeight: '1', background: '#000', padding: '1px' } },
      indexName,
      chartSeriesList.map((series) => {
        const color = { style: { color: series.color, fontSize: 8 } };
        let elementValue;
        if (series.portSensorData != null) {
          let data = series.portSensorData.data[element.pointIndex];
          if (
            series.sensorGroup.displayUpperLimit === 360 ||
            series.sensorGroup.displayUpperLimit === 359.9
          ) {
            data = data > 180 ? data - 360 : data;
          }
          const dispval = roundValueToString(
            data,
            series.portSensorData.sensor.displayRoundingPosition
          );
          elementValue =
            ',' +
            intl.formatMessage({
              id: constants.labelPrefix.trend + series.sensorGroup.sensors[0].sensorName,
            }) +
            ':' +
            dispval;
        } else {
          elementValue = '';
        }

        return React.createElement('span', color, elementValue);
      })
    );

    const divR = React.createElement(
      'div',
      { style: { lineHeight: '1', background: '#000', padding: '1px' } },
      indexName,
      chartSeriesList.map((series) => {
        const color = { style: { color: series.color, fontSize: 8 } };
        let elementValue;
        if (series.starboardSensorData != null) {
          let data = series.starboardSensorData.data[element.pointIndex];
          if (
            series.sensorGroup.displayUpperLimit === 360 ||
            series.sensorGroup.displayUpperLimit === 359.9
          ) {
            data = data > 180 ? data - 360 : data;
          }
          const dispval = roundValueToString(
            data,
            series.starboardSensorData.sensor.displayRoundingPosition
          );
          elementValue =
            ',' +
            intl.formatMessage({
              id:
                constants.labelPrefix.trend +
                (series.sensorGroup.sensors.length === 2
                  ? series.sensorGroup.sensors[1].sensorName
                  : series.sensorGroup.sensors[0].sensorName),
            }) +
            ':' +
            dispval;
        } else {
          elementValue = '';
        }

        return React.createElement('span', color, elementValue);
      })
    );

    const tickLeftId = 'tickLeft' + chartId;
    const tickRightId = 'tickRight' + chartId;

    ReactDOM.render(divL, document.getElementById(tickLeftId));
    ReactDOM.render(divR, document.getElementById(tickRightId));
  }

  function onHover(event: PlotMouseEvent) {
    hoverProc(event);
  }

  function onClick(event: Readonly<Plotly.PlotMouseEvent>) {
    AppLogger.debug(event);
    if (ActiveLine) {
      const curveNumber = event.points[0].curveNumber;
      const PlotLId = 'PlotL' + chartId;
      const PlotRId = 'PlotR' + chartId;
      const elementL = document.querySelector('#' + PlotLId) as HTMLElement;
      const elementR = document.querySelector('#' + PlotRId) as HTMLElement;
      chartSeriesList.forEach((series, index) => {
        if (curveNumber === index) {
          PlotlyRestyle(elementL, { line: { width: 3, color: series.color } }, index);
          PlotlyRestyle(elementR, { line: { width: 3, color: series.color } }, index);
        } else {
          PlotlyRestyle(elementL, { line: { width: 1, color: series.color } }, index);
          PlotlyRestyle(elementR, { line: { width: 1, color: series.color } }, index);
        }
      });
    }
  }
  function onUnHover() {
    const tickLeftId = 'tickLeft' + chartId;
    const tickRightId = 'tickRight' + chartId;
    const div = React.createElement('div');
    ReactDOM.render(div, document.getElementById(tickLeftId));
    ReactDOM.render(div, document.getElementById(tickRightId));
  }
  function onRelayout(event: Plotly.PlotRelayoutEvent) {
    if (doubleClick) return;
    const e = event as LayoutChange;
    if (
      e['xaxis.autorange'] === undefined &&
      e['xaxis.range[0]'] === undefined &&
      e['xaxis.range[1]'] === undefined &&
      e['yaxis.autorange'] === undefined &&
      e['yaxis.range[0]'] === undefined &&
      e['yaxis.range[1]'] === undefined
    ) {
      return;
    }
    if (chartSeriesList.length === 0) {
      return;
    }

    let xaxis = {};
    let yaxis = {};
    if (e['xaxis.autorange'] !== undefined) {
      if (e['xaxis.autorange']) {
        xaxis = { 'xaxis.autorange': true };
      } else {
        xaxis = {
          'xaxis.autorange': false,
          'xaxis.range[0]': layoutRange['xaxis.range[0]'],
          'xaxis.range[1]': layoutRange['xaxis.range[1]'],
        };
      }
    } else {
      if (e['xaxis.range[0]'] !== undefined) {
        xaxis = {
          'xaxis.autorange': false,
          'xaxis.range[0]': layoutRange['xaxis.range[0]'],
          'xaxis.range[1]': layoutRange['xaxis.range[1]'],
        };
      } else {
        xaxis = {
          'xaxis.range[0]': layoutRange['xaxis.range[0]'],
          'xaxis.range[1]': layoutRange['xaxis.range[1]'],
        };
      }
    }
    if (e['yaxis.autorange'] !== undefined) {
      if (e['yaxis.autorange']) {
        yaxis = {
          'yaxis.autorange': false,
          'yaxis.range[0]': chartSeriesList[0].range.min,
          'yaxis.range[1]': chartSeriesList[0].range.max,
        };
      } else {
        yaxis = {
          'yaxis.autorange': false,
          'yaxis.range[0]': layoutRange['yaxis.range[0]'],
          'yaxis.range[1]': layoutRange['yaxis.range[1]'],
        };
      }
    } else {
      if (e['yaxis.range[0]'] !== undefined) {
        yaxis = {
          'yaxis.autorange': false,
          'yaxis.range[0]': layoutRange['yaxis.range[0]'],
          'yaxis.range[1]': layoutRange['yaxis.range[1]'],
        };
      } else {
        yaxis = {
          'yaxis.range[0]': layoutRange['yaxis.range[0]'],
          'yaxis.range[1]': layoutRange['yaxis.range[1]'],
        };
      }
    }
    const layout = Object.assign(xaxis, yaxis) as LayoutChange;
    const changeLayout = Object.assign(layout, event) as LayoutChange;
    let minParsent = 0;
    let maxParsent = 100;
    if (changeLayout['yaxis.range[0]'] !== undefined && chartSeriesList.length > 0) {
      const min = changeLayout['yaxis.range[0]'] as number;
      const max = changeLayout['yaxis.range[1]'] as number;
      const range = chartSeriesList[0].range.max - chartSeriesList[0].range.min;
      minParsent = ((min - chartSeriesList[0].range.min) / range) * 100;
      maxParsent = ((max - chartSeriesList[0].range.min) / range) * 100;
    }

    setLayoutRange({
      ...layout,
      ...event,
      index: 0,
      minParsent: minParsent,
      maxParsent: maxParsent,
    });
  }
  function resetLayout() {
    doubleClick = true;
    setLayoutRange({ 'xaxis.autorange': true, minParsent: 0, maxParsent: 100 });
    setTimeout(() => {
      doubleClick = false;
    }, 50);
  }

  const styleLeftPlot: React.CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    background: 'rgba(0,0,0,0)',
  };
  const styleRightPlot: React.CSSProperties = {
    position: 'absolute',
    top: graphPanelHeight - topLabelHeiht,
    left: 0,
    background: 'rgba(0,0,0,0)',
  };
  const styleTickRight: React.CSSProperties = {
    textAlign: 'right',
    maxWidth: graphPanelWidth - 50 + 'px',
    height: layoutBottomMargin + 'px',
    top: graphPanelHeight - topLabelHeiht + 'px',
    right: 0,
  };
  const styleTickLeft: React.CSSProperties = {
    textAlign: 'right',
    maxWidth: graphPanelWidth - 50 + 'px',
    height: layoutBottomMargin + 'px',
    top: 0,
    right: 0,
  };

  const setAxisDragHandlesDisabled = (graphDiv: HTMLDivElement | undefined) => {
    if (graphDiv != null) {
      const classNames = [
        'nwdrag drag cursor-nw-resize',
        'swdrag drag cursor-sw-resize',
        'nsdrag drag cursor-ns-resize',
        'sdrag drag cursor-s-resize',
        'ndrag drag cursor-n-resize',
      ];
      classNames.forEach((cn) => {
        const rects = graphDiv.getElementsByClassName(cn);
        if (rects != null) {
          for (let i = 0; i < rects.length; i++) {
            const style = rects[i].getAttribute('style')?.replace('all', 'none');
            if (style != null) {
              rects[i].setAttribute('style', style);
            }
          }
        }
      });
    }
  };

  const handleInitialized = (figure: Readonly<Figure>, graphDiv: Readonly<HTMLElement>) => {
    graphDiv.addEventListener('mouseout', () => {
      setTimeout(onUnHover, 20);
    });

    setAxisDragHandlesDisabled(graphDiv as HTMLDivElement);
  };

  const handleUpdate = (figure: Readonly<Figure>, graphDiv: Readonly<HTMLElement>) => {
    setAxisDragHandlesDisabled(graphDiv as HTMLDivElement);
  };

  const PlotLId = 'PlotL' + chartId;
  const PlotRId = 'PlotR' + chartId;

  layoutRight.margin = { ...layoutRight.margin, b: layoutBottomMargin };
  layoutRight.height = graphPanelHeight + topLabelHeiht;
  const tickLeftId = 'tickLeft' + chartId;
  const tickRightId = 'tickRight' + chartId;

  function timeTick(time: number) {
    if (chartSeriesList.length > 0 && chartSeriesList[0].timeData != null) {
      const pointIndex = chartSeriesList[0].timeData.filter((value) => {
        if (time + timezoneOffset >= value && time + timezoneOffset < value + 100) {
          return true;
        } else {
          return false;
        }
      });
      if (pointIndex && pointIndex.length > 0) {
        const points = [];
        const date = new Date();
        date.setTime(time - date.getTimezoneOffset() * 60000);
        const milisec = date.toISOString().replace('T', ' ').split('-').join('/').split('.');
        let xVal;
        xVal = milisec[1].substring(0, 1);
        if (xVal === '0') {
          xVal = milisec[0];
        } else {
          xVal = milisec[0] + '.' + xVal;
        }
        points.push({ x: xVal, pointIndex: chartSeriesList[0].timeData.indexOf(pointIndex[0]) });
        const event = { points: points } as PlotMouseEvent;
        hoverProc(event);
      }
    }
  }

  function timeBarFunc() {
    if (playing) {
      const elementL = document.querySelector('#' + PlotLId) as HTMLElement;
      const elementR = document.querySelector('#' + PlotRId) as HTMLElement;
      if (layout.shapes !== undefined && timeData.length > 0) {
        const time = timeData[0] + SliderTime();
        const reLayout = { shapes: [...layout.shapes] };
        reLayout.shapes[reLayout.shapes.length - 1].x0 = time;
        reLayout.shapes[reLayout.shapes.length - 1].x1 = time;
        if (elementL) {
          PlotlyRelayout(elementL, reLayout);
          PlotlyRelayout(elementR, reLayout);
          timeTick(time);
        }
      }
    }
  }

  if (playing) {
    if (timerId === null) {
      timerId = setInterval(timeBarFunc, 100);
    } else {
      // resetしないとreduxバインドの内容が反映されない
      clearInterval(timerId);
      timerId = setInterval(timeBarFunc, 100);
    }
  }

  if (!playing) {
    clearInterval(timerId);
    timerId = null;
  }

  // resetRangeがtrueだった場合拡大率のリセットを実施し上に伝える
  useEffect(() => {
    if (resetRange && setResetRange) {
      setLayoutRange({
        'xaxis.autorange': true,
        minParsent: 0,
        maxParsent: 100,
      });
      setResetRange();
    }
  }, [resetRange, setResetRange]);

  return (
    <RootDiv>
      <GraphValuePanel id={tickLeftId} sx={styleTickLeft} />
      <GraphValuePanel id={tickRightId} sx={styleTickRight} />
      <Plot style={styleLeftPlot} data={[dumyData]} layout={layoutLeft} config={plotConfig} />
      <Plot
        divId={PlotLId}
        style={styleLeftPlot}
        data={graphDataL}
        layout={{ ...layout, xaxis: { ...layout.xaxis, tickfont: { color: 'rgba(0,0,0,0)' } } }}
        config={plotConfig}
        onInitialized={handleInitialized}
        onUpdate={handleUpdate}
        onHover={(e) => onHover(e)}
        onUnhover={() => onUnHover()}
        onRelayout={(e) => onRelayout(e)}
        onDoubleClick={() => resetLayout()}
        onClick={onClick}
      />
      <Plot style={styleRightPlot} data={[dumyData]} layout={layoutRight} config={plotConfig} />
      <Plot
        divId={PlotRId}
        style={styleRightPlot}
        data={graphDataR}
        layout={{ ...layout, margin: { ...layoutRight.margin }, height: layoutRight.height }}
        config={plotConfig}
        onInitialized={handleInitialized}
        onUpdate={handleUpdate}
        onHover={(e) => onHover(e)}
        onUnhover={() => onUnHover()}
        onRelayout={(e) => onRelayout(e)}
        onDoubleClick={() => resetLayout()}
        onClick={onClick}
      />
    </RootDiv>
  );
}
