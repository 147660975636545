import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import {
  EditDialog,
  EditDialogContents,
  EditDialogFooterButtonGrid,
  EditDialogInputFieldGrid,
  EditDialogRootGrid,
  EditDialogTitleGrid,
} from 'components/EditDialog';
import { Box, MenuItem, Typography } from '@mui/material';
import { SmallTextField } from 'components/SmallTextField';
import { NormalButton } from 'components/NormalButton';
import { SelectTextField } from 'components/SelectTextField';
import { ErrorInfo, ErrorMessages, FieldLabels, getErrorMessage } from 'models/error';
import { AlertDialog } from 'components/AlertDialog';
import dimens from 'resources/dimens';
import { CustomScrollBars } from 'components/CustomScrollBars';
import colors from 'resources/colors';
import { FadeLoading } from 'components/FadeLoading';
import { useAccount } from 'features/login/accountSlice';
import { AuthorityAdminUsers, AuthorityUsers } from 'models/accounts';
import {
  setAccountEditing,
  setEditAccountError,
  setEditAccountResult,
  updateAccount,
  useGeneralSettingsEditAccount,
  useGeneralSettingsEditAccountResult,
  useGeneralSettingsEditMode,
  useGeneralSettingsFetching,
  useGeneralSettingssEditAccountError,
} from '../generalSettingsSlice';
import { theme } from 'resources/theme';

/**
 * 権限リスト
 */
export const Autorities = [
  { id: AuthorityAdminUsers, msgId: msgId.authorityAdminUsers },
  { id: AuthorityUsers, msgId: msgId.authorityUsers },
];

interface AccountAuthorityEditDialogProps {
  contentHeight: number;
}

export function AccountAuthorityEditDialog(props: AccountAuthorityEditDialogProps): JSX.Element {
  const { contentHeight } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const account = useAccount();
  const editMode = useGeneralSettingsEditMode();
  const editAccount = useGeneralSettingsEditAccount();
  const updateResult = useGeneralSettingsEditAccountResult();
  const editAccountError = useGeneralSettingssEditAccountError();
  const inProgress = useGeneralSettingsFetching();
  const [state, setState] = useState<{
    userName: string;
    authorityId: number;
  }>({
    userName: '',
    authorityId: AuthorityUsers,
  });

  let errorMsg: ErrorMessages = {};
  const labels: FieldLabels = {
    PARAMETER_LENGTH_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorMessageLengthError100 }),
      password: intl.formatMessage({ id: msgId.errorPasswordRequirement }),
    },
    PARAMETER_FORMAT_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorLoginIdInvalidCharacter }),
      mailAddress: intl.formatMessage({ id: msgId.errorMailAddressFormat }),
      passsword: intl.formatMessage({ id: msgId.errorPasswordFormat }),
    },
    PARAMETER_REQUIREMENTS_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorPasswordRequirement }),
    },
    PASSWORD_MISMATCH_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorCurrentPasswordMismatch }),
    },
    PARAMETER_INVALID_CHARACTER_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorPasswordFormat }),
    },
    PARAMETER_DUPLICATION_ERROR: {
      fallback: intl.formatMessage({ id: msgId.errorMessageParameterRequirements }),
    },
    fallback: { fallback: intl.formatMessage({ id: msgId.errorMessageOther }) },
  };
  let errors: ErrorInfo[] = [];
  if (editAccountError) {
    if (Array.isArray(editAccountError.response)) {
      errors = editAccountError.response;
    } else {
      errors = [editAccountError.response];
    }
  }
  errorMsg = getErrorMessage(errors, labels);

  /**
   * バックボタン押下
   */
  const handleBackClick = () => {
    dispatch(setAccountEditing({ editing: false, account: undefined }));
    dispatch(setEditAccountError(undefined));
    setState({
      userName: '',
      authorityId: AuthorityUsers,
    });
  };

  /**
   * 登録ボタン押下時に呼ばれる。
   */
  const handleRegistrationClick = () => {
    if (editAccount) {
      dispatch(
        updateAccount(editAccount.accountId, {
          authorityId: state.authorityId,
        })
      );
    }
  };

  const handleSelectChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    if (event.target.name) {
      switch (event.target.name) {
        case 'authority-id':
          setState({ ...state, ['authorityId']: parseInt(event.target.value + '', 10) });
          break;
      }
    }
  };

  const handleResultClear = (isSuccess: boolean) => {
    if (isSuccess) {
      dispatch(setAccountEditing({ editing: false, account: undefined }));
      dispatch(setEditAccountError(undefined));
      setState({
        userName: '',
        authorityId: AuthorityUsers,
      });
    }
    dispatch(setEditAccountResult(undefined));
  };

  useEffect(() => {
    setState({
      userName: editAccount ? editAccount.cognitoAccountId : '',
      authorityId: editAccount ? editAccount.authorityId : AuthorityUsers,
    });
  }, [dispatch, editAccount]);

  useEffect(() => {
    if (updateResult && updateResult.isSuccess && updateResult.isDeleteRequest) {
      dispatch(setAccountEditing({ editing: false, account: undefined }));
      dispatch(setEditAccountError(undefined));
      setState({
        userName: '',
        authorityId: AuthorityUsers,
      });
      dispatch(setEditAccountResult(undefined));
    }
  }, [dispatch, updateResult]);

  return (
    <EditDialog
      id="accountEditDialog"
      open={editMode === 'account'}
      onBackClick={handleBackClick}
      title={intl.formatMessage({
        id: msgId.accountAuthorityInformation,
      })}
    >
      <EditDialogContents>
        <CustomScrollBars
          thumbColor={colors.scrollBar.thumb.light}
          height={contentHeight}
          width={dimens.dialogContents.width}
          autoHide={false}
        >
          <EditDialogRootGrid>
            <EditDialogTitleGrid>
              <Typography variant="h5">
                {intl
                  .formatMessage({ id: msgId.guideMessageEditAccountAuthority })
                  .split('\n')
                  .map((t, i) => {
                    return (
                      <Typography key={i} variant="h5" align="center">
                        {t}
                      </Typography>
                    );
                  })}
              </Typography>
            </EditDialogTitleGrid>
            <EditDialogInputFieldGrid>
              <SmallTextField
                id="userName"
                fullWidth={true}
                label={intl.formatMessage({ id: msgId.accountName })}
                required={true}
                value={state.userName}
                disabled={true}
                error={errorMsg['cognitoAccountId'].length > 0 || !state.userName}
                helperText={
                  !state.userName
                    ? intl.formatMessage({ id: msgId.messageInputRequired })
                    : errorMsg['cognitoAccountId']
                }
              />
            </EditDialogInputFieldGrid>
            <EditDialogInputFieldGrid>
              <SelectTextField
                id="authority-id"
                fullWidth={true}
                label={intl.formatMessage({ id: msgId.authority })}
                required={true}
                value={state.authorityId}
                disabled={editAccount != null && editAccount.accountId === account?.accountId}
                onChange={handleSelectChange}
                error={errorMsg['authorityId'].length > 0}
                helperText={errorMsg['authorityId']}
              >
                {Autorities.map((authority) => {
                  return (
                    <MenuItem key={authority.id} value={authority.id}>
                      {intl.formatMessage({ id: authority.msgId })}
                    </MenuItem>
                  );
                })}
              </SelectTextField>
            </EditDialogInputFieldGrid>
            <EditDialogInputFieldGrid>
              <Box>
                {intl
                  .formatMessage({ id: msgId.accountAuthorityDescription })
                  .split('\n')
                  .map((t, i) => {
                    return (
                      <Typography key={i} variant="body2" align="left">
                        {t}
                      </Typography>
                    );
                  })}
              </Box>
            </EditDialogInputFieldGrid>
            <EditDialogFooterButtonGrid>
              <NormalButton
                sx={{ width: dimens.button.footerWidth }}
                label={intl.formatMessage({ id: msgId.registration })}
                color="primary"
                onClick={() => handleRegistrationClick()}
                disabled={editAccount != null && editAccount.accountId === account?.accountId}
              />
            </EditDialogFooterButtonGrid>
          </EditDialogRootGrid>
          {updateResult != null && updateResult.showDialog && editMode === 'account' && (
            <AlertDialog
              title={intl.formatMessage({
                id: updateResult.isSuccess ? msgId.confirm : msgId.error,
              })}
              message={intl.formatMessage({ id: updateResult.msgId })}
              onClose={() => handleResultClear(updateResult.isSuccess)}
            />
          )}
        </CustomScrollBars>
      </EditDialogContents>
      <FadeLoading loading={inProgress} />
    </EditDialog>
  );
}
