import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import colors from 'resources/colors';
import msgId from 'resources/intl';
import { Ship } from 'models/ships';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  Typography,
} from '@mui/material';
import { ContentsHeader } from 'components/ContentsHeader';
import dimens from 'resources/dimens';
import { DiagnosisListItem } from 'models/diagnosis';
import { AisData, SensorThinnedDataDates } from 'models/data';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  CloseIconButton,
  SubDrawerBox,
} from 'components/subDrawer';
import { SearchBar } from 'components/SearchBar';
import { ShipItem } from './ShipItem';
import { theme } from 'resources/theme';
import { CustomScrollBars } from 'components/CustomScrollBars';

interface ShipDrawerProps {
  sx?: SxProps;
  ships: Ship[];
  contractedShipIds?: string[];
  aisDataList?: AisData[];
  open?: boolean;
  onChangeOpen?: (open: boolean) => void;
  onExpandedChange?: (shipId: string, expanded: boolean) => void;
  onLocation?: (shipId: string) => void;
  onSearchInputChange?: (input: string) => void;
  showCalendar?: boolean;
  diagnosisListItems?: DiagnosisListItem[];
  sensorThinnedDataDatesList?: SensorThinnedDataDates[];
  onClickDay?: (machineId: number, date: string) => void;
  currentMachineId?: number;
  currentDate?: string;
  isMultipleShips?: boolean;
  scrollvbarMargin?: number;
  onChangeShip?: (shipId: string) => void;
}

export function ShipDrawer(props: ShipDrawerProps): JSX.Element {
  const {
    sx,
    ships,
    contractedShipIds,
    aisDataList,
    open,
    onChangeOpen,
    onExpandedChange,
    onLocation,
    onSearchInputChange,
    showCalendar,
    diagnosisListItems,
    sensorThinnedDataDatesList,
    onClickDay,
    currentMachineId,
    currentDate,
    isMultipleShips,
    scrollvbarMargin,
    onChangeShip,
  } = props;
  const sbMargin = scrollvbarMargin ? scrollvbarMargin : 0;
  const intl = useIntl();
  const [searchValue, setSearchValue] = useState<string>('');
  const handleOnClickDay = (machineId: number, date: string) => {
    onClickDay && onClickDay(machineId, date);
  };
  /**
   * センサー名変更
   * @param event イベント
   */
  const handleChangeShip = (event: SelectChangeEvent) => {
    const shipId = event.target.value;
    if (shipId != null) {
      onChangeShip && onChangeShip(shipId);
    }
  };

  const handleSeatchInputChange = (input: string) => {
    setSearchValue(input);
    onSearchInputChange && onSearchInputChange(input);
  };

  const selectShip = isMultipleShips
    ? ships.filter(
        (ship) =>
          ship.machines.filter((machine) => machine.machineId === currentMachineId).length > 0
      )
    : ships;

  const validSensorDataDate = (ship: Ship): boolean => {
    if (
      currentDate === undefined ||
      currentDate.length === 0 ||
      sensorThinnedDataDatesList === undefined ||
      sensorThinnedDataDatesList.length === 0
    ) {
      return true;
    }

    for (let i = 0; i < sensorThinnedDataDatesList.length; i++) {
      for (let j = 0; j < ship.machines.length; j++) {
        if (sensorThinnedDataDatesList[i].machineId === ship.machines[j].machineId) {
          if (sensorThinnedDataDatesList[i].dates.includes(currentDate)) {
            return true;
          }
        }
      }
    }

    return false;
  };

  return (
    <SubDrawerBox
      sx={{
        width: open ? dimens.subDrawer.width : dimens.subDrawer.minWidth,
        minWidth: open ? dimens.subDrawer.width : dimens.subDrawer.minWidth,
        ...sx,
      }}
    >
      {open && (
        <React.Fragment>
          <ContentsHeader
            title={isMultipleShips ? undefined : intl.formatMessage({ id: msgId.shipList })}
          >
            {onChangeOpen != null && (
              <CloseIconButton onClick={() => onChangeOpen(false)}>
                <ChevronLeftIcon />
              </CloseIconButton>
            )}
          </ContentsHeader>
          {isMultipleShips && onChangeShip && (
            <Box sx={{ width: dimens.subDrawer.width, padding: theme.spacing(0.5) }}>
              <FormControl variant="outlined" size="small" sx={{ width: '100%' }}>
                <InputLabel id="ship-name-label" sx={{ background: '#FFF' }}>
                  {intl.formatMessage({ id: msgId.ship })}
                </InputLabel>
                <Select
                  sx={{
                    fontSize: '0.8rem',
                    backgroundColor: colors.subDrawer.background,
                    border: `1px solid ${colors.accordion.border}`,
                  }}
                  labelId="ship-name-label"
                  id="ship-name"
                  value={selectShip.length > 0 ? selectShip[0].shipId : ''}
                  onChange={handleChangeShip}
                >
                  {ships.map((ship) => {
                    return (
                      <MenuItem
                        value={ship.shipId}
                        key={ship.shipId}
                        disabled={!validSensorDataDate(ship)}
                      >
                        {ship.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          )}
          {(!isMultipleShips && onChangeShip) ||
            (!currentMachineId && (
              <SearchBar
                sx={{
                  marginTop: theme.spacing(0.5),
                  width: dimens.subDrawer.width,
                  padding: '4px 8px 4px 8px',
                }}
                placeholder={intl.formatMessage({ id: msgId.shipNameSearch })}
                value={searchValue}
                onChange={(event) => handleSeatchInputChange(event.target.value)}
              />
            ))}
          <CustomScrollBars height={`calc(100% - ${sbMargin}px)`}>
            {selectShip.length > 0 &&
              selectShip.map((ship) => {
                let selectValue = null;
                ship.machines.forEach((machine) => {
                  if (currentMachineId === machine.machineId && currentDate) {
                    selectValue = new Date(currentDate);
                  } else if (!isMultipleShips) {
                    selectValue = undefined;
                  }
                });

                return (
                  <ShipItem
                    key={ship.shipId}
                    ship={ship}
                    showCalendar={showCalendar}
                    diagnosisListItems={diagnosisListItems?.filter((x) => x.shipId === ship.shipId)}
                    expanded={!contractedShipIds?.some((x) => x === ship.shipId)}
                    onExpandedChange={onExpandedChange}
                    onLocation={onLocation}
                    aisData={aisDataList?.find((x) => x.shipId === ship.shipId)}
                    sensorThinnedDataDatesList={sensorThinnedDataDatesList}
                    onClickDay={handleOnClickDay}
                    selectValue={selectValue}
                    selectedMachineId={currentMachineId}
                  />
                );
              })}
            {selectShip.length === 0 && (
              <Typography variant="h5" sx={{ margin: theme.spacing(1) }}>
                {intl.formatMessage({ id: msgId.noResults })}
              </Typography>
            )}
          </CustomScrollBars>
        </React.Fragment>
      )}
      {onChangeOpen != null && !open && (
        <React.Fragment>
          <ContentsHeader
            title={isMultipleShips ? undefined : intl.formatMessage({ id: msgId.shipList })}
            vertical={true}
          >
            {onChangeOpen != null && (
              <CloseIconButton onClick={() => onChangeOpen(true)}>
                <ChevronRightIcon />
              </CloseIconButton>
            )}
          </ContentsHeader>
        </React.Fragment>
      )}
    </SubDrawerBox>
  );
}

ShipDrawer.defaultProps = {
  open: true,
  showCalendar: false,
};
