/**
 * コンフィグ値の定義
 */
const config = {
  version: '1.0.1',
  buildNumber: '20241120_00',
  reduxLog: false,
  valueInspectionLog: false,
  marineWeatherValueInspection: false,
  googleMapKey: 'AIzaSyDedjks5SBwmXVY6Rm0Yt_fPsaL6FHywZE',
  reportMaxDays: 31,
  shipWaveThinningInterval: 1,
  minDate: '2019-01-01',
  maxDate: '2100-12-31',
  searchRangeLimit: 2 * 24 * 60 * 60 * 1000,
  aisDataRefreshIntervalMinutes: 61,
  marineWeatherOverlayBounds: { left: 25.0, top: 120.0, right: 45.0, bottom: 150.0 },
  shipWaveDistanceThreshold: 0.01,
  dataRecalcRegistraionMaxShips: 10,
  dataRecalcRangeLimit: 180 * 24 * 60 * 60 * 1000,
  maintenanceMode: false,
};

export default config;
