import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import { InfoWindow, Marker } from '@react-google-maps/api';
import ShipPin from 'resources/assets/ShipPin.svg';
import ShipPinError from 'resources/assets/ShipPinError.svg';
import { AisData } from 'models/data';
import { Ship } from 'models/ships';
import dayjs from 'dayjs';
import { MultiLineTypography } from 'components/MultiLineTypography';
import constants from 'resources/constants';
import { roundValueToString } from 'utils/misc';

interface VesInfo {
  vesLatC1: number;
  vesLonC1: number;
  vesSpdSOGC1: number | undefined;
  vesCourseTrueDirC1: number | undefined;
  vesLatC1Text: string;
  vesLonC1Text: string;
  vesSpdSOGC1Text: string | undefined;
  vesCourseTrueDirC1Text: string | undefined;
  vesWindDirRelC1Text: string | undefined;
  vesWindSpdRelC1Text: string | undefined;
  updatedAt: string | undefined;
}

interface ShipMarkerProps {
  ship: Ship | undefined;
  aisData: AisData;
}

export function ShipMarker(props: ShipMarkerProps): JSX.Element {
  const { ship, aisData } = props;
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(new Image());

  let vesInfo: VesInfo | undefined;
  if (aisData != null) {
    vesInfo = {
      vesLatC1: aisData.latitude,
      vesLonC1: aisData.longitude,
      vesSpdSOGC1: aisData.speed,
      vesCourseTrueDirC1: aisData.heading != null ? aisData.heading : aisData.course,
      vesLatC1Text: roundValueToString(aisData.latitude, 4),
      vesLonC1Text: roundValueToString(aisData.longitude, 4),
      vesSpdSOGC1Text: roundValueToString(aisData.speed, 2),
      vesCourseTrueDirC1Text: roundValueToString(aisData.heading, 2),
      vesWindDirRelC1Text: undefined,
      vesWindSpdRelC1Text: undefined,
      updatedAt: aisData.updatedAt,
    };
  }

  const rotateIcon = (deg: number) => {
    const canvas = document.createElement('canvas');
    canvas.width = 42;
    canvas.height = 42;
    const context = canvas.getContext('2d');
    const centerX = 21;
    const centerY = 21;
    const angle = (deg * Math.PI) / 180;

    if (context != null) {
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.save();
      context.translate(centerX, centerY);
      context.rotate(angle);
      context.translate(-centerX, -centerY);
      context.drawImage(image, 0, 0);
      context.restore();
    }

    return canvas.toDataURL('image/png');
  };

  const changeImage = () => {
    if (vesInfo) {
      const image = new Image();
      if (aisData && aisData.isIncludedInLastResponse) {
        image.src = ShipPin;
      } else {
        image.src = ShipPinError;
      }
      image.onload = () => {
        setImage(image);
      };
    }
  };

  const handleOpen = (open: boolean) => {
    changeImage();
    setOpen(open);
  };

  function getMinSec(num: string) {
    let min, sec;
    if (num.split('.').length === 1) {
      min = 0;
      sec = '00';
    } else {
      min = parseInt(num.split('.')[1].substring(0, 2), 10);
      sec = num.split('.')[1].substring(2, 4);
    }

    return { min: min, sec: sec };
  }

  function getLatText(lat: number): string {
    let val = lat;
    let latText;
    if (lat < 0) {
      val *= -1;
      latText = intl.formatMessage({ id: msgId.shipInfoLatSouth });
    } else {
      latText = intl.formatMessage({ id: msgId.shipInfoLatNorth });
    }
    const degrees = parseInt(val + '', 10);
    const minutes = ((val - degrees) * 6) / 10 + '000';
    const { min, sec } = getMinSec(minutes);
    latText +=
      degrees +
      intl.formatMessage({ id: msgId.shipInfoUnitDegrees }) +
      min +
      '.' +
      sec +
      intl.formatMessage({ id: msgId.shipInfoUnitMinutes });

    return latText;
  }

  function getLonText(lon: number): string {
    let val = lon;
    let lonText;
    if (lon < 0) {
      val *= -1;
      lonText = intl.formatMessage({ id: msgId.shipInfoLonWest });
    } else {
      lonText = intl.formatMessage({ id: msgId.shipInfoLonEast });
    }
    const degrees = parseInt(val + '', 10);
    const minutes = ((val - degrees) * 6) / 10 + '000';
    const { min, sec } = getMinSec(minutes);
    lonText +=
      degrees +
      intl.formatMessage({ id: msgId.shipInfoUnitDegrees }) +
      min +
      '.' +
      sec +
      intl.formatMessage({ id: msgId.shipInfoUnitMinutes });

    return lonText;
  }

  useEffect(() => {
    const image = new Image();
    image.src = aisData && aisData.isIncludedInLastResponse ? ShipPin : ShipPinError;
    image.onload = () => {
      setImage(image);
    };
  }, [aisData]);

  return (
    <React.Fragment>
      {vesInfo != null && (
        <Marker
          position={{ lat: vesInfo.vesLatC1, lng: vesInfo.vesLonC1 }}
          icon={{
            url: rotateIcon(vesInfo.vesCourseTrueDirC1 != null ? vesInfo.vesCourseTrueDirC1 : 0),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(21, 21),
            scaledSize: new window.google.maps.Size(42, 42),
          }}
          label={{
            text: ship ? ship.name : '',
            className: 'marico-map-marker-label',
          }}
          onClick={() => handleOpen(!open)}
          zIndex={100}
        >
          {open && (
            <InfoWindow
              position={{ lat: vesInfo.vesLatC1, lng: vesInfo.vesLonC1 }}
              onCloseClick={() => handleOpen(false)}
            >
              <div>
                <div>{getLatText(vesInfo.vesLatC1) + ' ' + getLonText(vesInfo.vesLonC1)}</div>
                {vesInfo.vesSpdSOGC1Text != null && vesInfo.vesCourseTrueDirC1Text != null && (
                  <div>
                    {intl.formatMessage({ id: msgId.shipInfoSpeed }) +
                      ' ' +
                      vesInfo.vesSpdSOGC1Text +
                      intl.formatMessage({ id: msgId.aisVesSpeedUnit }) +
                      ' ' +
                      intl.formatMessage({ id: msgId.shipInfoDirection }) +
                      ' ' +
                      (vesInfo.vesCourseTrueDirC1Text === constants.invalid.value
                        ? constants.invalid.value
                        : vesInfo.vesCourseTrueDirC1Text +
                          intl.formatMessage({ id: msgId.aisDirectionUnit }))}
                  </div>
                )}
                {vesInfo.vesSpdSOGC1Text != null && vesInfo.vesCourseTrueDirC1Text == null && (
                  <div>
                    {intl.formatMessage({ id: msgId.shipInfoSpeed }) +
                      ' ' +
                      vesInfo.vesSpdSOGC1Text +
                      intl.formatMessage({ id: msgId.aisVesSpeedUnit })}
                  </div>
                )}
                {vesInfo.vesSpdSOGC1Text == null && vesInfo.vesCourseTrueDirC1Text != null && (
                  <div>
                    {intl.formatMessage({ id: msgId.shipInfoDirection }) +
                      ' ' +
                      vesInfo.vesCourseTrueDirC1Text +
                      intl.formatMessage({ id: msgId.aisDirectionUnit })}
                  </div>
                )}
                {vesInfo.vesWindDirRelC1Text != null && vesInfo.vesWindSpdRelC1Text != null && (
                  <div>
                    {intl.formatMessage({ id: msgId.shipInfoWindDirection }) +
                      ' ' +
                      vesInfo.vesWindDirRelC1Text +
                      ' ' +
                      intl.formatMessage({ id: msgId.shipInfoWindSpeed }) +
                      ' ' +
                      vesInfo.vesWindSpdRelC1Text}
                  </div>
                )}
                {vesInfo.vesWindDirRelC1Text != null && vesInfo.vesWindSpdRelC1Text == null && (
                  <div>
                    {intl.formatMessage({ id: msgId.shipInfoWindDirection }) +
                      ' ' +
                      vesInfo.vesWindDirRelC1Text}
                  </div>
                )}
                {vesInfo.vesWindDirRelC1Text == null && vesInfo.vesWindSpdRelC1Text != null && (
                  <div>
                    {intl.formatMessage({ id: msgId.shipInfoWindSpeed }) +
                      ' ' +
                      vesInfo.vesWindSpdRelC1Text}
                  </div>
                )}
                {/* <div>
                  {intl.formatMessage({ id: msgId.updatedAt }) +
                    ' ' +
                    dayjs(vesInfo.updatedAt).utc().format(constants.dateFormat.YYYYMMDDHHmmss)}
                </div> */}
                {!aisData.isIncludedInLastResponse && (
                  <MultiLineTypography
                    variant="body2"
                    color="error"
                    align="left"
                    text={intl.formatMessage(
                      { id: msgId.realtimeAisNotIncludeLastResponseWarning },
                      {
                        date: dayjs(aisData.updatedAt)
                          .utc()
                          .format(constants.dateFormat.YYYYMMDDHHmmss),
                      }
                    )}
                  />
                )}
              </div>
            </InfoWindow>
          )}
        </Marker>
      )}
    </React.Fragment>
  );
}
