import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import colors from 'resources/colors';
import dimens from 'resources/dimens';
import { AccordionDetails } from 'components/Accordion';
import msgId from 'resources/intl';
import dayjs from 'dayjs';
import { ConfirmedSearchResultItem } from 'models/search';
import { Divider, Grid, IconButton, styled, SxProps, Typography } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { ExpandMore } from '@mui/icons-material';
import { theme } from 'resources/theme';
import Empty from 'resources/icons/Empty';

const ColumnDiv = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: 48,
  width: '100%',
});

const expandIcon: SxProps = {
  padding: 0,
  transform: 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
};

const contractIcon: SxProps = {
  padding: 0,
  transform: 'rotate(0deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
};

export const ChartAccordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))({
  padding: 0,
  border: '0px solid rgba(0, 0, 0, .125)',
  minWidth: dimens.chart.analysis.width,
  width: '100%',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '&$expanded': {
    margin: 'auto',
  },
  '&:last-child': {
    marginTop: 0,
  },
});

const ChartAccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))({
  padding: `0 0 0 4px`,
  background: colors.listItem.subHeader,
  marginBottom: 0,
  minHeight: 32,
  width: '100%',
  '&$expanded': {
    minHeight: 32,
    padding: `0 0 0 4px`,
  },
  borderTop: `1px solid ${colors.accordion.border}`,
  borderBottom: `1px solid ${colors.accordion.border}`,
  '& .MuiAccordionSummary-content': {
    margin: '0px 0',
    '&$expanded': {
      margin: '0px 0',
    },
    overflow: 'hidden',
  },
  expanded: {
    margin: '0px 0',
  },
});

export const ChartContentsDiv = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  background: colors.chart.header.background,
});

export const ChartDivider = styled(Divider)({
  background: colors.chart.legend.border,
});

interface ChartContainerProp {
  chartId: string;
  children: ReactNode;
  shipName: string;
  machineName: string;
  analysisName: string;
  confirmedSearchResultItem: ConfirmedSearchResultItem;
  title: string;
  expanded: boolean;
  onExpandChanged?: (expanded: boolean) => void;
}

export function ChartContainer(props: ChartContainerProp): JSX.Element {
  const {
    chartId,
    children,
    shipName,
    machineName,
    analysisName,
    confirmedSearchResultItem,
    expanded,
    onExpandChanged,
  } = props;
  const intl = useIntl();
  const startDate = dayjs(confirmedSearchResultItem.searchResultItem.startDate).format(
    'YYYY/MM/DD'
  );
  const endDate = dayjs(confirmedSearchResultItem.searchResultItem.endDate).format('YYYY/MM/DD');

  return (
    <ChartAccordion
      square
      expanded={onExpandChanged ? expanded : true}
      onChange={(event, expanded) => onExpandChanged && onExpandChanged(expanded)}
    >
      <ChartAccordionSummary
        aria-controls={chartId + '-content'}
        id={chartId}
        sx={{ pointerEvents: onExpandChanged ? 'auto' : 'none' }}
      >
        <ColumnDiv>
          <IconButton
            sx={expanded ? expandIcon : contractIcon}
            focusRipple={false}
            disableRipple
            disabled={true}
          >
            {onExpandChanged && <ExpandMore color="primary" />}
            {!onExpandChanged && <Empty />}
          </IconButton>
          <Grid container direction="column">
            <Typography variant="body2" noWrap sx={{ width: '100%', paddingRight: '30px' }}>
              {intl.formatMessage({ id: msgId.shipName }) + ' : ' + shipName + ' - ' + machineName}
            </Typography>
            <Typography variant="body2" noWrap>
              {analysisName +
                '　' +
                intl.formatMessage({ id: msgId.periodResult }) +
                ' : ' +
                startDate +
                ' - ' +
                endDate}
            </Typography>
          </Grid>
        </ColumnDiv>
      </ChartAccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </ChartAccordion>
  );
}
