import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import colors from 'resources/colors';
import dimens from 'resources/dimens';
import { Machine, SensorGroup, Ship } from 'models/ships';
import { TrendGraph } from './TrendChart';
import msgId from 'resources/intl';
import {
  addChartSeries,
  removeChartSeries,
  setChartSeriesRange,
  resetChartSeriesList,
  applyChartSeriesList,
  setFetchError,
  setTitle,
  usePastDataTrendChartFetchError,
  usePastDataTrendChartContexts,
  ConfirmedTrendChartCondition,
  setChartLegendExpandUpdated,
  usePastDataTrendChartLegendExpandUpdated,
} from './trendChartSlice';
import { ChartSeries } from 'models/chart';
import { ChartButton } from 'components/chart/ChartButton';
import { ChartHeaderPanel } from 'components/chart/ChartHeaderPanel';
import { ChartLegendPanel } from 'components/chart/ChartLegendPanel';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import constants from 'resources/constants';
import { Box, Divider, styled } from '@mui/material';
import { theme } from 'resources/theme';
import { AddCircle, Lock } from '@mui/icons-material';
import { ApiErrorDialog } from 'components/ApiErrorDialog';
import { setTrendOnly } from '../../pastDataSlice';

const DetailHeader = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  height: '32px',
  background: colors.chart.header.background,
  borderTop: `1px solid ${colors.chart.header.border}`,
});

const ChartRow = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  background: colors.chart.header.background,
  borderTop: `1px solid ${colors.chart.header.border}`,
});

const CharDivider = styled(Divider)({
  background: colors.chart.divider,
  marginTop: 32,
});

dayjs.extend(utc);

interface TrendChartContainerProp {
  ship: Ship;
  startDate: string;
  endDate: string;
  width: number;
  height: number;
  trendOnly: boolean;
  thinnedData: boolean;
  condition: ConfirmedTrendChartCondition | undefined;
  resetRange?: boolean;
  setResetRange?: () => void;
}

export function TrendChartContainer(props: TrendChartContainerProp): JSX.Element {
  const {
    ship,
    startDate,
    endDate,
    width,
    height,
    trendOnly,
    thinnedData,
    condition,
    resetRange,
    setResetRange,
  } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const fetchError = usePastDataTrendChartFetchError();
  const contexts = usePastDataTrendChartContexts();
  const chartLegendExpandUpdated = usePastDataTrendChartLegendExpandUpdated();
  let csvEnabled = true;

  const handleChartSeriesApply = (
    chartId: string,
    ship: Ship,
    machine: Machine,
    chartSeriesList: ChartSeries[]
  ) => {
    let diff = false;
    if (
      condition &&
      condition.thinnedData === thinnedData &&
      condition.startDate === startDate &&
      condition.endDate === endDate
    ) {
      diff = true;
    }
    const context = contexts[chartId];
    dispatch(
      applyChartSeriesList(
        chartId,
        machine,
        startDate,
        endDate,
        chartSeriesList,
        context.confirmedChartSeriesList,
        thinnedData,
        diff
      )
    );
  };

  const handleChartSeriesReset = (chartId: string) => {
    dispatch(resetChartSeriesList({ chartId }));
  };

  const handleChartSeriesAdd = (chartId: string, sensorGroup: SensorGroup) => {
    dispatch(addChartSeries({ chartId, sensorGroup }));
  };

  const handleChartSeriesRemove = (chartId: string, chartSeries: ChartSeries) => {
    dispatch(removeChartSeries({ chartId, chartSeries }));
  };

  const handleChartSeriesRangeChange = (
    chartId: string,
    chartSeries: ChartSeries,
    min: number,
    max: number
  ) => {
    dispatch(setChartSeriesRange({ chartId, chartSeries, min, max }));
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleExpandChanged = (chartId: string, sensorGroupName: string, expanded: boolean) => {
    dispatch(setChartLegendExpandUpdated(true));
  };

  const handleCsvOutput = (chartId: string) => {
    if (!csvEnabled) {
      return;
    }
    const cotext = contexts[chartId];
    const port = intl.formatMessage({ id: msgId.trendLabelPort });
    const stbd = intl.formatMessage({ id: msgId.trendLabelStbd });
    const dataArray = new Array<string>();
    const csvArray = new Array<Array<string>>();
    if (cotext.confirmedChartSeriesList.length > 0) {
      dataArray.push(intl.formatMessage({ id: msgId.date }));
      cotext.confirmedChartSeriesList[0].timeData?.map((value) => {
        const date = new Date();
        date.setTime(value);
        dataArray.push(date.toISOString());

        return false;
      });

      cotext.confirmedChartSeriesList.map((chartSeries, index) => {
        const pIndex = index * 2;
        const sIndex = index * 2 + 1;
        csvArray.push(new Array<string>());
        csvArray.push(new Array<string>());
        // グラフに表示している時点で大丈夫だが念のためundefinedを考慮した表示にする
        csvArray[pIndex].push(
          chartSeries.portSensorData
            ? intl.formatMessage({ id: chartSeries.portSensorData?.sensor.sensorName })
            : port +
                ' ' +
                intl.formatMessage({ id: chartSeries.sensorGroup.section }) +
                ' ' +
                intl.formatMessage({ id: chartSeries.sensorGroup.sensorGroupName })
        );
        csvArray[sIndex].push(
          chartSeries.starboardSensorData
            ? intl.formatMessage({ id: chartSeries.starboardSensorData?.sensor.sensorName })
            : stbd +
                ' ' +
                intl.formatMessage({ id: chartSeries.sensorGroup.section }) +
                ' ' +
                intl.formatMessage({ id: chartSeries.sensorGroup.sensorGroupName })
        );
        chartSeries.portSensorData?.data.map((value1, cnt) => {
          if (isNaN(value1)) {
            csvArray[pIndex].push('');
          } else {
            csvArray[pIndex].push(value1 + '');
          }
          if (chartSeries.starboardSensorData != null) {
            if (isNaN(chartSeries.starboardSensorData.data[cnt])) {
              csvArray[sIndex].push('');
            } else {
              csvArray[sIndex].push(chartSeries.starboardSensorData.data[cnt] + '');
            }
          }

          return false;
        });

        return false;
      });

      let csvString = '';
      const row = new Array<string>();
      dataArray.map((v, i) => {
        row.push(v);
        csvArray.map((value) => {
          row.push(value[i]);

          return false;
        });
        csvString += row.join(',') + '\r\n';
        row.length = 0;

        return false;
      });
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const blob = new Blob([bom, csvString], { type: 'text/csv' });
      const url = (window.URL || window.webkitURL).createObjectURL(blob);
      const link = document.createElement('a');
      const fileName =
        cotext.title +
        '_' +
        dayjs(startDate).utc().format(constants.dateFormat.csvTitleyyyyMMddHHmmss) +
        '_' +
        dayjs(endDate).utc().format(constants.dateFormat.csvTitleyyyyMMddHHmmss) +
        '.csv';
      link.download = fileName;
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert(intl.formatMessage({ id: msgId.errorMessageNoData }));
    }
    csvEnabled = false;
    setTimeout(() => {
      csvEnabled = true;
    }, 1000);
  };

  const handleFullScreen = (chartId: string, fullScreen: boolean) => {
    dispatch(setTrendOnly(fullScreen));
  };

  /**
   * データ取得エラー
   */
  const handleFetchError = () => {
    dispatch(setFetchError(undefined));
  };

  useEffect(() => {
    if (chartLegendExpandUpdated) {
      setTimeout(() => {
        dispatch(setChartLegendExpandUpdated(false));
      }, 800);
    }
  }, [dispatch, chartLegendExpandUpdated]);

  return (
    <Box id="ChartContainer" sx={{ width: width }}>
      {/* <DetailHeader>
        <ChartButton
          sx={{ minWidth: 160 }}
          variant="contained"
          startIcon={<AddCircle />}
          endIcon={<Lock />}
          disabled={true}
          label={intl.formatMessage({ id: msgId.trendNewTrendGraph })}
        />
      </DetailHeader> */}
      <ChartRow>
        {Object.keys(contexts).map((chartId) => {
          return (
            <React.Fragment key={chartId}>
              <Box sx={{ width: width - dimens.chart.legend.width }}>
                <ChartHeaderPanel
                  chartId={chartId}
                  title={contexts[chartId].title}
                  titleEditable={true}
                  fullScreen={trendOnly}
                  onCsvOutput={handleCsvOutput}
                  onFullScreen={handleFullScreen}
                  csvOutputEnabled={contexts[chartId].confirmedChartSeriesList.length > 0}
                  onTitleChanged={(chartId, inputTitle) =>
                    dispatch(setTitle({ chartId, title: inputTitle }))
                  }
                />
                <TrendGraph
                  chartId={chartId}
                  chartSeriesList={contexts[chartId].confirmedChartSeriesList}
                  panelHeight={height}
                  panelWidth={width - dimens.chart.legend.width}
                  resetRange={resetRange}
                  setResetRange={setResetRange}
                />
              </Box>
              <CharDivider orientation="vertical" flexItem />
              <ChartLegendPanel
                id="TrendLegendPanel"
                chartId={chartId}
                height={height}
                baseShip={ship}
                chartSeriesList={contexts[chartId].chartSeriesList}
                chartSeriesChanged={contexts[chartId].chartSeriesChanged}
                onApply={handleChartSeriesApply}
                onReset={handleChartSeriesReset}
                onAdd={handleChartSeriesAdd}
                onRemove={handleChartSeriesRemove}
                onChangeRange={handleChartSeriesRangeChange}
                onExpandChanged={handleExpandChanged}
              />
            </React.Fragment>
          );
        })}
      </ChartRow>
      {fetchError != null && <ApiErrorDialog error={fetchError} onClose={handleFetchError} />}
    </Box>
  );
}
