import { SxProps, TextField } from '@mui/material';
import React from 'react';
import { theme } from 'resources/theme';

export interface NumberTextFieldProps {
  sx?: SxProps;
  id?: string;
  variant: 'filled' | 'standard' | 'outlined';
  defaultValue?: number;
  value?: number;
  label?: string;
  disabled?: boolean;
  error?: boolean;
  maxLength?: number;
  onChange: (value: number | string) => void;
  onBlur: (value: number | string) => void;
}

export function NumberTextField(props: NumberTextFieldProps): JSX.Element {
  const {
    sx,
    id,
    variant,
    defaultValue,
    value,
    label,
    disabled,
    error,
    maxLength,
    onChange,
    onBlur,
  } = props;

  /**
   * onchange event
   * @param event onchange
   */
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (maxLength && maxLength < (event.target.value as string).length) {
      // maxLengthよりも長ければ短くする
      event.target.value = (event.target.value as string).substring(0, maxLength);
    }
    const val = event.target.value as string;
    const numError = isNaN(Number(val)) || val === '' || val.includes('e') || val.includes('E');
    // errorであれば文字なし、そうでなければ値を返却
    onChange(numError ? '' : val);
  };

  /**
   * onblur event
   * @param event onblur
   */
  const handleBlur = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (isNaN(Number(val)) || val === '' || val.includes('e') || val.includes('E')) {
      // 数値ではない扱いの値は元の値とする。
      event.target.value = defaultValue + '';
    } else {
      // 入力値で確定
      const val2 = (Number(val) + '').substring(0, maxLength);
      event.target.value = Number(val2) + '';
    }
    onBlur(event.target.value as string);
  };

  /**
   * onkeydown event
   * @param event onkeydown
   */
  const handleKeyDown = (event: React.KeyboardEvent) => {
    // eとEははじく
    if (event.key === 'e' || event.key === 'E') {
      event.preventDefault();
    }
  };

  /**
   * onpaste event
   * @param event onpaste
   */
  const handlePaste = (event: React.ClipboardEvent) => {
    const text = event.clipboardData.getData('text/plain');
    if (text !== undefined || text !== null) {
      const replace = text.replace(/[^0-9\\.+-]/g, '');
      // 数値+-.以外ははじく
      if (replace.length !== text.length) {
        event.preventDefault();
      }
    }
  };

  return (
    <TextField
      sx={{
        margin: 0,
        width: 74,
        minWidth: 74,
        '& input': {
          backgroundColor: 'white',
          textAlign: 'center',
          marginRight: theme.spacing(0.5),
          marginLeft: theme.spacing(0.5),
          fontSize: '12px',
          padding: theme.spacing(0.5),
        },
        ...sx,
      }}
      id={id}
      disabled={disabled}
      defaultValue={defaultValue}
      value={value}
      variant={variant}
      type="number"
      size="small"
      margin="none"
      error={error}
      label={label}
      InputLabelProps={{
        shrink: true,
      }}
      onChange={(event) => handleChange(event)}
      onBlur={(event) => handleBlur(event)}
      onKeyDown={handleKeyDown}
      onPaste={handlePaste}
    />
  );
}

NumberTextField.defaultProps = {
  variant: 'standard',
};
