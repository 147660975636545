import React from 'react';
import { Drawer, Grid, IconButton, List, SvgIcon } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import colors from 'resources/colors';
import dimens from 'resources/dimens';
import { theme } from 'resources/theme';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';
import {
  selectMenu,
  toggleOpen,
  useMainDrawerOpen,
  useMainDrawerSelectedMenuKey,
} from './mainDrawerSlice';
import KhiIcon from 'resources/icons/Khi';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import { DrawerItem } from './DrawerItem';
import { MenuContactSupport } from 'models/menu';

const StyledDrawer = styled(Drawer)({
  width: dimens.drawer.width,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  backgroundColor: theme.palette.primary.main,
  overflowX: 'hidden',
});

const StyledList = styled(List)({
  padding: 0,
});

export interface MainDrawerItem {
  title: string;
  buttonKey: string;
  icon: typeof SvgIcon;
  lockIcon?: typeof SvgIcon;
  enabled: boolean;
}

interface MainDrawerProps {
  items: MainDrawerItem[];
}

export function MainDrawer(props: MainDrawerProps): JSX.Element {
  const { items } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const selectedMenuKey = useMainDrawerSelectedMenuKey();
  const open = useMainDrawerOpen();

  const handleDrawerToggle = () => {
    dispatch(toggleOpen());
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 400);
  };

  const handleMenuClick = (index: number) => {
    if (items[index].buttonKey != MenuContactSupport) {
      navigate('/' + items[index].buttonKey);
      dispatch(selectMenu(items[index].buttonKey));
    } else {
      const mail = document.createElement('a');
      mail.href =
        'mailto:' +
        intl.formatMessage({ id: msgId.contactSupportMailTo }) +
        '?subject=' +
        intl.formatMessage({ id: msgId.contactSupportMailTitle }) +
        '&body=' +
        intl.formatMessage({ id: msgId.contactSupportMailBody });
      mail.click();
    }
  };

  return (
    <StyledDrawer
      sx={{ width: open ? dimens.drawer.width : dimens.drawer.minWidth }}
      variant="permanent"
      PaperProps={{
        sx: {
          width: open ? dimens.drawer.width : dimens.drawer.minWidth,
          background: colors.primary,
          overflow: 'hidden',
        },
      }}
    >
      {open && (
        <Grid
          container
          wrap="nowrap"
          spacing={0}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          height={dimens.toolbar.height}
          bgcolor={colors.white}
        >
          <Grid item>
            <KhiIcon />
          </Grid>
          <Grid item>
            <IconButton onClick={handleDrawerToggle}>
              <ArrowBackIcon
                sx={{
                  color: theme.palette.background.default,
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: '15px',
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
      )}
      {!open && (
        <Grid
          container
          wrap="nowrap"
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
          height={dimens.toolbar.height}
          bgcolor={colors.white}
        >
          <Grid item>
            <IconButton onClick={handleDrawerToggle}>
              <MenuIcon
                sx={{
                  color: theme.palette.primary.main,
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
      )}
      <StyledList>
        {items.map((row, index) => {
          return (
            <DrawerItem
              key={index.toString()}
              index={index}
              selected={row.buttonKey === selectedMenuKey}
              title={row.title}
              icon={row.icon}
              lockIcon={row.lockIcon}
              buttonKey={row.buttonKey}
              enabled={row.enabled}
              onItemClicked={handleMenuClick}
            />
          );
        })}
      </StyledList>
    </StyledDrawer>
  );
}
