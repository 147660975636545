import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { AppThunk } from 'app/store';
import { Account, CognitoUserInfo } from 'models/accounts';
import msgId from 'resources/intl';
import {
  changeMailAddressAsync,
  changePasswordAsync,
  accountsChangePasswordAsync,
  confirmChangeMailAddressAsync,
  confirmResetPasswordAsync,
  getAccountAsync,
  resetPasswordAsync,
  getAccountGroupsLogoAsync,
} from 'api/maricoApiAccounts';
import { ErrorInfo, ErrorResult, getErrorMsgIdFromErrorResult, getErrorResult } from 'models/error';
import AppLogger from 'utils/AppLogger';
import { useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';
import IconKawasaki from 'resources/assets/IconKawasaki.svg';

export interface CognitoResult {
  /** 結果 */
  isSuccess: boolean;
  /** エラーダイアログでメッセージを表示するかどうか */
  showDialog: boolean;
  /** ステップ */
  step: number;
  /** エラーフィールド名 */
  errorFieldName?: string;
  /** エラーメッセージID(isSuccessがfalseの場合に設定される) */
  msgId?: string;
}

/**
 * Account インターフェース
 */
interface AccountState {
  /** 認証チェック完了状態 */
  isAuthCheckCompleted: boolean;
  /** 処理中状態 */
  inProgress: boolean;
  /** サインインエラー情報 */
  signInError: ErrorResult | undefined;
  /** サインインエラーかどうか */
  isSignInError: boolean;
  /** 仮パスワード期限切れ */
  isTemporaryPasswordExpired: boolean;
  /** Cognitoユーザー情報 */
  cognitoUserInfo: CognitoUserInfo | undefined;
  /** アカウント情報 */
  account: Account | undefined;
  /** パスワードリセット開閉状態 */
  passwordResetOpen: boolean;
  /** パスワードリセット結果 */
  passwordResetResult: CognitoResult | undefined;
  /** パスワードリセットエラー情報 */
  passwordResetError: ErrorResult | undefined;
  /** パスワード変更開閉状態 */
  passwordChangeOpen: boolean;
  /** パスワード変更結果 */
  passwordChangeResult: CognitoResult | undefined;
  /** パスワード変更エラー情報 */
  passwordChangeError: ErrorResult | undefined;
  /** メールアドレス変更開閉状態 */
  mailAddressChangeOpen: boolean;
  /** メールアドレス変更結果 */
  mailAddressChangeResult: CognitoResult | undefined;
  /** メールアドレス変更エラー情報 */
  mailAddressChangeError: ErrorResult | undefined;
  /** リダイレクト先 */
  redirectPath: string | undefined;
  /** ロゴIMG */
  accountGroupLogoBase64: string | undefined;
}

/**
 * Account の初期状態
 */
const initialState: AccountState = {
  isAuthCheckCompleted: false,
  inProgress: false,
  signInError: undefined,
  isSignInError: false,
  isTemporaryPasswordExpired: false,
  cognitoUserInfo: undefined,
  account: undefined,
  passwordResetOpen: false,
  passwordResetResult: undefined,
  passwordChangeOpen: false,
  passwordChangeResult: undefined,
  mailAddressChangeOpen: false,
  mailAddressChangeResult: undefined,
  passwordChangeError: undefined,
  mailAddressChangeError: undefined,
  redirectPath: undefined,
  passwordResetError: undefined,
  accountGroupLogoBase64: undefined,
};

const account = createSlice({
  name: 'account',
  initialState,
  reducers: {
    /**
     * 認証チェック完了状態を設定する。
     */
    setIsAuthCheckCompleted: (state: AccountState, { payload }: PayloadAction<boolean>) => {
      state.isAuthCheckCompleted = payload;
    },
    /**
     * 処理中状態を設定する。
     */
    setInProgress: (state: AccountState, { payload }: PayloadAction<boolean>) => {
      state.inProgress = payload;
    },
    /**
     * サインインエラー状態を設定する。
     */
    setSignInError: (state: AccountState, { payload }: PayloadAction<ErrorResult | undefined>) => {
      state.signInError = payload;
      state.inProgress = false;
    },
    /**
     * サインインエラーかどうかを設定する。
     */
    setIsSignInError: (state: AccountState, { payload }: PayloadAction<boolean>) => {
      state.isSignInError = payload;
    },
    /**
     * 仮パスワード期限切れを設定する。
     */
    setTemporaryPasswordExpired: (state: AccountState, { payload }: PayloadAction<boolean>) => {
      state.isTemporaryPasswordExpired = payload;
      state.inProgress = false;
    },
    /**
     * Cognitoユーザー情報を設定する。
     */
    setCognitoUserInfo: (state: AccountState, { payload }: PayloadAction<CognitoUserInfo>) => {
      state.cognitoUserInfo = payload;
    },
    /**
     * アカウント情報を設定する。
     */
    setAccounts: (state: AccountState, { payload }: PayloadAction<Account>) => {
      state.account = payload;
      state.inProgress = false;
    },
    /**
     * パスワードリセット開閉状態を設定する。
     */
    setPasswordResetOpen: (state: AccountState, { payload }: PayloadAction<boolean>) => {
      state.passwordResetResult = undefined;
      state.passwordResetOpen = payload;
    },
    /**
     * パスワードリセット結果を設定する。
     */
    setPasswordResetResult: (
      state: AccountState,
      { payload }: PayloadAction<CognitoResult | undefined>
    ) => {
      state.passwordResetResult = payload;
      state.inProgress = false;
    },
    /**
     * パスワード変更エラー状態を設定する。
     */
    setPasswordResetError: (
      state: AccountState,
      { payload }: PayloadAction<ErrorResult | undefined>
    ) => {
      state.passwordResetError = payload;
      state.inProgress = false;
    },
    /**
     * パスワード変更開閉状態を設定する。
     */
    setPasswordChangeOpen: (state: AccountState, { payload }: PayloadAction<boolean>) => {
      state.passwordChangeOpen = payload;
    },
    /**
     * パスワード変更結果を設定する。
     */
    setPasswordChangeResult: (
      state: AccountState,
      { payload }: PayloadAction<CognitoResult | undefined>
    ) => {
      state.passwordChangeResult = payload;
      state.inProgress = false;
    },
    /**
     * パスワード変更エラー状態を設定する。
     */
    setPasswordChangeError: (
      state: AccountState,
      { payload }: PayloadAction<ErrorResult | undefined>
    ) => {
      state.passwordChangeError = payload;
      state.inProgress = false;
    },
    /**
     * メールアドレス変更開閉状態を設定する。
     */
    setMailAddressChangeOpen: (state: AccountState, { payload }: PayloadAction<boolean>) => {
      state.mailAddressChangeOpen = payload;
    },
    /**
     * メールアドレス変更結果を設定する。
     */
    setMailAddressChangeResult: (
      state: AccountState,
      { payload }: PayloadAction<CognitoResult | undefined>
    ) => {
      state.mailAddressChangeResult = payload;
      state.inProgress = false;
    },
    /** リダイレクト先を設定する。 */
    setRedirectPath: (state: AccountState, { payload }: PayloadAction<string | undefined>) => {
      state.redirectPath = payload;
    },
    /**
     * メールアドレス変更エラー状態を設定する。
     */
    setMailAddressChangeError: (
      state: AccountState,
      { payload }: PayloadAction<ErrorResult | undefined>
    ) => {
      state.mailAddressChangeError = payload;
      state.inProgress = false;
    },
    /**
     * アカウントグループロゴを設定する。
     */
    setAccountGroupLogoBase64: (state: AccountState, { payload }: PayloadAction<string>) => {
      state.accountGroupLogoBase64 = payload;
    },

    /**
     * Accountをクリアする。
     */
    clearAccount: (state: AccountState) => {
      if (state.account) {
        state.redirectPath = state.account.homePath;
      }
      state.isAuthCheckCompleted = initialState.isAuthCheckCompleted;
      state.inProgress = initialState.inProgress;
      state.signInError = initialState.signInError;
      state.cognitoUserInfo = initialState.cognitoUserInfo;
      state.account = initialState.account;
      state.passwordResetOpen = initialState.passwordResetOpen;
      state.passwordResetResult = initialState.passwordResetResult;
      state.passwordChangeOpen = initialState.passwordChangeOpen;
      state.passwordChangeResult = initialState.passwordChangeResult;
      state.mailAddressChangeOpen = initialState.mailAddressChangeOpen;
      state.mailAddressChangeResult = initialState.mailAddressChangeResult;
      state.passwordChangeError = initialState.passwordChangeError;
      state.mailAddressChangeError = initialState.mailAddressChangeError;
      state.passwordResetError = initialState.passwordResetError;
      state.accountGroupLogoBase64 = initialState.accountGroupLogoBase64;
    },
  },
});

export const {
  setSignInError,
  setPasswordResetOpen,
  setPasswordResetResult,
  setPasswordChangeOpen,
  setPasswordChangeResult,
  setMailAddressChangeOpen,
  setMailAddressChangeResult,
  setMailAddressChangeError,
  setRedirectPath,
  setPasswordChangeError,
  setPasswordResetError,
} = account.actions;

/**
 * アカウント情報が有効かどうかをチェックする。
 */
export const checkAccounts = (): AppThunk => async (dispatch) => {
  try {
    const account_ = await getAccountAsync();
    if (account_ != null) {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      dispatch(
        account.actions.setCognitoUserInfo({
          userName: cognitoUser.getUsername(),
        })
      );

      if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        dispatch(account.actions.setPasswordChangeOpen(true));

        return;
      }

      dispatch(account.actions.setAccounts(account_));
    }
  } catch (error) {
    AppLogger.debug('error checkAccounts: ', error);
  } finally {
    dispatch(account.actions.setInProgress(false));
    dispatch(account.actions.setIsAuthCheckCompleted(true));
  }
};

/**
 * アカウントグループURLからロゴを取得する。
 */
export const getAccountGroupsLogoUrl =
  (url: string): AppThunk =>
  async (dispatch) => {
    try {
      const accountGroupsLogo = await getAccountGroupsLogoAsync(url);
      dispatch(account.actions.setAccountGroupLogoBase64(accountGroupsLogo));
    } catch (error) {
      AppLogger.debug('error accountGroupsLogoUrl: ', error);
      dispatch(account.actions.setAccountGroupLogoBase64(IconKawasaki.toString()));
    }
  };

/**
 * サインインを実行する。
 */
export const signIn =
  (userName: string, password: string): AppThunk =>
  async (dispatch) => {
    dispatch(account.actions.setSignInError(undefined));
    dispatch(account.actions.setIsSignInError(false));
    dispatch(account.actions.setTemporaryPasswordExpired(false));
    dispatch(account.actions.setInProgress(true));
    try {
      const cognitoUser = await Auth.signIn(userName, password);
      if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        dispatch(account.actions.setPasswordChangeOpen(true));
        dispatch(account.actions.setInProgress(false));
        dispatch(
          account.actions.setCognitoUserInfo({
            userName: cognitoUser.getUsername(),
          })
        );

        return;
      }

      dispatch(
        account.actions.setCognitoUserInfo({
          userName: cognitoUser.getUsername(),
        })
      );

      try {
        const account_ = await getAccountAsync();
        dispatch(account.actions.setAccounts(account_));
        dispatch(account.actions.setInProgress(false));
      } catch (error) {
        AppLogger.error('error signInAsync: ', error);
        dispatch(account.actions.setSignInError(getErrorResult(error)));
        dispatch(account.actions.setIsSignInError(true));
        dispatch(account.actions.setInProgress(false));
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      AppLogger.error('error signInAsync: ', error);

      if (
        error.code === 'NotAuthorizedException' &&
        error.message.includes('Temporary password has expired')
      ) {
        dispatch(account.actions.setTemporaryPasswordExpired(true));
      } else if (error.code === 'PasswordResetRequiredException') {
        dispatch(account.actions.setPasswordResetOpen(true));
        dispatch(
          account.actions.setCognitoUserInfo({
            userName: userName,
          })
        );
        dispatch(
          account.actions.setPasswordResetResult({
            isSuccess: true,
            showDialog: true,
            step: 1,
          })
        );
      } else {
        dispatch(account.actions.setSignInError(getErrorResult(error)));
        dispatch(account.actions.setIsSignInError(true));
      }
      dispatch(account.actions.setInProgress(false));
    }
  };

/**
 * サインアウトを実行する。
 */
export const signOut = (): AppThunk => async (dispatch) => {
  dispatch(account.actions.setInProgress(true));
  try {
    await Auth.signOut();
  } catch (error) {
    AppLogger.error('error signOut: ', error);
  }
  dispatch(account.actions.clearAccount());
};

export const forgetPassword =
  (cognitoAccountId: string): AppThunk =>
  async (dispatch) => {
    dispatch(account.actions.setInProgress(true));
    try {
      await resetPasswordAsync(cognitoAccountId);
      dispatch(
        account.actions.setPasswordResetResult({
          isSuccess: true,
          showDialog: true,
          step: 1,
        })
      );
    } catch (error) {
      const errorResult = getErrorResult(error);
      dispatch(account.actions.setPasswordResetError(errorResult));
      dispatch(
        account.actions.setPasswordResetResult({
          isSuccess: false,
          showDialog: errorResult.status !== 400 && errorResult.status !== 404,
          step: 1,
          msgId: getErrorMsgIdFromErrorResult(errorResult),
        })
      );
    }
  };

export const sendAuthCode =
  (cognitoAccountId: string, authCode: string, newPassword: string): AppThunk =>
  async (dispatch) => {
    dispatch(account.actions.setInProgress(true));
    try {
      await confirmResetPasswordAsync(cognitoAccountId, authCode, newPassword);
      dispatch(
        account.actions.setPasswordResetResult({
          isSuccess: true,
          showDialog: true,
          step: 2,
        })
      );
    } catch (error) {
      const errorResult = getErrorResult(error);
      dispatch(account.actions.setPasswordResetError(errorResult));
      dispatch(
        account.actions.setPasswordResetResult({
          isSuccess: false,
          showDialog: errorResult.status !== 400,
          step: 2,
          msgId: getErrorMsgIdFromErrorResult(errorResult),
        })
      );
    }
  };

export const changePassword =
  (cognitoAccountId: string, nowPassword: string, newPassword: string): AppThunk =>
  async (dispatch) => {
    dispatch(account.actions.setInProgress(true));
    try {
      await changePasswordAsync(cognitoAccountId, nowPassword, newPassword);
      dispatch(
        account.actions.setPasswordChangeResult({
          isSuccess: true,
          showDialog: true,
          step: 0,
          msgId: msgId.messageChangePasswordSuccess,
        })
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errorResult = getErrorResult(error);
      dispatch(account.actions.setPasswordChangeError(errorResult));
      dispatch(
        account.actions.setPasswordChangeResult({
          isSuccess: false,
          showDialog: errorResult.status !== 400,
          step: 1,
          msgId: getErrorMsgIdFromErrorResult(errorResult),
        })
      );
    }
  };

export const accountChangePassword =
  (nowPassword: string, newPassword: string): AppThunk =>
  async (dispatch) => {
    dispatch(account.actions.setInProgress(true));
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      cognitoUser.getSession(async (error: Error | null, session: CognitoUserSession | null) => {
        if (session) {
          const accessToken = session.getAccessToken().getJwtToken();
          try {
            await accountsChangePasswordAsync(accessToken, nowPassword, newPassword);
            dispatch(
              account.actions.setPasswordChangeResult({
                isSuccess: true,
                showDialog: true,
                step: 0,
                msgId: msgId.messageChangePasswordSuccess,
              })
            );
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            const errorResult = getErrorResult(error);
            dispatch(
              account.actions.setPasswordChangeResult({
                isSuccess: false,
                showDialog: errorResult.status !== 400,
                step: 1,
                msgId: getErrorMsgIdFromErrorResult(errorResult),
              })
            );
            const errorInfo = errorResult.response as ErrorInfo[];
            if (errorInfo != null && errorInfo.length > 0) {
              if (errorInfo[0].errorId === 'NOT_AUTHORIZED_ERROR') {
                errorInfo[0].errorField = 'password';
              }
            }
            dispatch(account.actions.setPasswordChangeError(errorResult));
          }
        } else {
          let showErrorDialog = false;
          if (error != null) {
            const errorResult = getErrorResult(error);
            dispatch(account.actions.setPasswordChangeError(errorResult));
            showErrorDialog = errorResult.status !== 400;
          } else {
            dispatch(
              account.actions.setPasswordChangeError({
                status: 500,
                response: {
                  errorField: '',
                  errorDescription: '',
                  errorId: 'sessionError',
                },
              })
            );
            showErrorDialog = true;
          }
          dispatch(
            account.actions.setPasswordChangeResult({
              isSuccess: false,
              showDialog: showErrorDialog,
              step: 1,
              msgId: msgId.errorMessageOther,
            })
          );
        }
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errorResult = getErrorResult(error);
      dispatch(account.actions.setPasswordChangeError(errorResult));
      dispatch(
        account.actions.setPasswordChangeResult({
          isSuccess: false,
          showDialog: errorResult.status !== 400,
          step: 1,
          msgId: getErrorMsgIdFromErrorResult(errorResult),
        })
      );
    }
  };

export const changeMailAddress =
  (cognitoUserInfo: CognitoUserInfo, mailAddress: string): AppThunk =>
  async (dispatch) => {
    dispatch(account.actions.setInProgress(true));
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      cognitoUser.getSession(async (error: Error | null, session: CognitoUserSession | null) => {
        if (session) {
          const accessToken = session.getAccessToken().getJwtToken();
          try {
            await changeMailAddressAsync(accessToken, mailAddress);
            dispatch(
              account.actions.setMailAddressChangeResult({
                isSuccess: true,
                showDialog: true,
                step: 1,
                msgId: undefined,
              })
            );
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            const errorResult = getErrorResult(error);
            dispatch(
              account.actions.setMailAddressChangeResult({
                isSuccess: false,
                showDialog: errorResult.status !== 400,
                step: 1,
                msgId: getErrorMsgIdFromErrorResult(errorResult),
              })
            );
            const errorInfo = errorResult.response as ErrorInfo[];
            if (errorInfo != null && errorInfo.length > 0) {
              if (errorInfo[0].errorId === 'NOT_AUTHORIZED_ERROR') {
                errorInfo[0].errorField = 'mailAddress';
              }
            }
            dispatch(setMailAddressChangeError(errorResult));
          }
        } else {
          let showErrorDialog = false;
          if (error != null) {
            const errorResult = getErrorResult(error);
            dispatch(account.actions.setMailAddressChangeError(errorResult));
            showErrorDialog = errorResult.status !== 400;
          } else {
            dispatch(
              account.actions.setMailAddressChangeError({
                status: 500,
                response: {
                  errorField: '',
                  errorDescription: '',
                  errorId: 'sessionError',
                },
              })
            );
            showErrorDialog = true;
          }
          dispatch(
            account.actions.setMailAddressChangeResult({
              isSuccess: false,
              showDialog: showErrorDialog,
              step: 1,
              msgId: msgId.errorMessageOther,
            })
          );
        }
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errorResult = getErrorResult(error);
      dispatch(
        account.actions.setMailAddressChangeResult({
          isSuccess: false,
          showDialog: errorResult.status !== 400,
          step: 1,
          msgId: getErrorMsgIdFromErrorResult(errorResult),
        })
      );
      dispatch(setMailAddressChangeError(error));
    }
  };

export const confirmChangeMailAddress =
  (cognitoUserInfo: CognitoUserInfo, authCode: string): AppThunk =>
  async (dispatch) => {
    dispatch(account.actions.setInProgress(true));
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      cognitoUser.getSession(async (error: Error | null, session: CognitoUserSession | null) => {
        if (session) {
          const accessToken = session.getAccessToken().getJwtToken();
          try {
            await confirmChangeMailAddressAsync(accessToken, authCode);
            dispatch(
              account.actions.setMailAddressChangeResult({
                isSuccess: true,
                showDialog: true,
                step: 2,
                msgId: msgId.messageChangeMailAddressSuccess,
              })
            );
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            const errorResult = getErrorResult(error);
            dispatch(
              account.actions.setMailAddressChangeResult({
                isSuccess: false,
                showDialog: errorResult.status !== 400,
                step: 2,
                msgId: getErrorMsgIdFromErrorResult(errorResult),
              })
            );
            dispatch(setMailAddressChangeError(errorResult));
          }
        } else {
          let showErrorDialog = false;
          if (error != null) {
            const errorResult = getErrorResult(error);
            dispatch(account.actions.setMailAddressChangeError(errorResult));
            showErrorDialog = errorResult.status !== 400;
          } else {
            dispatch(
              account.actions.setMailAddressChangeError({
                status: 500,
                response: {
                  errorField: '',
                  errorDescription: '',
                  errorId: 'sessionError',
                },
              })
            );
            showErrorDialog = true;
          }
          dispatch(
            account.actions.setMailAddressChangeResult({
              isSuccess: false,
              showDialog: showErrorDialog,
              step: 2,
              msgId: msgId.errorMessageOther,
            })
          );
        }
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errorResult = getErrorResult(error);
      dispatch(
        account.actions.setMailAddressChangeResult({
          isSuccess: false,
          showDialog: errorResult.status !== 400,
          step: 2,
          msgId: getErrorMsgIdFromErrorResult(errorResult),
        })
      );
      dispatch(setMailAddressChangeError(errorResult));
    }
  };

// export const fetchCredentials = (): AppThunk => async (dispatch) => {
//   dispatch(account.actions.setInProgress(true));
//   try {
//     const credentials = await Auth.currentCredentials();
//     AppLogger.info(credentials);

//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   } catch (error: any) {
//     AppLogger.error('error fetchCredentials: ', error);
//   }
// };

const accountState = (state: RootState) => state.account;
const selectIsAuthCheckCompleted = createSelector(accountState, (x) => x.isAuthCheckCompleted);
const selectInProgress = createSelector(accountState, (x) => x.inProgress);
const selectSignInError = createSelector(accountState, (x) => x.signInError);
const selectIsSignInError = createSelector(accountState, (x) => x.isSignInError);
const selectIsTemporaryPasswordExpired = createSelector(
  accountState,
  (x) => x.isTemporaryPasswordExpired
);
const selectAccount = createSelector(accountState, (x) => x.account);
const selectCognitoUserInfo = createSelector(accountState, (x) => x.cognitoUserInfo);
const selectPasswordResetOpen = createSelector(accountState, (x) => x.passwordResetOpen);
const selectPasswordResetResult = createSelector(accountState, (x) => x.passwordResetResult);
const selectPasswordChangeOpen = createSelector(accountState, (x) => x.passwordChangeOpen);
const selectPasswordChangeResult = createSelector(accountState, (x) => x.passwordChangeResult);
const selectMailAddressChangeOpen = createSelector(accountState, (x) => x.mailAddressChangeOpen);
const selectMailAddressChangeResult = createSelector(
  accountState,
  (x) => x.mailAddressChangeResult
);
const selectPasswordChangeError = createSelector(accountState, (x) => x.passwordChangeError);
const selectRedirectPath = createSelector(accountState, (x) => x.redirectPath);
const selectMailAddressChangeError = createSelector(accountState, (x) => x.mailAddressChangeError);
const selectPasswordResetError = createSelector(accountState, (x) => x.passwordResetError);
const selectAccountGroupLogoBase64 = createSelector(accountState, (x) => x.accountGroupLogoBase64);

export const useIsAuthCheckCompleted = () => useSelector(selectIsAuthCheckCompleted);
export const useAccountInProgress = () => useSelector(selectInProgress);
export const useAccountIsSignInError = () => useSelector(selectIsSignInError);
export const useAccountSignInError = () => useSelector(selectSignInError);
export const useIsTemporaryPasswordExpired = () => useSelector(selectIsTemporaryPasswordExpired);
export const useAccount = () => useSelector(selectAccount);
export const useAccountCognitoUserInfo = () => useSelector(selectCognitoUserInfo);
export const useAccountPasswordResetOpen = () => useSelector(selectPasswordResetOpen);
export const useAccountPasswordResetResult = () => useSelector(selectPasswordResetResult);
export const useAccountPasswordChangeOpen = () => useSelector(selectPasswordChangeOpen);
export const useAccountPasswordChangeResult = () => useSelector(selectPasswordChangeResult);
export const useAccountMailAddressChangeOpen = () => useSelector(selectMailAddressChangeOpen);
export const useAccountMailAddressChangeResult = () => useSelector(selectMailAddressChangeResult);
export const useAccountPasswordChangeError = () => useSelector(selectPasswordChangeError);
export const useAccountRedirectPath = () => useSelector(selectRedirectPath);
export const useAccountMailAddressChangeError = () => useSelector(selectMailAddressChangeError);
export const useAccountPasswordResetError = () => useSelector(selectPasswordResetError);
export const useAccountAccountGroupLogoBase64 = () => useSelector(selectAccountGroupLogoBase64);

export default account.reducer;
