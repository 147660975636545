import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'app/store';
import { MaintenanceData, UpdateMaintenanceData } from 'models/maintenance';
import { ErrorResult, getErrorMsgIdFromErrorResult, getErrorResult } from 'models/error';
import { RootState } from 'app/rootReducer';
import { useSelector } from 'react-redux';
import {
  deleteMaintenanceDataAsync,
  getMaintenanceDataListAsync,
  registrationMaintenanceDataAsync,
  updateMaintenanceDataAsync,
} from 'api/maricoApiMaintenance';
import { UpdateResult } from 'models/admin';
import msgId from 'resources/intl';

/**
 * Maintenance状態モデル
 */
interface MaintenanceState {
  /** メンテナンスデータリスト */
  maintenanceDataList: MaintenanceData[] | undefined;
  /** 選択中のメンテナンスデータ */
  selectedMaintenanceData: MaintenanceData | undefined;
  /** 編集中のメンテナンスデータ */
  editMaintenanceData: MaintenanceData | undefined;
  /** メンテナンスデータリスト取得中状態 */
  listFetching: boolean;
  /** メンテナンスデータリスト取得エラー */
  listFetchError: ErrorResult | undefined;
  /** メンテナンスデータ編集中状態 */
  dataEditing: boolean;
  /** メンテナンスデータ編集エラー */
  dataEditError: ErrorResult | undefined;
  /** メンテナンスデータ編集結果 */
  editMaintenanceDataResult: UpdateResult | undefined;
}

/**
 * Diagnosis 初期状態
 */
const initialState: MaintenanceState = {
  maintenanceDataList: undefined,
  selectedMaintenanceData: undefined,
  editMaintenanceData: undefined,
  listFetching: false,
  listFetchError: undefined,
  dataEditing: false,
  dataEditError: undefined,
  editMaintenanceDataResult: undefined,
};

export const maintenance = createSlice({
  name: 'maintenance',
  initialState,
  reducers: {
    /**
     * メンテナンスデータリスト取得を開始する。
     */
    startListFetch: (state) => {
      state.listFetching = true;
    },
    /**
     * メンテナンスデータリストを設定する。
     */
    setMaintenanceDataList: (state, { payload }: PayloadAction<MaintenanceData[] | undefined>) => {
      state.maintenanceDataList = payload;
      state.listFetching = false;
    },
    /**
     * メンテナンスデータリスト取得エラーを設定する。
     */
    setListFetchError: (state, { payload }: PayloadAction<ErrorResult>) => {
      state.listFetchError = payload;
      state.listFetching = false;
    },
    /**
     * 選択中のメンテナンスデータを設定する。
     */
    setSelectedMaintenanceData: (
      state,
      { payload }: PayloadAction<MaintenanceData | undefined>
    ) => {
      state.selectedMaintenanceData = payload;
    },
    /**
     * 編集中のメンテナンスデータを設定する。
     */
    setEditMaintenanceData: (state, { payload }: PayloadAction<MaintenanceData | undefined>) => {
      state.editMaintenanceData = payload;
    },
    /**
     * メンテナンスデータ編集を開始する。
     */
    startDataEdit: (state, { payload }: PayloadAction<boolean>) => {
      state.dataEditing = payload;
    },
    /**
     * メンテナンスデータ編集エラーを設定する。
     */
    setEditMaintenanceDataError: (state, { payload }: PayloadAction<ErrorResult | undefined>) => {
      state.dataEditError = payload;
      state.dataEditing = false;
    },
    /**
     * メンテナンスデータ編集結果を設定する。
     */
    setEditMaintenanceDataResult: (state, { payload }: PayloadAction<UpdateResult | undefined>) => {
      state.editMaintenanceDataResult = payload;
      state.dataEditing = false;
    },
    /**
     * Maintenanceを初期化する。
     */
    clearMaintenance: (state) => {
      state.maintenanceDataList = initialState.maintenanceDataList;
      state.selectedMaintenanceData = initialState.selectedMaintenanceData;
      state.editMaintenanceData = initialState.editMaintenanceData;
      state.listFetching = initialState.listFetching;
      state.listFetchError = initialState.listFetchError;
      state.dataEditing = initialState.dataEditing;
      state.dataEditError = initialState.dataEditError;
      state.editMaintenanceDataResult = initialState.editMaintenanceDataResult;
    },
  },
});

export const {
  setListFetchError,
  setEditMaintenanceDataError,
  setSelectedMaintenanceData,
  setEditMaintenanceData,
  setEditMaintenanceDataResult,
  clearMaintenance,
} = maintenance.actions;

/**
 * メンテナンスデータリストを取得する。
 */
export const fetchMaintenanceDataList = (): AppThunk => async (dispatch) => {
  dispatch(maintenance.actions.startListFetch());
  try {
    const maintenanceDataList = await getMaintenanceDataListAsync();
    dispatch(maintenance.actions.setMaintenanceDataList(maintenanceDataList));
  } catch (error) {
    dispatch(maintenance.actions.setListFetchError(getErrorResult(error)));
  }
};

/**
 * メンテナンス情報を登録する。
 */
export const registrationMaintenanceData =
  (updateMaintenanceData: UpdateMaintenanceData): AppThunk =>
  async (dispatch) => {
    dispatch(maintenance.actions.startDataEdit(true));
    try {
      await registrationMaintenanceDataAsync(updateMaintenanceData);
      dispatch(
        maintenance.actions.setEditMaintenanceDataResult({
          isSuccess: true,
          showDialog: true,
          msgId: msgId.resultCompleted,
          isDeleteRequest: false,
        })
      );
    } catch (error) {
      const errorResult = getErrorResult(error);
      dispatch(maintenance.actions.setEditMaintenanceDataError(errorResult));
      dispatch(
        maintenance.actions.setEditMaintenanceDataResult({
          isSuccess: false,
          showDialog: errorResult.status !== 400,
          msgId: getErrorMsgIdFromErrorResult(errorResult),
          isDeleteRequest: false,
        })
      );
    }
  };

/**
 * メンテナンスデータを更新する。
 */
export const updateMaintenanceData =
  (maintenanceId: number, updateMaintenanceData: UpdateMaintenanceData): AppThunk =>
  async (dispatch) => {
    dispatch(maintenance.actions.startDataEdit(true));
    try {
      await updateMaintenanceDataAsync(maintenanceId, updateMaintenanceData);
      dispatch(
        maintenance.actions.setEditMaintenanceDataResult({
          isSuccess: true,
          showDialog: true,
          msgId: msgId.resultCompleted,
          isDeleteRequest: false,
        })
      );
    } catch (error) {
      const errorResult = getErrorResult(error);
      dispatch(maintenance.actions.setEditMaintenanceDataError(errorResult));
      dispatch(
        maintenance.actions.setEditMaintenanceDataResult({
          isSuccess: false,
          showDialog: errorResult.status !== 400,
          msgId: getErrorMsgIdFromErrorResult(errorResult),
          isDeleteRequest: false,
        })
      );
    }
  };

/**
 * メンテナンスデータを削除する。
 */
export const deleteMaintenance =
  (accountId: number): AppThunk =>
  async (dispatch) => {
    dispatch(maintenance.actions.startDataEdit(true));
    try {
      await deleteMaintenanceDataAsync(accountId);
      dispatch(
        maintenance.actions.setEditMaintenanceDataResult({
          isSuccess: true,
          showDialog: false,
          msgId: msgId.resultCompleted,
          isDeleteRequest: true,
        })
      );
    } catch (error) {
      const errorResult = getErrorResult(error);
      dispatch(maintenance.actions.setEditMaintenanceDataError(errorResult));
      dispatch(
        maintenance.actions.setEditMaintenanceDataResult({
          isSuccess: false,
          showDialog: errorResult.status !== 400,
          msgId: getErrorMsgIdFromErrorResult(errorResult),
          isDeleteRequest: true,
        })
      );
    }
  };

const maintenanceState = (state: RootState) => state.maintenance;

const selectMaintenanceDataList = createSelector(maintenanceState, (x) => x.maintenanceDataList);
const selectListFetching = createSelector(maintenanceState, (x) => x.listFetching);
const selectListFetchError = createSelector(maintenanceState, (x) => x.listFetchError);
const selectDataEditing = createSelector(maintenanceState, (x) => x.dataEditing);
const selectDataEditError = createSelector(maintenanceState, (x) => x.dataEditError);
const selectSelectedMaintenanceData = createSelector(
  maintenanceState,
  (x) => x.selectedMaintenanceData
);
const selectEditMaintenanceData = createSelector(maintenanceState, (x) => x.editMaintenanceData);
const selectEditMaintenanceDataResult = createSelector(
  maintenanceState,
  (x) => x.editMaintenanceDataResult
);

export const useMaintenanceDataList = () => useSelector(selectMaintenanceDataList);
export const useMaintenanceListFetching = () => useSelector(selectListFetching);
export const useMaintenanceListFetchError = () => useSelector(selectListFetchError);
export const useMaintenanceDataEditing = () => useSelector(selectDataEditing);
export const useMaintenanceDataEditError = () => useSelector(selectDataEditError);
export const useMaintenanceSelectedMaintenanceData = () =>
  useSelector(selectSelectedMaintenanceData);
export const useMaintenanceEditMaintenanceData = () => useSelector(selectEditMaintenanceData);
export const useEditMaintenanceDataResult = () => useSelector(selectEditMaintenanceDataResult);

export default maintenance.reducer;
