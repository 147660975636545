import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import colors from 'resources/colors';
import msgId from 'resources/intl';
import { ChartButton } from './ChartButton';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  styled,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { theme } from 'resources/theme';
import {
  Create,
  DeleteForever,
  Lock,
  SaveAlt,
  Timeline,
  Fullscreen,
  FullscreenExit,
} from '@mui/icons-material';

const RootDiv = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '4px 8px',
  height: 32,
  backgroundColor: colors.chart.header.background,
  color: colors.chart.header.color,
});

const LockIcon = styled(Lock)({
  marginLeft: '-6px',
});

interface ChartHeaderPanelProps {
  sx?: SxProps;
  chartId: string;
  title: string;
  titleEditable: boolean;
  width: number | string;
  csvOutputEnabled: boolean;
  csvOutputLocked: boolean;
  fullScreen: boolean;
  onRemove?: (chartId: string) => void;
  onCompare?: (chartId: string) => void;
  onFullScreen?: (chartId: string, fullScreen: boolean) => void;
  onCsvOutput?: (chartId: string) => void;
  onTitleChanged?: (chartId: string, title: string) => void;
}

export function ChartHeaderPanel(props: ChartHeaderPanelProps): JSX.Element {
  const {
    sx,
    chartId,
    title,
    titleEditable,
    width,
    csvOutputEnabled,
    csvOutputLocked,
    fullScreen,
    onCsvOutput,
    onFullScreen,
    onRemove,
    onCompare,
    onTitleChanged,
  } = props;
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [inputTitle, setInputTitle] = useState<string>(title);

  /**
   * タイトル編集オープン
   */
  const handleTitleEditOpen = () => {
    setInputTitle(title);
    setOpen(true);
  };

  /**
   * タイトル編集クローズ
   */
  const handleTitleEditClose = (
    reason: 'backdropClick' | 'escapeKeyDown' | 'userClose',
    result: boolean
  ) => {
    if (reason === 'backdropClick') {
      return false;
    }

    if (reason === 'escapeKeyDown') {
      return false;
    }

    setOpen(false);
    if (result && onTitleChanged) {
      onTitleChanged(chartId, inputTitle);
    }
  };

  return (
    <RootDiv sx={{ width: width, ...sx }}>
      <Typography variant="body2" noWrap>
        {title}
      </Typography>
      {titleEditable && (
        <React.Fragment>
          <IconButton
            size="small"
            onFocus={(event) => event.stopPropagation()}
            onClick={() => handleTitleEditOpen()}
          >
            <Create htmlColor={colors.chart.header.color} />
          </IconButton>
          <Dialog
            disableEscapeKeyDown
            transitionDuration={{ appear: 0, enter: 0, exit: 0 }}
            open={open}
            onClose={(e, reason) => handleTitleEditClose(reason, false)}
          >
            <DialogContent>
              <TextField
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                variant="standard"
                margin="dense"
                fullWidth
                value={inputTitle}
                onChange={(event) => setInputTitle(event.target.value)}
                error={inputTitle.length === 0 || inputTitle.length > 30}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleTitleEditClose('userClose', false)} color="primary">
                {intl.formatMessage({ id: msgId.cancel })}
              </Button>
              <Button
                onClick={() => handleTitleEditClose('userClose', true)}
                color="primary"
                disabled={inputTitle.length === 0 || inputTitle.length > 30}
              >
                {intl.formatMessage({ id: msgId.ok })}
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      )}
      <Box sx={{ flexGrow: 1 }} />
      {onRemove && (
        <ChartButton
          sx={{ marginLeft: theme.spacing(1) }}
          startIcon={<DeleteForever />}
          endIcon={<LockIcon />}
          onClick={() => {
            onRemove(chartId);
          }}
          disabled={true}
          label={intl.formatMessage({ id: msgId.trendGraphDelete })}
        />
      )}
      {onCompare && (
        <ChartButton
          sx={{ marginLeft: theme.spacing(1) }}
          startIcon={<Timeline />}
          endIcon={<LockIcon />}
          onClick={() => {
            onCompare(chartId);
          }}
          disabled={true}
          label={intl.formatMessage({ id: msgId.trendGraphCompare })}
        />
      )}
      {onFullScreen && (
        <ChartButton
          sx={{ marginLeft: theme.spacing(1) }}
          startIcon={fullScreen ? <FullscreenExit /> : <Fullscreen />}
          onClick={() => {
            onFullScreen(chartId, !fullScreen);
          }}
          label={intl.formatMessage({ id: fullScreen ? msgId.fullScreenExit : msgId.fullScreen })}
        />
      )}
      {onCsvOutput && (
        <ChartButton
          sx={{ marginLeft: theme.spacing(1) }}
          startIcon={<SaveAlt />}
          endIcon={csvOutputLocked && <LockIcon />}
          disabled={!csvOutputEnabled}
          onClick={() => {
            onCsvOutput(chartId);
          }}
          label={intl.formatMessage({ id: msgId.trendCsvOutput })}
        />
      )}
    </RootDiv>
  );
}

ChartHeaderPanel.defaultProps = {
  width: '100%',
  csvOutputEnabled: true,
  csvOutputLocked: false,
};

ChartHeaderPanel.defaultProps = {
  fullScreen: false,
};
