import colors from 'resources/colors';

/** 気象海象情報表示タイプ */
export declare type MarineWeatherType =
  | 'none'
  | 'wind_dir_wind_speed'
  | 'sig_height'
  | 'swell_height_swell_dir'
  | 'swell_period_swell_dir';

/** 気象海象データステータスタイプ */
export declare type MarineWeatherBundleStatus = 'none' | 'ready' | 'requested' | 'error';

/**
 * 気象海象情報
 */
export interface MarineWeather {
  /** 緯度 */
  latitude: number;
  /** 経度 */
  longitude: number;
  /** 風速[km/h] */
  windSpeed: number;
  /** 風向[°] */
  windDirection: number;
  /** 16点コンパスの風向 */
  windDirection16Point: string;
  /** うねり高さ[m] */
  swellHeight: number;
  /** うねり方向[°] */
  swellDirection: number;
  /** 16点コンパスのうねり方向 */
  swellDirection16Point: string;
  /** うねり周期[s] */
  swellPeriodSecs: number;
}

/**
 * 気象海象マーカーデータ
 */
export interface MarineWeatherMarkerData {
  /** 緯度 */
  latitude: number;
  /** 経度 */
  longitude: number;
  /** 風速[km/h] */
  windSpeed: number;
  /** 風向[°] */
  windDirection: number;
  /** 16点コンパスの風向 */
  windDirection16Point: string;
  /** うねり高さ[m] */
  swellHeight: number;
  /** うねり方向[°] */
  swellDirection: number;
  /** 16点コンパスのうねり方向 */
  swellDirection16Point: string;
  /** うねり周期[s] */
  swellPeriodSecs: number;
  /** マーカーの色 */
  color: string;
}

/**
 * 気象海象データモデル
 */
export interface MarineWeatherData {
  /** ステータス */

  /** 時間 */
  hour: number;
  /** 気象海象データリスト */
  weathers: MarineWeather[];
}

export interface MarineWeatherBundle {
  /** 取得日時 */
  updatedAt: number;
  /** ステータス */
  status: MarineWeatherBundleStatus;
  /** 気象海象データ */
  marineWeatherData: MarineWeatherData | undefined;
  /** 気象海象有義波高カラーマップ */
  marineWeatherSigHeightColorMap: string | undefined;
  /** 気象海象うねり高さカラーマップ */
  marineWeatherSwellHeightColorMap: string | undefined;
  /** 気象海象うねり周期カラーマップ */
  marineWeatherSwellPeriodColorMap: string | undefined;
}

export interface MarineWeatherBundle2 {
  /** キー */
  key: string;
  /** 時間 */
  hour: number;
  /** 気象海象データリスト */
  weathers: MarineWeather[];
  /** 有義波高カラーマップ */
  sigHeihtM: string;
  /** うねり高さカラーマップ */
  swellHeightM: string;
  /** うねり周期カラーマップ */
  swellPeriodSecs: string;
}

/**
 * 海域データモデル
 */
export interface MarineAriaData {
  /** 緯度 */
  latitude: number;
  /** 経度 */
  longitude: number;
  /** 海域エリアかどうか */
  isMarine: boolean;
}

export interface GoogleMapState {
  bounds: google.maps.LatLngBounds;
  zoom: number;
}

/**
 * ズームレベルに応じた間引き率 (1/x)
 */
export const weatherAreaZoomThinningRate = [
  64, // 0
  64, // 1
  64, // 2
  64, // 3
  64, // 4
  64, // 5
  64, // 6
  32, // 7
  16, // 8
  8, // 9
  4, // 10
  2, // 11
  2, // 12
  1, // 13
  1, // 14
  1, // 15
  1, // 16
  1, // 17
  1, // 18
  1, // 19
  1, // 20
  1, // 21
  1, // 22
  1, // 23
  1, // 24
  1, // 25
];

/**
 * 最も近い測定点の気象海象情報を検索する。
 * @param areaData 海域エリアデータ
 * @param weathers 気象海象情報配列
 * @returns 最も近い測定点の気象海象情報
 */
export function findNearWeather(
  areaData: MarineAriaData,
  weathers: MarineWeather[]
): MarineWeather | undefined {
  const x2 = areaData.latitude;
  const y2 = areaData.longitude;
  let min = Number.MAX_SAFE_INTEGER;
  let minIndex = -1;
  for (let i = 0; i < weathers.length; i++) {
    const dist = Math.sqrt(
      Math.pow(x2 - weathers[i].latitude, 2) + Math.pow(y2 - weathers[i].longitude, 2)
    );
    if (min > dist) {
      min = dist;
      minIndex = i;
    }
  }

  return minIndex !== -1 ? weathers[minIndex] : undefined;
}

/**
 * 風速を色に変換する。
 * @param speed 風速
 * @returns 色
 */
export function windSpeedToColor(speed: number): string {
  const index = Math.floor(speed / 3);
  if (index < 0) {
    return colors.marineWeather.windSpeed[0];
  } else if (index >= colors.marineWeather.windSpeed.length) {
    return colors.marineWeather.windSpeed[colors.marineWeather.windSpeed.length - 1];
  } else {
    return colors.marineWeather.windSpeed[index];
  }
}

export function generateMarkerDataList(
  mapState: GoogleMapState,
  marineAriaDataMatrix: MarineAriaData[][],
  marineWeathers: MarineWeather[]
): MarineWeatherMarkerData[] {
  // AppLogger.debug('mapState.zoom =' + mapState.zoom);
  const coef = weatherAreaZoomThinningRate[mapState.zoom];
  // AppLogger.debug('  coef =' + coef);
  const northEast = mapState.bounds.getNorthEast();
  const southWest = mapState.bounds.getSouthWest();
  let northEastLng = northEast.lng();
  const northEastLat = northEast.lat();
  const southWestLng = southWest.lng();
  const southWestLat = southWest.lat();
  if (northEastLng < 0) {
    northEastLng += 360;
  }

  const thinnedWindInfoList: MarineWeatherMarkerData[] = [];
  for (let i = coef; i < marineAriaDataMatrix.length; i += coef) {
    const latAriaData = marineAriaDataMatrix[i];
    if (northEastLat > latAriaData[0].latitude && southWestLat < latAriaData[0].latitude) {
      for (let j = coef; j < latAriaData.length; j += coef) {
        if (
          latAriaData[j].isMarine &&
          northEastLng > latAriaData[j].longitude &&
          southWestLng < latAriaData[j].longitude
        ) {
          // 最も近い測定点のデータを採用
          const weather = findNearWeather(latAriaData[j], marineWeathers);
          if (weather != null) {
            thinnedWindInfoList.push({
              ...weather,
              latitude: latAriaData[j].latitude,
              longitude: latAriaData[j].longitude,
              color: windSpeedToColor(weather.windSpeed),
            });
          }
        }
      }
    }
  }

  // AppLogger.debug('generateMarkerDataList count=' + thinnedWindInfoList.length);

  return thinnedWindInfoList;
}
