import { clearCommon } from 'app/commonSlice';
import { clearAppBar } from 'features/appBar/mainAppBarSlice';
import { clearAdminSettings } from 'features/contents/adminSettings/adminSettingsSlice';
import { clearAnalysis } from 'features/contents/analysis/analysisSlice';
import { clearAnalysisSearch } from 'features/contents/analysis/search/searchSlice';
import { clearDiagnosis } from 'features/contents/dashboard/diagnosis/diagnosisSlice';
import { clearMaintenance } from 'features/contents/dashboard/maintenance/maintenanceSlice';
import { clearReport } from 'features/contents/dashboard/report/reportSlice';
import { clearGeneralSettings } from 'features/contents/generalSettings/generalSettingsSlice';
import { clearShipStatePastDataTrendChart } from 'features/contents/shipState/pastData/chart/trend/trendChartSlice';
import { clearShipStatePastDataMap } from 'features/contents/shipState/pastData/map/mapSlice';
import { clearShipStatePastData } from 'features/contents/shipState/pastData/pastDataSlice';
import { clearShipStateSearch } from 'features/contents/shipState/pastData/search/searchSlice';
import { clearShipStatePastDataTimeSlider } from 'features/contents/shipState/pastData/timeSlider/timeSliderSlice';
import { clearShipStateRealtimeAisDataMap } from 'features/contents/shipState/realtimeAisData/map/mapSlice';
import { clearShipStateRealtimeAisData } from 'features/contents/shipState/realtimeAisData/realtimeAisDataSlice';
import { clearSystemSettings } from 'features/contents/systemSettings/systemSettingsSlice';
import { clearMainDrawer } from 'features/drawer/mainDrawerSlice';
import { signOut } from 'features/login/accountSlice';
import { clearAccountInformation } from 'features/main/accountInformation/accountInformationSlice';
import { Dispatch } from 'react';
import { NavigateFunction } from 'react-router-dom';

/**
 * 各種情報をClearしてSignOutする。
 * @param dispatch dispatcher
 * @param history ブラウザの履歴
 */
export const allClearAndSignOut = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  homePath: string | undefined,
  navigate: NavigateFunction
): void => {
  const event = new Event('forceShutdown');
  document.dispatchEvent(event);

  if (homePath) {
    navigate('/' + homePath);
  } else {
    navigate('/');
  }
  dispatch(clearCommon());
  dispatch(clearDiagnosis());
  dispatch(clearMaintenance());
  dispatch(clearReport());
  dispatch(clearShipStateSearch());
  dispatch(clearShipStatePastDataTimeSlider());
  dispatch(clearShipStatePastDataMap());
  dispatch(clearShipStatePastData());
  dispatch(clearShipStatePastDataTrendChart());
  dispatch(clearShipStateRealtimeAisData());
  dispatch(clearShipStateRealtimeAisDataMap());
  dispatch(clearAnalysisSearch());
  dispatch(clearAnalysis());
  dispatch(clearGeneralSettings());
  dispatch(clearAdminSettings());
  dispatch(clearSystemSettings());
  dispatch(clearAccountInformation());
  dispatch(clearMainDrawer());
  dispatch(clearAppBar());
  dispatch(signOut());
};
