import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GoogleMap, GroundOverlay, useLoadScript } from '@react-google-maps/api';
import config from 'resources/config';
import { useDispatch } from 'react-redux';
import { setCenter, useRealtimeAisDataMapCenter } from './mapSlice';
import { ShipMarker } from './ShipMarker';
import { useCommonMarineAriaDataMatrix, useCommonShips } from 'app/commonSlice';
import { useRealtimeAisDataList, useRealtimeAisMarineWeatherBundle } from '../realtimeAisDataSlice';
import { Box, styled } from '@mui/material';
import { useMarineWeatherType } from 'features/appBar/mainAppBarSlice';
import {
  generateMarkerDataList,
  GoogleMapState,
  MarineWeatherMarkerData,
} from 'models/marineWeather';
import { useIntl } from 'react-intl';
import msgId from 'resources/intl';
import colors from 'resources/colors';
import constants from 'resources/constants';
import { MarineWeatherLegend } from '../../pastData/map/MarineWeatherLegend';
import { MarineWeatherPopupLabel } from '../../pastData/map/MarineWeatherPopupLabel';
import { MarineWeatherWindMarker } from '../../pastData/map/MarineWeatherWindMarker';
import { MarineWeatherSwellMarker } from '../../pastData/map/MarineWeatherSwellMarker';
import dimens from 'resources/dimens';

const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
});

const options: google.maps.MapOptions = {
  disableDefaultUI: true,
  scaleControl: true,
  scaleControlOptions: {
    style: 0, // google.maps.ScaleControlStyle.DEFAULT,
  },
  fullscreenControl: true,
  fullscreenControlOptions: {
    position: 5, // google.maps.ControlPosition.LEFT_TOP,
  },
  zoomControl: true,
  zoomControlOptions: {
    position: 4, // google.maps.ControlPosition.LEFT_CENTER,
  },
};

const mapContainerStyle = {
  height: '100%',
  width: '100%',
};

interface RealtimeMapContainerProps {
  width: number;
  defaultCenter: { lat: number; lng: number };
  defaultZoom: number;
}

export function RealtimeMapContainer(props: RealtimeMapContainerProps): JSX.Element {
  const { width, defaultCenter, defaultZoom } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const ships = useCommonShips();
  const marineAriaDataMatrix = useCommonMarineAriaDataMatrix();
  const aisDataList = useRealtimeAisDataList();
  const center = useRealtimeAisDataMapCenter();
  const marineWeatherType = useMarineWeatherType();
  const marineWeatherBundle = useRealtimeAisMarineWeatherBundle();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: config.googleMapKey,
  });
  const [googleMap, setGoogleMap] = useState<google.maps.Map | undefined>(undefined);
  const [mapState, setMapState] = useState<GoogleMapState | undefined>(undefined);
  const [focusMarkerData, setFocusMarkerData] = useState<MarineWeatherMarkerData | undefined>(
    undefined
  );
  const legendRef = useRef<HTMLDivElement>(null);
  let overlayBounds: google.maps.LatLngBounds | undefined = undefined;

  const updateMapState = (googleMap: google.maps.Map) => {
    if (googleMap != null) {
      const bounds = googleMap.getBounds();
      const zoom = googleMap.getZoom();
      if (bounds != null && zoom != null) {
        if (mapState?.bounds != bounds || mapState?.zoom != zoom) {
          setMapState({ bounds: bounds, zoom: zoom });
        }
      }
    }
  };

  const onMapLoad = useCallback((map) => {
    setGoogleMap(map);
    if (map != null) {
      const bounds = map.getBounds();
      const zoom = map.getZoom();
      if (bounds != null && zoom != null) {
        setMapState({ bounds: bounds, zoom: zoom });
      }
      if (legendRef.current) {
        map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].clear();
        map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(legendRef.current);
      }
    }
  }, []);

  const onMapIdle = () => {
    if (googleMap != null) {
      updateMapState(googleMap);
    }
  };

  useEffect(() => {
    if (center != null) {
      if ((isNaN(center.lat) || center.lat < 1) && (isNaN(center.lng) || center.lng < 1)) {
        googleMap?.setCenter({ lat: 0, lng: 0 });
        googleMap?.setZoom(1);
      } else {
        googleMap?.setCenter(center);
      }
      dispatch(setCenter(undefined));
    }
  }, [dispatch, googleMap, center]);

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  if (!isLoaded) {
    return <React.Fragment />;
  } else {
    // オーバーレイする画像の右上と左下の点
    overlayBounds = new google.maps.LatLngBounds(
      {
        lat: config.marineWeatherOverlayBounds.left - 0.0125,
        lng: config.marineWeatherOverlayBounds.top - 0.0125,
      },
      {
        lat: config.marineWeatherOverlayBounds.right - 0.0375,
        lng: config.marineWeatherOverlayBounds.bottom - 0.0375,
      }
    );
  }

  const handleHover = (markerData: MarineWeatherMarkerData) => {
    setFocusMarkerData(markerData);
  };

  const handleUnhover = () => {
    setFocusMarkerData(undefined);
  };

  return (
    <RootDiv>
      <GoogleMap
        id="realtime-ais-map"
        options={options}
        mapContainerStyle={mapContainerStyle}
        zoom={defaultZoom}
        center={defaultCenter}
        onLoad={onMapLoad}
        onIdle={onMapIdle}
      >
        {/** 風向風速マーカー */}
        {marineWeatherType === 'wind_dir_wind_speed' &&
          marineAriaDataMatrix != null &&
          mapState != null &&
          marineWeatherBundle != null && (
            <MarineWeatherWindMarker
              markerDataList={generateMarkerDataList(
                mapState,
                marineAriaDataMatrix,
                marineWeatherBundle.weathers
              )}
              zoom={mapState.zoom}
              onHover={handleHover}
              onUnhover={handleUnhover}
            />
          )}
        {marineWeatherType === 'sig_height' && marineWeatherBundle != null && (
          <GroundOverlay
            key={'ais_sig_height_overlay' + marineWeatherBundle.key}
            url={marineWeatherBundle.sigHeihtM}
            bounds={overlayBounds}
            opacity={0.8}
          />
        )}
        {/** うねり高さマーカー */}
        {marineWeatherType === 'swell_height_swell_dir' &&
          marineAriaDataMatrix != null &&
          mapState != null &&
          marineWeatherBundle != null && (
            <MarineWeatherSwellMarker
              markerDataList={generateMarkerDataList(
                mapState,
                marineAriaDataMatrix,
                marineWeatherBundle.weathers
              )}
              zoom={mapState.zoom}
              onHover={handleHover}
              onUnhover={handleUnhover}
            />
          )}
        {marineWeatherType === 'swell_height_swell_dir' && marineWeatherBundle != null && (
          <GroundOverlay
            key={'ais_swell_height_swell_dir_overlay' + marineWeatherBundle.key}
            url={marineWeatherBundle.swellHeightM}
            bounds={overlayBounds}
            opacity={0.8}
          />
        )}
        {/** うねり周期マーカー */}
        {marineWeatherType === 'swell_period_swell_dir' &&
          marineAriaDataMatrix != null &&
          mapState != null &&
          marineWeatherBundle != null && (
            <MarineWeatherSwellMarker
              markerDataList={generateMarkerDataList(
                mapState,
                marineAriaDataMatrix,
                marineWeatherBundle.weathers
              )}
              zoom={mapState.zoom}
              onHover={handleHover}
              onUnhover={handleUnhover}
            />
          )}
        {marineWeatherType === 'swell_period_swell_dir' && marineWeatherBundle != null && (
          <GroundOverlay
            key={'ais_swell_period_swell_dir_overlay' + marineWeatherBundle.key}
            url={marineWeatherBundle.swellPeriodSecs}
            bounds={overlayBounds}
            opacity={0.8}
          />
        )}
        {aisDataList &&
          aisDataList.map((aisData) => {
            return (
              <ShipMarker
                key={aisData.shipId}
                ship={ships?.find((x) => x.shipId === aisData.shipId)}
                aisData={aisData}
              />
            );
          })}

        <Box ref={legendRef} sx={{ pointerEvents: 'none' }}>
          <Box sx={{ position: 'relative', width: 300, height: 250 }}>
            {/** 風向風速 */}
            {width > dimens.marineWeather.popup.displayMinWidth &&
              marineWeatherType === 'wind_dir_wind_speed' &&
              focusMarkerData != null && (
                <MarineWeatherPopupLabel
                  label={
                    intl.formatMessage({ id: msgId.shipInfoWindSpeed }) +
                    ' : ' +
                    focusMarkerData.windSpeed +
                    'm/s ' +
                    intl.formatMessage({ id: msgId.shipInfoWindDirection }) +
                    ' : ' +
                    focusMarkerData.windDirection16Point
                  }
                  lat={focusMarkerData.latitude}
                  lng={focusMarkerData.longitude}
                />
              )}
            {width > dimens.marineWeather.legend.displayMinWidth &&
              marineWeatherType === 'wind_dir_wind_speed' && (
                <MarineWeatherLegend
                  legendColors={colors.marineWeather.windSpeed.slice(0).reverse()}
                  legendLabels={constants.marineWeather.legend.windSpeedLabels}
                  unit="[m/s]"
                />
              )}
            {/** 有義波高 */}
            {width > dimens.marineWeather.legend.displayMinWidth &&
              marineWeatherType === 'sig_height' && (
                <MarineWeatherLegend
                  legendColors={colors.marineWeather.swellHeight.slice(0).reverse()}
                  legendLabels={constants.marineWeather.legend.sigHeihgtLabels}
                  unit="[m]"
                />
              )}
            {/** うねりの高さ */}
            {width > dimens.marineWeather.popup.displayMinWidth &&
              marineWeatherType === 'swell_height_swell_dir' &&
              focusMarkerData != null && (
                <MarineWeatherPopupLabel
                  label={
                    intl.formatMessage({ id: msgId.swellHeight }) +
                    ' : ' +
                    focusMarkerData.swellHeight +
                    'm ' +
                    intl.formatMessage({ id: msgId.swellDir }) +
                    ' : ' +
                    focusMarkerData.swellDirection16Point
                  }
                  lat={focusMarkerData.latitude}
                  lng={focusMarkerData.longitude}
                />
              )}
            {width > dimens.marineWeather.legend.displayMinWidth &&
              marineWeatherType === 'swell_height_swell_dir' && (
                <MarineWeatherLegend
                  legendColors={colors.marineWeather.swellHeight.slice(0).reverse()}
                  legendLabels={constants.marineWeather.legend.swellHeightLabels}
                  unit="[m]"
                />
              )}
            {/** うねりの周期 */}
            {width > dimens.marineWeather.popup.displayMinWidth &&
              marineWeatherType === 'swell_period_swell_dir' &&
              focusMarkerData != null && (
                <MarineWeatherPopupLabel
                  label={
                    intl.formatMessage({ id: msgId.swellPeriod }) +
                    ' : ' +
                    focusMarkerData.swellPeriodSecs +
                    's ' +
                    intl.formatMessage({ id: msgId.swellDir }) +
                    ' : ' +
                    focusMarkerData.swellDirection16Point
                  }
                  lat={focusMarkerData.latitude}
                  lng={focusMarkerData.longitude}
                />
              )}
            {width > dimens.marineWeather.legend.displayMinWidth &&
              marineWeatherType === 'swell_period_swell_dir' && (
                <MarineWeatherLegend
                  legendColors={colors.marineWeather.swellPeriod.slice(0).reverse()}
                  legendLabels={constants.marineWeather.legend.swellPeriodLabels}
                  unit="[s]"
                />
              )}
          </Box>
        </Box>
      </GoogleMap>
    </RootDiv>
  );
}

RealtimeMapContainer.defaultProps = {
  defaultCenter: { lat: 0, lng: 0 },
  defaultZoom: 1,
};
