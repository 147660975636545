import React from 'react';
import { theme } from 'resources/theme';
import dimens from 'resources/dimens';
import colors from 'resources/colors';
import { Dialog, Grid, GridProps, styled } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import { MainAppBar } from 'features/appBar/MainAppBar';

const RootDiv = styled('div')({
  position: 'relative',
  width: `100%`,
  height: `100%`,
});

export const EditDialogContents = styled('div')({
  position: 'absolute',
  top: dimens.toolbar.height,
  overflow: 'hidden',
  height: `calc(100% - ${dimens.toolbar.height}px)`,
  width: '100%',
  minWidth: 0,
  background: colors.subDrawer.background,
  display: 'flex',
  alignItems: 'top',
  justifyContent: 'center',
});

export const EditDialogRootGrid = styled((props: GridProps) => (
  <Grid
    container
    wrap="nowrap"
    direction="column"
    justifyContent="center"
    alignItems="center"
    {...props}
  />
))({
  position: 'absolute',
  background: theme.palette.background.default,
  maxWidth: dimens.dialogContents.width,
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
});

export const EditDialogTitleGrid = styled((props: GridProps) => <Grid item {...props} />)({
  marginBottom: theme.spacing(4),
});

export const EditDialogWideInputFieldGrid = styled((props: GridProps) => <Grid item {...props} />)({
  marginBottom: theme.spacing(2),
});

export const EditDialogInputFieldGrid = styled((props: GridProps) => <Grid item {...props} />)({
  width: 360,
  marginBottom: theme.spacing(2),
  alignItems: 'flex-start',
});

export const EditDialogFooterButtonGrid = styled((props: GridProps) => <Grid item {...props} />)({
  marginTop: theme.spacing(2),
});

export const EditDialogMiniButtonGrid = styled((props: GridProps) => <Grid item {...props} />)({
  width: 360 + 32 + 32,
  justifyContent: 'end',
  display: 'flex',
});

interface EditDialogProps {
  id: string;
  open: boolean;
  title: string;
  children: React.ReactNode;
  onBackClick: () => void;
}

export function EditDialog(props: EditDialogProps): JSX.Element {
  const { id, open, title, children, onBackClick } = props;

  return (
    <Dialog fullScreen open={open} onClose={undefined} disableEnforceFocus>
      <RootDiv id={id}>
        <MainAppBar title={title} showSensorDataMenu={false} onBackClick={onBackClick} />
        {children}
      </RootDiv>
    </Dialog>
  );
}

export const EditDialogMiniDeleteButton = styled((props: ButtonProps) => (
  <Button variant="outlined" disableElevation {...props} />
))({
  marginLeft: theme.spacing(1),
  height: dimens.button.height,
  borderRadius: dimens.button.borderRadius,
  minWidth: 80,
  background: colors.white,
  color: theme.palette.error.main,
  border: `1px solid ${theme.palette.error.main}`,
  '&:hover': {
    color: theme.palette.error.dark,
  },
});

export const EditDialogMiniButton = styled((props: ButtonProps) => (
  <Button variant="outlined" disableElevation {...props} />
))({
  marginLeft: theme.spacing(1),
  height: dimens.button.height,
  background: colors.white,
  borderRadius: dimens.button.borderRadius,
  minWidth: 80,
});
