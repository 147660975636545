import { API, Storage } from 'aws-amplify';
import logger from 'utils/AppLogger';
import { API_NAME, readFileAsText } from './maricoApi';
import { Machine } from 'models/ships';
import { mapToSensorData } from './maricoApiData';
import { DiagnosisListItem, ShipDiagnosis } from 'models/diagnosis';
import { getErrorResult } from 'models/error';
import { SensorDataList } from 'models/data';

/**
 * 診断結果リスト取得
 */
export async function getDiagnosisListItemsAsync(): Promise<DiagnosisListItem[]> {
  try {
    const params = {
      headers: {},
      response: true,
    };
    const result = await API.get(API_NAME, '/v1/ships/diagnoses', params);
    const data = result.data as ShipDiagnosis[];
    let listItems: DiagnosisListItem[] = [];

    let listParentId = 0;
    let listId = 0;
    data.forEach((parent) => {
      listId++;
      listParentId = listId;
      const parentItem: DiagnosisListItem = {
        listId: listId,
        listParentId: 0,
        shipId: parent.shipId,
        shipName: parent.shipName,
        machineId: parent.machineId,
        machineName: parent.machineName,
        sensorId: parent.sensorId,
        sensorName: parent.sensorName,
        status: parent.status,
        count: parent.diagnoses.length,
        startDate: parent.diagnoses[0].startDate,
        endDate: undefined,
        maintenanceAlert: parent.diagnoses[0].maintenanceAlert,
        inMaintenance: parent.diagnoses[0].inMaintenance,
        diagnosisId: 0,
      };
      listItems.push(parentItem);

      parent.diagnoses.forEach((child) => {
        listId++;
        const childItem: DiagnosisListItem = {
          listId: listId,
          listParentId: listParentId,
          diagnosisId: child.diagnosisId,
          shipId: parent.shipId,
          shipName: parent.shipName,
          machineId: parent.machineId,
          machineName: parent.machineName,
          sensorId: parent.sensorId,
          sensorName: parent.sensorName,
          status: parent.status,
          count: parent.diagnoses.length,
          startDate: child.startDate,
          endDate: child.endDate,
          maintenanceAlert: child.maintenanceAlert,
          inMaintenance: child.inMaintenance,
        };
        listItems.push(childItem);
      });
    });

    listItems = listItems.sort((a, b) => {
      if (a.startDate > b.startDate) {
        return -1;
      } else if (a.startDate < b.startDate) {
        return 1;
      } else {
        return 0;
      }
    });

    return listItems;
  } catch (error) {
    logger.error('error getDiagnosisListItems: ', error);
    throw getErrorResult(error);
  }
}

/**
 * 診断結果IDに対応するセンサーデータを取得する。
 * @param machine 機械
 * @param diagnosisListItem 診断結果
 */
export async function getDiagnosisDataAsync(
  machine: Machine,
  diagnosisListItem: DiagnosisListItem
): Promise<SensorDataList> {
  try {
    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        diagnosisId: diagnosisListItem.diagnosisId,
      },
      response: true,
      timeout: 30000,
    };
    const result = await API.post(API_NAME, '/v1/data/diagnoses', params);

    const { key, level, identityId } = result.data;
    const data = (await Storage.get(key, {
      level,
      identityId,
      download: true,
    })) as { Body: Blob };
    const csv = await readFileAsText(data.Body);

    return mapToSensorData(machine, diagnosisListItem, csv, 100, undefined);
  } catch (error) {
    logger.error('error getDiagnosisData: ', error);
    throw getErrorResult(error);
  }
}
