import { API } from 'aws-amplify';
import { API_NAME } from './maricoApi';
import AppLogger from 'utils/AppLogger';
import { getErrorResult } from 'models/error';
import { Account, UpdateAccount } from 'models/accounts';
import dayjs from 'dayjs';
import constants from 'resources/constants';

const API_UNAUTH_NAME = 'maricoapiv1unauth';

/**
 * アカウント情報取得
 */
export async function getAccountAsync(): Promise<Account> {
  try {
    const params = {
      headers: {},
      response: true,
    };
    const result = await API.get(API_NAME, '/v1/accounts/self', params);

    return result.data;
  } catch (error) {
    AppLogger.error('error getAccountAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * アカウントのパスワードリセットを要求する。
 * @param cognitoAccountId CognitoアカウントID
 */
export async function resetPasswordAsync(cognitoAccountId: string): Promise<boolean> {
  try {
    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        cognitoAccountId: cognitoAccountId,
      },
      response: true,
    };

    await API.post(API_UNAUTH_NAME, '/v1/unauth/accounts/reset-password', params);

    return true;
  } catch (error) {
    AppLogger.error('error resetPasswordAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * アカウントのパスワードをリセットを確定する。
 * @param cognitoAccountId CognitoアカウントID
 * @param authCode 認証コード
 * @param newPassword パスワード
 */
export async function confirmResetPasswordAsync(
  cognitoAccountId: string,
  authCode: string,
  newPassword: string
): Promise<boolean> {
  try {
    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        cognitoAccountId: cognitoAccountId,
        authCode: authCode,
        newPassword: newPassword,
      },
      response: true,
    };

    await API.post(API_UNAUTH_NAME, '/v1/unauth/accounts/confirm-reset-password', params);

    return true;
  } catch (error) {
    AppLogger.error('error confirmResetPasswordAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * アカウントのパスワードを変更する。
 * @param cognitoAccountId CognitoアカウントID
 * @param authCode 認証コード
 * @param newPassword パスワード
 */
export async function changePasswordAsync(
  cognitoAccountId: string,
  nowPassword: string,
  newPassword: string
): Promise<boolean> {
  try {
    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        cognitoAccountId: cognitoAccountId,
        password: nowPassword,
        newPassword: newPassword,
      },
      response: true,
    };

    await API.post(API_UNAUTH_NAME, '/v1/unauth/accounts/change-password', params);

    return true;
  } catch (error) {
    AppLogger.error('error changePasswordAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * アカウントのパスワードを変更する。
 * @param accessToken アクセストークン
 * @param nowPassword 現在のパスワード
 * @param newPassword 新しいパスワード
 */
export async function accountsChangePasswordAsync(
  accessToken: string,
  nowPassword: string,
  newPassword: string
): Promise<boolean> {
  try {
    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        accessToken: accessToken,
        password: nowPassword,
        newPassword: newPassword,
      },
      response: true,
    };

    await API.post(API_NAME, '/v1/accounts/change-password', params);

    return true;
  } catch (error) {
    AppLogger.error('error accountsChangePasswordAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * アカウントのメールアドレスを変更する。
 * @param mailAddress 新しいメールアドレス
 */
export async function changeMailAddressAsync(
  accessToken: string,
  mailAddress: string
): Promise<boolean> {
  try {
    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        accessToken: accessToken,
        mailAddress: mailAddress,
      },
      response: true,
    };

    await API.post(API_NAME, '/v1/accounts/change-mail-address', params);

    return true;
  } catch (error) {
    AppLogger.error('error changeMailAddressAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * アカウントのメールアドレス変更を確定する。
 * @param authCode 認証コード
 * @param mailAddress 新しいメールアドレス
 */
export async function confirmChangeMailAddressAsync(
  accessToken: string,
  authCode: string
): Promise<boolean> {
  try {
    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        accessToken: accessToken,
        authCode: authCode,
      },
      response: true,
    };

    await API.post(API_NAME, '/v1/accounts/confirm-change-mail-address', params);

    return true;
  } catch (error) {
    AppLogger.error('error confirmChangeMailAddressAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * アカウントのロゴを取得する。
 * @param accountGroupUrl アカウントグループURL
 */
export async function getAccountGroupsLogoAsync(accountGroupUrl: string): Promise<string> {
  try {
    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: {},
      response: true,
    };

    const result = await API.get(
      API_UNAUTH_NAME,
      '/v1/unauth/account-groups/logos/' + accountGroupUrl,
      params
    );

    return 'data:image/png;base64,' + result.data;
  } catch (error) {
    AppLogger.error('error getAccountGroupsLogoAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * アカウント情報リストを取得する。
 */
export async function getAccountsAsync(): Promise<Account[]> {
  try {
    const params = {
      headers: {},
      response: true,
    };

    const result = await API.get(API_NAME, '/v1/accounts', params);
    const accounts = result.data as Account[];

    return accounts.map((x) => {
      return {
        accountId: x.accountId,
        cognitoAccountId: x.cognitoAccountId,
        authorityId: x.authorityId,
        authorityName: x.authorityName,
        accountGroupId: x.accountGroupId,
        accountGroupName: x.accountGroupName,
        mailAddress: x.mailAddress,
        mailAddressVerified: x.mailAddressVerified,
        accountStatus: x.accountStatus,
        homePath: x.homePath,
        createdAt: dayjs(x.createdAt).format(constants.dateFormat.iso8601WithoutSeconds),
        updatedAt: dayjs(x.updatedAt).format(constants.dateFormat.iso8601WithoutSeconds),
      };
    });
  } catch (error) {
    AppLogger.error('error getAccountsAsync: ', error);
    throw getErrorResult(error);
  }
}

/**
 * アカウント情報を更新する。
 * @param accountId アカウントID
 * @param updateAccount アカウント更新情報
 * @returns 更新されたアカウント情報
 */
export async function updateAccountAsync(
  accountId: number,
  updateAccount: UpdateAccount
): Promise<Account> {
  try {
    const params = {
      headers: {},
      response: true,
      body: updateAccount,
    };

    const result = await API.put(API_NAME, '/v1/accounts/' + accountId, params);

    return result.data;
  } catch (error) {
    AppLogger.error('error updateAccountAsync: ', error);
    throw getErrorResult(error);
  }
}
